import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogContent,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import ServiceArea from "./ServiceArea";
import ServiceAreaListItem from "./ServiceAreaListItem";

const ServiceAreasList = ({
  employeeData = {},
  employeeId = "",
  titleVariant = "h5",
}) => {
  const { areas = {} } = employeeData; // Ensure fallback to an empty object
  const { openDrawer } = useDrawer();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleItemClick = (areaKey) => {
    openDrawer(ServiceArea, {
      title: `Edit ${areas[areaKey].city} area`,
      areaKey,
      areas,
      employeeId,
      disableSwipeToClose: true,
    });
  };

  const handleAddServiceArea = () => {
    openDrawer(ServiceArea, {
      title: `Add new area`,
      areas,
      employeeId,
      disableSwipeToClose: true,
    });
  };

  console.log("areas: ", areas);
  console.log("employeeData: ", employeeData);

  return (
    <DialogContent
      sx={{
        // width: { xs: "unset", sm: "unset" }
        px: { xs: 1, sm: 2 },
        pt: { xs: 1, sm: "unset" },
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            position: "absolute",
            right: "12px",
            top: "12px",
            ml: "auto",
            mr: "auto",
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={handleAddServiceArea}
            sx={{
              width: "100%",
              borderRadius: "10px",
              height: "56px",
              textTransform: "none",
            }}
            startIcon={<Add />}
          >
            New
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            variant={titleVariant}
            fontWeight={"700"}
          >
            Service Areas
          </Typography>
          <Button
            variant="text"
            onClick={handleAddServiceArea}
            sx={{ textTransform: "none" }}
          >
            New
          </Button>
        </Box>
      )}
      <List disablePadding>
        {Object.entries(areas).map(([key, area]) => (
          <ServiceAreaListItem
            key={key}
            areaKey={key}
            area={area}
            handleItemClick={handleItemClick}
            employeeData={employeeData}
          />
        ))}
        {Object.keys(areas).length === 0 && (
          <Typography
            align="center"
            sx={{
              p: 4,
              m: 4,
              color: "text.secondary",
              // mx: "auto",
              border: "1px solid rgba(0,0,0,0.23)",
              borderRadius: "15px",
            }}
          >
            Add a service area
          </Typography>
        )}
      </List>
    </DialogContent>
  );
};

export default ServiceAreasList;
