import { Whatshot } from "@mui/icons-material";
import { Box, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import { activeBoosts, calculateRemainingDays } from "./Boosts";
import { renderIcon } from "./ProgressTab"; // Using your existing renderIcon function
import { renderLevel } from "./StreaksV2";

const MultiplierDialog = ({
  onClose,
  selectedKey = "acceptedVisits",
  streaks,
  boosts,
  map,
  open,
}) => {
  // Retrieve the corresponding map data for the selectedKey
  const mapData = map[selectedKey];

  if (!mapData) {
    return null; // Handle the case where the selectedKey is not found in the map
  }

  // Determine if the selected key is a streak or a boost based on the map type
  const isStreak = mapData.type === "streaks";
  const isBoost = mapData.type === "boosts";

  // Fetch data based on type from streaks or boosts
  const data = isStreak
    ? streaks?.[selectedKey]
    : isBoost
    ? boosts?.[selectedKey]
    : null;

  // Calculate the current level for streaks, if applicable
  const level = isStreak
    ? Math.min(Math.floor((data?.count || 0) / mapData.threshold), 5) // Cap the level at 5
    : 0;

  // For boosts, add created and totalDuration data
  const additionalData = isBoost
    ? {
        created: data?.created ? data.created : null, // Assuming Firebase Timestamp is used
        totalDuration: mapData.totalDuration || "Indefinite",
      }
    : {};

  // Calculate remaining days for active boosts
  const remainingDays = calculateRemainingDays(
    data?.created,
    mapData?.totalDuration
  );

  // Determine if the boost is active
  const activeBoost = activeBoosts(
    additionalData.created,
    mapData.totalDuration
  );

  return (
    <ResponsiveDialog
      title={`${isStreak ? "Streak" : "Boost"} details`}
      width={"450px"}
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      anchor="bottom"
    >
      <>
        <DialogTitle>
          {/* Render the icon and the title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
              width: "auto",
              height: "auto",
            }}
          >
            {renderIcon({
              key: selectedKey,
              glow: true,
            })}
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {mapData?.description || selectedKey}
            </Typography>
            <Typography variant="body1">
              {mapData?.body || "No description available."}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          {isStreak && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#e0e0e0",
                  px: 1,
                  py: 0.5,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mr: "4px" }}>Tier:</Typography>
                  <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                    {level}/5
                  </Typography>
                  {renderLevel(level, mapData.color)}
                </Box>

                {level === 5 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ mr: "4px" }}>Maximum tier</Typography>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#e0e0e0",
                  px: 1,
                  py: 0.5,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mr: "4px" }}>Streak:</Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {data?.count || 0} <Whatshot sx={{ color: "#e6594c" }} />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {level < 5 && (
                    <>
                      <Typography sx={{ mr: "4px" }}>Next tier at:</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {mapData.threshold * level + mapData.threshold}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </>
          )}
          {isStreak && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#e0e0e0",
                px: 1,
                py: 0.5,
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ mr: "4px" }}>Points per visit: </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {level > 0
                    ? `+${(
                        mapData.multiplier +
                        level * mapData.increment
                      ).toFixed(0)}`
                    : "0"}{" "}
                  pts
                </Typography>
              </Box>
              {level < 5 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mr: "4px" }}>Next tier points per visit:</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    +
                    {(
                      mapData?.increment * (level + (level === 0 ? 0 : 1)) +
                      mapData?.multiplier
                    ).toFixed(0)}{" "}
                    pts
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {isBoost && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#e0e0e0",
                px: 1,
                py: 0.5,
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ mr: "4px" }}>Points per visit: </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  +{mapData.multiplier.toFixed(0)}{" "}pts
                </Typography>
              </Box>
              {activeBoost && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mr: "4px" }}>Boost remaining:</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {remainingDays !== null
                      ? `${remainingDays} ${remainingDays > 1 ? "days" : "day"}`
                      : "∞"}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {isBoost && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#e0e0e0",
                px: 1,
                py: 0.5,
                borderRadius: "5px",
              }}
            >
              {remainingDays !== null ||
                (!activeBoost && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ mr: "4px" }}>Boost duration:</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {additionalData.totalDuration === "Indefinite"
                        ? "∞"
                        : `${additionalData.totalDuration} days`}
                    </Typography>
                  </Box>
                ))}
              {activeBoost && additionalData?.created !== null && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mr: "4px" }}>Created on:</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {additionalData?.created
                        ? additionalData.created
                            .toDate()
                            .toLocaleDateString("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })
                        : "Unknown"}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {mapData?.cancelCriteria && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                px: 1,
                py: 0.5,
                borderRadius: "5px",
              }}
            >
              <Typography sx={{ mr: "4px" }}>Cancel criteria:</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {mapData.cancelCriteria}
              </Typography>
            </Box>
          )}
        </DialogContent>
      </>
    </ResponsiveDialog>
  );
};

export default MultiplierDialog;
