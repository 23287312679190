import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from "react";
import { formatTimestamp } from "../../../services/dateServices";

// ListLogs Component
const ListLogs = ({ logs }) => {
  // State to manage the expanded/collapsed logs
  const [expandedLogs, setExpandedLogs] = useState({});

  // Toggles the expanded state of a log based on its ID
  const toggleExpand = (logId) => {
    setExpandedLogs((prev) => ({
      ...prev,
      [logId]: !prev[logId],
    }));
  };

  return (
    <List sx={{pb: 0}}>
      {logs.map((log, index) => {
        const longDateFormat = moment(log.created.toDate()).format(
          "MMMM D, YYYY h:mm A"
        );
        const isExpandable = log.logType === "communication";
        const isExpanded = expandedLogs[log.id] || false;

        return (
          <React.Fragment key={log.id}>
            <ListItemButton
              onClick={() => isExpandable && toggleExpand(log.id)}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                pl: 3,
                pr: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/* Only show the arrow if the log is expandable */}
                {isExpandable && (
                  <ExpandMore
                  color="primary"
                    sx={{
                      transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)", // Rotate based on expanded state
                      transition: "transform 0.1s ease", // Smooth transition for rotation
                      position: "absolute",
                      left: "0px",
                    }}
                  />
                )}
                <Typography variant="body2">{log.summary}</Typography>
                <Tooltip
                  title={longDateFormat}
                  arrow
                  placement="left"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        borderRadius: "4px",
                      },
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      whiteSpace: "nowrap",
                      marginLeft: 1,
                    }}
                  >
                    {formatTimestamp(log.created)}
                  </Typography>
                </Tooltip>
              </Box>

              {/* Conditionally render expanded content for "communication" type logs */}
              {isExpandable && isExpanded && (
                <Box sx={{ py: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    {log?.communication?.description ||
                      "No additional information available."}
                  </Typography>
                </Box>
              )}
            </ListItemButton>

            {index < logs.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default ListLogs;
