import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";

import { db } from "../../../firebase";
import PhoneField from "../../fields/PhoneField";

export const validatePhone = (editPhone) => {
  let errors = {};

  if (!editPhone || editPhone.replace(/\D/g, "").length !== 10)
    errors.phone = "Your phone number must be 10 digits.";
  return errors;
};

const EditPhone2 = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  phone,
  memberId,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [editPhone, setEditPhone] = useState(phone);

  const handleChange = (e) => {
    setEditPhone(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    let newError = validatePhone(editPhone);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "membersPrivate", memberId),
        {
          phone2: editPhone.replace(/\D/g, ""),
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Secondary Phone
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          This number will be used as a back-up.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <PhoneField
            phone={editPhone}
            error={errors.phone}
            helperText={
              "Your phone number is private and is only shared with your helper when you book a visit"
            }
            handleChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Save</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default EditPhone2;
