import { Add, Close, Delete, Edit, Help } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { db } from "../../firebase";
import { useDrawer } from "../../providers/DrawerProvider";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import IncrementerField from "../fields/IncrementerField";
import ServiceArea from "../serviceArea/ServiceArea";

const sampleAreas = {
  "area-1": {
    bounds: [
      { lat: 47.6588, lng: -117.426 },
      { lat: 47.659, lng: -117.427 },
    ],
    city: "College at Gonzaga",
    city: "Spokane",
    state: "WA",
    windows: {
      window1: {
        start: "2024-01-15",
        end: "2024-05-30",
        weeklyAvailability: 10,
      },
      window2: {
        start: "2024-06-01",
        end: "2024-08-15",
        weeklyAvailability: 15,
      },
    },
  },
  "area-2": {
    bounds: [
      { lat: 48.5126, lng: -122.6127 },
      { lat: 48.513, lng: -122.613 },
    ],
    city: "Home in Anacortes",
    city: "Anacortes",
    state: "WA",
    windows: {
      window1: {
        start: "2024-09-01",
        end: "2024-12-31",
        weeklyAvailability: 20,
      },
    },
  },
};

const EmployeeTimeline = ({
  employeeId,
  areas = {},
  canEdit = true,
  showHelp = true,
}) => {
  const { openDrawer } = useDrawer();
  const [localAreas, setLocalAreas] = useState({});
  const [timelineRows, setTimelineRows] = useState([]);
  const [editsMade, setEditsMade] = useState(false);
  const [editRow, setEditRow] = useState(null); // ID of row being edited
  const [menuAnchor, setMenuAnchor] = useState({ element: null, id: null });
  const [loading, setLoading] = useState(false);
  const [editAdd, setEditAdd] = useState("Edit");
  const [dismissedAlerts, setDismissedAlerts] = useState({});
  const [areaColors, setAreaColors] = useState({});

  const timeZoneId = "America/Los_Angeles"; // just a universal that they all adhere to

  useEffect(() => {
    const colors = [
      "hsl(210, 100%, 30%)",
      "hsl(190, 100%, 30%)",
      "hsl(150, 100%, 30%)",
      "hsl(300, 100%, 30%)",
      "hsl(260, 100%, 30%)",
      "hsl(280, 100%, 30%)",
      "hsl(230, 100%, 30%)",
    ];
    let colorIndex = 0;

    const newAreaColors = {};

    Object.keys(areas || {}).forEach((areaKey) => {
      const areaColor = colors[colorIndex % colors.length];
      newAreaColors[areaKey] = areaColor;
      colorIndex++;
    });

    setAreaColors(newAreaColors);
  }, [areas]);

  useEffect(() => {
    // Load dismissed alerts from localStorage on component mount
    const storedAlerts = JSON.parse(
      localStorage.getItem("dismissedAlerts") || "{}"
    );
    setDismissedAlerts(storedAlerts);
  }, []);

  const dismissAlert = (alertType) => {
    const areaKey = timelineRows[timelineRows.length - 1]?.areaKey;
    const startDate = timelineRows[timelineRows.length - 1]?.startDate;

    const key = `${areaKey}_${startDate}_${alertType}`;

    const updatedAlerts = { ...dismissedAlerts, [key]: true };
    setDismissedAlerts(updatedAlerts);

    // Store updated alerts in localStorage
    localStorage.setItem("dismissedAlerts", JSON.stringify(updatedAlerts));
  };

  const shouldShowAlert = (alertType) => {
    const areaKey = timelineRows[timelineRows.length - 1]?.areaKey;
    const startDate = timelineRows[timelineRows.length - 1]?.startDate;

    const key = `${areaKey}_${startDate}_${alertType}`;

    console.log("!dismissedAlerts[key]: ", !dismissedAlerts[key]);

    return !dismissedAlerts[key];
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    console.log("RE-RENDERING");
  }, []);

  useEffect(() => {
    if (Object.keys(areas).length > 0) {
      // Initialize areas and map windows using their existing keys
      const updatedAreas = { ...areas };
      const rows = [];

      // Preserve the edited row if it exists
      const editedRow = timelineRows.find((row) => row.id === editRow);

      Object.keys(updatedAreas).forEach((areaKey) => {
        const area = updatedAreas[areaKey];
        if (area.windows) {
          Object.entries(area.windows).forEach(([windowKey, window]) => {
            rows.push({
              id: windowKey, // Use the existing windowKey
              areaKey,
              windowKey, // Retain the original windowKey
              city: area.city,
              lat: area.lat,
              lng: area.lng,
              startDate: window.start
                ? window.start instanceof Timestamp
                  ? DateTime.fromJSDate(window.start.toDate(), {
                      zone: timeZoneId,
                    }) // Convert Timestamp to DateTime
                  : DateTime.fromISO(window.start, { zone: timeZoneId }) // Handle ISO strings for backward compatibility
                : null,
              weeklyAvailability: window.weeklyAvailability || 0,
              originalAreaKey: areaKey, // Track original keys for deletion
              originalWindowKey: windowKey,
            });
          });
        }
      });

      if (editedRow) {
        const updatedEditedRow = rows.find((row) => row.id === editRow);
        if (!updatedEditedRow) {
          console.log("pushed the edit row back");

          rows.push(editedRow); // Add the edited row back if missing
        }
      }

      setLocalAreas(updatedAreas);
      setTimelineRows(rows.sort((a, b) => a.startDate - b.startDate));
    }
  }, [areas]);

  const derivedAreas = (() => {
    const uniqueAreas = new Map();
    Object.entries(areas).forEach(([areaKey, area]) => {
      if (area.city !== "Unknown Area" && !uniqueAreas.has(areaKey)) {
        uniqueAreas.set(areaKey, {
          key: areaKey,
          city: area.city,
        });
      }
    });
    return Array.from(uniqueAreas.values());
  })();

  const handleRowChange = (id, field, value, name = "") => {
    if (!canEdit) return;
    setLoading(true);

    setEditsMade(true);

    setTimelineRows((prev) =>
      prev.map((row) => {
        // We only edit the row we have open
        if (row.id === id) {
          console.log("row.id === id: ", [row.id, id]);

          // If changing the Service area, update areaKey and city
          if (field === "areaKey") {
            console.log("updated the areaKey");
            console.log("Name: ", name);

            return {
              ...row,
              [field]: value,
              city: name ? name : derivedAreas[value]?.city || "Unknown area",
            };
          }
          return { ...row, [field]: value };
        }
        return row;
      })
    );

    setLoading(false);
  };

  const handleAddRow = () => {
    if (!canEdit) return;
    setLoading(true);
    setEditAdd("Add");

    const defaultAreaKey = Object.keys(localAreas)[0]; // Default to the first area
    const id = uuid();

    // Find the last row to calculate the new row's start date
    const lastRow = timelineRows[timelineRows.length - 1];
    let newStartDate;

    if (lastRow?.startDate) {
      const proposedDate = lastRow.startDate.plus({ weeks: 1 }); // 1 week further
      newStartDate =
        proposedDate < DateTime.now().setZone(timeZoneId)
          ? DateTime.now().setZone(timeZoneId).plus({ weeks: 1 })
          : proposedDate; // Use today if in the past
    } else {
      newStartDate = DateTime.now().setZone(timeZoneId).plus({ weeks: 1 }); // Default to today if no rows exist
    }

    const newRow = {
      id,
      areaKey: lastRow?.areaKey || "",
      windowKey: uuid(), // Unique key for the new window
      city: localAreas[defaultAreaKey]?.city || "Unknown Area",
      startDate: newStartDate,
      weeklyAvailability: 10,
      originalAreaKey: null, // No original area for new rows
      originalWindowKey: null,
    };

    setTimelineRows((prev) => [...prev, newRow]);
    setEditRow(id);
    setEditsMade(true);
    setLoading(false);
  };

  const handleDeleteRow = async (id) => {
    if (!canEdit) return;
    setLoading(true);

    // Update the timelineRows state
    const updatedRows = timelineRows.filter((row) => row.id !== id);
    setTimelineRows(updatedRows);
    setMenuAnchor(null);

    // Save the updated data immediately
    const updatedAreas = Object.fromEntries(
      Object.entries(localAreas).map(([id, area]) => {
        const { key, ...rest } = area; // Exclude the key property
        return [id, rest];
      })
    );

    // Reset all windows in each area
    Object.keys(updatedAreas).forEach((key) => {
      updatedAreas[key].windows = {};
    });

    // Sort rows by startDate to calculate endDate
    const sortedRows = [...updatedRows].sort(
      (a, b) => a.startDate - b.startDate
    );

    // Calculate endDate and assign rows to areas
    sortedRows.forEach((row, index) => {
      const {
        id: windowKey,
        areaKey,
        startDate,
        weeklyAvailability,
        originalAreaKey,
        originalWindowKey,
      } = row;

      if (!areaKey || !updatedAreas[areaKey]) {
        console.error(`Invalid areaKey ${areaKey} for row:`, row);
        return;
      }

      // Compute endDate as the day before the next row's startDate
      let calculatedEndDate = null;
      if (index < sortedRows.length - 1) {
        const nextRow = sortedRows[index + 1];
        // if (nextRow.areaKey === areaKey) {
        calculatedEndDate = nextRow.startDate.minus({ days: 1 }).endOf("day");
        // }
      }

      // Remove old window if area or key has changed
      if (
        originalAreaKey &&
        (originalAreaKey !== areaKey || originalWindowKey !== windowKey)
      ) {
        delete updatedAreas[originalAreaKey]?.windows[originalWindowKey];
      }

      // Add or update the window in the new area
      if (!updatedAreas[areaKey].windows) {
        updatedAreas[areaKey].windows = {};
      }

      updatedAreas[areaKey].windows[windowKey] = {
        start: startDate
          ? Timestamp.fromDate(startDate.startOf("day").toJSDate())
          : null,
        end: calculatedEndDate
          ? Timestamp.fromDate(calculatedEndDate.toJSDate())
          : null,
        weeklyAvailability,
      };
    });

    try {
      await updateDoc(doc(db, "employees", employeeId), {
        areas: updatedAreas,
      });
      setLocalAreas(updatedAreas);
      setEditsMade(false);
      setEditRow(null); // Exit edit mode
    } catch (error) {
      console.error("Error saving timeline after deletion:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddServiceArea = () => {
    if (!canEdit) return;

    openDrawer(
      ServiceArea,
      { title: `Add new area`, areas, employeeId, disableSwipeToClose: true },
      (newServiceArea) => {
        console.log("newServiceArea callback --- ");
        // If the ServiceArea saves and passes a key in the callback
        if (newServiceArea.key) {
          console.log("newServiceArea.key: ", newServiceArea.key);
          const newKey = newServiceArea.key;
          const city = newServiceArea.city;

          // Update localAreas with the new service area
          setLocalAreas((prev) => ({
            ...prev,
            [newKey]: {
              ...newServiceArea,
            },
          }));

          handleRowChange(editRow, "areaKey", newKey, city);
        }
      }
    );
  };

  const handleSave = async () => {
    if (!canEdit) return;
    setLoading(true);

    // Save the updated data immediately
    const updatedAreas = Object.fromEntries(
      Object.entries(localAreas).map(([id, area]) => {
        const { key, ...rest } = area; // Exclude the key property
        return [id, rest];
      })
    );

    // Reset all windows in each area
    Object.keys(updatedAreas).forEach((key) => {
      updatedAreas[key].windows = {};
    });

    // Sort rows by startDate to calculate endDate
    const sortedRows = [...timelineRows].sort(
      (a, b) => a.startDate - b.startDate
    );

    // Calculate endDate and assign rows to areas
    sortedRows.forEach((row, index) => {
      const {
        id: windowKey,
        areaKey,
        startDate,
        weeklyAvailability,
        originalAreaKey,
        originalWindowKey,
      } = row;

      if (!areaKey || !updatedAreas[areaKey]) {
        console.error(`Invalid areaKey ${areaKey} for row:`, row);
        return;
      }

      // Compute endDate as the day before the next row's startDate
      let calculatedEndDate = null;
      if (index < sortedRows.length - 1) {
        const nextRow = sortedRows[index + 1];
        // if (nextRow.areaKey === areaKey) {
        calculatedEndDate = nextRow.startDate.minus({ days: 1 }).endOf("day");
        // }
      }

      // Remove old window if area or key has changed
      if (
        originalAreaKey &&
        (originalAreaKey !== areaKey || originalWindowKey !== windowKey)
      ) {
        delete updatedAreas[originalAreaKey]?.windows[originalWindowKey];
      }

      // Add or update the window in the new area
      if (!updatedAreas[areaKey].windows) {
        updatedAreas[areaKey].windows = {};
      }

      updatedAreas[areaKey].windows[windowKey] = {
        start: startDate
          ? Timestamp.fromDate(startDate.startOf("day").toJSDate())
          : null,
        end: calculatedEndDate
          ? Timestamp.fromDate(calculatedEndDate.toJSDate())
          : null,
        weeklyAvailability,
      };
    });

    try {
      await updateDoc(doc(db, "employees", employeeId), {
        areas: updatedAreas,
      });
      setLocalAreas(updatedAreas);
      setEditsMade(false);
      setEditRow(null); // Exit edit mode
    } catch (error) {
      console.error("Error saving timeline:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (!canEdit) return;
    setLoading(true);

    setEditRow(null); // Exit edit mode
    setEditsMade(false); // Reset the editsMade flag

    // Revert timelineRows to the original localAreas state
    const rows = [];
    Object.keys(localAreas).forEach((areaKey) => {
      const area = localAreas[areaKey];
      if (area.windows) {
        Object.entries(area.windows).forEach(([windowKey, window]) => {
          rows.push({
            id: windowKey,
            areaKey,
            windowKey,
            city: area.city,
            startDate: window.start
              ? DateTime.fromJSDate(window.start.toDate(), { zone: timeZoneId })
              : null,
            weeklyAvailability: window.weeklyAvailability || 0,
            originalAreaKey: areaKey,
            originalWindowKey: windowKey,
          });
        });
      }
    });
    setTimelineRows(rows.sort((a, b) => a.startDate - b.startDate));
    setLoading(false);
  };

  const handleEditRow = (id) => {
    if (!canEdit) return;
    setEditAdd("Edit");
    console.log("handleEditRow id: ", id);
    setEditRow(id); // Track row by UUID
  };

  console.log("localAreas:", localAreas);
  console.log("timelineRows:", timelineRows);
  console.log("editRow: ", editRow);
  console.log("Derived areas: ", derivedAreas);

  const renderEditWindow = (row) => {
    return (
      <>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              mr: 4,
            }}
          >
            <DatePicker
              label="Start Date"
              value={row?.startDate}
              onChange={(date) => handleRowChange(row?.id, "startDate", date)}
              inputFormat="MMM dd, yyyy" // Custom date format: Oct 10, 2024
              sx={{ width: { xs: "unset", sm: "150px" } }}
            />
            <TextField
              select
              label="Service area"
              value={
                row?.areaKey
                // derivedAreas.find((area) => area.key === row?.areaKey)
                //   ? row?.areaKey
                //   : ""
              }
              sx={{
                width: { xs: "100%", sm: "fit-content" },
                minWidth: "200px",
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "new") {
                  handleRowChange(row?.id, "areaKey", value); // Update the row's areaKey with the selected value
                }
              }}
            >
              {Object.entries(localAreas).map(([key, area]) => (
                <MenuItem key={key} value={key}>
                  {area.city}
                </MenuItem>
              ))}

              {/* Add New Service Area Option */}
              <MenuItem
                onClick={handleAddServiceArea} // Call function on click
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  cursor: "pointer",
                  pointerEvents: "auto", // Ensure it's interactive
                }}
                value="" // Prevent it from being selectable
              >
                <Add sx={{ marginRight: 1 }} />
                New service area
              </MenuItem>
            </TextField>

            <IncrementerField
              variant="outlined"
              value={row?.weeklyAvailability}
              onChange={(newValue) =>
                handleRowChange(row?.id, "weeklyAvailability", newValue)
              }
              label="Desired hours"
              min={0}
              max={40}
              endAdornment={"hrs"}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              position: { xs: "absolute", sm: "relative" },
              right: { xs: "4px", sm: "4px" },
              top: { xs: "24px", sm: "4px" },
              mr: "auto",
              textTransform: "none",
              color: "text.secondary",
            }}
            disabled={loading}
            onClick={() => {
              handleDeleteRow(row?.id);
            }}
            startIcon={<Delete />}
          >
            {!isMobile && "Delete"}
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", sm: "row" },
              gap: 2,
              ml: "auto",
              width: { xs: "100%", sm: "unset" },
            }}
          >
            <Button
              variant="text"
              color="primary"
              disabled={loading}
              onClick={handleCancel}
              sx={{
                width: { xs: "100%", sm: "150px" },
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!editsMade || loading}
              color="primary"
              onClick={handleSave}
              sx={{
                width: { xs: "100%", sm: "150px", height: "44px" },
                textTransform: "none",
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </DialogActions>
      </>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box sx={{ mx: 1, mb: 1 }}>
        {shouldShowAlert("return") &&
          timelineRows.length > 1 &&
          timelineRows[timelineRows.length - 1]?.areaKey !==
            timelineRows[timelineRows.length - 2]?.areaKey && (
            <Box
              sx={{
                mt: 1,
                p: 1,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "hsl(25, 100%, 95%)", // Softer warning background
              }}
            >
              {canEdit && (
                <IconButton
                  color="warning"
                  onClick={() => dismissAlert("return")}
                  sx={{ mr: 1 }}
                >
                  <Close />
                </IconButton>
              )}
              {!canEdit && <Help color="warning" sx={{ mr: 1 }} />}
              <Typography sx={{ flex: 1 }}>
                Will you be returning back to{" "}
                {localAreas[timelineRows[timelineRows.length - 2]?.areaKey]
                  ?.city || "Unknown Location"}
                ?
              </Typography>
              {canEdit && (
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={handleAddRow} // Add logic to return to the previous area
                  sx={{
                    textTransform: "none", // Remove uppercase styling
                    borderRadius: "5px",
                    ml: 1,
                  }}
                >
                  Yes
                </Button>
              )}
            </Box>
          )}
        {shouldShowAlert("break") &&
          timelineRows[timelineRows.length - 1]?.weeklyAvailability === 0 && (
            <Box
              sx={{
                mt: 1,
                p: 1,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "hsl(25, 100%, 95%)", // Softer warning background
              }}
            >
              {canEdit && (
                <IconButton
                  color="warning"
                  onClick={() => dismissAlert("break")}
                  sx={{ mr: 1 }}
                >
                  <Close />
                </IconButton>
              )}
              {!canEdit && <Help color="warning" sx={{ mr: 1 }} />}
              <Typography sx={{ flex: 1 }}>
                When will you be done with your break?
              </Typography>
              {canEdit && (
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={handleAddRow} // Add logic to return to the previous area
                  sx={{
                    textTransform: "none", // Remove uppercase styling
                    borderRadius: "5px",
                    ml: 1,
                  }}
                >
                  Add
                </Button>
              )}
            </Box>
          )}
      </Box>

      <DialogContent sx={{ pt: 1 }}>
        {/* Show status by area */}
        {/* <Button
          startIcon={showStatus ? <VisibilityOff /> : <Visibility />}
          sx={{ textTransform: "none" }}
          onClick={() => setShowStatus(!showStatus)}
        >
          {showStatus ? "Hide" : "View"} public status
        </Button>
        {Object.entries(localAreas).map(([areaKey, area]) => {
          const lat = area?.lat;
          const lng = area?.lng;

          const {
            inArea = false,
            futureAvailability = 0,
            end: serviceEnd = undefined,
            start: serviceStart = undefined,
          } = getEmployeeStatus(lat, lng, areas, availability);

          console.log("EmployeeTimeline - inArea: ", inArea);

          const statusString = getEmployeeServiceString(
            inArea,
            futureAvailability,
            bookingDaysInAdvance,
            isAvailableToday,
            serviceStart,
            serviceEnd
          );

          console.log("EmployeeTimeline - statusString: ", statusString);

          return (
            <Box>
              <Typography variant="body1" fontWeight={"700"}>{area.city}</Typography>
              <Typography variant="body1">{statusString}</Typography>
            </Box>
          );
        })} */}
        <Box display="flex">
          <Box sx={{ width: "100%" }}>
            {timelineRows.map((row, index) => {
              const now = DateTime.now().setZone(timeZoneId);
              const rowStartDate = row.startDate;
              const nextRowStartDate =
                index + 1 < timelineRows.length
                  ? timelineRows[index + 1].startDate
                  : null;

              // Check if this is the current row
              const isCurrentRow =
                rowStartDate <= now &&
                (!nextRowStartDate || nextRowStartDate > now);

              // Include all rows from the current onward
              const isFutureRow = rowStartDate >= now;

              if (!isCurrentRow && !isFutureRow) return null;

              const nextRow = timelineRows[index + 1];
              const calculatedEndDate = nextRow
                ? nextRow.startDate.minus({ days: 1 })
                : null;

              return (
                <Box
                  key={row.id}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  mb={2}
                  sx={{ position: "relative" }}
                >
                  {/* Custom Stepper: Dot and Line */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flexGrow: 0,
                      alignSelf: "stretch",
                      pt: "16px",
                    }}
                  >
                    {/* Filled Dot */}
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        backgroundColor:
                          row.weeklyAvailability > 0
                            ? areaColors[row?.areaKey]
                            : "rgba(128, 128, 128, 0.5)",
                        flexShrink: 0,
                      }}
                    />
                    {/* Line (except for the last item) */}
                    <Box
                      sx={{
                        width: "2px",
                        flexGrow: 1,
                        backgroundColor:
                          row.weeklyAvailability > 0
                            ? areaColors[row?.areaKey]
                            : "rgba(128, 128, 128, 0.5)",
                        mt: 0.5,
                        mb: "-26px",
                        display:
                          index === timelineRows.length - 1 ? "none" : "block",
                      }}
                    />
                  </Box>

                  {editRow === row.id && !isMobile ? (
                    <Card
                      variant="outlined"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        gap: 2,
                        flexGrow: 1,
                        ml: 1,
                        position: "relative",
                      }}
                    >
                      {renderEditWindow(row)}
                    </Card>
                  ) : (
                    <ListItemButton
                      onClick={() => handleEditRow(row.id)}
                      sx={{
                        p: 0,
                        cursor: !canEdit ? "default" : "pointer", // Optional: Change cursor style when disabled
                        "&:hover": !canEdit
                          ? {
                              backgroundColor: "transparent", // Remove hover background when disabled
                              pointerEvents: "none", // Disable hover interactions
                            }
                          : {}, // Allow normal hover styles when canEdit is true
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                          flexGrow: 1,
                          mt: 1,
                          mb: 1,
                          ml: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: {
                              xs: "space-between",
                              sm: "flex-start",
                            },
                          }}
                        >
                          <Typography variant="body1" fontWeight={"600"}>
                            {isCurrentRow
                              ? "Now"
                              : row.startDate?.toFormat("MMM d")}{" "}
                            -{" "}
                            {calculatedEndDate
                              ? calculatedEndDate.toFormat("MMM d")
                              : "Ongoing"}
                          </Typography>
                          <Typography
                            sx={{
                              px: "8px",
                              py: "4px",
                              backgroundColor:
                                row.weeklyAvailability > 0
                                  ? "hsla(190, 60%, 50%, 0.2)"
                                  : "hsla(190, 00%, 50%, 0.2)",
                              color:
                                row.weeklyAvailability > 0
                                  ? "hsla(190, 100%, 20%, 1)"
                                  : "hsla(190, 00%, 50%, 1)",
                              width: "fit-content",
                              borderRadius: "5px",
                              fontSize: "0.75rem",
                            }}
                          >
                            {row.weeklyAvailability > 0
                              ? `${row.weeklyAvailability} hrs/week`
                              : "Break"}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          display="flex"
                          alignItems="center"
                        >
                          {localAreas[row?.areaKey]?.city || "Unknown City"}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => handleEditRow(row.id)}
                        sx={{ display: canEdit ? "block" : "none" }}
                      >
                        <Edit />
                      </IconButton>
                    </ListItemButton>
                  )}
                </Box>
              );
            })}
            {timelineRows.length === 0 && (
              <Typography
                align="center"
                color="text.secondary"
                justifyContent={"center"}
                sx={{
                  px: 1,
                  py: 3,
                  my: 1,
                  mx: "auto",
                  borderRadius: "15px",
                  border: "1px solid rgba(0,0,0,0.23)",
                }}
              >
                Set your area
              </Typography>
            )}
            {canEdit && showHelp && (
              <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                <Typography
                  variant="caption"
                  align="center"
                  color="text.secondary"
                >
                  Set where you’ll be working and for how long. Your status is
                  used for booking, availability, and staffing.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      {/* Add Button Row */}
      {canEdit && (
        <Box
          sx={{
            position: { xs: "absolute", sm: "absolute" },
            right: { xs: "12px", sm: "12px" },
            top: { xs: "12px", sm: "10px" },
            ml: "auto",
            mr: "auto",
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={handleAddRow}
            sx={{
              width: "100%",
              borderRadius: "10px",
              height: "56px",
              textTransform: "none",
            }}
          >
            New
          </Button>
        </Box>
      )}
      {isMobile && (
        <ResponsiveDialog
          open={editRow}
          onClose={handleCancel}
          title={`${editAdd} timeline row`}
          width={"800px"}
          // desktopAnchor={"right"}
          anchor={"bottom"}
        >
          {editRow ? (
            renderEditWindow(timelineRows.find((row) => row.id === editRow))
          ) : (
            <Stack gap={2} padding={2}>
              <Skeleton width={"100%"} height={"32px"} />
              <Skeleton width={"100%"} height={"32px"} />
              <Skeleton width={"100%"} height={"32px"} />
              <Skeleton width={"100%"} height={"32px"} />
              <Skeleton width={"100%"} height={"32px"} />
              <Skeleton width={"100%"} height={"32px"} />
            </Stack>
          )}
        </ResponsiveDialog>
      )}
    </LocalizationProvider>
  );
};

export default EmployeeTimeline;
