import {
  Box,
  CircularProgress,
  ListItemButton,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { renderIcon } from "./ProgressTab";

// Helper function to calculate progress percentage based on created date and total duration
const calculateBoostProgress = (created, totalDuration) => {
  if (!totalDuration) return 100; // If no total duration (indefinite), it's always 100%

  if (!created?.seconds) return null;

  const createdTime = DateTime.fromSeconds(created?.seconds);
  const now = DateTime.now();
  const elapsedDays = now.diff(createdTime, "days").days;

  const remainingDays = totalDuration - elapsedDays;
  const progressPercentage = (remainingDays / totalDuration) * 100;

  return Math.max(progressPercentage, 0); // Ensure it doesn't go below 0%
};

// Helper function to calculate remaining days
export const calculateRemainingDays = (created, totalDuration) => {
  if (!totalDuration || !created?.seconds) return null; // If no total duration (indefinite), return null

  const createdTime = DateTime.fromSeconds(created?.seconds);
  const now = DateTime.now();
  const elapsedDays = now.diff(createdTime, "days").days;
  const remainingDays = totalDuration - elapsedDays;

  return Math.ceil(remainingDays); // Round up remaining days
};

export const activeBoosts = (created, totalDuration) => {
  console.log("created: ", created);
  if (created === null) return false;

  const remainingDays = calculateRemainingDays(created, totalDuration);

  console.log("remainingDays: ", remainingDays);

  // Consider active if remaining days > 0 or if it has an indefinite duration
  return remainingDays > 0 || totalDuration === null;
};

const Boosts = ({ boosts, map, onClick = () => {} }) => {
  // Filter out only active boosts

  return (
    <>
      {Object.entries(map)
        .filter(([key, value]) => value.type === "boosts") // Filter to only include "boosts"
        .map(([boostKey, boostData]) => {
          const { created = null } = boosts?.[boostKey] || {}; // Safely access `created` from boosts
          const { multiplier, totalDuration } = boostData; // Destructure `multiplier` and `totalDuration` from `boostData`

          const progress = calculateBoostProgress(created, totalDuration);
          const remainingDays = calculateRemainingDays(created, totalDuration);
          const active = activeBoosts(created, totalDuration);

          return (
            <ListItemButton
              key={boostKey}
              onClick={() => onClick(boostKey)}
              sx={{
                py: 1,
                px: { xs: 1, sm: 3, md: 6 },
                flexGrow: 1,
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fafafa",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "2px",
                    height: "8px",
                  }}
                >
                  {active && (
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        backgroundColor: map[boostKey]?.color || "#c0c0c0", // Dot color based on boost
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ position: "relative", mt: "4px" }}>
                  {/* Circular progress ring */}
                  <CircularProgress
                    variant="determinate"
                    value={active && progress}
                    size={80}
                    thickness={4}
                    sx={{
                      color: active ? map[boostKey]?.color : "#c0c0c0",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                      "& .MuiCircularProgress-circle": {
                        strokeLinecap: "round", // This rounds the edges of the progress
                      },
                    }}
                  />

                  {/* Center of the circular progress */}
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "60px",
                      zIndex: 2,
                      m: "10px",
                    }}
                  >
                    {/* Icon and Boost Description */}
                    {renderIcon({
                      key: boostKey,
                      multiplier,
                      active,
                      glow: active,
                    })}
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  sx={{ color: active ? "#333" : "#c0c0c0" }}
                >
                  {active
                    ? `${remainingDays !== null ? `${remainingDays}d` : "∞"}`
                    : "\u00A0"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: active ? "#666" : "#999" }}
                >
                  {active
                    ? `+${multiplier.toFixed(0)} ${
                        multiplier == 1 ? "pt" : "pts"
                      }`
                    : "\u00A0"}
                </Typography>
              </Box>
            </ListItemButton>
          );
        })}
    </>
  );
};

export default Boosts;
