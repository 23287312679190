import { Paper, useTheme, useMediaQuery, Box } from "@mui/material";

const ActionFooter = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const elevation = isMobile ? 4 : 0;

  const mobileStyle = {
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    borderRadius: "0px",
    // minHeight: "66px",
  };

  return (
    <Paper
      elevation={elevation}
      sx={{
        backgroundColor: "background.paper",
        pt: isMobile ? 0 : 4,
        width: "100%",
        maxWidth: "600px",
        zIndex: "1",
        ...(isMobile ? mobileStyle : {}),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "8px",
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default ActionFooter;
