import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { getAuth } from "firebase/auth"; // Import Firebase auth functions
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { db, functions } from "../../../firebase";

// Email validation function
export const validateEmail = (editEmail) => {
  let errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
  if (!editEmail || !emailRegex.test(editEmail)) {
    errors.email = "Please enter a valid email address.";
  }
  return errors;
};

const EditEmail = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  email,
  stripeId = "",
  memberId,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [editEmail, setEditEmail] = useState(email); // Store email input

  // Handle input change
  const handleChange = (e) => {
    setEditEmail(e.target.value);
    setErrors({});
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);

    // Validate email
    let newError = validateEmail(editEmail);
    if (Object.keys(newError).length > 0 && !canSkip) {
      setErrors(newError);
      setLoading(false); // Stop loading on validation error
      return; // Exit if there are validation errors
    }

    try {
      const auth = getAuth(); // Get the current Firebase auth instance
      const user = auth.currentUser; // Get the currently authenticated user

      if (user) {
        // For non-self users (aka Admins)
        const updateUserEmail = httpsCallable(functions, "updateUserEmail"); // Reference Cloud Function

        // Call the Cloud Function to update the user's email in stripe and firebase auth
        await updateUserEmail({
          memberId,
          newEmail: editEmail,
          stripeId,
        });

        // 2. Update Firestore with the private data
        await setDoc(
          doc(db, "membersPrivate", memberId),
          {
            email: editEmail,
          },
          { merge: true } // Merge so existing fields are not overwritten
        );

        handleNext(); // Move to the next step after successful email update
      } else {
        throw new Error("User is not authenticated."); // Handle case where no user is logged in
      }
    } catch (error) {
      console.error("Error updating email: ", error);

      // Check if the error has a specific Firebase error code
      if (error.code && error.details?.code) {
        setErrors({
          submit: `${error}`,
        });
      } else {
        setErrors({ submit: "Failed to update email. Please try again." });
      }
    } finally {
      setLoading(false); // End loading state
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Update your email
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          This email will be used for account-related communication.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Email Address"
            value={editEmail}
            onChange={handleChange}
            error={!!errors.email || !!errors.submit}
            helperText={
              errors?.submit
                ? errors.submit
                : errors.email ||
                  "Enter the email you want to associate with this account."
            }
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Full-width button
          >
            {loading ? ( // Show loading spinner while email is updating
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Save</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default EditEmail;
