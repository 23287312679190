import React from "react";
import useMemberData from "../../../hooks/useMemberData";
import VisitProvider from "../../../providers/VisitProvider";
import ScheduleVisitAdmin from "../../ScheduleVisitAdmin";
import HelpersProvider from "../../../providers/HelpersProvider";

const ScheduleNextVisit = ({
  memberId,
  eId = "",
  eName = "",
  onClose = () => {},
}) => {
  const {
    memberData,
    memberAdminData,
    memberAccountData,
    memberPrivateData,
    visitsData,
    employeesData,
    loading,
    loadingVisits,
    loadingPrivate,
  } = useMemberData(memberId);

  const defaultAddressKey = memberPrivateData?.defaultAddress;
  const address = memberAccountData?.addresses?.[defaultAddressKey];

  return (
    <>
      <HelpersProvider
        lat={memberPrivateData?.location?.lat}
        lng={memberPrivateData?.location?.lng}
      >
        <ScheduleVisitAdmin
          handleClose={onClose}
          adminMode={true}
          eId={eId}
          eName={eName}
          memberId={memberId}
          memberData={memberData}
          memberPrivateData={memberPrivateData}
          memberAdminData={memberAdminData}
          memberAccountData={memberAccountData}
        />
      </HelpersProvider>
    </>
  );
};

export default ScheduleNextVisit;
