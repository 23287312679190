import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useSnackbar } from "../providers/SnackbarProvider";
import {
  addCardToCustomer,
  createSetupIntent,
} from "../services/memberServices";

const AddCreditCard = ({ onCardAdded, stripeId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [addingCard, setAddingCard] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleAddCard = async () => {
    if (!stripe || !elements) {
      // Handle the error: inform the user that Stripe has not initialized
      return;
    }
    try {
      setAddingCard(true); // Start the loading indicator

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        showSnackbar("Card details not entered", "error");
        return;
      }

      // Replace with the actual call to your backend to create a SetupIntent
      const { clientSecret } = await createSetupIntent({
        customerId: stripeId,
      });

      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              // Add billing details if necessary
            },
          },
        }
      );

      if (error) {
        throw error; // If there's an error, throw it to be caught by the catch block below
      }

      // If the stripe.createPaymentMethod call was successful, continue with your logic
      await addCardToCustomer({
        paymentMethodId: setupIntent.payment_method,
        customerId: stripeId,
      });

      // Assuming onCardAdded is a function passed via props that refreshes the card list
      onCardAdded(); // Refresh the card list
      showSnackbar("Card added successfully", "success");
    } catch (error) {
      // Catch any errors thrown from the try block
      console.error("[error]", error);
      showSnackbar(error.message, "error");
    } finally {
      // Finally block will run regardless of the try/catch result
      setAddingCard(false); // Stop the loading indicator
    }
  };

  return (
    <>
      <DialogContent sx={{ mb: 8 }}>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Enter your card details below.
        </Typography>

        <CardElement
          options={{
            disableLink: true,
            style: {
              base: {
                fontSize: "16px", // Adjust the font size here
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          onClick={handleAddCard}
          variant="outlined"
          disabled={!stripe || addingCard}
          startIcon={addingCard ? <CircularProgress size={20} /> : null}
          sx={{
            minWidth: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          {addingCard ? "Saving..." : "Save Card"}
        </Button>
      </DialogActions>
    </>
  );
};

export default AddCreditCard;
