import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHelpers } from "../providers/HelpersProvider";
import VisitPicker from "./scheduler/VisitPicker";

/**Context Requirement:**
 * This component must be used within the `HelpersProvider` context. The context provides
 * essential data such as `memberData`, `memberPrivateData`, and other member-specific
 * properties. If used outside the `HelpersProvider`, the component will not have access
 * to the required data and will not function correctly.
 */

const RescheduleVisitPublic = ({
  onSubmit,
  showTitle = true,
  initialFrequency = null,
  visitData = {},
}) => {
  const { employeesData, slotsData, loadingSlots } = useHelpers();

  console.log("RescheduleVisitPublic - loadingSlots: ", loadingSlots);
  console.log("RescheduleVisitPublic - employeesData: ", employeesData);
  console.log("RescheduleVisitPublic - slotsData: ", slotsData);

  const [selectedVisit, setSelectedVisit] = useState({});
  const [filters, setFilters] = useState(() => {
    const calculateNumEmployees = () => {
      if (visitData.status === "confirmed") {
        // Calculate as is for "confirmed" status
        return Object.values(visitData.employees).filter(
          (employee) =>
            employee.responseStatus === "accepted" ||
            employee.responseStatus === "needsAction"
        ).length;
      } else if (visitData.status === "reschedule") {
        // Only count employees with responseStatus "declined"
        return Object.values(visitData.employees).filter(
          (employee) => employee.responseStatus === "declined"
        ).length;
      }
      // Default to 1 if no specific status is matched
      return 1;
    };

    return {
      duration:
        (visitData.end.toMillis() - visitData.start.toMillis()) /
        (60 * 60 * 1000),
      frequency:
        visitData?.seriesEmployeesNeeded > 0
          ? visitData?.recurrence?.frequency || 0
          : 0,
      employeeIds: [],
      numEmployees: calculateNumEmployees(),
    };
  });

  // pre-populate with characteristics from the past visitData
  useEffect(() => {
    const duration =
      (visitData.end.toMillis() - visitData.start.toMillis()) /
      (60 * 60 * 1000);

    const frequency =
      initialFrequency !== null
        ? initialFrequency
        : visitData?.seriesEmployeesNeeded > 0
        ? visitData?.recurrence.frequency
        : 0;

    setFilters((prevFilters) => ({
      ...prevFilters,
      duration,
      frequency,
    }));
  }, [visitData]);

  const params = new URLSearchParams(window.location.search);
  const key = params.get("key") || "key_123";

  console.log("=====> selectedVisit: ", selectedVisit);

  const handleSubmit = () => {
    console.log("submitting 1");

    // Map employee IDs to their corresponding objects
    const selectedEmployees = selectedVisit.visit.employeeIds.reduce(
      (acc, id) => {
        const employee = employeesData[id];
        if (employee) {
          acc[id] = employee;
        }
        return acc;
      },
      {}
    );

    onSubmit({
      visitId: visitData?.id,
      key,
      timeZoneId: visitData?.timeZoneId,
      startTimestamp: selectedVisit.visit.start,
      endTimestamp: selectedVisit.visit.end,
      frequency: filters.frequency,
      employees: selectedEmployees,
    });
  };

  console.log("visitData");

  return (
    <>
      {loadingSlots ? (
        <Box
          sx={{
            display: "flex",
            position: "sticky",
            top: { xs: "20vh", sm: undefined },
            height: { xs: "unset", sm: "400px" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%", // Ensure it spans the full width
            gap: 2,
            marginY: 2,
          }}
        >
          <CircularProgress size={48} color="inherit" />
          {/* Additional content */}
        </Box>
      ) : (
        <>
          <DialogContent>
            <VisitPicker
              visits={slotsData}
              selectedVisit={selectedVisit}
              setSelectedVisit={setSelectedVisit}
              filters={filters}
              setFilters={setFilters}
              employees={employeesData}
              timeZoneId={visitData?.timeZoneId}
              memberId={visitData?.member}
              bestMatchEmployee={null}
            />
          </DialogContent>

          {/* Use AppBar for mobile view */}
          <DialogActions
            sx={{
              zIndex: 10,
              justifyContent: "center",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Button
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                width: { xs: "100%", sm: "200px" },
                height: { xs: "60px", sm: "56px" },
              }}
              disableElevation={true}
              variant="contained"
              disabled={Object.keys(selectedVisit).length === 0}
              fullWidth
            >
              Confirm Reschedule
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default RescheduleVisitPublic;
