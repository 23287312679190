import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import EmployeeTimeline from "../timeline/EmployeeTimeline";

const TimelineCompact = ({
  areaData,
  areas,
  employeeId,
  userRole = "member",
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);
  const { openDrawer } = useDrawer();

  return (
    <Box sx={{ mt: 1, position: "relative" }}>
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          backgroundColor: "#F5F5F5",
          p: 2,
          borderRadius: "15px",
          alignItems: "flex-start",
          width: "100%",
          boxSizing: "border-box"
        }}
      >
        <Typography fontWeight={"700"}>Service Areas</Typography>
        <Box sx={{ ml: -1, mr: -3, mt: -1, mb: -3, width: "100%" }}>
          <EmployeeTimeline
            employeeId={employeeId}
            areas={areas}
            canEdit={userRole === "admin" ? true : false}
            showHelp={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TimelineCompact;
