import React from "react";
import {
  TextField,
  Button,
  Slide,
  IconButton,
  InputAdornment,
  CircularProgress,
  Card,
  Divider,
  Typography,
  ButtonBase,
} from "@mui/material";
import {
  Phone,
  PhoneIphone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

const PasswordInput = ({
  onBack,
  onNext,
  onSmsLogin,
  values,
  handleChange,
  errors,
  setErrors,
  loading,
  showPassword,
  setShowPassword,
  handleLogin,
}) => {
  const handleBack = () => {
    // Update only the password field in the errors object
    setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    onBack();
  };

  return (
    <>
      <>
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          name="password"
          type={showPassword ? "text" : "password"}
          value={values.password}
          onChange={(e) =>
            handleChange({
              target: { name: "password", value: e.target.value },
            })
          }
          error={!!errors.password}
          helperText={errors.password || " "}
          sx={{ mb: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="text"
          onClick={onSmsLogin}
          sx={{ width: "100%", height: "56px", textTransform: "none" }} // Added marginTop to push the button to the bottom
        >
          <Typography variant="body2" color="primary">
            Forgot Password?
          </Typography>
        </Button>
        <Button
          variant={values.password === "" ? "outlined" : "contained"}
          onClick={handleLogin}
          disabled={loading || values.password === ""} // Disabled if loading or email is invalid
          sx={{ width: "100%", height: "56px", mt: 1 }} // Added marginTop to push the button to the bottom
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
        </Button>
        {/* <Divider sx={{ width: "100%", my: 2 }}>
          <Typography
            variant="body2"
            component="span"
            color="textSecondary"
            mx={2}
          >
            or
          </Typography>
        </Divider>
        <Button
          variant="contained"
          onClick={onSmsLogin}
          disabled={loading}
          startIcon={<PhoneIphone />} // Add the icon as the startIcon
          sx={{ width: "100%", height: "56px" }} // Added marginTop to push the button to the bottom
        >
          Login with Phone
        </Button>
        */}
        <Button
          variant="text"
          onClick={handleBack}
          sx={{
            width: "100%",
            height: "56px",
            mt: 1,
            color: "gray", // sets the text color to gray
            backgroundColor: "none", // sets the background color to none initially
            transition: "background-color 0.3s", // smooth transition for background color change
            "&:active": {
              backgroundColor: "lightgray", // changes background color when pressed
            },
          }} // Added marginTop to push the button to the bottom
        >
          Back
        </Button>
      </>
    </>
  );
};

export default PasswordInput;
