import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useEmployee } from "../../../providers/EmployeeProvider";
import VisitOverviewCard from "../../visit/VisitOverviewCard";
import { useAuth } from "../../../providers/AuthProvider";

const MemberOverview = ({
  userRole = "member",
  memberData,
  visitsData,
  adminData,
  loadingVisits,
  employeesData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // employee vars
  const { employeeData } = useEmployee();
  const {currentUser} = useAuth()

  const eId = currentUser?.uid;
  const eDisplayName = `${
    employeeData?.firstName
  } ${employeeData?.lastName.charAt(0)}.`;
  const eAvatarUrl = employeeData?.avatarUrl;

  const upcomingVisit = Object.values(visitsData)
    .filter((visit) => visit.start.toDate() > new Date()) // Convert Firestore Timestamp to Date
    .sort((a, b) => a.start.toDate() - b.start.toDate())[0]; // Sort by start date

  const mostRecentVisit = Object.values(visitsData)
    .filter((visit) => visit.start.toDate() < new Date()) // Filter visits that happened in the past
    .sort((a, b) => b.start.toDate() - a.start.toDate())[0]; // Sort by start date in descending order

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row" },
        gap: 1,
      }}
    >
      <VisitOverviewCard
        visit={mostRecentVisit}
        userRole={userRole}
        loading={loadingVisits}
        localEmployees={employeesData}
        eId={eId}
        eName={employeeData?.firstName}
        label={"past"}
        my={0}
      />
      <VisitOverviewCard
        visit={upcomingVisit}
        userRole={userRole}
        loading={loadingVisits}
        localEmployees={employeesData}
        eId={eId}
        eName={employeeData?.firstName}
        label={"upcoming"}
        my={0}
      />
    </Box>
  );
};

export default MemberOverview;
