import { Alert, Box, Fade, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import IntakeForm from "./IntakeForm";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

const ContactSectionV2 = ({pageName = ""}) => {

  const ref = useTrackVisibility(() => {},
  `${pageName}/"contact section"`); // Pass the logMessage here

  const [zipCode, setZipCode] = useState("98221");

  const handleButtonClick = (value) => {
    setLeadCaptured(true);
    setZipCode(value);
  };

  const [leadCaptured, setLeadCaptured] = useState(false); //change back to false

  useEffect(() => {
    const leadCaptured = localStorage.getItem("leadCaptured");
    const zipCode = localStorage.getItem("zipCode");

    // Assuming the intention is to check if leadCaptured is "true"
    if (leadCaptured === "true" && zipCode) {
      setLeadCaptured(true); // Assuming you have a state setter function named setLeadCaptured
      setZipCode(zipCode); // Correctly updating the zipCode within the values state
    }
  }, []);

  return (
    <Box ref={ref} sx={{ position: "relative", width: "auto" }}>
      {/* <CredibilityGrid /> */}

      {!leadCaptured ? (
        <Fade in={!leadCaptured} timeout={500}>
          <Box>
            <IntakeForm
              handleClick={handleButtonClick}
              setLeadCaptured={setLeadCaptured}
            />
          </Box>
        </Fade>
      ) : (
        <Fade in={leadCaptured} timeout={500}>
          <Box
            variant="outlined"
            sx={{
              py: { xs: 2, sm: 8 },
              px: { xs: 2, sm: 8 },
              my: { xs: 2, sm: 10 },
              maxWidth: "600px",
              mx: { xs: 2, sm: "auto" },
              gap: 2,
              display: "flex",
              flexDirection: "column",
              borderRadius: "15px",
              background: "white",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "15px",
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)", // Custom shadow
            }}
          >
            <Typography variant="h4">Thanks!</Typography>
            <Typography variant="body1">
              We've received your message. We will give you a call as soon as we can
              to answer your questions and discuss how we can meet your needs.
              We look forward to speaking with you!
            </Typography>
            <Alert sx={{maxWidth: "fit-content"}}>Submission received</Alert>

            {/* <PricingCards dense={true} zipCode={zipCode} /> */}
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default ContactSectionV2;
