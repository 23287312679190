import { Box, DialogContent } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import useEmployeeData from "../../hooks/useEmployeeData";
import { useEmployee } from "../../providers/EmployeeProvider";
import { useMember } from "../../providers/MemberProvider";
import {
  getCurrentArea,
  getEmployeeStatus,
} from "../../services/locationServices";
import ProgressTab from "../progress/ProgressTab";
import VisitReviewList from "../reviews/VisitReviewList";
import ServiceAreasList from "../serviceArea/ServiceAreasList";
import EmployeeBio from "./EmployeeBio";
import EmployeeInfo from "./EmployeeInfo";
import EmployeeSchedule from "./EmployeeSchedule";
import LocationMap from "./LocationMap";
import TimelineCompact from "./TimelineCompact";

const EmployeeCardV2 = ({
  employeeId = "",
  memberId = "", // used for following the helper
  userRole = "member",
  // coordinates = null,
  onClose = () => {},
}) => {
  const [realTimeMemberPrivateData, setRealTimeMemberPrivateData] =
    useState(null);

  const {
    employeeData = {},
    employeePrivateData,
    visitsData,
    loading,
  } = useEmployeeData(employeeId);

  // Call hooks and provide default values
  const { employeeData: selfEmployeeData = {} } = useEmployee() || {}; // Default to an empty object if undefined
  const { memberPrivateData = {} } = useMember() || {}; // Default to an empty object if undefined

  console.log("EmployeeCardV2 - memberPrivateData:", memberPrivateData);

  // Add snapshot listener for "membersPrivate" if `memberPrivateData` is undefined
  useEffect(() => {
    if (!memberId || Object.keys(memberPrivateData).length > 0) return;

    const memberDocRef = doc(db, "membersPrivate", memberId);

    const unsubscribe = onSnapshot(
      memberDocRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setRealTimeMemberPrivateData(snapshot.data());
        } else {
          console.warn(`No member data found for memberId: ${memberId}`);
          setRealTimeMemberPrivateData({}); // Default to empty object
        }
      },
      (error) => {
        console.error("Error listening to memberPrivateData:", error);
      }
    );

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  // Use realTimeMemberPrivateData if memberPrivateData is undefined
  const effectiveMemberPrivateData =
    Object.keys(memberPrivateData).length > 0
      ? memberPrivateData
      : realTimeMemberPrivateData || {};

  const { areaKey = "", areaData = {} } =
    getCurrentArea(employeeData?.areas) || {};

  // Determine userName based on userRole
  // Determine coordinates based on userRole
  const coordinates =
    userRole === "employee" || userRole === "admin"
      ? {
          lat: getCurrentArea(selfEmployeeData?.areas).areaData.lat || null,
          lng: getCurrentArea(selfEmployeeData?.areas).areaData.lng || null,
        }
      : userRole === "member"
      ? {
          lat: memberPrivateData?.location?.lat || null,
          lng: memberPrivateData?.location?.lng || null,
        }
      : { lat: null, lng: null }; // Default if no valid role

  console.log("coordinates: ", coordinates);
  console.log("employeeData: ", employeeData);
  console.log("visitsData: ", visitsData);

  if (loading || employeeData === null) {
    return <>loading...</>;
  }

  const {
    inArea = false,
    futureAvailability = 0,
    end,
    start,
  } = getEmployeeStatus(
    coordinates?.lat,
    coordinates?.lng,
    employeeData.areas,
    employeeData.availability
  );

  return (
    <>
      <DialogContent>
        {/* <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            <strong>In Area:</strong> {inArea ? "Yes" : "No"}
          </Typography>
          <Typography variant="body1">
            <strong>Start:</strong>{" "}
            {start ? start.toFormat("MMM d, yyyy") : "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>End:</strong> {end ? end.toFormat("MMM d, yyyy") : "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Total Future Hours:</strong> {futureAvailability.toFixed(0)}{" "}
            hours
          </Typography>
        </Box> */}
        <EmployeeInfo
          employeeData={employeeData}
          employeePrivateData={employeePrivateData}
          memberPrivateData={effectiveMemberPrivateData}
          memberId={memberId}
          userRole={userRole}
          inArea={inArea}
          futureAvailability={futureAvailability}
          serviceStart={start}
          serviceEnd={end}
          employeeId={employeeId}
        />
        <LocationMap
          areaData={areaData}
          areaKey={areaKey}
          areas={employeeData?.areas}
          employeeId={employeeId}
          userRole={userRole}
        />
        {userRole === "admin" && (
          <Box
            sx={{
              gap: 1,
              backgroundColor: "#F5F5F5",
              p: 2,
              mt: 1,
              px: 0,
              borderRadius: "15px",
              boxSizing: "border-box",
            }}
          >
            <ServiceAreasList
              titleVariant={"body1"}
              employeeData={employeeData}
              employeeId={employeeId}
            />
          </Box>
        )}
        {(userRole === "employee" || userRole === "admin") && (
          <TimelineCompact
            areaData={areaData}
            areas={employeeData?.areas}
            employeeId={employeeId}
            userRole={userRole}
          />
        )}
        <EmployeeBio employeeData={employeeData} />
        {(userRole === "employee" || userRole === "admin") && (
          <>
            <EmployeeSchedule
              employeeData={employeeData}
              visitsData={visitsData}
              userRole={userRole}
            />
          </>
        )}
        {userRole === "admin" && (
          <ProgressTab
            showHeader={false}
            progress={employeePrivateData?.progress}
            currentLevel={employeeData?.currentLevel}
            employeeId={employeeId}
            employeeName={`${employeeData?.firstName} ${employeeData?.lastName}`}
            avatarUrl={employeeData?.avatarUrl}
            wage={employeePrivateData?.wage}
          />
        )}
        <VisitReviewList employeeId={employeeId} STAR_MIN={4} maxWidth="auto" />
      </DialogContent>
    </>
  );
};

export default EmployeeCardV2;
