import {
  ChatOutlined,
  Login as LoginIcon,
  LoyaltyOutlined,
  PhoneOutlined,
  SmsOutlined,
  Today,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import ActionButtonRow from "../../buttons/ActionButtonRow";

const MemberActions = ({
  phone,
  onScheduleVisit,
  onLogConvo,
  onSendLoginLink,
  onApplyDiscount,
  userRole = "admin",
}) => {
  // Function to open Google Voice with a phone number on desktop or mobile
  const openGoogleVoiceCall = (phoneNumber) => {
    // Format the phone number for URL compatibility
    const formattedNumber = `1${phoneNumber?.replace(/\D/g, "")}`; // Removes any non-digit characters

    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Try to open Google Voice app on mobile
      window.location.href = `googlevoice://call?number=${formattedNumber}`;
    } else {
      // Open Google Voice web interface for desktop
      window.open(
        `https://voice.google.com/u/0/calls?a=nc,%2B${formattedNumber}`,
        "_blank"
      );
    }
  };

  const openGoogleVoiceSms = (phoneNumber) => {
    // Format the phone number for URL compatibility
    const formattedNumber = `1${phoneNumber?.replace(/\D/g, "")}`; // Removes any non-digit characters

    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Try to open Google Voice app on mobile
      window.location.href = `googlevoice://call?number=${formattedNumber}`;
    } else {
      // Open Google Voice web interface for desktop
      window.open(
        `https://voice.google.com/u/0/messages?itemId=t.%2B${formattedNumber}`,
        "_blank"
      );
    }
  };

  const openNativeCall = (phoneNumber) => {
    // Format the phone number to ensure compatibility
    const formattedNumber = `1${phoneNumber?.replace(/\D/g, "")}`; // Removes any non-digit characters

    // Use the 'tel:' protocol to open the native phone dialer
    window.location.href = `tel:${formattedNumber}`;
  };

  const openNativeSms = (phoneNumber, message = "") => {
    // Format the phone number to ensure compatibility
    const formattedNumber = `1${phoneNumber?.replace(/\D/g, "")}`; // Removes any non-digit characters

    // Use the 'sms:' protocol to open the native SMS app with the number and optional message
    const smsUrl = `sms:${formattedNumber}${
      message ? `?&body=${encodeURIComponent(message)}` : ""
    }`;
    window.location.href = smsUrl;
  };

  const handleCall = () => {
    if (userRole === "admin") {
      openGoogleVoiceCall(phone);
    } else if (userRole === "employee") {
      openNativeCall(phone);
    }
  };

  const handleSms = () => {
    if (userRole === "admin") {
      openGoogleVoiceSms(phone);
    } else if (userRole === "employee") {
      openNativeSms(phone);
    }
  };

  let actions = [
    {
      label: "Schedule",
      tooltip: "Schedule a visit",
      icon: <Today />,
      onClick: onScheduleVisit,
    },
    {
      label: "Log",
      tooltip: "Log a conversation",
      icon: <ChatOutlined />,
      onClick: onLogConvo,
    },
    {
      label: "Call",
      tooltip: `Call member`,
      icon: <PhoneOutlined />,
      // onClick: () => navigator.clipboard.writeText(phone),
      onClick: handleCall,
    },
    {
      label: "Text",
      tooltip: `Send a text`,
      icon: <SmsOutlined />,
      // onClick: () => navigator.clipboard.writeText(phone),
      onClick: handleSms,
    },
    {
      label: "Login",
      tooltip: "Send login link",
      icon: <LoginIcon />,
      onClick: onSendLoginLink,
    },
  ];

  // Conditionally push the "Discount" action if the user role is admin
  if (userRole === "admin") {
    actions.push({
      label: "Discount",
      tooltip: "Create a discount",
      icon: <LoyaltyOutlined />,
      onClick: onApplyDiscount,
    });
  }

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        mx: { xs: -3, sm: 0 },
        borderRadius: { xs: 0, sm: "15px" },
        py: 1,
        px: 2,
        // borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        overflowX: "auto", // Enable horizontal scrolling
        overflowY: "hidden", // Disable vertical scrolling
        whiteSpace: "nowrap", // Prevent line breaks
        "&::-webkit-scrollbar": {
          display: "none", // Hide the scrollbar for webkit browsers
        },
      }}
    >
      <ActionButtonRow actions={actions} />
    </Box>
  );
};

export default MemberActions;
