import React, { createContext, useState, useContext, useCallback } from "react";
import {
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const SnackbarContext = createContext();

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(`useSnackbar must be used within a SnackbarProvider`);
  }
  return context;
};

export const SnackbarProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
    isLoading: false,
  });

  const [fabOffset, setFabOffset] = useState(0);

  const showSnackbar = useCallback(
    (message, severity = "info") => {
      setSnackbar({ open: true, message, severity, isLoading: false });
      if (isMobile) {
        setFabOffset(60); // Adjust FAB when Snackbar is shown
      }
    },
    [isMobile]
  );

  const showLoading = useCallback(
    (message) => {
      setSnackbar({ open: true, message, severity: "info", isLoading: true });
      if (isMobile) {
        setFabOffset(60); // Adjust FAB when Snackbar is shown
      }
    },
    [isMobile]
  );

  const closeSnackbar = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
    setFabOffset(0); // Reset FAB position when Snackbar is hidden
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar, showLoading, fabOffset }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={10000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: isMobile ? "top" : "bottom",
          horizontal: isMobile ? "center" : "left",
        }}
      >
        {snackbar.isLoading ? (
          <Alert
            variant="filled"
            onClose={closeSnackbar}
            severity={snackbar.severity}
            icon={false}
          >
            <Box display="flex" alignItems="center">
              <CircularProgress
                size={20}
                color="inherit"
                style={{ color: "white" }}
                thickness={4.5}
              />
              <Typography
                variant="body2"
                fontWeight="500"
                style={{ marginLeft: 14 }}
              >
                {snackbar.message}
              </Typography>
            </Box>
          </Alert>
        ) : (
          <Alert
            variant="filled"
            onClose={closeSnackbar}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        )}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
