import {
  Box,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import React from "react";
import Logo from "../svgs/Logo";

const ContentHeader = ({ children, title, logo = false, align = "center" }) => {
  // Accept children props
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const trigger = useScrollTrigger();

  // Determine whether the title should be centered based on the presence of children
  const childrenPresent = React.Children.count(children) > 0;

  const justifyContentMobile = childrenPresent ? "space-between" : "center";

  const mobileStyle = {
    top: 0,
    left: 0,
    right: 0,
    height: "56px",
    position: "fixed",
    backgroundColor: "white",
    paddingLeft: 16,
    justifyContent:
      align === "center"
        ? childrenPresent
          ? "space-between"
          : "center"
        : "flex-start",
    paddingRight: childrenPresent ? 8 : 16,
    boxShadow:
      "0px -3px 3px -2px rgba(0,0,0,0.2), 0px -3px 4px 0px rgba(0,0,0,0.14), 0px -1px 8px 0px rgba(0,0,0,0.12)",
  };

  const iconButtonContainerStyle = {
    display: "flex",
    justifyContent: "flex-end", // Align the children (icon buttons) to the right
    alignItems: "center", // if you want the icons to align vertically in the middle
    // flexGrow: 1, // Allow this container to take up all available space to ensure it stays to the right
    gap: "8px", // Add 8px spacing between each direct child (useful for a list of buttons or similar elements)
  };

  return (
    <>
      {isMobile && (
        <Box
          display="flex"
          alignItems="center"
          zIndex={1}
          marginBottom={4}
          style={
            isMobile
              ? mobileStyle
              : { justifyContent: "space-between", height: "40px" }
          }
        >
          {isMobile && !logo && (
            <Typography variant="h5" align="right">
              {title}
            </Typography>
          )}
          {isMobile && logo && (
            <Box width={200} sx={{ transform: "translateY(3px)" }}>
              <Logo color="rgba(0, 0, 0, 0.87)" />
            </Box>
          )}
          <Box style={iconButtonContainerStyle}>{children}</Box>
        </Box>
      )}
      {/* {!isMobile && (
        <Box
          display="flex"
          alignItems="center"
          zIndex={1}
          marginBottom={4}
          style={
            isMobile
              ? mobileStyle
              : { justifyContent: "space-between", height: "40px" }
          }
        >
          {!logo && <Typography variant="h5">{title}</Typography>}
          {logo && (
            <Box width={200} sx={{ transform: "translateY(3px)" }}>
              <Logo color="rgba(0, 0, 0, 0.87)" />
            </Box>
          )}
          <Box style={iconButtonContainerStyle}>{children}</Box>
        </Box>
      )} */}

      {/* {!isMobile && (
        <Divider
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            // marginRight: "-32px",
            // marginLeft: "-32px",
            // width: "100%",
          }}
        />
      )} */}
    </>
  );
};

export default ContentHeader;
