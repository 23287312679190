import {
  Box,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import React, { memo, useEffect, useState } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../providers/AuthProvider";
import { useDrawer } from "../../providers/DrawerProvider";
import { formatTimeRange } from "../../services/dateServices";
import { addAndMergeWindows } from "../../services/employeeServices";
import { getCurrentArea } from "../../services/locationServices";
import VisitDrawer from "../drawers/VisitDrawer";
import EmployeeTimeline from "../timeline/EmployeeTimeline";

const VisitItem = ({
  visit,
  type = "visit",
  window,
  employeeId,
  timeZoneId,
  start,
  end,
  onSelect,
  currentUser,
  userRole,
}) => {
  const theme = useTheme();

  const responseStatus = visit.employees[employeeId]?.responseStatus;
  const visitStart = window.start.clone();
  const visitEnd = window.end.clone();

  // Extract only the time portion of the visit start and end times
  const visitStartTime =
    visitStart.tz(timeZoneId).hour() * 60 + visitStart.tz(timeZoneId).minute();
  const visitEndTime =
    visitEnd.tz(timeZoneId).hour() * 60 + visitEnd.tz(timeZoneId).minute();

  // Similarly for the start and end of the timeline
  const timelineStart = start.hour() * 60 + start.minute();
  const timelineEnd = end.hour() * 60 + end.minute();

  const totalTimelineMinutes = timelineEnd - timelineStart;
  const startMinutes = visitStartTime - timelineStart;
  const durationMinutes = visitEndTime - visitStartTime;

  const top = (startMinutes / totalTimelineMinutes) * 100;
  const height = (durationMinutes / totalTimelineMinutes) * 100;

  let style;
  switch (responseStatus) {
    case "accepted":
      style = {
        border: `1px solid ${theme.palette.primary.dark}`,
        backgroundColor: theme.palette.primary.main,
        color: "white",
      };
      break;
    case "needsAction":
      style = {
        border: `1px solid ${theme.palette.warning.main}`,
        backgroundColor: "hsla(36, 100%, 95%, 1)",
        color: theme.palette.warning.main,
      };
      break;
    default:
      style = { backgroundColor: "grey", color: "white" };
  }

  if (type === "openVisit") {
    style = {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: "hsla(190, 100%, 96%, 1)",
      color: theme.palette.primary.main,
    };
  }

  const handleClick = () => {
    // console.log("handle click visit: ", visit);
    onSelect(visit, type); // Pass the visit ID to the parent's handler
  };

  return (
    <Box
      sx={{
        ...style,
        position: "absolute",
        top: `${top}%`,
        height: `${height}%`,
        left: { xs: 0, sm: 5, md: 15 },
        right: { xs: 2, sm: 5, md: 15 },
        padding: { xs: "3px", sm: "5px" },
        overflow: "hidden",
        whiteSpace: "normal", // Allows text to wrap
        wordWrap: "break-word", // Breaks words to prevent overflow
        boxSizing: "border-box",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        zIndex: 2,
        cursor:
          (currentUser?.uid === employeeId || userRole === "admin") &&
          "pointer", // Make it visually clear that it's clickable
        userSelect: "none",
      }}
      onClick={handleClick}
    >
      <Typography
        variant="subtitle2"
        fontWeight={"600"}
        sx={{
          fontSize: { xs: "0.75rem", sm: "0.875rem" },
          lineHeight: { xs: "0.8rem", sm: "0.9rem" },
          mb: 0.5,
        }}
      >
        {visit.displayName}
      </Typography>
      <Typography variant="caption" lineHeight={"0.8rem"}>
        {formatTimeRange(window.start, window.end, visit.timeZoneId, true)}
      </Typography>
    </Box>
  );
};

const AvailabilityWindowItem = ({
  window,
  dayStart,
  dayEnd,
  height,
  timeZoneId,
  employeeId,
  currentUser,
  userRole,
}) => {
  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    event.preventDefault();
    if (currentUser?.uid === employeeId || userRole === "admin") {
      setMenuAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  // Extract only the time portion of the window start and end times
  const windowStartTime =
    window.start.tz(timeZoneId).hour() * 60 +
    window.start.tz(timeZoneId).minute();
  const windowEndTime =
    window.end.tz(timeZoneId).hour() * 60 + window.end.tz(timeZoneId).minute();

  // Similarly for the start and end of the day
  const dayStartTime = dayStart.hour() * 60 + dayStart.minute();
  const dayEndTime = dayEnd.hour() * 60 + dayEnd.minute();

  const totalDayMinutes = dayEndTime - dayStartTime;
  const startMinutes = windowStartTime - dayStartTime;
  const durationMinutes = windowEndTime - windowStartTime;

  // Calculate the top position and height as percentages
  const top = (startMinutes / totalDayMinutes) * 100;
  const windowHeight = (durationMinutes / totalDayMinutes) * 100;

  const handleDeleteAvailability = async (id) => {
    try {
      // console.log("Deleting availability with ID:", id);

      if (employeeId) {
        const employeeDocRef = doc(db, "employees", employeeId);

        // Update the document to delete that specific key
        await updateDoc(employeeDocRef, {
          [`availability.windows.${id}`]: deleteField(),
        });

        // console.log("Availability deleted successfully.");
      }
    } catch (error) {
      console.error("Error deleting availability:", error);
    }
  };

  const handleDelete = async () => {
    await handleDeleteAvailability(window.key); // Pass the window id for deletion
    handleCloseMenu();
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: `${top}%`,
          height: `${windowHeight}%`,
          left: { xs: 0, sm: 2, md: 5 },
          right: { xs: 1, sm: 2, md: 5 },
          padding: { xs: "2px", sm: "5px", md: "10px" },
          boxSizing: "border-box",
          backgroundColor: theme.palette.success.main,
          opacity: 0.8,
          borderRadius: "5px",
          border: "1px solid hsl(150,100%,20%)",
          zIndex: 1,
          textOverflow: "ellipsis", // Add this to truncate text
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
          overflow: "hidden",
          whiteSpace: "normal", // Allows text to wrap
          wordWrap: "break-word", // Breaks words to prevent overflow
          cursor:
            (currentUser?.uid === employeeId || userRole === "admin") &&
            "pointer", // Make it visually clear that it's clickable
        }}
        onClick={handleOpenMenu} // Attach the menu open handler
      >
        <Typography
          variant="subtitle2"
          fontWeight={"600"}
          color="white"
          sx={{
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            lineHeight: { xs: "0.8rem", sm: "0.9rem" },
            mb: 0.5,
          }}
        >
          Availability
        </Typography>
        <Typography variant="caption" lineHeight={"0.8rem"} color="white">
          {formatTimeRange(window.start, window.end, timeZoneId, true)}
        </Typography>
      </Box>
      <Menu
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          menuAnchorEl
            ? { top: menuAnchorEl.top, left: menuAnchorEl.left }
            : undefined
        }
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

const DayColumn = memo(
  ({
    date,
    areas,
    visits,
    openVisits,
    employeeId,
    start,
    end,
    index,
    onSelectVisit,
    height,
    availability,
    onCreateAvailability,
    visitsExist,
    currentUser,
    userRole,
    canAddAvailability,
    isBreak = true,
  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const luxonDate = DateTime.fromISO(date.toISOString()).setZone(
      "America/Los_Angeles"
    );

    // console.log("DayColumn - render: ", date.toISOString())

    const {
      areaData: {
        timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone ||
          "America/Los_Angeles",
      } = {},
    } = getCurrentArea(areas, luxonDate) || {};

    // const timeZoneId = labelTimeZoneId;

    const [dragStart, setDragStart] = useState(null);
    const [dragEnd, setDragEnd] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [touchStartTimeout, setTouchStartTimeout] = useState(null);

    // Convert height to a number for calculations
    const numericHeight = parseInt(height, 10);

    const intervalHeight = numericHeight / (end.diff(start, "minutes") / 15); // 600px height divided by number of intervals

    const delay = isMobile ? 100 : 0;

    // console.log("availability DayView: ", availability);

    // console.log("numericHeight: ", numericHeight);
    // console.log("dragging: ", dragging);
    // console.log("dragEnd: ", dragEnd);
    // console.log("dragStart: ", dragStart);

    // Current time marker
    const now = moment().tz(timeZoneId); // Current time in the specified timezone
    const isToday = now.isSame(date, "day"); // Check if the column represents today

    // Extract only the time portion of the current time
    const currentTimeInMinutes = now.hour() * 60 + now.minute();

    // Similarly for the start and end of the timeline
    const timelineStartInMinutes = start.hour() * 60 + start.minute();
    const timelineEndInMinutes = end.hour() * 60 + end.minute();

    // Calculate position
    const currentTimePosition = isToday
      ? ((currentTimeInMinutes - timelineStartInMinutes) /
          (timelineEndInMinutes - timelineStartInMinutes)) *
        numericHeight
      : null;

    // Define the start and end of the day
    let dayVisits = [];
    let dayOpenVisits = [];

    if (visitsExist) {
      dayVisits = Object.entries(visits) // Convert visits to an array of [id, visit] pairs
        .filter(([id, visit]) =>
          moment(visit.start.toDate()).tz(timeZoneId).isSame(date, "day")
        )
        .map(([id, visit]) => ({ ...visit, id })); // Add the id to each visit object
    }

    dayOpenVisits = Object.entries(openVisits) // Convert visits to an array of [id, visit] pairs
      .filter(([id, visit]) =>
        moment(visit.start.toDate()).tz(timeZoneId).isSame(date, "day")
      )
      .map(([id, visit]) => ({ ...visit, id })); // Add the id to each visit object

    const snapToInterval = (y) => {
      const intervalIndex = Math.round(y / intervalHeight);
      return intervalIndex * intervalHeight;
    };

    const calculateTime = (y) => {
      const minutesFromStart = (y / intervalHeight) * 15;
      const timeOfDay = start.clone().add(minutesFromStart, "minutes");

      return moment(date)
        .tz(timeZoneId)
        .hour(timeOfDay.hour())
        .minute(timeOfDay.minute())
        .second(0) // Reset seconds and milliseconds for consistency
        .millisecond(0);
    };

    const getTouchY = (event) => {
      const touch = event.touches[0] || event.changedTouches[0];
      const rect = event.currentTarget.getBoundingClientRect();
      return touch.clientY - rect.top;
    };

    const handleMouseDown = (event) => {
      // Clear any existing timeout
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
      }

      const y = event.clientY - event.currentTarget.getBoundingClientRect().top;
      // Set a timeout to delay the start handling
      const timeoutId = setTimeout(() => {
        handleStart(y);
      }, delay); // 400ms delay, adjust as needed

      setTouchStartTimeout(timeoutId);
    };

    const handleMouseMove = (event) => {
      const y = event.clientY - event.currentTarget.getBoundingClientRect().top;
      handleMove(y);
    };

    const handleMouseUp = () => {
      if (Math.abs(dragEnd - dragStart) < 20) {
        setDragStart(null);
        setDragEnd(null);
        setDragging(false);
        return;
      }

      // Clear the timeout if touch ends before the delay
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
        setTouchStartTimeout(null);
      }
      handleEnd();
    };

    const findTimeBounds = ({ visit, employeeId }) => {
      const employee = visit?.employees[employeeId];
      let timeWindows = [];

      if (employee && employee.windows) {
        Object.values(employee.windows).forEach((window) => {
          const windowStart = moment(window?.start.toDate()).tz(
            visit.timeZoneId
          );
          const windowEnd = moment(window?.end.toDate()).tz(visit.timeZoneId);
          timeWindows.push({ start: windowStart, end: windowEnd });
        });
      } else {
        // If no specific windows are set for the employee, use the visit's start/end times
        let start = moment(visit.start.toDate()).tz(visit.timeZoneId);
        let end = moment(visit.end.toDate()).tz(visit.timeZoneId);
        timeWindows.push({ start, end });
      }

      return timeWindows;
    };

    const handleStart = (y) => {
      setDragStart(snapToInterval(y));
      setDragEnd(snapToInterval(y + 10));
      setDragging(true);
    };

    const handleMove = (y) => {
      if (!dragging) {
        if (touchStartTimeout) {
          clearTimeout(touchStartTimeout);
          setTouchStartTimeout(null);
        }
        return;
      }
      setDragEnd(snapToInterval(y));
    };

    const handleEnd = () => {
      setDragging(false);

      const startTime = calculateTime(Math.min(dragStart, dragEnd));
      const endTime = calculateTime(Math.max(dragStart, dragEnd));
      onCreateAvailability({ start: startTime, end: endTime });
      setDragStart(null);
      setDragEnd(null);
      // Additional logic if needed when drag ends
    };

    const handleTouchStart = (event) => {
      // Clear any existing timeout
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
      }

      const touchY = getTouchY(event);
      // Set a timeout to delay the start handling
      const timeoutId = setTimeout(() => {
        handleStart(touchY);
      }, 200); // 400ms delay, adjust as needed

      setTouchStartTimeout(timeoutId);
    };

    const handleTouchMove = (event) => {
      if (!dragging) {
        if (touchStartTimeout) {
          clearTimeout(touchStartTimeout);
          setTouchStartTimeout(null);
        }
        return;
      }

      handleMove(getTouchY(event));

      // Reset the timer on movement
    };

    const handleTouchEnd = (event) => {
      // Clear the timeout if touch ends before the delay
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
        setTouchStartTimeout(null);
      }

      handleEnd();
    };

    return (
      <Box
        sx={{
          borderLeft: "1px solid hsl(0, 0%, 86%)", //index === 0 ? "1px solid hsl(0, 0%, 86%)" : "none",
          borderRight: "none", //"1px solid hsl(0, 0%, 86%)",
          borderTop: "1px solid hsl(0, 0%, 76%)",
          borderBottom: "none", //"1px solid hsl(0, 0%, 86%)",
          boxSizing: "border-box",
          position: "relative",
          height: height,
          flexGrow: 1, // Added for even splitting of width
          padding: 1,
          userSelect: "none", // Disable text selection across browsers
          WebkitTouchCallout: "none", // Disable touch callouts on iOS
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Render the temporary rectangle */}
        {dragging &&
          Math.abs(dragEnd - dragStart) > 20 &&
          canAddAvailability && (
            <Box
              sx={{
                position: "absolute",
                top: `${Math.min(dragStart, dragEnd)}px`,
                height: `${Math.abs(dragEnd - dragStart)}px`,

                left: { xs: 0, sm: 2, md: 5 },
                right: { xs: 1, sm: 2, md: 5 },
                padding: { xs: "2px", sm: "5px", md: "10px" },

                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: "5px",
                border: "1px solid rgba(0, 0, 0, 0.4)",
                boxSizing: "border-box",
                zIndex: 10,
                overflow: "hidden",
                userSelect: "none",
                textOverflow: "ellipsis", // Add this to truncate text
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="caption" lineHeight={"0.8rem"}>
                {formatTimeRange(
                  calculateTime(Math.min(dragStart, dragEnd)),
                  calculateTime(Math.max(dragStart, dragEnd)),
                  timeZoneId,
                  true
                )}
              </Typography>
            </Box>
          )}
        {isToday && currentTimePosition !== null && (
          <Box
            sx={{
              position: "absolute",
              top: `${currentTimePosition}px`,
              left: 0,
              right: 0,
              height: "1px",
              zIndex: 20,
              backgroundColor: theme.palette.secondary.light,
              "&:before": {
                // Circle at the left edge
                content: '""',
                position: "absolute",
                left: "-5px",
                top: "-4px",
                width: "8px",
                height: "8px",
                backgroundColor: theme.palette.secondary.light,
                borderRadius: "50%",
              },
            }}
          />
        )}
        {dayVisits.map((visit) => {
          const timeWindows = findTimeBounds({ visit, employeeId });
          return timeWindows.map((window, index) => (
            <VisitItem
              key={`${visit.id}-${index}`}
              visit={visit}
              employeeId={employeeId}
              timeZoneId={timeZoneId}
              window={window}
              start={start}
              end={end}
              onSelect={onSelectVisit}
              currentUser={currentUser}
              userRole={userRole}
            />
          ));
        })}
        {dayOpenVisits.map((visit) => {
          const timeWindows = findTimeBounds({ visit, employeeId });
          return timeWindows.map((window, index) => (
            <VisitItem
              type={"openVisit"}
              key={`${visit.id}-${index}`}
              visit={visit}
              employeeId={employeeId}
              timeZoneId={timeZoneId}
              window={window}
              start={start}
              end={end}
              onSelect={onSelectVisit}
              userRole={userRole}
            />
          ));
        })}
        {availability &&
          availability.map((window, index) => (
            <AvailabilityWindowItem
              key={index}
              window={window}
              dayStart={start}
              dayEnd={end}
              height={height}
              timeZoneId={timeZoneId}
              employeeId={employeeId}
              currentUser={currentUser}
              userRole={userRole}
            />
          ))}
        {isBreak && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              backgroundColor: "rgba(128, 128, 128, 0.5)", // Semi-transparent gray
              pointerEvents: "none", // Optional: allow interactions to pass through
            }}
          ></Box>
        )}
      </Box>
    );
  }
);

const TimeMarker = ({ start, end, height }) => {
  const totalDuration = end.diff(start, "minutes");
  const markerCount = totalDuration / 15; // 15-minute intervals

  // Determine the shift needed based on the start time
  const startMinute = start.minute();

  const isLessThanFourHours = end.diff(start, "hours") < 4;

  const shift =
    startMinute === 0
      ? 0
      : startMinute === 15
      ? 1
      : startMinute === 30
      ? 2
      : -1;

  // Convert height from "200px" to 200
  const numericHeight = parseInt(height, 10);

  // Generate markers
  const markers = Array.from({ length: markerCount + 1 }, (_, i) => {
    const time = start.clone().add(i * 15, "minutes");
    let label = "";
    if ((i + shift) % 4 === 0) {
      label =
        time.minutes() === 0
          ? time.format("ha").toLowerCase()
          : time.format("h:mma").toLowerCase();
    }

    const is45MinMarker = (i + shift) % 4 === 1 || (i + shift) % 4 === 3;
    const alpha =
      numericHeight < 300 && is45MinMarker && !isLessThanFourHours
        ? 0
        : (i + shift) % 4 === 0
        ? 0.14
        : 0.04;

    return {
      position: (i / markerCount) * 100,
      label: label,
      alpha: alpha,
    };
  });

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
    >
      {markers.map((marker, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: `${marker.position}%`,
            height: "1px",
            left: 0, // Adjust top for vertical centering
            width: "100%",
            backgroundColor: `rgba(0, 0, 0, ${marker.alpha})`,
            zIndex: "-2",
          }}
        >
          {marker.label && (
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "50%", // Changed from '100%' to '0'
                transform: "translate(-110%, -50%)", // Adjust to move labels above the line
                whiteSpace: "nowrap",
                color: `rgba(0, 0, 0, 0.6)`,
              }}
            >
              {marker.label}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

const DayHeader = ({ date }) => {
  const theme = useTheme(); // Access the theme
  const isToday = moment().isSame(date, "day"); // Check if the date is today

  return (
    <Box
      sx={{
        // border: "1px solid black",
        position: "relative",
        padding: "5px",
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        // boxSizing: "border-box",
        userSelect: "none",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{date.format("ddd")}</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            // color: isToday ? "white" : "inherit", // Text color white if today, else default
            fontWeight: isToday ? "bold" : "inherit",
            backgroundColor: isToday
              ? theme.palette.primary.lighter
              : "transparent", // Circle color if today
            borderRadius: isToday ? "50%" : "none", // Circular if today
            width: theme.spacing(4), // Width of the circle if today
            height: theme.spacing(4), // Height of the circle if today
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {date.format("D")}
        </Typography>
      </Box>
    </Box>
  );
};

const ColumnView = ({
  numberOfDays,
  startDay,
  visits,
  openVisits = {},
  employeeId,
  // timeZoneId,
  areas = {},
  truncateView = false,
  height = "400px",
  availability = {},
  hideAvailability = false,
  canAddAvailability = false,
  userRole = "member",
}) => {
  const [days, setDays] = useState([]);
  const [selectedVisitId, setSelectedVisitId] = useState(null);
  const [selectedVisitType, setSelectedVisitType] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [openVisitDialog, setOpenVisitDialog] = useState(false);
  const [visitsExist, setVisitsExist] = useState(false);
  const [timelineElements, setTimelineElements] = useState([]);
  const [breaksMap, setBreaksMap] = useState({}); // New state for breaks

  // console.log("ColumnView - render")

  const { currentUser } = useAuth();

  const { openDrawer } = useDrawer();

  let dayStart, dayEnd;

  const luxonDate = DateTime.fromISO(startDay.toISOString()).setZone(
    "America/Los_Angeles"
  );

  const {
    areaData: {
      timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone ||
        "America/Los_Angeles",
    } = {},
  } = getCurrentArea(areas, luxonDate) || {};

  // console.log("startDay: ", startDay);
  // console.log("availability: ", availability);

  const processAreaWindows = (windows) => {
    const windowArray = Object.values(windows).map((window) => ({
      start: moment(window.start.toDate())
        .tz("America/Los_Angeles")
        .startOf("day"),
      end: window?.end
        ? moment(window.end.toDate()).tz("America/Los_Angeles").endOf("day")
        : moment().tz("America/Los_Angeles").endOf("day").add(100, "years"),
      weeklyAvailability: window.weeklyAvailability, // Include weeklyAvailability
      city: window.city, // Include city
    }));

    // Sort windows by start time
    windowArray.sort((a, b) => a.start - b.start);

    const mergedWindows = [];

    windowArray.forEach((window) => {
      if (mergedWindows.length === 0) {
        mergedWindows.push(window);
      } else {
        const lastWindow = mergedWindows[mergedWindows.length - 1];

        // If current window has weeklyAvailability of 0, start a new window
        if (window.weeklyAvailability === 0) {
          mergedWindows.push(window);
        }
        // If last window had weeklyAvailability of 0, start a new window
        else if (lastWindow.weeklyAvailability === 0) {
          mergedWindows.push(window);
        }
        // Merge windows if they are from the same city and overlap/are adjacent
        else if (
          lastWindow.city === window.city &&
          window.start.isSameOrBefore(lastWindow.end.clone().add(1, "minute"))
        ) {
          // Merge overlapping or adjacent windows
          lastWindow.end = moment.max(lastWindow.end, window.end);
        } else {
          mergedWindows.push(window);
        }
      }
    });

    return mergedWindows;
  };

  useEffect(() => {
    const daysArray = [];
    for (let i = 0; i < numberOfDays; i++) {
      daysArray.push(moment(startDay).tz(timeZoneId).add(i, "days"));
    }
    setDays(daysArray);
  }, [numberOfDays, startDay]);

  useEffect(() => {
    if (visits && Object.keys(visits).length > 0) {
      setVisitsExist(true);
    }
  }, [visits]);

  // Process areas to create timeline elements
  useEffect(() => {
    const totalDays = days.length;
    const colors = [
      "hsl(210, 100%, 30%)",
      "hsl(190, 100%, 30%)",
      "hsl(150, 100%, 30%)",
      "hsl(300, 100%, 30%)",
      "hsl(260, 100%, 30%)",
      "hsl(280, 100%, 30%)",
      "hsl(230, 100%, 30%)",
    ];
    let colorIndex = 0;

    const areaColors = {};
    const breaksMap = {}; // Initialize breaksMap
    const elements = [];

    if (
      !areas ||
      Object.keys(areas).length === 0 ||
      Object.values(areas).every(
        (area) =>
          !area.windows ||
          Object.values(area.windows).every((window) => window === 0)
      )
    ) {
      // If no areas exist or all windows are empty, display "Set service area" as the entire bar
      elements.push({
        left: 0,
        width: 100,
        color: "white", // Neutral gray for default message
        border: "1px solid hsl(190, 100%, 21%)",
        fontColor: "hsl(190, 100%, 21%)",
        city: "Tap to assign service area...", // Display text
        isBreak: false, // Not a break
      });
    } else {
      Object.entries(areas || {}).forEach(([areaKey, area]) => {
        // Assign a color to the area
        const areaColor = colors[colorIndex % colors.length];
        areaColors[areaKey] = areaColor;
        colorIndex++;

        // Process and merge windows
        const mergedWindows = processAreaWindows(
          area.windows || {},
          area.weeklyAvailability
        );

        mergedWindows.forEach((window) => {
          let startIndex = null;
          let endIndex = null;

          days.forEach((day, index) => {
            const dayStart = day.clone().startOf("day");
            const dayEnd = day.clone().endOf("day");

            // If window overlaps with the day
            if (
              window.end.tz("America/Los_Angeles").isAfter(dayStart) &&
              window.start.tz("America/Los_Angeles").isBefore(dayEnd)
            ) {
              if (startIndex === null) {
                startIndex = index;
              }
              endIndex = index;

              // Only mark the day as a break if the window overlaps and has weeklyAvailability === 0
              if (window.weeklyAvailability === 0) {
                breaksMap[
                  day.clone().tz("America/Los_Angeles").format("YYYY-MM-DD")
                ] = true;
              }
            }
          });

          if (startIndex !== null && endIndex !== null) {
            // Compute left and width in percentage
            const left = (startIndex / totalDays) * 100;
            const width = ((endIndex - startIndex + 1) / totalDays) * 100;

            elements.push({
              left,
              width,
              color:
                window.weeklyAvailability === 0
                  ? "rgba(128, 128, 128, 0.5)"
                  : areaColors[areaKey], // Gray if it's a break
              areaKey,
              city: window.weeklyAvailability === 0 ? "Break" : area.city, // Show "Break" if weeklyAvailability is zero
              isBreak: window.weeklyAvailability === 0, // Flag for break
            });
          }
        });
      });
    }

    setTimelineElements(elements);
    setBreaksMap(breaksMap); // Update breaksMap state
  }, [areas, days, timeZoneId]);

  if (!startDay) return;

  const filterAvailabilityForDay = (date) => {
    if (!availability || Object.keys(availability).length === 0) return [];

    // Convert the date to a moment object with the correct timezone
    const momentDate = moment(date).tz(timeZoneId);

    return Object.entries(availability).reduce(
      (filteredWindows, [key, window]) => {
        // Convert window start and end times to moment objects with the correct timezone
        const windowStart = moment(window.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

        // Check if the window falls on the same day
        if (
          windowStart.isSame(momentDate, "day") ||
          windowEnd.isSame(momentDate, "day") ||
          (windowStart.isBefore(momentDate, "day") &&
            windowEnd.isAfter(momentDate, "day"))
        ) {
          // Push the converted start and end moments along with the original window data
          filteredWindows.push({
            key,
            start: windowStart,
            end: windowEnd,
          });
        }
        return filteredWindows;
      },
      []
    );
  };

  const handleSelectVisit = (visit, type) => {
    // console.log("column view visitId: ", visit.id);
    if (
      userRole === "admin" ||
      (userRole === "employee" &&
        (visit?.employeeArr?.includes(currentUser?.uid) || visit?.isOpenVisit))
    ) {
      // console.log("userRole: ", userRole);
      // console.log(
      //   "visit?.employeeArr?.includes(currentUser?.uid): ",
      //   visit?.employeeArr?.includes(currentUser?.uid)
      // );
      // console.log("visit?.isOpenVisit: ", visit?.isOpenVisit);

      openDrawer(VisitDrawer, { visitId: visit.id, userRole });
    } else {
      console.log("You don't have permission to view this visit");
    }

    // setSelectedVisitId(visit.id);
    // setSelectedVisitType(type);
    // setOpenVisitDialog(true);
  };

  let minTimeInMinutes = 24 * 60; // Latest possible time in minutes (23:59)
  let maxTimeInMinutes = 0; // Earliest possible time in minutes (00:00)

  if (truncateView && visitsExist) {
    days.forEach((day) => {
      Object.values(visits).forEach((visit) => {
        let employeeWindows = availability;

        // Function to update min and max time based on a given time
        const updateTimeBounds = (time) => {
          const timeMinutes = time.hour() * 60 + time.minute();
          minTimeInMinutes = Math.min(minTimeInMinutes, timeMinutes);
          maxTimeInMinutes = Math.max(maxTimeInMinutes, timeMinutes);
        };

        // Check and update time bounds based on employee-specific custom windows for the visit
        const employeeVisitWindows = visit.employees?.[employeeId]?.windows;
        if (employeeVisitWindows) {
          // If employee windows exist, use those windows and ignore visit.start and visit.end
          Object.values(employeeVisitWindows).forEach((window) => {
            const windowStart = moment(window.start.toDate()).tz(timeZoneId);
            const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

            if (
              windowStart.isSame(day, "day") ||
              windowEnd.isSame(day, "day")
            ) {
              updateTimeBounds(windowStart);
              updateTimeBounds(windowEnd);
            }
          });
        } else {
          // If employee windows do not exist, use visit start and end times
          const visitStart = moment(visit.start.toDate()).tz(timeZoneId);
          const visitEnd = moment(visit.end.toDate()).tz(timeZoneId);

          if (visitStart.isSame(day, "day") || visitEnd.isSame(day, "day")) {
            updateTimeBounds(visitStart);
            updateTimeBounds(visitEnd);
          }
        }

        // Iterate over each window in employee windows (availability)
        if (employeeWindows) {
          Object.values(employeeWindows).forEach((window) => {
            const windowStart = moment(window.start.toDate()).tz(timeZoneId);
            const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

            if (
              windowStart.isSame(day, "day") ||
              windowEnd.isSame(day, "day")
            ) {
              updateTimeBounds(windowStart);
              updateTimeBounds(windowEnd);
            }
          });
        }

        // If hideAvailability is false, adjust time bounds based on availability windows
        if (!hideAvailability) {
          const availabilityWindows = availability[day.format("YYYY-MM-DD")];

          if (availabilityWindows) {
            availabilityWindows.forEach((window) => {
              const availStart = moment(window.start.toDate()).tz(timeZoneId);
              const availEnd = moment(window.end.toDate()).tz(timeZoneId);

              if (
                availStart.isSame(day, "day") ||
                availEnd.isSame(day, "day")
              ) {
                // Consider availability bounds as well as visit bounds
                minTimeInMinutes = Math.min(
                  minTimeInMinutes,
                  availStart.hour() * 60 + availStart.minute()
                );
                maxTimeInMinutes = Math.max(
                  maxTimeInMinutes,
                  availEnd.hour() * 60 + availEnd.minute()
                );
              }
            });
          }
        }

        // If openVisits is not empty, factor in open visits' time ranges
        if (Object.keys(openVisits).length > 0) {
          Object.values(openVisits).forEach((openVisit) => {
            const openVisitStart = moment(openVisit.start.toDate()).tz(
              timeZoneId
            );
            const openVisitEnd = moment(openVisit.end.toDate()).tz(timeZoneId);

            if (
              openVisitStart.isSame(day, "day") ||
              openVisitEnd.isSame(day, "day")
            ) {
              updateTimeBounds(openVisitStart);
              updateTimeBounds(openVisitEnd);
            }
          });
        }
      });
    });

    const dayStartHour = Math.floor(minTimeInMinutes / 60);
    const dayStartMinute = minTimeInMinutes % 60;
    const dayEndHour = Math.floor(maxTimeInMinutes / 60);
    const dayEndMinute = maxTimeInMinutes % 60;

    dayStart = moment()
      .hour(dayStartHour)
      .minute(dayStartMinute)
      .subtract(15, "minutes");
    dayEnd = moment().hour(dayEndHour).minute(dayEndMinute).add(15, "minutes");
  } else {
    // Standard time range if truncateView is false
    dayStart = moment(startDay).hour(8).minute(0);
    dayEnd = moment(startDay).hour(20).minute(0);
  }

  // funciton called when new availability slot is dragged and created
  const handleCreateAvailability = async ({ start, end }) => {
    // Return if start and end are on different days
    if (!start.isSame(end, "day")) return;

    // console.log("hideAvailability: ", hideAvailability);
    // console.log("!canAddAvailability: ", !canAddAvailability);

    if (hideAvailability || !canAddAvailability) return;

    // Force end time to 8 PM if it's later than that
    if (end.hour() >= 20) {
      end = end.clone().hour(20).minute(0).second(0).millisecond(0);
    }

    // Force start time to 8 AM if it's earlier than that
    if (start.hour() < 8) {
      start = start.clone().hour(8).minute(0).second(0).millisecond(0);
    }

    // Assuming 'end' is a moment object
    const startOfTheWeek = moment().tz(timeZoneId).startOf("week"); // This will be Sunday of the current week

    if (start.isBefore(startOfTheWeek)) {
      return; // End is before the start of the week
    }

    if (end.diff(start, "minutes") < 30) return;

    try {
      const updatedAvailability = {
        start: start.toDate(),
        end: end.toDate(),
      };

      if (employeeId) {
        const updatedWindows = addAndMergeWindows({
          availability,
          addWindow: updatedAvailability,
        });

        // You could put a check around this to see if the previous availability had zero future
        const employeeDocRef = doc(db, "employees", employeeId);
        await updateDoc(employeeDocRef, {
          "flags.pendingServiceUpdate": true,
          "flags.hasNewFutureAvailability": true,
          "availability.windows": updatedWindows,
        });

        // console.log("Availability created successfully:", updatedAvailability);
      }
    } catch (error) {
      console.error("Error creating availability:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          // borderRadius: "15px",
          overflow: "hidden",
          pl: { xs: "35px", sm: 6 },
          // border: "1px solid rgba(0, 0, 0, 0.12)",
          zIndex: 15,
          userSelect: "none", // Disable text selection across browsers
          WebkitTouchCallout: "none", // Disable touch callouts on iOS
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            zIndex: "10",
            overflowX: "hidden",
            pt: { xs: 0, sm: 1 },
            userSelect: "none", // Disable text selection across browsers
            WebkitTouchCallout: "none", // Disable touch callouts on iOS
          }}
        >
          {days.map((date) => (
            <DayHeader key={date.toString()} date={date} />
          ))}
        </Box>
        {/* Timeline Component */}
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "24px",
            borderRadius: "5px",
            mb: "2px",
          }}
        >
          {timelineElements.map((element, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                left: `${element.left}%`,
                width: `${element.width}%`,
                height: "100%",
                backgroundColor: element.color,
                border: element.border,
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                pl: "8px",
                boxSizing: "border-box",
                cursor: "pointer", // Disable pointer for breaks
                color: element?.fontColor ? element.fontColor : "white",
                fontSize: "12px",
                fontWeight: "600",
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              onClick={() =>
                openDrawer(EmployeeTimeline, {
                  employeeId,
                  areas,
                  availability,
                  title: "Area Timeline",
                })
              }
            >
              {element.city}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            position: "relative",
            zIndex: "10",
            userSelect: "none", // Disable text selection across browsers
            WebkitTouchCallout: "none", // Disable touch callouts on iOS
          }}
        >
          <TimeMarker start={dayStart} end={dayEnd} height={height} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              position: "relative",
              width: "100%",
              overflow: "hidden",
              userSelect: "none", // Disable text selection across browsers
              WebkitTouchCallout: "none", // Disable touch callouts on iOS
            }}
          >
            {days.map((date, index) => (
              <DayColumn
                key={date.toString()}
                areas={areas}
                date={date}
                visits={visits}
                openVisits={openVisits}
                employeeId={employeeId}
                start={dayStart}
                end={dayEnd}
                index={index}
                onSelectVisit={handleSelectVisit}
                height={height}
                currentUser={currentUser}
                availability={
                  hideAvailability ? [] : filterAvailabilityForDay(date)
                } // Pass the availability data unless we hide it
                onCreateAvailability={handleCreateAvailability}
                visitsExist={visitsExist}
                userRole={userRole}
                canAddAvailability={canAddAvailability}
                isBreak={!!breaksMap[date.format("YYYY-MM-DD")]} // Correctly pass isBreak
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ColumnView;
