import { AddAPhoto, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { db, storage } from "../../firebase"; // Import your Firebase configurations
import PostHeader from "./PostHeader";

const CreatePost = ({
  user,
  addNewPost = () => {},
  mediaRequired = false,
  textRequired = true,
  placeholder = "What's on your mind?",
}) => {
  const [postContent, setPostContent] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]); // Changed to handle multiple files
  const [mediaPreviews, setMediaPreviews] = useState([]); // Previews for multiple files
  const [uploading, setUploading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDrop = useCallback((acceptedFiles) => {
    // Update the mediaFiles state with new files
    setMediaFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    // Generate previews
    const newPreviews = acceptedFiles.map((file) => {
      // Similar logic as in handleMediaChange
      if (file.type.startsWith("video/")) {
        return Promise.resolve(URL.createObjectURL(file));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
        });
      }
    });

    Promise.all(newPreviews).then((newPreviews) => {
      setMediaPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getTooltipMessage = () => {
    let message = "";

    if (
      mediaRequired &&
      !mediaFiles.length &&
      textRequired &&
      !postContent.trim()
    ) {
      message = "Add a photo or video and some text above.";
    } else if (mediaRequired && !mediaFiles.length) {
      message = "Make sure to add a photo or video.";
    } else if (textRequired && !postContent.trim()) {
      message = "Almost there! Write some text above.";
    }

    return message;
  };

  const handleMediaChange = (e) => {
    const files = Array.from(e.target.files);
    setMediaFiles(files);

    const previews = files.map((file) => {
      // Check if the file is a video
      if (file.type.startsWith("video/")) {
        // Create an object URL for videos
        return Promise.resolve(URL.createObjectURL(file));
      } else {
        // Use FileReader for images
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
        });
      }
    });

    Promise.all(previews).then(setMediaPreviews);
  };

  // Function to handle file deletion
  const handleDeleteFile = (index) => {
    // Remove the file at the given index
    const newFiles = [...mediaFiles];
    newFiles.splice(index, 1);
    setMediaFiles(newFiles);

    // Remove the preview at the given index
    const newPreviews = [...mediaPreviews];
    newPreviews.splice(index, 1);
    setMediaPreviews(newPreviews);
  };

  const uploadMedia = async () => {
    if (mediaFiles.length === 0) return [];
    return Promise.all(
      mediaFiles.map((file) => {
        const mediaRef = ref(storage, `media/${user?.id}/${file.name}`);
        return uploadBytes(mediaRef, file).then((snapshot) =>
          getDownloadURL(snapshot.ref)
        );
      })
    );
  };

  const handleSubmit = async () => {
    if (uploading || !postContent.trim()) return; //|| !mediaFiles.length

    setUploading(true);
    try {
      const mediaUrls = await uploadMedia();
      const fullName = `${user.firstName} ${user.lastName}`;

      const postDoc = {
        location: user.location,
        userId: user?.id,
        displayName: fullName,
        avatarUrl: user.avatarUrl,
        content: postContent,
        mediaUrls, // Array of media URLs
        createdAt: serverTimestamp(),
        likeCount: 0,
        commentCount: 0,
        visibility: "public", // or set based on user choice
      };

      const docRef = await addDoc(collection(db, "posts"), postDoc);
      addNewPost({ id: docRef.id, ...postDoc });
    } catch (error) {
      console.error("Error uploading media: ", error);
    }

    setPostContent("");
    setMediaFiles([]);
    setMediaPreviews([]);
    setUploading(false);
  };

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column" },
          }}
        >
          <PostHeader
            avatarUrl={user.avatarUrl}
            displayName={`${user.firstName} ${user.lastName}`}
            city={user?.location?.city}
            state={user?.location?.state}
          />
          <TextField
            multiline
            variant="outlined" // Changed variant
            fullWidth
            placeholder={placeholder} // Stand-in text
            value={postContent}
            onChange={(e) => setPostContent(e.target.value)}
            sx={{
              marginBottom: 2,
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the border
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the border on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the border when the TextField is focused
              },
            }}
          />
          <Box
            sx={{
              my: 2,
              borderRadius: "15px",
              transition: "background-color .2s ease-in-out",
              "&:hover": {
                backgroundColor: "#F5F5F5", // Change this to your desired color
              },
            }}
          >
            <div {...getRootProps()} style={dropzoneStyle(isDragActive)}>
              <input {...getInputProps()} accept="image/*,video/*" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AddAPhoto sx={{ mr: 1 }} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    {isMobile
                      ? "Tap here to select files"
                      : "Drag 'n' drop files here, or click to select"}
                  </p>
                )}
              </div>
            </div>
          </Box>
        </Box>
        <Grid container spacing={2}>
          {mediaPreviews.map((preview, index) => (
            <Grid item xs={4} key={index}>
              <Box position="relative">
                <IconButton
                  onClick={() => handleDeleteFile(index)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                    zIndex: 10,
                  }}
                >
                  <Delete />
                </IconButton>
                {mediaFiles[index] &&
                mediaFiles[index].type.startsWith("video/") ? (
                  <video
                    src={preview}
                    alt="Video Preview"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "15px",
                    }}
                    controls
                  />
                ) : (
                  <img
                    src={preview}
                    alt="Image Preview"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "15px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 10,
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Tooltip
          title={getTooltipMessage()}
          enterTouchDelay={0}
          arrow
          disableHoverListener={!getTooltipMessage()}
        >
          <span style={{ width: "100%" }}>
            {/* Tooltip requires a non-disabled child */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                uploading ||
                (mediaRequired && !mediaFiles.length) ||
                (textRequired && !postContent.trim())
              }
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
              }}
            >
              {uploading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Post"
              )}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};

// Styling for the dropzone
const dropzoneStyle = (isDragActive) => ({
  border: "2px dashed #eeeeee",
  borderRadius: "15px",
  textAlign: "center",
  color: isDragActive ? "#2196f3" : "#757575",
  cursor: "pointer", // Cursor as pointer
  transition: "border .24s ease-in-out",
  padding: "16px",
});

export default CreatePost;
