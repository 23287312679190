import { VisibilityOff, VisibilityOffOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { useSnackbar } from "../../../providers/SnackbarProvider";
import { onHideOpenVisit } from "../../../services/employeeServices";

const HideOpenVisitButton = ({
  visitId,
  currentEmployeeId,
  customStyles,
  handleHide,
  loading,
  setLoading,
}) => {
  const { showSnackbar } = useSnackbar();

  const handleHideClick = () => {
    onHideOpenVisit({
      currentEmployeeId,
      visitId, // Assuming each visit has an id property
      setLoading,
      showSnackbar,
      handleHide,
    });
  };

  return (
    <>
      <Button
        style={customStyles}
        disabled={loading}
        onClick={handleHideClick}
        sx={{ textTransform: "none" }}
      >
        {loading === "hide" ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <>
            <VisibilityOffOutlined sx={{mr: 1}} />
            Hide
          </>
        )}
      </Button>
    </>
  );
};

export default HideOpenVisitButton;
