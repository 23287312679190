import { createTheme } from "@mui/material/styles";

// import palette from './palette';

const theme = createTheme({
  palette: {
    primary: {
      main: "hsl(190,100%,30%)",
      vibrant: "hsla( 190,100%,50% ,1)",
      lighter: "hsla( 190, 100%, 80%, 1)",
      lightest: "hsla( 190, 100%, 95%, 1)",
      darkest: "hsla( 190, 100%, 20%, 1)",
    },
    secondary: {
      main: "hsl(10,100%,30%)",
      vibrant: "hsla( 10,100%,50% ,1)",
      lighter: "hsla( 10, 100%, 80%, 1)",
      lightest: "hsla( 10, 100%, 95%, 1)",
      darkest: "hsla( 10, 100%, 20%, 1)",
    },
    success: {
      main: "hsl(150,100%,30%)",
      vibrant: "hsla( 150,100%,50% ,1)",
      lighter: "hsla( 150, 100%, 80%, 1)",
      lightest: "hsla( 150, 100%, 95%, 1)",
      darkest: "hsla( 150, 100%, 20%, 1)",
    },
    allWhite: {
      main: "hsl(0,100%,100%)",
    },
    grayscale: {
      light: "#f5f5f5", // A light shade of gray
      main: "#9e9e9e", // A medium shade of gray
      dark: "#616161", // A dark shade of gray
    },
    vibrant: {
      main: "hsla( 190,100%,50% ,1)",
    },
    complementary: {
      color1: "hsl(190, 80%, 80%)",
      color2: "hsl(310, 80%, 80%)",
      color3: "hsl(120, 80%, 80%)",
      color4: "hsl(250, 80%, 80%)",
      color6: "hsl(10, 80%, 80%)",
      color5: "hsl(60, 80%, 80%)",
    },
    complementaryContrast: {
      color1: "hsl(190, 65%, 65%)",
      color2: "hsl(310, 65%, 65%)",
      color3: "hsl(120, 65%, 65%)",
      color4: "hsl(250, 65%, 65%)",
      color6: "hsl(10, 65%, 65%)",
      color5: "hsl(60, 65%, 65%)",
    },
  },
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    h1: {
      fontFamily: "'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif",

      fontWeight: 700, // or 'bold'
    },
    h2: {
      fontFamily: "'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif",

      fontWeight: 700, // or 'bold'
    },
    h3: {
      fontFamily: "'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif",

      fontWeight: 700, // or 'bold'
    },
    h4: {
      fontFamily: "'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif",

      fontWeight: 700, // or 'bold'
    },
    h5: {
      fontFamily: "'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif",

      fontWeight: 700, // or 'bold'
    },
    h6: {
      fontFamily: "'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif",

      fontWeight: 700, // or 'bold'
    },
  },
  shape: {
    borderRadius: 15, // This will set the default border radius for most components
  },
  shape: {
    borderRadius: 15,
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 15,
      },
    },
  },
  components: {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': { // Mobile breakpoint for small screens
            paddingBottom: "24px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
    // MuiCard: {
    //   styleOverrides: {
    //     // Apply this style to both outlined and standard variants
    //     root: {
    //       boxShadow:
    //         "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
    //       "&.MuiPaper-outlined": {
    //         // This targets the outlined variant specifically
    //         boxShadow:
    //           "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
    //       },
    //     },
    //   },
    // },
  },
});

export default theme;
