import { Delete, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import {
  fetchLocationDetailsFromBounds,
  generateGeohashesForEmployee,
} from "../../services/locationServices";
import GoogleMapsDrawingV2 from "../maps/GoogleMapsDrawingV2";

const validateBounds = (bounds) => {
  let errors = {};

  if (!bounds || bounds.length < 3)
    errors.bounds = "You must draw your service map.";

  return errors;
};

const ServiceArea = ({
  areaKey,
  employeeId,
  areas,
  onClose = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  buttonText = "Continue",
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(!areaKey); // Start in edit mode if no key
  const [area, setArea] = useState(areas[areaKey] || {});
  const [bounds, setBounds] = useState(area.bounds || []);
  const [title, setTitle] = useState(area.displayName || "");
  const [city, setCity] = useState(area.city || "");
  const [state, setState] = useState(area.state || "");
  const [zipCode, setZipCode] = useState(area.zipCode || "");

  // Inside your ServiceArea component
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleDeleteArea = async () => {
    try {
      if (areaKey) {
        // Remove the area from the local state
        const updatedAreas = { ...areas };
        delete updatedAreas[areaKey];

        // Generate geohashes for the updated areas
        const geohash5Arr = generateGeohashesForEmployee(updatedAreas);

        // Update Firestore
        await updateDoc(doc(db, "employees", employeeId), {
          geohash5Arr,
          [`areas.${areaKey}`]: deleteField(), // Delete the specific area
        });

        console.log("Service area deleted successfully.");
        onClose(); // Close the modal or perform any necessary UI updates
      }
    } catch (error) {
      console.error("Error deleting service area:", error);
    } finally {
      handleMenuClose();
    }
  };

  const saveArea = async () => {
    setLoading(true);

    let newError = validateBounds(bounds);
    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }

    const key = areaKey || uuidv4(); // Generate a new key if none provided

    try {
      // Fetch location details from bounds
      const locationDetails = await fetchLocationDetailsFromBounds(bounds);
      setCity(locationDetails.city);
      setState(locationDetails.state);
      setZipCode(locationDetails.zipCode);

      // Prepare the updated area data
      const updatedArea = {
        ...areas[key], // Preserve existing properties, if any
        bounds,
        displayName: title,
        city: locationDetails.city,
        state: locationDetails.state,
        zipCode: locationDetails.zipCode,
        lat: locationDetails.lat,
        lng: locationDetails.lng,
        geohash6: locationDetails.geohash6,
        timeZoneId: locationDetails.timeZoneId,
      };

      // Safely update the areas map without mutating the original
      const updatedAreas = {
        ...areas,
        [key]: updatedArea,
      };

      // Generate geohashes for the updated areas
      const geohash5Arr = generateGeohashesForEmployee(updatedAreas);

      // Update the `areas` field in Firestore
      await updateDoc(doc(db, "employees", employeeId), {
        areas: updatedAreas,
        geohash5Arr,
        "flags.pendingServiceUpdate": true,
        "flags.hasBoundsUpdate": true,
      });

      console.log("Service area saved successfully.");

      onClose({
        key,
        displayName: title,
        bounds,
        city: locationDetails.city,
        state: locationDetails.state,
        zipCode: locationDetails.zipCode,
        lat: locationDetails.lat,
        lng: locationDetails.lng,
        timeZoneId: locationDetails.timeZoneId,
        geohash6: locationDetails.geohash6,
      });
    } catch (error) {
      console.error("Error saving service area:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (areaKey && areas[areaKey]) {
      setArea(areas[areaKey]);
      setBounds(areas[areaKey].bounds || []);
      setTitle(areas[areaKey].displayName || "");
      setCity(areas[areaKey].city || "");
      setState(areas[areaKey].state || "");
      setZipCode(areas[areaKey].zipCode || "");
    }
  }, [areaKey, areas]);

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          pt: { xs: 0, sm: 2 },
          pb: { xs: 0, sm: 2 },
          px: 2,
          minHeight: "300px",
          alignItems: "flex-start",
        }}
      >
        {/* Top-right absolute menu */}
        <Box
          sx={{
            position: "absolute",
            top: 18,
            right: 16,
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleDeleteArea}>
              <Delete sx={{ mr: 1 }} />
              Delete
            </MenuItem>
          </Menu>
        </Box>

        {/* Editable Fields */}
        {/* <TextField
          label="Nickname"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          inputProps={{ maxLength: 50 }} // Restrict input to 50 characters
          helperText={`${title.length}/50 characters`} // Show character count
        /> */}
        <Box
          sx={{
            height: "auto",
            width: { xs: "100vw", sm: "100%" },
            mx: { xs: -2, sm: 0 },
            boxSizing: "content-box",
          }}
        >
          <GoogleMapsDrawingV2
            onBoundsChange={(bounds) => {
              setBounds(bounds);
              setEditMode(true);
            }}
            employeeGeohash={area?.geohash6}
            initialBounds={bounds || []}
            initialCenter={{
              lat: area?.lat || "",
              lng: area?.lng || "",
            }}
          />
        </Box>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button
          variant="contained"
          disabled={bounds.length === 0 || loading}
          color="primary"
          onClick={saveArea}
          sx={{ marginRight: 2, height: "56px", width: "100%" }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceArea;
