import { useTheme } from "@emotion/react";
import { Close, Search, Send } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { Timestamp, arrayUnion, doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnlockFAQ from "../../../components/popup/UnlockFAQ";
import { db } from "../../../firebase";
import { addField, logAction } from "../../../hooks/useLogPageVisit";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&::before": {
    display: "none",
  },
  borderRadius: "15px",
  overflow: "hidden",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FAQAccordion = ({ data, stickySearch = true, pageName = "" }) => {
  const [expanded, setExpanded] = React.useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [anonymousQuestion, setAnonymousQuestion] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const theme = useTheme();

  const [lastSubmitTime, setLastSubmitTime] = useState(0); // Track the last submit time

  const handleAnonymousSubmit = () => {
    const currentTime = Date.now();

    // Check if 10 seconds have passed since the last submit
    if (currentTime - lastSubmitTime >= 10000) {
      if (anonymousQuestion.trim()) {
        setSubmitted(true);
        // sendSMSClient({
        //   phone: 3603366344,
        //   body: `Q: ${anonymousQuestion}`,
        // });
        addField("_questions", `Q: ${anonymousQuestion}`);
        logAction(`Q: ${anonymousQuestion}`);

        setAnonymousQuestion(""); // Clear the input after submission
        setLastSubmitTime(currentTime); // Update last submit time
      }
    } else {
      console.log("Please wait before submitting again."); // Optional feedback to the user
    }
  };

  const [expansionCount, setExpansionCount] = useState(
    Number(sessionStorage.getItem("expansionCount") || 0)
  );
  const [leadCaptured, setLeadCaptured] = useState(
    localStorage.getItem("leadCaptured") === "true"
  );

  const handleChange = (panel) => async (event, newExpanded) => {
    if (newExpanded) {
      setExpansionCount((prevCount) => {
        const newCount = prevCount + 1;
        sessionStorage.setItem("expansionCount", newCount);
        return newCount;
      });
    }

    setExpanded(newExpanded ? panel : false);

    if (newExpanded) {
      logAction(`${pageName}/faq/${data[panel]?.question?.title || ""}`);
    }
  };

  // Filter FAQ data based on search term, ignoring case and spaces
  const filteredData = data.filter(
    (item) =>
      item.question.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.question.subtitle
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const clearSearch = () => {
    setSearchTerm("");
  };

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000); // 1 second debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Log search only after debounced term updates
  useEffect(() => {
    const logSearch = async () => {
      if (debouncedSearchTerm.trim() !== "") {
        const logEntry = {
          page: "faq",
          timestamp: Timestamp.now(),
          action: "search",
          searchTerm: debouncedSearchTerm,
        };

        let storedSessionId = localStorage.getItem("sessionId");
        if (storedSessionId) {
          try {
            await setDoc(
              doc(db, "sessions", storedSessionId),
              {
                "~logs": arrayUnion(logEntry),
              },
              { merge: true }
            );
          } catch (err) {
            console.log("error logging search: ", err);
          }
        }
      }
    };

    if (debouncedSearchTerm) {
      logSearch();
    }
  }, [debouncedSearchTerm]);

  const handleUnlock = () => {
    setLeadCaptured(true);
  };

  return (
    <div>
      <Box
        sx={{
          my: 2,
          position: "relative",
          backgroundColor: "white",
          zIndex: 10,
          boxSizing: "border-box",
        }}
      >
        <TextField
          fullWidth
          placeholder="Search FAQs..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ mr: 1 }} />
              </InputAdornment>
            ),
            sx: {
              "& .MuiInputBase-root": {
                backgroundColor: "white", // Set background color for the InputBase
              },
            },
          }}
        />
      </Box>
      {filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <React.Fragment key={index}>
            <Accordion
              key={index}
              onChange={handleChange(index)}
              expanded={expanded === index}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  {item.icon && <div>{item.icon}</div>}
                  <Box>
                    <Typography variant="body1" fontWeight={"550"}>
                      {item.question.title}
                    </Typography>
                    {item.question.subtitle && (
                      <Typography variant="body2" color="text.secondary">
                        {item.question.subtitle}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {leadCaptured || expansionCount < 3 || true ? (
                  <Typography variant="body2">{item.answer}</Typography>
                ) : (
                  <>
                    <Box
                      sx={{
                        position: "relative",
                        filter: "blur(4px)",
                        pointerEvents: "none",
                      }}
                    >
                      <Typography variant="body2">{item.answer}</Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      <UnlockFAQ handleSubmit={handleUnlock} />
                    </Box>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Box sx={{ height: "16px" }} />
          </React.Fragment>
        ))
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 2 }}>
          No FAQs match your search.
        </Typography>
      )}
      <Box
        sx={{
          mb: 4,
          textAlign: "center",
          mx: "auto",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "15px",
          p: 2,
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(255, 255, 255, .05)"
              : "rgba(0, 0, 0, .03)",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Missing a question?{" "}
        </Typography>
        <Typography>
          Submit your unanswered questions anonymously and we'll update our FAQ.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 1,
            mt: 2,
          }}
        >
          <TextField
            fullWidth
            placeholder="Type your question..."
            variant="outlined"
            multiline={true}
            value={anonymousQuestion}
            onChange={(e) => {
              const sanitizedInput = e.target.value.replace(/<[^>]*>/g, ""); // Remove HTML tags
              if (sanitizedInput.length <= 200) {
                // Limit length to 200 characters
                setAnonymousQuestion(sanitizedInput);
              }
            }}
            sx={{ background: "white" }}
            inputProps={{ maxLength: 200 }} // Set max length directly on input
          />

          <Button
            onClick={handleAnonymousSubmit}
            disabled={!anonymousQuestion.trim()}
            color="primary"
            variant="contained"
            startIcon={<Send />}
            sx={{
              height: "56px",
              textTransform: "none",
              width: { xs: "100%", sm: "150px" },
            }}
          >
            Submit
          </Button>
        </Box>
        {submitted && anonymousQuestion.length === 0 && (
          <>
            <Typography variant="body2" sx={{ mt: 1, color: "success.main" }}>
              Thank you! Your question has been submitted anonymously, check
              back for updates within the next day.
            </Typography>
            <Alert
              severity="warning"
              onClick={() => navigate("/contact")}
              sx={{
                cursor: "pointer",
                mx: "auto",
                width: "fit-content",
                mt: 1,
              }}
            >
              If you'd like us to reply, please{" "}
              <Link sx={{ color: "black" }} href="/contact">
                contact us directly
              </Link>
              .
            </Alert>
          </>
        )}
      </Box>
    </div>
  );
};

export default FAQAccordion;
