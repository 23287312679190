import { ExpandMore, LocationOn } from "@mui/icons-material";
import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import GoogleMapsDisplay from "../../dashboard/employee/tabs/GoogleMapsDisplay";
import { useDrawer } from "../../providers/DrawerProvider";
import { convertStateNameToAbbreviation } from "../../services/locationServices";
import ServiceArea from "../serviceArea/ServiceArea";

const LocationMap = ({
  areaData,
  areaKey,
  areas,
  employeeId,
  userRole = "member",
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);
  const { openDrawer } = useDrawer();

  return (
    <Box sx={{ mt: 1 }}>
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          backgroundColor: "#F5F5F5",
          p: 2,
          borderRadius: "15px",
          alignItems: "flex-start",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <LocationOn />
            <Typography variant="body1">
              {areaData?.city},{" "}
              {convertStateNameToAbbreviation(areaData?.state)}
            </Typography>
          </Box>
          <ExpandMore
            color="primary"
            sx={{
              transform: expanded ? "rotate(0deg)" : "rotate(90deg)", // Rotate based on expanded state
              transition: "transform 0.1s ease", // Smooth transition for rotation
            }}
          />
        </Box>

        <Collapse in={expanded} sx={{ width: "100%", mt: -1 }}>
          <div style={{ height: "10px" }}></div>
          <GoogleMapsDisplay
            // handleEdit={() => ()}
            buttonText={"Save"}
            employeeGeohash={areaData?.geohash}
            initialBounds={areaData?.bounds || []}
            initialCenter={{
              lat: areaData?.lat || "",
              lng: areaData?.lng || "",
            }}
          />
          {userRole === "admin" && (
            <Button
              onClick={() =>
                openDrawer(ServiceArea, {
                  title: `Edit ${areaData.city} area`,
                  areaKey,
                  areas,
                  employeeId,
                  disableSwipeToClose: true,
                })
              }
              sx={{ textTransform: "none", mt: 1 }}
            >
              Edit
            </Button>
          )}
        </Collapse>
      </Box>
    </Box>
  );
};

export default LocationMap;
