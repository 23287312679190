import { Check } from "@mui/icons-material";
import {
  Box,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import useTrackVisibility from "../../../hooks/useTrackVisibility";
import { TASKS_LIST } from "../../../services/taskServices";

const ServicesGrid = ({ expanded = true, pageName = "", listOnly = false }) => {
  const ref = useTrackVisibility(() => {}, `${pageName}/"services overview"`); // Pass the logMessage here

  const renderTask = (task) => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Icon>{task.icon}</Icon>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: listOnly ? "14px" : expanded ? "20px" : "14px",
                sm: listOnly ? "14px" : "20px",
              },
              fontWeight: listOnly ? 500 : 800,
            }}
          >
            {task.title}
          </Typography>
        </Box>
        {expanded && (
          <Typography variant="body2" color="text.secondary">
            {task.description}
          </Typography>
        )}
        {expanded && (
          <List dense>
            {task.examples.map((example, idx) => (
              <ListItem key={idx} disableGutters disablePadding>
                <Check color="primary" sx={{ mr: 1 }} />
                <ListItemText primary={example} />
              </ListItem>
            ))}
          </List>
        )}
      </>
    );
  };

  return (
    <Box
      ref={ref}
      sx={{
        flexGrow: 1,
        padding: { xs: 0, sm: listOnly ? 0 : 2 },
        maxWidth: "1200px",
        width: "100%",
        mx: "auto",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: listOnly
            ? {
                xs: "repeat(2, 1fr)",
                sm: "repeat(auto-fill, minmax(175px, 1fr))",
              }
            : expanded
            ? "repeat(auto-fill, minmax(250px, 1fr))" // 1 column for expanded on mobile
            : {
                xs: "repeat(2, 1fr)",
                sm: "repeat(auto-fill, minmax(250px, 1fr))",
              }, // 2 columns on mobile, default on larger screens
          gap: { xs: listOnly ? 0 : 1, sm: listOnly ? 0 : 2 },
          padding: { xs: 0, sm: listOnly ? 0 : 2 },
          boxSizing: "border-box",
        }}
      >
        {TASKS_LIST.map((task, index) => (
          <Box
            sx={{
              padding: { xs: listOnly ? 1 : 2, sm: listOnly ? 1 : 4 },
              backgroundColor: listOnly ? "transparent" : "background.paper",
              borderRadius: 1,
              border: listOnly ? "none" : "1px solid rgba(0,0,0,0.1)",
              boxShadow: listOnly
                ? "transparent"
                : "rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 3px 6px",
              // height: "100%",
            }}
          >
            {renderTask(task)}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ServicesGrid;
