import { Public } from "@mui/icons-material";
import { Avatar, CardHeader, Stack, Tooltip } from "@mui/material";
import moment from "moment-timezone";
import React from "react";

const PostHeader = ({
  avatarUrl,
  displayName,
  createdAt = null,
  city = null,
  state = null,
  visibility = "public",
}) => {
  const convertToDate = (createdAt) => {
    if (!createdAt) return null;

    // Check if createdAt is a Firebase Timestamp
    if (typeof createdAt.toDate === "function") {
      return createdAt.toDate();
    }

    // Check if createdAt is a Moment object
    if (createdAt instanceof moment) {
      return createdAt.toDate();
    }

    // If already a Date object or something else
    return createdAt;
  };

  const createdAtDate = convertToDate(createdAt);
  const formattedDate = moment(createdAtDate).format("MMM Do YYYY, hh:mma");

  const joinWithSeparator = (parts, separator = " · ") =>
    parts.flatMap((part, index) =>
      index < parts.length - 1 ? [part, separator] : part
    );

  return (
    <CardHeader
      avatar={
        <Avatar
          src={avatarUrl}
          alt={displayName}
          sx={{ width: "50px", height: "50px" }}
        >
          {!avatarUrl ? displayName?.charAt(0) : ""}
        </Avatar>
      }
      title={displayName}
      titleTypographyProps={{ variant: "body1" }}
      subheader={
        <Stack direction="row" alignItems="center">
          {visibility === "public" && (
            <Tooltip title={"Visible to anyone on or off Linked Lives"}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <Public sx={{ mr: "4px", width: "16px", height: "16px" }} />
              </span>
            </Tooltip>
          )}

          {createdAtDate && (
            <Tooltip title={formattedDate}>
              <span style={{ marginRight: "4px" }}>
                {" · "}
                {moment(createdAtDate).fromNow()}
              </span>
            </Tooltip>
          )}
          {city && state && (
            <span>
              {"  · "} {`${city}`}
            </span>
          )}
        </Stack>
      }
      subheaderTypographyProps={{ variant: "body2" }}
    />
  );
};

export default PostHeader;
