import moment from "moment-timezone";
import React, { useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import WeekView from "../calendar/WeekView";

const EmployeeSchedule = ({
  employeeData,
  visitsData,
  userRole = "member",
}) => {
  const {
    id: employeeId,
    timeZoneId,
    availability: { windows = {} } = {},
    areas = {},
  } = employeeData;

  console.log("employeeId EmployeeSchedule: ", employeeId);

  console.log("employeeData EmployeeSchedule: ", employeeData);

  const { currentUser } = useAuth();

  const [calendarDate, setCalendarDate] = useState(moment().tz(timeZoneId));

  return (
    <WeekView
      initialDay={calendarDate}
      areas={areas}
      calendarDate={calendarDate}
      setCalendarDate={setCalendarDate}
      visits={visitsData}
      employeeId={employeeId}
      timeZoneId={timeZoneId}
      availability={windows}
      userRole={userRole}
      canAddAvailability={
        (userRole === "employee" && employeeId === currentUser?.uid) ||
        userRole === "admin"
      }
      // isMobile={false}
      // height={boxHeight - 48}
    />
  );
};

export default EmployeeSchedule;
