import React from "react";
import { Box, Divider, ListItemButton, ListItemText } from "@mui/material";
import GoogleMapsDisplay from "../../dashboard/employee/tabs/GoogleMapsDisplay";

const ServiceAreaListItem = ({ areaKey, area, handleItemClick, employeeData }) => {
  return (
    <>
      <ListItemButton
        onClick={() => handleItemClick(areaKey)}
        sx={{ p: { xs: 1, sm: 2 }, width: "100%" }}
      >
        <ListItemText
          primary={area.displayName}
          secondary={`${area.city}, ${area.state}`}
          sx={{ width: "40vw" }}
        />
        <Box sx={{ width: "100%", height: {xs: "100%"} }}>
          {area?.bounds?.length > 0 && (
            <GoogleMapsDisplay
              buttonText={"Save"}
              employeeGeohash={area?.geohash6}
              initialBounds={area?.bounds || []}
              desktopHeight={"200px"}
              initialCenter={{
                lat: area?.lat || "",
                lng: area?.lng || "",
              }}
            />
          )}
        </Box>
      </ListItemButton>
      <Divider />
    </>
  );
};

export default ServiceAreaListItem;
