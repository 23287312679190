import { FormatListBulletedOutlined, Notes } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

const DescriptionListItem = ({
  visitData = {},
  visitPrivateData = {},
  userRole = "",
}) => {
  const {
    description = "",
    start = Timestamp.now(),
    id: visitId = "",
  } = visitData || {};

  const fallbackDescription =
    description || visitPrivateData?.description || "";

  const isPrivate = description === null;

  const [editableDescription, setEditableDescription] =
    useState(fallbackDescription);

  useEffect(() => {
    // Update editableDescription whenever visitPrivateData changes
    const updatedDescription =
      description || visitPrivateData?.description || "";
    setEditableDescription(updatedDescription);
  }, [visitPrivateData, description]);

  const isPastEvent = DateTime.now() > DateTime.fromJSDate(start.toDate());

  const handleSave = async () => {
    let visitRef;
    if (isPrivate) {
      visitRef = doc(db, "visits", visitId, "private", "data");
    } else {
      visitRef = doc(db, "visits", visitId);
    }

    console.log("visitId: ", visitId);
    console.log("editableDescription: ", editableDescription);

    try {
      await updateDoc(visitRef, { description: editableDescription });
      console.log("Description updated successfully");
    } catch (error) {
      console.error("Error updating description: ", error);
    }
  };

  const handleCancel = () => {
    setEditableDescription(fallbackDescription);
  };

  const renderFixedDescription = () => (
    <ListItem sx={{ px: 0 }}>
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <Notes color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography style={{ wordBreak: "break-word" }}>
            {fallbackDescription || "No visit notes"}
          </Typography>
        }
      />
    </ListItem>
  );

  const renderDescription = () => (
    <ListItem sx={{ paddingX: "0px" }}>
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <FormatListBulletedOutlined color="primary" />
      </ListItemIcon>

      {isPastEvent ? (
        <Typography
          variant="body1"
          sx={{
            backgroundColor: "grey.100",
            px: 2, // Adjust padding as needed
            py: 1.5,
            borderRadius: "15px", // To match TextField's default style
            // Add more styling as needed to match the TextField appearance
          }}
        >
          {editableDescription || "No tasks specified"}
        </Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <TextField
            fullWidth
            minRows={4}
            multiline={true}
            variant="outlined"
            value={editableDescription}
            onChange={(e) => setEditableDescription(e.target.value)}
            placeholder="Click to add tasks"
            sx={{
              backgroundColor: "grey.100",
            }}
          />
          <Collapse
            in={!isPastEvent && editableDescription !== fallbackDescription}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                mt: 1,
              }}
            >
              <Button
                onClick={handleCancel}
                variant="text"
                sx={{
                  mr: 1,
                  textTransform: "none",
                  width: { xs: "50%", sm: "150px", height: "44px" },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                  width: { xs: "50%", sm: "150px", height: "44px" },
                }}
              >
                Save
              </Button>
            </Box>
          </Collapse>
        </Box>
      )}
    </ListItem>
  );

  return (
    <>
      {userRole === "employee" && renderFixedDescription()}
      {(userRole === "member" || userRole === "admin") && renderDescription()}
    </>
  );
};

export default DescriptionListItem;
