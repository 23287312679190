import { getFunctions, httpsCallable } from "firebase/functions";

export const processVisitRefund = async (data) => {
  const functions = getFunctions();
  const processVisitRefundFunction = httpsCallable(
    functions,
    "processVisitRefund"
  );
  try {
    const result = await processVisitRefundFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling processVisitRefund function:", error);
    throw error;
  }
};

export const cancelVisit = async (data) => {
  const functions = getFunctions();
  const cancelVisitFunction = httpsCallable(functions, "cancelVisit");
  try {
    const result = await cancelVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling cancelVisit function:", error);
    throw error;
  }
};
