import {
  AccountBalance,
  ArrowForwardIosRounded,
  Close,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { deleteField, doc, Timestamp, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import BillingTab from "../../../dashboard/member/tabs/BillingTab";
import NotificationsTab from "../../../dashboard/member/tabs/NotificationsTab";
import { db } from "../../../firebase";
import { useAuth } from "../../../providers/AuthProvider";
import { useEmployee } from "../../../providers/EmployeeProvider";
import { useHelpers } from "../../../providers/HelpersProvider";
import ApplyDiscountForm from "../../ApplyDiscountForm";
import DiscountsList from "../../DiscountsList";
import ScheduleVisitAdmin from "../../ScheduleVisitAdmin";
import SendLoginLink from "../../SendLoginLink";
import VisitList from "../../VisitList";
import ServiceStatusAlert from "../../availability/ServiceStatusAlert";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";
import HelpersList from "../../employee/HelpersList";
import SignupFeePayment from "../../payment/SignupFeePayment";
import LogCommunication from "../communication/LogCommunication";
import ViewLogs from "../logs/ViewLogs";
import CardTabs from "../tabs/CardTabs";
import MemberOverview from "../tabs/MemberOverview";
import MemberActions from "./MemberActions";
import MemberDetails from "./MemberDetails";
import MemberInfo from "./MemberInfo";
import MemberStats from "./MemberStats";

// Load your publishable key from the environment variables or configuration
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY
);

const MemberCard = ({
  memberId,
  userRole = "member",
  memberData,
  memberAdminData,
  memberAccountData,
  memberPrivateData,
  visitsData,
  employeesData,
  loading,
  loadingVisits,
  loadingPrivate,
  onClose = () => {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { serviceStatus } = useHelpers();

  console.log("memberPrivateData: ", memberPrivateData);

  // Dialogs
  const [openViewVisits, setOpenViewVisits] = useState(false);
  const [openScheduleVisit, setOpenScheduleVisit] = useState(false);
  const [openSendLoginLink, setOpenSendLoginLink] = useState(false);
  const [openViewDiscounts, setOpenViewDiscounts] = useState(false);
  const [openViewInformation, setOpenViewInformation] = useState(false);
  const [openPaySignupFee, setOpenPaySignupFee] = useState(false);
  const [openManageAddress, setOpenManageAddress] = useState(false);
  const [openLogCommunication, setOpenLogCommunication] = useState(false);
  const [openApplyDiscount, setOpenApplyDiscount] = useState(false);

  // employee vars
  const { employeeData } = useEmployee();
  const { currentUser } = useAuth();
  const eId = currentUser?.uid;
  const eDisplayName = `${
    employeeData?.firstName
  } ${employeeData?.lastName.charAt(0)}.`;
  const eAvatarUrl = employeeData?.avatarUrl;

  const defaultAddressKey = memberPrivateData?.defaultAddress;
  const address = memberAccountData?.addresses?.[defaultAddressKey];

  useEffect(() => {
    // Check if the role is "admin"
    if (userRole === "admin" && memberId) {
      const memberPrivateDocRef = doc(db, "membersPrivate", memberId);

      const updateMemberCrm = async () => {
        try {
          const updateData = {
            "crm.lastViewed.displayName": eDisplayName,
            "crm.lastViewed.avatarUrl": eAvatarUrl,
            "crm.lastViewed.id": eId,
            "crm.lastViewed.timestamp": Timestamp.now(), // Firestore server timestamp

            "crm.inUse.displayName": eDisplayName,
            "crm.inUse.avatarUrl": eAvatarUrl,
            "crm.inUse.id": eId,
            "crm.inUse.timestamp": Timestamp.now(), // Firestore server timestamp
          };

          await updateDoc(memberPrivateDocRef, updateData);
          console.log(`Member ${memberId} crm updated successfully`);
        } catch (error) {
          console.error("Error updating member crm:", error);
        }
      };

      // Call the update function
      updateMemberCrm();

      // Cleanup function to clear 'inUse' on dismount
      return () => {
        const clearInUse = async () => {
          try {
            await updateDoc(memberPrivateDocRef, {
              "crm.inUse": deleteField(), // Clears the inUse field
            });
            console.log(
              `Member ${memberId} 'inUse' cleared successfully on dismount`
            );
          } catch (error) {
            console.error("Error clearing 'inUse' on dismount:", error);
          }
        };

        clearInUse();
      };
    }
  }, [memberId, userRole]);

  const handleScheduleVisit = () => {
    setOpenScheduleVisit(true); // Assuming you have a state for this as well
    console.log("handle schedule visit");
  };

  const handleSendLoginLink = () => {
    setOpenSendLoginLink(true); // Assuming a state for sending login links
    console.log("handle send login link");
  };

  const handleViewDiscounts = () => {
    setOpenViewDiscounts(true); // Assuming a state for viewing discounts
    console.log("handle view discounts");
  };

  const handlePaySignupFee = () => {
    setOpenPaySignupFee(true); // Assuming a state for viewing discounts
    console.log("handle pay signup fee");
  };

  const handleManageAddress = () => {
    setOpenManageAddress(true); // Assuming a state for viewing discounts
    console.log("handle manage address");
  };

  const handleApplyDiscount = () => {
    setOpenApplyDiscount(true); // Assuming a state for viewing discounts
    console.log("handle manage discount");
  };

  const handleLogCommunication = () => {
    setOpenLogCommunication(true); // Assuming a state for viewing discounts
    console.log("handle manage address");
  };

  const upcomingVisit = Object.values(visitsData)
    .filter((visit) => visit.start.toDate() > new Date()) // Convert Firestore Timestamp to Date
    .sort((a, b) => a.start.toDate() - b.start.toDate())[0]; // Sort by start date

  // Define the structure to map tabs to components
  const tabs = [
    { label: "Logs", component: <ViewLogs id={memberId} employeeId={eId} /> },
    {
      label: "Helpers",
      component: (
        <HelpersList
          employeesData={employeesData}
          memberData={memberData}
          memberPrivateData={memberPrivateData}
          userRole={userRole}
        />
      ),
    },
    {
      label: "Visits",
      component: (
        <VisitList
          visits={visitsData}
          localEmployees={employeesData}
          eId={eId}
          eName={employeeData?.firstName}
          userRole={userRole}
        />
      ),
    },
    // {
    //   label: "Communication",
    //   component: (
    //     <ViewLogs id={memberId} employeeId={eId} filter={"communication"} />
    //   ),
    // },
    {
      label: "Discounts",
      component: (
        <DiscountsList
          discounts={memberAdminData?.discounts}
          memberId={memberData?.id}
          timeZoneId={memberData?.timeZoneId}
          isAdmin={true}
          memberPhone={memberPrivateData?.phone}
        />
      ),
    },
    {
      label: "Details",
      component: (
        <MemberDetails
          memberData={memberData}
          memberPrivateData={memberPrivateData}
          memberAccountData={memberAccountData}
          memberAdminData={memberAdminData}
          userRole={userRole}
        />
      ),
    },
    {
      label: "Notifications",
      component: (
        <NotificationsTab
          memberId={memberId}
          memberPrivateData={memberPrivateData}
          showTitle={false}
        />
      ),
    },
    userRole === "admin"
      ? {
          label: "Billing",
          component: (
            <Elements stripe={stripePromise}>
              <BillingTab
                memberId={memberId}
                memberData={memberData}
                memberPrivateData={memberPrivateData}
                memberAdminData={memberAdminData}
                showTitle={false}
              />
            </Elements>
          ),
        }
      : null,
  ].filter(Boolean);

  console.log("upcoming Visit: ", upcomingVisit);

  return (
    <>
      <DialogContent>
        <ServiceStatusAlert
          serviceStatus={serviceStatus}
          signupFeeStatus={memberPrivateData?.signupFeeStatus}
          city={memberData?.location?.city}
        />
        <MemberInfo
          memberData={memberData}
          memberPrivateData={memberPrivateData}
        />
        <Collapse
          in={
            !loadingPrivate && memberPrivateData?.signupFeeStatus === undefined
          }
        >
          <Button
            color="warning"
            variant="outlined"
            onClick={handlePaySignupFee}
            sx={{
              mb: 2,
              p: 2,
              borderRadius: "15px",
              textTransform: "none",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccountBalance
                  sx={{ color: theme.palette.warning.dark, mr: 1 }}
                />
                <Typography sx={{ color: theme.palette.warning.dark }}>
                  Pay sign up fee
                </Typography>
              </Box>
              <ArrowForwardIosRounded
                sx={{ color: theme.palette.warning.dark }}
              />
            </Box>
          </Button>
        </Collapse>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <MemberActions
            phone={memberPrivateData?.phone}
            onScheduleVisit={handleScheduleVisit}
            onLogConvo={handleLogCommunication}
            onSendLoginLink={handleSendLoginLink}
            onApplyDiscount={handleApplyDiscount}
            userRole={userRole}
          />
          <Collapse in={openLogCommunication}>
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                borderRadius: "15px",
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  m: 1,
                }}
              >
                <IconButton onClick={() => setOpenLogCommunication(false)}>
                  <Close />
                </IconButton>
                <Typography align="center" variant="h6">
                  Log communication
                </Typography>

                <Close sx={{ visibility: "hidden" }} />
              </Box>

              <LogCommunication
                open={openLogCommunication}
                onClose={() => setOpenLogCommunication(false)}
                idsArr={[memberId, eId]}
                employee={{
                  type: "employee",
                  id: eId,
                  displayName: eDisplayName,
                  avatarUrl: eAvatarUrl,
                }}
                client={{
                  type: "member",
                  id: memberId,
                  displayName: `${
                    memberData?.firstName
                  } ${memberData?.lastName.charAt(0)}.`,
                  avatarUrl: memberData?.avatarUrl,
                }}
                collectionName={"membersPrivate"}
                location={memberData?.location}
              />
            </Box>
          </Collapse>
          <MemberStats
            memberPrivateData={memberPrivateData}
            visitsData={visitsData}
            memberAdminData={memberAdminData}
          />

          <MemberOverview
            memberData={memberData}
            userRole={userRole}
            visitsData={visitsData}
            memberAdminData={memberAdminData}
            loadingVisits={loadingVisits}
            employeesData={employeesData}
          />
        </Box>
        <CardTabs tabs={tabs} />
      </DialogContent>
      <ResponsiveDialog
        open={openViewVisits}
        onClose={() => setOpenViewVisits(false)}
        title={"Visits"}
        desktopAnchor={"right"}
        anchor={"right"}
        width={"800px"}
        showBackdrop={false}
        zIndex={1300}
        fullHeight={true}
      >
        {openViewVisits && (
          <DialogContent>
            <VisitList
              visits={visitsData}
              localEmployees={employeesData}
              eId={eId}
              eName={employeeData?.firstName}
              userRole={userRole}
            />
          </DialogContent>
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        title={"Schedule a visit"}
        open={openScheduleVisit}
        onClose={() => setOpenScheduleVisit(false)}
        width="800px"
        fullHeight={true}
        maxHeight="583px"
      >
        <ScheduleVisitAdmin
          handleClose={() => setOpenScheduleVisit(false)}
          adminMode={true}
          eId={eId}
          eName={employeeData?.firstName}
          memberId={memberId}
          memberData={memberData}
          memberPrivateData={memberPrivateData}
          memberAdminData={memberAdminData}
          memberAccountData={memberAccountData}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openViewDiscounts}
        onClose={() => setOpenViewDiscounts(false)}
        title={"Discounts"}
        width={"800px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
      >
        <DiscountsList
          discounts={memberAdminData?.discounts}
          memberId={memberData?.id}
          timeZoneId={memberData?.timeZoneId}
          isAdmin={true}
          memberPhone={memberPrivateData?.phone}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openSendLoginLink}
        onClose={() => setOpenSendLoginLink(false)}
        title={"Login Link"}
        width={"800px"}
        // desktopAnchor={"right"}
        anchor={"bottom"}
        fullHeight={true}
      >
        <SendLoginLink
          open={openSendLoginLink}
          onClose={() => setOpenSendLoginLink(false)}
          memberId={memberId}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openPaySignupFee}
        showBackdrop={false}
        onClose={() => setOpenPaySignupFee(false)}
        title={"Signup Fee"}
        width={"800px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
      >
        {openPaySignupFee && (
          <Elements stripe={stripePromise}>
            <SignupFeePayment
              customerId={memberAdminData?.stripeId}
              memberId={memberData?.id}
              handleClose={() => setOpenPaySignupFee(false)}
              zipCode={memberData?.location?.zipCode}
              signupFeeStatus={memberPrivateData?.signupFeeStatus}
              serviceStatus={serviceStatus}
              adminMode={true}
              mName={memberData?.firstName}
              eName={employeeData?.firstName}
              eId={eId}
              city={memberPrivateData?.location?.city}
            />
          </Elements>
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openApplyDiscount}
        onClose={() => setOpenApplyDiscount(false)}
        title={"Apply discount"}
        width={"800px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
        showBackdrop={false}
      >
        <ApplyDiscountForm
          memberId={memberId}
          memberPhone={memberPrivateData?.phone}
        />
      </ResponsiveDialog>
    </>
  );
};

export default MemberCard;
