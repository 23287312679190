import { ChevronRightRounded, Info, InfoOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import HomeCard from "../HomeCard";

const PointsBar = ({
  currentLevel,
  pointsInCurrentLevel,
  progressPercentage,
  handleOpenLevelUp,
  showLevelUp,
  onClick = () => {},
  avatarUrl = "",
}) => {
  return (
    <HomeCard onClick={onClick}>
      {/* <HomeCardHeader
        title={"Overview"}
        // subtitle={"Earn points by completing visits."}
        IconComponent={Insights}
      /> */}
      <Avatar
        src={avatarUrl}
        sx={{
          width: { xs: "64px", sm: "96px" },
          height: { xs: "64px", sm: "96px" },
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: 0.5,
          mt: 0.5,
          flexWrap: "wrap",
          justifyContent: { xs: "space-around", sm: "center" },
        }}
      >
        <InfoOutlined sx={{ color: "#666", position: "absolute", right: "12px", top: "12px" }} />
        {/* Header with Level and Progress */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            justifyContent: { xs: "space-between", sm: "space-between" },
            alignItems: { xs: "center", sm: "baseline" },
            width: "100%",
          }}
        >
          {/* Level display */}
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "1.75rem", sm: "2rem" },
                color: "#333",
              }}
            >
              Level {currentLevel}
            </Typography>
          </Box>

          {/* Current points */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "500",
                color: "#333",
              }}
            >
              {pointsInCurrentLevel}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "500",
                color: "#666",
              }}
            >
              /1000 pts
            </Typography>
          </Box>
        </Box>

        {/* Progress bar */}
        {!showLevelUp && (
          <Box
            sx={{
              position: "relative",
              backgroundColor: "#e0e0e0",
              borderRadius: "15px",
              height: "24px",
              width: "100%",
              overflow: "hidden",
              mt: { xs: 0, sm: 0 },
              // boxShadow:
              //   "0 0 10px 5px rgba(76, 175, 80, 0.2), 0 0 20px 10px rgba(33, 150, 243, 0.2)",
            }}
          >
            <Box
              sx={{
                width: `${progressPercentage}%`,
                background: "linear-gradient(90deg, #4CAF50 0%, #2196F3 100%)",
                height: "100%",
                borderRadius: "15px",
                minWidth: "8px",
                transition: "width 0.5s ease",
              }}
            ></Box>

            {/* Progress text inside the bar */}
            {/* <Typography
          variant="body2"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          {progressPercentage.toFixed(1)}%
        </Typography> */}
          </Box>
        )}

        {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            mt: "4px",
            color: "#666",
          }}
        >
          $17.00/hr
        </Typography>
        <Typography
          variant="body2"
          sx={{
            position: "relative", // Relative to its new outer container
            mt: "4px",
            right: 0,
            textAlign: "right",
            color: "#666",
          }}
        >
          Next $17.20/hr
        </Typography>
      </Box> */}
        {showLevelUp && (
          <Button
            disableElevation
            variant="contained"
            color="success"
            sx={{
              width: "100%",
              height: "24px",
              textTransform: "none",
              background: "linear-gradient(90deg, #4CAF50 0%, #2196F3 100%)",
              mx: "auto",
              position: "relative", // Needed for the overlay
              overflow: "hidden", // Ensures the effect stays within the button boundaries
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: "-150%", // Start off the button
                width: "100%", // Make it wide enough to cover the button
                height: "100%",
                background:
                  "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)", // Fade in/out effect
                transform: "skewX(-20deg)", // Slight skew to angle the wipe
                animation: "wipe 2.5s infinite", // Smooth, infinite animation
              },
              "@keyframes wipe": {
                "0%": { left: "-200%" },
                "100%": { left: "200%" }, // Move it fully across
              },
            }}
            onClick={handleOpenLevelUp}
            endIcon={<ChevronRightRounded />}
          >
            Level up
          </Button>
        )}

        <div style={{ height: "10px" }} />
      </Box>
    </HomeCard>
  );
};

export default PointsBar;
