import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  ListItemIcon,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import React, { forwardRef } from "react";
import { formatTimeRange } from "../services/dateServices";
import ColorAvatar from "./ColorAvatar";
import ExpirationChip from "./ExpirationChip";
import RatingChip from "./RatingChip";

const VisitListItem = forwardRef(
  (
    {
      visit,
      compact = false,
      showChips = true,
      margins = "normal",
      showMember = false,
      adminMode = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const timeZoneId = visit?.timeZoneId;

    console.log("VisitListItem - timeZoneId: ", timeZoneId)

    const formatWeekDay = (date) => {
      if (compact) {
        return moment(date).tz(timeZoneId).format("ddd"); // Short format for day of the week
      }
      return moment(date)
        .tz(timeZoneId)
        .format(isMobile ? "ddd" : "dddd");
    };

    const formatDate = (date) => {
      return moment(date).tz(timeZoneId).format("MMM Do");
    };

    // Custom formatting function
    const formatTime = (momentObj) => {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedTime =
        momentObj.minute() === 0
          ? momentObj.format("ha").toLowerCase() // Formats as '3pm' if minutes are 00
          : momentObj.format("h:mma").toLowerCase(); // Formats as '3:30pm' otherwise

      const displayTimeZone =
        browserTimeZone !== timeZoneId
          ? ` (${momentObj.tz(timeZoneId).zoneAbbr()})`
          : "";

      return `${formattedTime}${displayTimeZone}`;
    };

    // Helper function to get initials from displayName
    function getInitials(name) {
      if (!name) return ""; // handle cases where name is undefined, null, or empty

      const parts = name.split(" ");

      if (parts.length === 1) {
        return parts[0][0].toUpperCase(); // single-word name case
      }

      return parts
        .map((part) => part[0] || "") // ensure we handle empty parts gracefully
        .join("")
        .toUpperCase();
    }

    // Filter employees who meet the criteria
    const filteredEmployees = Object.values(visit.employees).filter(
      (employee) =>
        employee.responseStatus === "accepted" ||
        employee.responseStatus === "needsAction" ||
        employee.responseStatus === "refunded"
    );

    // Function to compare visit window with employee windows
    const isAnyWindowDifferent = (visit) => {
      if (!visit || !visit.employees || !visit.start || !visit.end) {
        return false; // Return false if visit or necessary properties are missing
      }

      const visitStart = moment(visit.start.toDate()).tz(timeZoneId);
      const visitEnd = moment(visit.end.toDate()).tz(timeZoneId);

      // Filter employees with "responseStatus" == "accepted"
      const acceptedEmployees = Object.values(visit.employees).filter(
        (employee) => employee.responseStatus === "accepted"
      );

      // Check if any employee's window is different
      return acceptedEmployees.some((employee) =>
        Object.values(employee.windows || {}).some((window) => {
          const windowStart = moment(window.start.toDate()).tz(timeZoneId);
          const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

          return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
        })
      );
    };

    // Determining how to display time format
    const employeeCount =
      visit?.employees &&
      Object.values(visit.employees).filter(
        (employee) => employee.responseStatus === "accepted"
      ).length;
    const windowsAreDifferent = isAnyWindowDifferent(visit);
    const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

    const isPastEvent = moment()
      .tz(timeZoneId)
      .isAfter(moment(visit?.start?.toDate()).tz(timeZoneId));
    const isRefunded = visit.flags && visit.flags.includes("refundIssued");

    const needsReschedule = visit?.status === "reschedule";
    const isReviewed =
      typeof visit?.rating === "number" && !isNaN(visit?.rating);
    const canReview = isPastEvent && !isReviewed && !needsReschedule;

    // Prepare the AvatarGroup if there are filtered employees
    const avatarGroup = (
      <AvatarGroup max={4}>
        {/* Adjust 'max' value as needed */}
        {filteredEmployees.map((employee, index) => (
          <Avatar
            sx={{ width: 50, height: 50, mr: -2 }}
            key={index}
            alt={employee.displayName}
            src={employee.avatarUrl}
            {...(!employee.avatarUrl && {
              children: getInitials(employee.displayName),
            })}
          />
        ))}

        {/* Additional blank avatars if it's a future event */}
        {visit?.employeesNeeded &&
          !isPastEvent &&
          [...Array(visit?.employeesNeeded)].map((_, index) => (
            <Avatar
              sx={{ width: 50, height: 50, mr: -2 }}
              key={`blank-${index}`}
            />
          ))}
      </AvatarGroup>
    );

    const renderDate = () => (
      <>
        {formatWeekDay(moment(visit?.start?.toDate()).tz(timeZoneId))}
        {", "}
        {formatDate(moment(visit?.start?.toDate()).tz(timeZoneId).toDate())}
      </>
    );

    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {showMember ? (
          <ColorAvatar
            name={`${visit?.displayName}`}
            avatarUrl={visit?.avatarUrl}
          />
        ) : (
          <ListItemIcon sx={{ mr: 3 }}>{avatarGroup}</ListItemIcon>
        )}
        <Stack
          direction="column"
          alignItems={"flex-start"}
          sx={{ flexGrow: 1 }}
        >
          <Typography variant="h6">
            {showMember ? visit?.displayName : renderDate()}
          </Typography>

          {showMember && (
            <Typography variant="body1" color="text.secondary">
              {renderDate()}
            </Typography>
          )}

          {!shouldShowTimes ? (
            <Typography variant="body1" color="text.secondary" align="left">
              {Object.entries(visit.employees).filter(
                ([_, employee]) => employee.responseStatus === "accepted"
              )[0]?.[1]?.windows
                ? Object.values(
                    Object.entries(visit.employees).filter(
                      ([_, employee]) => employee.responseStatus === "accepted"
                    )[0][1].windows
                  ).map((window, index) => (
                    <React.Fragment key={index}>
                      {formatTimeRange(
                        moment(window.start.toDate()).tz(timeZoneId),
                        moment(window.end.toDate()).tz(timeZoneId),
                        timeZoneId
                      )}
                      <br />
                    </React.Fragment>
                  ))
                : formatTimeRange(
                    moment(visit?.start?.toDate()).tz(timeZoneId).toDate(),
                    moment(visit?.end?.toDate()).tz(timeZoneId).toDate(),
                    timeZoneId
                  )}
            </Typography>
          ) : (
            <Typography variant="body1" color="text.secondary">
              Multiple times
            </Typography>
          )}

          {visit?.recurrence?.frequency > 0 && (
            <Typography>
              {visit?.recurrence.frequency > 1
                ? `Every ${visit?.recurrence.frequency}-weeks`
                : `Every week`}
            </Typography>
          )}
        </Stack>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column" },
            flexWrap: "wrap",
            gap: 1,
            justifyContent: { xs: "flex-start", sm: "center" },
          }}
        >
          {visit.status === "cancelled" && showChips && (
            <Chip
              label="Cancelled"
              color="error"
              size="small"
              variant="outlined"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
          {isRefunded && showChips && (
            <Chip
              label="Refund issued"
              color="success"
              size="small"
              variant="outlined"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
          {isReviewed && showChips && (
            <Box
              sx={{
                zIndex: 1,
                display: "flex",
              }}
            >
              <RatingChip rating={visit.rating} />
            </Box>
          )}
          {canReview && showChips && (
            <Chip
              label="Review"
              color="info"
              variant="outlined"
              size="small"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
          {needsReschedule && showChips && (
            <Chip
              label="Reschedule"
              color="success"
              variant="outlined"
              size="small"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
          {adminMode && visit?.expiration && (
            <ExpirationChip
              showTooltip={true}
              expirationDate={visit?.expiration}
              timeZoneId={visit?.timeZoneId}
            />
          )}
        </Box>

        {/* {isPastEvent && (
        <>
          {visit?.rating ? (
            <Box
              sx={{
                zIndex: 1,
                display: "flex",
                ...(isMobile ? chipStyles : desktopChipStyles),
              }}
            >
              <RatingChip rating={visit.rating} />
            </Box>
          ) : (

          )}
        </>
      )} */}
      </Box>
    );
  }
);

export default VisitListItem;
