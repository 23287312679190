import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function CardGrid({ items }) {
  const navigate = useNavigate();

  return (
    <Container sx={{ my: 0 }}>
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid item xs={12} md={4} lg={4} key={index}>
            <Box display="flex" height="100%">
              <Card
                elevation={0} // start with a smaller elevation
                sx={{
                  p: {xs: 0, sm: 2,},
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  transition: "box-shadow 0.3s ease-in-out", // smooth transition for the box-shadow
                  "&:hover": {
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)", // subtle shadow on hover
                  },
                }}
              >
                <Box
                  sx={{
                    height: 0,
                    paddingBottom: "56.25%", // 9:16 aspect ratio
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item.imageName}
                    alt={item.header}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h5"
                    fontWeight="700"
                    component="div"
                    gutterBottom
                  >
                    {item.header}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.subText}
                  </Typography>
                </CardContent>
                {item.buttonText && (
                  <Box textAlign="center" p={1}>
                    <Link
                      to={item.buttonDestination}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: 150, textTransform: "none" }}
                      >
                        {item.buttonText}
                      </Button>
                    </Link>
                  </Box>
                )}
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default CardGrid;
