import { ContentCopy, MoreVert } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSnackbar } from "../../../providers/SnackbarProvider";
import { handleCopy } from "../../../services/formServices";
import { convertStateNameToAbbreviation } from "../../../services/locationServices";
import ColorAvatar from "../../ColorAvatar";
import { formatPhoneNumber } from "../../../services/stringServices";

const MemberInfo = ({ memberData, memberPrivateData }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { showSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);

  // Data structure for menu items
  const menuItems = [
    {
      label: "Copy phone",
      icon: <ContentCopy fontSize="small" />,
      action: () => {
        handleCopy(
          memberPrivateData?.phone,
          (message) => showSnackbar(message, "success"),
          (message) => showSnackbar(message, "error")
        );
        // alert("Phone number copied!");
      },
    },
    memberPrivateData?.phone2
      ? {
          label: "Secondary phone",
          icon: <ContentCopy fontSize="small" />,
          action: () => {
            handleCopy(
              memberPrivateData?.phone2,
              (message) => showSnackbar(message, "success"),
              (message) => showSnackbar(message, "error")
            );
            // alert("Phone number copied!");
          },
        }
      : null,
    {
      label: "Copy email",
      icon: <ContentCopy fontSize="small" />,
      action: () => {
        handleCopy(
          memberPrivateData?.email,
          (message) => showSnackbar(message, "success"),
          (message) => showSnackbar(message, "error")
        );
        // alert("Email copied!");
      },
    },
    memberPrivateData?.email2
      ? {
          label: "Secondary email",
          icon: <ContentCopy fontSize="small" />,
          action: () => {
            handleCopy(
              memberPrivateData?.email2,
              (message) => showSnackbar(message, "success"),
              (message) => showSnackbar(message, "error")
            );
            // alert("Email copied!");
          },
        }
      : null,
  ].filter(Boolean);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
          pb: 2,
          // border: "1px solid #e0e0e0",
          borderRadius: 1,
          position: "relative",
        }}
      >
        <ColorAvatar
          name={`${memberData?.firstName} ${memberData?.lastName}`}
          avatarUrl={memberData?.avatarUrl}
          size={96}
        />
        <Box>
          <Typography variant="h6" component="div">
            {memberData?.firstName ? (
              `${memberData.firstName} ${memberData.lastName}`
            ) : (
              <Skeleton width="150px" />
            )}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {memberData?.location?.city &&
            memberData?.location?.state &&
            memberData?.location?.zipCode ? (
              `${memberData.location.city}, ${convertStateNameToAbbreviation(
                memberData.location.state
              )} ${memberData.location.zipCode}`
            ) : (
              <Skeleton width="120px" />
            )}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {memberPrivateData?.phone ? (
              formatPhoneNumber(memberPrivateData.phone)
            ) : (
              <Skeleton width="80px" />
            )}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {memberPrivateData?.phone2 && formatPhoneNumber(memberPrivateData.phone2)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {memberPrivateData?.email ? (
              memberPrivateData.email
            ) : (
              <Skeleton width="120px" />
            )}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {memberPrivateData?.email2 && (
              memberPrivateData.email2
            )}
          </Typography>
        </Box>
        {/* Three Dots Menu */}
        <IconButton
          onClick={handleMenuOpen}
          sx={{ position: "absolute", right: 0, top: 0 }}
        >
          <MoreVert />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            // elevation: 1,
            sx: { width: "200px" },
          },
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.action();
              handleMenuClose();
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MemberInfo;
