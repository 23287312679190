import { Box, Dialog } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { db, functions } from "../../firebase";
import { extractPhoneNumber } from "../../services/inputServices";
import WheelSpinner from "../WheelSpinner";

function WheelPopup({ fullScreen = false }) {
  const [open, setOpen] = useState(false);

  const [leadCaptured, setLeadCaptured] = useState(
    localStorage.getItem("leadCaptured") === "true"
  );

  /* Uncomment to have popup trigger, removed to remove the _popup_shown flag
  useEffect(() => {
    let timer;
    let startTime = Date.now();
    let popupTime = parseInt(localStorage.getItem("popupTime")) || 0;
    const TRIGGER_TIME = 90;
    const EXIT_TRIGGER_TIME = 30;

    const updateTime = () => {
      popupTime += (Date.now() - startTime) / 1000; // Update total time in seconds
      localStorage.setItem("popupTime", popupTime);
      startTime = Date.now();

      // Check and open popup after updating time
      checkAndOpenPopup();

      // Stop logging time after so many seconds
      if (popupTime >= TRIGGER_TIME) {
        clearInterval(timer);
      }
    };

    // Function to check and open the dialog
    const checkAndOpenPopup = async (override = false) => {
      const timeSpent = parseInt(localStorage.getItem("popupTime")) || 0;
      if (
        ((override && timeSpent >= EXIT_TRIGGER_TIME) ||
          timeSpent >= TRIGGER_TIME) &&
        !leadCaptured &&
        localStorage.getItem("popupShown") !== "true"
      ) {
        setOpen(true);
        localStorage.setItem("popupShown", "true");

        // Log the popup being opened
        const sessionId = localStorage.getItem("sessionId");
        if (sessionId) {
          try {
            await setDoc(
              doc(db, "sessions", sessionId),
              { _popupOpened: new Date() },
              { merge: true }
            );
          } catch (err) {
            console.log("Error logging popup opened: ", err);
          }
        }
      }
    };

    const handleMouseLeave = (e) => {
      if (e.clientY < 24) {
        // If the mouse is near the top of the viewport
        checkAndOpenPopup(true);
      }
    };

    window.addEventListener("mousemove", handleMouseLeave);

    if (!leadCaptured) {
      timer = setInterval(updateTime, 1000); // Update time every second
    }

    // Clean up interval on component unmount
    return () => {
      clearInterval(timer);
      window.removeEventListener("mousemove", handleMouseLeave);
    };
  }, [leadCaptured]);
  */

  const handleSave = async (values) => {
    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    console.log("values: ", values);

    const leadData = {
      name: values?.name || "",
      phone: extractPhoneNumber(values?.phone || ""),
      location: {
        zipCode: values?.zipCode | "",
      },
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: moment(values?.followUpDate || new Date()).toDate(),
        contactedCount: 0,
        source: "wheel_popup",
        timeline: {
          wheel_popup: new Date(),
        },
        utmSource: utmSource || "",
        discount: values?.discount || "",
      },
    };

    console.log("leadData: ", leadData);

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date)
              ? [k, cleanData(v)]
              : [k, v]
          )
      );
    };

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    console.log("filteredLeadData: ", filteredLeadData);

    // Save the lead to Firebase
    // await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
    //   merge: true,
    // });

    const createLead = httpsCallable(functions, "createLead");
    createLead({ ...filteredLeadData, sessionId: storedSessionId });

    // await addDoc(collection(db, "leads"), leadData);
    console.log("Lead saved successfully");

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _popupSubmitted: new Date(),
            _source: "wheel_popup",
            _name: values?.name || "",
            _phone: values?.phone || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    // Set a flag in localStorage indicating the form has been leadCaptured
    // localStorage.setItem("leadCaptured", "true");
    // localStorage.setItem("zipCode", values.zipCode);

    setLeadCaptured(true);
  };

  const handleClose = async (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);

      // Log the popup being closed
      const sessionId = localStorage.getItem("sessionId");
      if (sessionId) {
        try {
          await setDoc(
            doc(db, "sessions", sessionId),
            { _popupClosed: new Date() },
            { merge: true }
          );
        } catch (err) {
          console.log("Error logging popup closed: ", err);
        }
      }
    }
  };

  const renderContent = () => {
    return (
      <WheelSpinner
        repeat={2}
        SQUARE_HEIGHT={800}
        SHOW_WIDTH={"300px"}
        handleSave={handleSave}
        handleClose={handleClose}
        fullScreen={fullScreen}
        isDialog={!fullScreen}
      />
    );
  };

  return (
    <>
      {fullScreen ? (
        <Box>{renderContent()}</Box>
      ) : (
        <Dialog
          open={open || fullScreen}
          fullScreen={fullScreen}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          sx={{
            zIndex: 1300, // Set zIndex for the Dialog component
          }}
          PaperProps={{
            sx: {
              zIndex: 1300,
              "&.MuiDialog-paper": {
                zIndex: 1300,
              },
            },
          }}
        >
          {(open || fullScreen) && renderContent()}
        </Dialog>
      )}
    </>
  );
}

export default WheelPopup;
