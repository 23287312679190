import {
  AccessTime,
  EventRepeatOutlined,
  EventRounded,
  FormatListBulletedOutlined,
  GroupOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import {formatTimeRange} from "../services/dateServices"

const VisitSummary = ({
  start,
  end,
  employees = false,
  timeZoneId = "America/Los_Angeles",
  frequency = false,
  address = false,
  tasks = false,
  spacing = "normal",
}) => {
  console.log("employees: ", employees);

  console.log("VisitSummary - timeZoneId: ", timeZoneId)

  const formatDate = (date) => {
    if (!date) return "Invalid date"; // Check if date is null or undefined
    const momentDate = moment(date).tz(timeZoneId);
    return momentDate.isValid() ? momentDate.format("MMMM D, YYYY") : "Invalid date";
  };

  // Determine ListItem style based on spacing prop
  const listItemStyle = spacing === "compact" ? { py: 0, px: 2 } : {};

  console.log("start: ", start);

  const startMoment = moment(
    typeof start === "number" ? start : start?.toDate ? start.toDate() : start
  ).tz(timeZoneId);

  console.log("StartMomemnt:", startMoment);

  const endMoment = moment(end?.toDate ? end.toDate() : end).tz(timeZoneId);

  return (
    <>
      <ListItem sx={listItemStyle}>
        <ListItemIcon>
          <EventRounded color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="h6">{formatDate(startMoment)}</Typography>
          }
        />
      </ListItem>
      <ListItem sx={listItemStyle}>
        <ListItemIcon>
          <AccessTime color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1">
              {formatTimeRange(startMoment, endMoment, timeZoneId)}
            </Typography>
          }
        />
      </ListItem>
      {frequency > 0 && (
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <EventRepeatOutlined color="primary" />
          </ListItemIcon>
          <ListItemText>
            {frequency > 1
              ? `Every ${frequency} weeks on ${startMoment.format("dddd")}`
              : `Every week on ${startMoment.format("dddd")}`}
          </ListItemText>
        </ListItem>
      )}
      {address && (
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <LocationOnOutlined color="primary" />
          </ListItemIcon>
          <ListItemText primary={address} />
        </ListItem>
      )}
      {tasks && (
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <FormatListBulletedOutlined color="primary" />
          </ListItemIcon>
          <ListItemText primary={tasks} />
        </ListItem>
      )}
      {employees && (
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <GroupOutlined color="primary" />
          </ListItemIcon>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {Object.values(employees).map((employee, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {employee.avatarUrl && (
                  <Avatar src={employee.avatarUrl} alt="Employee Avatar" />
                )}
                <Typography>
                  {employee?.displayName ||
                    `${employee?.firstName} ${employee?.lastName.charAt(0)}.`}
                </Typography>
              </Box>
            ))}
          </Box>
        </ListItem>
      )}
    </>
  );
};

export default VisitSummary;
