import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase"; // Adjust the path to your firebase config

const useVisitData = (visitId) => {
  const [visitData, setVisitData] = useState(null);
  const [visitPrivateData, setVisitPrivateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPrivate, setLoadingPrivate] = useState(true);

  useEffect(() => {
    // Return early if no visitId is provided
    if (!visitId) {
      setVisitData(null);
      setVisitPrivateData(null);
      setLoading(false);
      setLoadingPrivate(false);
      return;
    }

    const docRef = doc(db, "visits", visitId);
    const privateDocRef = doc(db, "visits", visitId, "private", "data");

    // Subscribe to the public document's changes
    const unsubscribePublic = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setVisitData({ id: docSnapshot.id, ...docSnapshot.data() });
        } else {
          console.error("No such document!");
          setVisitData(null);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching visit data:", error);
        setVisitData(null);
        setLoading(false);
      }
    );

    // Subscribe to the private document's changes
    const unsubscribePrivate = onSnapshot(
      privateDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setVisitPrivateData(docSnapshot.data());
        } else {
          console.error("No private data document found!");
          setVisitPrivateData(null);
        }
        setLoadingPrivate(false);
      },
      (error) => {
        console.error("Error fetching private visit data:", error);
        setVisitPrivateData(null);
        setLoadingPrivate(false);
      }
    );

    // Cleanup subscriptions on unmount or when visitId changes
    return () => {
      unsubscribePublic();
      unsubscribePrivate();
    };
  }, [visitId]);

  return { visitData, visitPrivateData, loading, loadingPrivate };
};

export default useVisitData;
