import { Box, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import GetStartedButton from "../../../components/buttons/GetStartedButton";
import CountdownBar from "../../../components/promotions/CountdownBar";
import useTrackVisibility from "../../../hooks/useTrackVisibility";
import { MEMBER_COUNT } from "../../../services/variableServices";

const COUNTDOWN_DURATION = 3600; // 1 hour countdown in seconds

const CountdownSection = ({ pageName = "" }) => {
  const [timeLeft, setTimeLeft] = useState(COUNTDOWN_DURATION);

  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Function to fetch or create firstVisited timestamp in session storage
  const getTimeLeft = () => {
    const now = Date.now();
    const firstVisited = localStorage.getItem("firstVisited");

    // If firstVisited doesn't exist, set it and start a new countdown
    if (!firstVisited) {
      localStorage.setItem("firstVisited", now);
      return COUNTDOWN_DURATION;
    }

    // Calculate time left based on the firstVisited timestamp
    const timePassed = Math.floor((now - firstVisited) / 1000);
    return Math.max(COUNTDOWN_DURATION - timePassed, 0);
  };

  useEffect(() => {
    // On component mount, fetch time left
    setTimeLeft(getTimeLeft());

    // Update the countdown every second
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTimeLeft = prevTime > 0 ? prevTime - 1 : 0;
        return newTimeLeft;
      });
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  // If user is logged in, don't show the countdown bar
  if (user) return null;

  // if (timeLeft <= 0)
  if (true)
    return (
      <Box
        sx={{
          mx: "auto",
          maxWidth: "500px",
          px: 2,
          pt: { xs: "24px", sm: "48px" },
          pb: "48px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" },
          }}
        >
          Ready?
        </Typography>
        <Typography
          variant="h6"
          align="center"
          fontWeight={"400"}
          sx={{
            fontSize: { xs: "18px", sm: "20px" }, // Responsive font sizes
          }}
        >
          Join over {Math.floor(MEMBER_COUNT / 10) * 10}+ seniors who count on
          us for their household help.
        </Typography>
        <GetStartedButton />
      </Box>
    );

  return (
    <Box
      sx={{
        mx: "auto",
        maxWidth: "500px",
        px: 2,
        pt: { xs: "24px", sm: "24px" },
        pb: { xs: "52px", sm: "76px" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        align="center"
        sx={{
          fontSize: { xs: "44px", sm: "h2.fontSize" }, // Responsive font sizes
          fontWeight: { xs: "800", sm: "800" },
          lineHeight: { xs: "56px", sm: "72px" },
        }}
      >
        Get 1-hour free!
      </Typography>
      <Typography
        variant="h6"
        align="center"
        fontWeight={"500"}
        sx={{
          fontSize: { xs: "18px", sm: "18px" }, // Responsive font sizes
        }}
      >
        Signup online, save on your first visit.
      </Typography>
      <Typography
        align="left"
        variant="body1"
        color={"text.secondary"}
        sx={{ mt: 1 }}
      >
        Join over {Math.floor(MEMBER_COUNT / 10) * 10}+ seniors who count on us
        for their household help. Your special deal for new members ends in:
      </Typography>
      <Box sx={{ my: 1 }}>
        <CountdownBar
          isCompact={false}
          showMessage={false}
          stackDirection={"row"}
          showClose={false}
        />
      </Box>
      {/* <GetStartedButton /> */}
    </Box>
  );
};

export default CountdownSection;
