import { ArrowForwardIosRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { keyframes } from "@emotion/react";

// Define our keyframes for the animation
const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const RoleSelectionCard = ({
  role,
  description,
  onSelectRole,
  loading,
  setLoading,
  disabled,
}) => {
  const handleCardClick = () => {
    onSelectRole(role);
    setLoading(true);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        m: 2,
        maxWidth: "300px",
        backgroundColor: "background.paper",
        cursor: disabled ? "default" : "pointer",
        "&:hover": {
          backgroundColor: disabled
            ? "background.paper"
            : "rgba(0, 0, 0, 0.04)", // Slight gray on hover
        },
        transition: "background-color 0.3s",
      }}
      onClick={() => !disabled && handleCardClick()}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {role.charAt(0).toUpperCase() + role.slice(1)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mt={2} width="100%">
          <Button
            variant="text"
            disabled={disabled}
            endIcon={!loading && <ArrowForwardIosRounded />}
            sx={{
              textTransform: "none",
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              `Continue as a ${role}`
            )}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const RoleSelection = ({ userCredential }) => {
  const [mLoading, setMLoading] = useState(false);
  const [eLoading, setELoading] = useState(false);
  const navigate = useNavigate();

  const handleRoleSelect = async (role) => {
    try {
      navigate(`/${role}/signup`);
    } catch (error) {
      // Handle errors
      console.error("Error in role selection: ", error);
    } finally {
      setMLoading(false);
      setELoading(false);
    }
  };

  return (
    <Box
      sx={{
        animation: `${fadeInMoveDown} 1s forwards`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" align="center" gutterBottom>
        Sign up as a...
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: "1em" }}
      >
        Already have an account?{" "}
        <Link
          to="/login"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          Login
        </Link>
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <RoleSelectionCard
            role="member"
            description="Join as a member to get excellent help with your household tasks."
            onSelectRole={handleRoleSelect}
            loading={mLoading}
            setLoading={setMLoading}
            disabled={mLoading || eLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RoleSelectionCard
            role="helper"
            description="Join as a helper to assist others in your community with household tasks."
            onSelectRole={handleRoleSelect}
            loading={eLoading}
            setLoading={setELoading}
            disabled={mLoading || eLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoleSelection;
