import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  Skeleton,
  ListItem,
  Typography,
} from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy, // Use onSnapshot for real-time updates
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import { db } from "../../../firebase";
import ListLogs from "./ListLogs";

const FETCH_LIMIT = 10;

const ViewLogs = ({ id }) => {
  const [logs, setLogs] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Add a ref for the loading trigger div
  const observerRef = useRef(null);

  const fetchLogs = async () => {
    if (!lastVisible || !hasMore || loading) return;
    setLoading(true);

    const logsRef = collection(db, "logs");
    const logsQuery = query(
      logsRef,
      where("idsArr", "array-contains", id),
      orderBy("created", "desc"),
      startAfter(lastVisible),
      limit(FETCH_LIMIT)
    );

    try {
      const snapshot = await getDocs(logsQuery);

      const newLogs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setLogs([...logs, ...newLogs]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

      if (snapshot.docs.length < FETCH_LIMIT) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more logs:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          fetchLogs();
        }
      },
      {
        rootMargin: "200px", // Load 200px before the end of the list is in view
      }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [loading, hasMore, fetchLogs]);

  useEffect(() => {
    setLoading(true);

    // Fetch logs in real-time with onSnapshot and order by "created"
    const logsRef = collection(db, "logs");
    const logsQuery = query(
      logsRef,
      where("idsArr", "array-contains", id),
      orderBy("created", "desc"), // Order logs by created field
      limit(FETCH_LIMIT)
    );

    const unsubscribe = onSnapshot(
      logsQuery,
      (snapshot) => {
        const newLogs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLogs(newLogs);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

        // If fewer than the limit, disable load more
        setHasMore(snapshot.docs.length === FETCH_LIMIT);

        // Stop loading after data is successfully fetched
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching logs:", error);
        // Stop loading in case of an error
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Cleanup the listener when component unmounts
  }, [id]);

  console.log("loading: ", loading);
  console.log("logs.length: ", logs.length);

  return (
    <Box sx={{ mx: { xs: -3, sm: 0 } }}>
      {/* Show Skeletons when logs are loading and no logs are present */}
      {logs.length > 0 && (
        /* Show logs if available */
        <ListLogs logs={logs} />
      )}

      {!loading && logs.length == 0 && (
        <Box sx={{ py: 2, px: 3 }}>
          <Box
            sx={{
              background: "#F5F5F5",
              width: "auto",
              height: "72px",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1" color="textSecondary">
              No logs found.
            </Typography>
          </Box>
        </Box>
      )}

      {/* Loading spinner only when loading more logs and logs are already shown */}
      {loading && (
        <List sx={{ pt: 0 }}>
          {[...Array(10)].map((_, index) => (
            <React.Fragment key={index}>
              <Divider />
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Skeleton variant="text" width="70%" height={20} />
                <Skeleton variant="text" width="20%" height={20} />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      )}
      <Box ref={observerRef} sx={{ height: "1px", width: "100%", mt: 3 }} />

      {/* Load More button */}
      {!loading && hasMore && (
        <Button onClick={() => fetchLogs(true)} disabled={loading}>
          Load More Logs
        </Button>
      )}
    </Box>
  );
};

export default ViewLogs;
