import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import MemberSignUp from "../../components/MemberSignUp";
import SimplePage from "../../components/SimplePage";
import useLogPageVisit from "../../hooks/useLogPageVisit";

const MemberSignUpPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useLogPageVisit("/member/signup");

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }
  }, []);

  return (
    <SimplePage>
      <MemberSignUp />

      {/* <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            px: { xs: "auto", md: 0, lg: 4 },
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <MemberSignUp />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GettingStartedCard />
        </Grid>
      </Grid> */}
    </SimplePage>
  );
};

export default MemberSignUpPage;
