import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import AvatarStatusBadge from "../../AvatarStatusBadge";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";
import EmployeeSearchBar from "../../manageEmployees/EmployeeSearchBar";

const EditHelpersDialog = ({
  open,
  setOpen,
  visitId,
  start,
  end,
  timeZoneId,
  visitEmployees,
}) => {
  const [loading, setLoading] = useState(false);

  // State to track checked employees
  const [checked, setChecked] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState({});

  useEffect(() => {
    // checkAvailability(); // Check availability when the component or employees list updates
    // Initialize 'checked' state to match employees present in the visit
    const initialCheckedState = {};
    Object.keys(visitEmployees).forEach((id) => {
      initialCheckedState[id] = visitEmployees.hasOwnProperty(id);
    });
    setChecked(initialCheckedState);
  }, [visitEmployees, open]);

  const handleToggle = (id) => () => {
    // Toggle the checked state for this employee ID
    const newCheckedState = {
      ...checked,
      [id]: !checked[id],
    };
    setChecked(newCheckedState);
  };

  const handleSave = async () => {
    setLoading(true); // Set loading to true while saving
    try {
      const newEmployees = {}; // Object to hold the new employees data
      const employeeArr = []; // Array to hold the employee IDs

      // Include existing employees if they are checked
      Object.keys(visitEmployees).forEach((id) => {
        if (checked[id]) {
          newEmployees[id] = visitEmployees[id]; // Use existing employee data
          employeeArr.push(id); // Add to employeeArr
        }
      });

      // Include new selected employees if they are checked
      Object.keys(selectedEmployees).forEach((id) => {
        if (checked[id]) {
          const employee = selectedEmployees[id];
          newEmployees[id] = {
            comments: "",
            rating: null,
            responseStatus: "needsAction",
            updated: Timestamp.now(),
            avatarUrl: employee.avatarUrl,
            displayName: `${employee.firstName} ${employee.lastName?.charAt(
              0
            )}.`,
          };
          employeeArr.push(id); // Add to employeeArr
        }
      });

      // Update Firestore with a single write
      const visitRef = doc(db, "visits", visitId);
      await updateDoc(visitRef, {
        employees: newEmployees, // Set the new employees object
        employeeArr: employeeArr, // Update employeeArr with the new employee IDs
      });

      console.log("Employees successfully updated.");

      // Clear selected employees
      handleClose();
    } catch (error) {
      console.error("Error saving employees:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeSelect = (employee) => {
    // Update selected employees state
    setSelectedEmployees((prev) => ({
      ...prev,
      [employee?.id]: employee, // Store the whole employee object
    }));

    // Update the checked state for the newly selected employee
    setChecked((prev) => ({
      ...prev,
      [employee?.id]: true, // Mark this employee as checked
    }));
  };

  const handleClose = () => {
    setSelectedEmployees({});
    setChecked({});
    setOpen(false);
  };

  const allEmployees = { ...visitEmployees, ...selectedEmployees };

  return (
    <ResponsiveDialog
      open={open}
      fullHeight={true}
      onClose={handleClose}
      title={`Manage Helpers`}
      width="600px"
      dialogActions={
        <>
          <Button
            onClick={handleClose}
            color="primary"
            variant="text"
            disabled={loading}
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={loading}
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
          </Button>
        </>
      }
    >
      <DialogContent>
        <EmployeeSearchBar
          clearOnSelect={true}
          onEmployeeSelect={handleEmployeeSelect}
        />
        <List>
          {Object.entries(allEmployees)
            .sort(([, employeeA], [, employeeB]) => {
              if (
                employeeA?.responseStatus === "declined" &&
                employeeB.responseStatus !== "declined"
              ) {
                return 1; // employeeA (declined) goes after employeeB
              } else if (
                employeeA?.responseStatus !== "declined" &&
                employeeB.responseStatus === "declined"
              ) {
                return -1; // employeeA goes before employeeB (declined)
              }

              // Sort by displayName if available, otherwise fall back to firstName
              const nameA =
                employeeA?.displayName || employeeA?.firstName || "";
              const nameB =
                employeeB?.displayName || employeeB?.firstName || "";

              return nameA.localeCompare(nameB);
            })

            .map(([id, employee]) => {
              const labelId = `checkbox-list-avatar-label-${id}`;

              // Retrieve the response status from the visit details, or set a default status
              const responseStatus =
                visitEmployees[id]?.responseStatus || "needsAction";

              const displayName =
                employee?.displayName ||
                `${employee?.firstName} ${employee?.lastName?.charAt(0)}`;

              return (
                <ListItemButton
                  key={id}
                  // disabled={!isAvailable}
                  // sx={{ opacity: isAvailable ? 1 : 0.5, borderRadius: "15px" }}
                  onClick={handleToggle(id)}
                >
                  {/* <ListItemIcon> */}
                  <Checkbox
                    edge="start"
                    checked={checked[id] || false} // controlled by the 'checked' state
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    onChange={handleToggle(id)}
                  />
                  {/* </ListItemIcon> */}

                  {/* Incorporating the AvatarStatusBadge and customized text */}
                  <Stack direction="row" gap={1} alignItems="center">
                    <ListItemAvatar>
                      <AvatarStatusBadge
                        status={responseStatus} // this should reflect the actual response status
                        avatarUrl={employee?.avatarUrl}
                        displayName={displayName}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={displayName}
                      secondary={
                        responseStatus === "declined" ? (
                          <>
                            {employee?.declineReason}{" "}
                            {employee?.contactOutcome
                              ? `[${employee.contactOutcome}]`
                              : ""}{" "}
                            - {employee?.declineMessage}
                          </>
                        ) : (
                          <>{responseStatus}</>
                        )
                      } // display the appropriate status text
                    />
                  </Stack>
                </ListItemButton>
              );
            })}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
};

export default EditHelpersDialog;
