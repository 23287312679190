import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material"; // Don't forget to import useTheme
import useTrackVisibility from "../../../hooks/useTrackVisibility";

function OurMembers({ pageName = "" }) {
  const ref = useTrackVisibility(() => {}, `${pageName}/"member stories"`); // Pass the logMessage here

  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      ref={ref}
      sx={{
        backgroundColor: theme.palette.primary.lightest,
      }}
    >
      <Container
        sx={{
          paddingY: theme.spacing(8),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            maxWidth: "800px",
          }}
        >
          <Typography variant="h4" fontWeight="700" gutterBottom>
            Every Member{" "}
            <span style={{ color: theme.palette.primary.vibrant }}>
              a Unique Story{" "}
            </span>
          </Typography>
          <Typography variant="body1" mb={4}>
            Regardless of age, hobbies, interests, or abilities, every member
            is looking for help in a unique way. It's often said among our helpers that
            no two visits are ever the same.
          </Typography>
          <Typography variant="body1" mb={4}>
            Take Sharon, an amazing writer in her early 80’s. Her weekly visits
            involve getting help with technology, cleaning & organizing, and
            accompanying her on shopping trips for groceries and pet essentials.
            These visits always bring words of appreciation.
          </Typography>
          <Typography variant="body1" mb={4}>
            Peggy, a school teacher in her 50’s, loves using Linked Lives once
            every season to help get her landscaping under control. It's
            rewarding work tackling the yard with an eletric trimmer and
            pruners, to fight back the new growth. It's always great to hear how
            her school year is going.
          </Typography>
          <Typography variant="body1" mb={4}>
            Then there's Curt, a handy guy in his 70’s. He loves working in the
            garage, putting in several intense days here and there with his
            helper working side-by-side to go through years of storage buildup.
          </Typography>
          {/* <Typography variant="body1" mb={4}>
            And with Betsy, an incredible artist and musician in her 80’s? We’re
            helping with computer-related tasks, and balancing that with weeding
            in the garden.
          </Typography> */}
          <Typography variant="body1" mb={4}>
            Discover your unique journey with Linked Lives. Become a member and
            let's see where our story takes us!
          </Typography>
          <Button
            component={Link} // use the Link component as the root node of the Button
            to="/get-started" // use 'to' instead of 'href' for react-router's Link
            variant="contained"
            size="large"
            color="primary"
            sx={{
              width: { xs: "100%", sm: 150 },
              height: "56px",
              textTransform: "none",
            }}
          >
            Sign up
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default OurMembers;
