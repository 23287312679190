import { Box } from "@mui/material";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../../firebase";
import CRMDateEditor from "../communication/CRMDateEditor";
import { DateTime } from 'luxon';

const CRMDates = ({ memberPrivateData }) => {
  const handleSaveLastContacted = async (newDate) => {
    try {
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.lastContactedDate": newDate ? newDate.toDate() : null,
      });
    } catch (error) {
      console.error("Error updating last contacted date: ", error);
    }
  };

  const handleClearLastContacted = async () => {
    try {
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.lastContactedDate": null,
      });
    } catch (error) {
      console.error("Error clearing last contacted date: ", error);
    }
  };

  const handleSaveFollowUpDate = async (newDate) => {
    try {
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.followUpDate": newDate ? newDate.toDate() : null,
      });
    } catch (error) {
      console.error("Error updating follow-up date: ", error);
    }
  };

  const handleClearFollowUpDate = async () => {
    try {
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.followUpDate": null,
      });
    } catch (error) {
      console.error("Error clearing follow-up date: ", error);
    }
  };

  const handleSetFollowUpDateToNow = async () => {
    try {
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.followUpDate": Timestamp.now(),
      });
    } catch (error) {
      console.error("Error setting follow-up date to now: ", error);
    }
  };

  const handleSetLastContactedDateToNow = async () => {
    try {
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.lastContactedDate": Timestamp.now(),
      });
    } catch (error) {
      console.error("Error setting follow-up date to now: ", error);
    }
  };

  const handleIncrementFollowUpDate = async (number, unit) => {
    try {
      // Start from the current date and increment
      const newDate = DateTime.now().plus({ [unit]: number });
  
      // Convert to Firestore Timestamp
      const newTimestamp = Timestamp.fromDate(newDate.toJSDate());
  
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.followUpDate": newTimestamp,
      });
    } catch (error) {
      console.error(`Error updating follow-up date by ${number} ${unit}: `, error);
    }
  };
  

  return (
    <>
      <CRMDateEditor
        label="Last contacted"
        dateValue={memberPrivateData?.crm?.lastContactedDate}
        onSave={handleSaveLastContacted}
        onClear={handleClearLastContacted}
        onSetToNow={handleSetLastContactedDateToNow}
      />

      <CRMDateEditor
        label="Follow up date"
        dateValue={memberPrivateData?.crm?.followUpDate}
        onSave={handleSaveFollowUpDate}
        onClear={handleClearFollowUpDate}
        onSetToNow={handleSetFollowUpDateToNow}
        onIncrement={handleIncrementFollowUpDate}
      />
    </>
  );
};

export default CRMDates;
