import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { db, functions } from "../../firebase";
import { extractPhoneNumber } from "../../services/inputServices";
import PhoneField from "../fields/PhoneField";

const ContactUs = ({
  fields = ["name", "phone", "message"],
  buttonText = "Submit",
  handleClose = () => {},
  location = null,
}) => {
  const [values, setValues] = useState({
    name: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSave = async (event) => {
    event.preventDefault();
    if (!validateStep()) return;
    setLoading(true);

    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    const leadData = {
      name: values?.name || "",
      phone: extractPhoneNumber(values?.phone || ""),
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: new Date(),
        contactedCount: 0,
        source: "interest_form",
        timeline: {
          interest_form: new Date(),
        },
        utmSource: utmSource || "",
        message: values?.message || "",
      },
      location: {
        line1: location?.line1 || "",
        city: location?.city || "",
        state: location?.state || "",
        zipCode: location?.zipCode ? Number(location.zipCode) : "",
      },
    };

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date)
              ? [k, cleanData(v)]
              : [k, v]
          )
      );
    };

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    console.log("leadData: ", filteredLeadData);

    const createLead = httpsCallable(functions, "createLead");
    createLead({ ...filteredLeadData, sessionId: storedSessionId });

    // Save the lead to Firebase
    // await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
    //   merge: true,
    // });

    console.log("Lead saved successfully");

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _contactSubmitted: new Date(),
            // _zipCode: values?.zipCode,
            _source: "interest_form",
            _name: values?.name || "",
            _phone: values?.phone || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    setLoading(false);
    setSubmitted(true);

    handleClose();
  };

  const validateStep = () => {
    let tempErrors = {};
    if (fields.includes("name") && !values.name)
      tempErrors.name = "Name is required";
    if (
      (fields.includes("phone") && !values.phone) ||
      values.phone.replace(/\D/g, "").length !== 10
    )
      tempErrors.phone = "Your phone number must be 10 digits.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {submitted ? (
        <Box sx={{ height: "120px" }}>
          <Alert>We will be reaching out shortly!</Alert>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              width: "100%",
            }}
          >
            {fields.includes("name") && (
              <TextField
                name="name" // Add this line
                id="name"
                type="text"
                fullWidth
                placeholder="Name"
                variant="outlined"
                value={values.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            )}
            {fields.includes("phone") && (
              <PhoneField
                placeholder={"Phone"}
                label={null}
                phone={values.phone}
                error={errors.phone}
                handleChange={handleChange}
              />
            )}
          </Box>
          {fields.includes("message") && (
            <TextField
              sx={{ mt: "16px" }} // Assuming the theme's spacing unit is 8px, 2 * 8px = 16px
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              name="message"
              // label="Message"
              value={values.message}
              onChange={handleChange}
              placeholder="Message (optional)"
            />
          )}
          <Button
            variant={
              values.name && values.phone.replace(/\D/g, "").length === 10
                ? "contained"
                : "outlined"
            }
            fullWidth
            disabled={loading}
            onClick={handleSave}
            sx={{ height: "56px", mt: 2, mb: 2, textTransform: "none" }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              buttonText
            )}
          </Button>
        </>
      )}
    </Box>
  );
};

export default ContactUs;
