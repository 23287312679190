import { Typography } from "@mui/material";
import React from "react";
import SimplePage from "../../components/SimplePage";

const ConfirmationPage = () => {
  return (
    <SimplePage>
      <Typography variant="h6">Thanks!</Typography>
      <Typography variant="body1">You can close this page now.</Typography>
    </SimplePage>
  );
};

export default ConfirmationPage;
