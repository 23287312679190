// EmployeeDrawer.js
import React from "react";
import EmployeeCardV2 from "../employee/EmployeeCardV2";

const EmployeeDrawer = ({
  employeeId,
  userRole = "member",
  memberId = "",
  onClose,
}) => {
  return (
    <>
      <EmployeeCardV2
        userRole={userRole}
        employeeId={employeeId}
        memberId={memberId}
        onClose={onClose}
      />
    </>
  );
};

export default EmployeeDrawer;
