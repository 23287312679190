import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import React, { useState } from "react";
import { auth, db } from "../firebase";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import SimplePage from "../components/SimplePage";
import { addEmployeeRole } from "../services/employeeServices";

const EmployeeSignUpPage = () => {
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [gLoading, setGLoading] = useState(false);

  const navigate = useNavigate();
  const nextUrl = "/dashboard";

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleGoogleSignUp = async () => {
    setGLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      await addEmployeeRole(); // Assuming this function updates the user's role

      // Force a refresh of the token to ensure the new role is reflected
      await user.getIdToken(true);
      navigate(nextUrl);
    } catch (err) {
      setErrors({ general: "Google sign up failed. Please try again." });
    } finally {
      setGLoading(false);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    const newError = {}; // initialize newError object here
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;

      await addEmployeeRole();

      // Force a refresh of the token to ensure the new role is reflected
      await user.getIdToken(true);

      // Create a document in Firestore for the member
      await Promise.all([
        // Setting the member's public data
        setDoc(doc(db, "employees", user.uid), {
          avatarUrl: "",
          created: Timestamp.now(),
        }),
        // Setting the member's private data
        setDoc(doc(db, "employeesPrivate", user.uid), {
          avatarUrl: "",
          email: values.email,
          created: Timestamp.now(),
        }),
      ]);

      navigate("/dashboard");
    } catch (err) {
      console.error("Error: ", err)
      switch (err.code) {
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/email-already-in-use":
          newError.email =
            "The email address is already in use by another account.";
          break;
        case "auth/weak-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimplePage>
      <Card variant="outlined" style={{ padding: "20px", maxWidth: "450px" }}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Become a Helper
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            Already have a helper account?{" "}
            <Link
              to="/helper/login"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              Login
            </Link>
          </Typography>
          <form onSubmit={handleSignUp} noValidate>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: "20px" }}
            >
              <span>
                By clicking Submit below and creating an account, I agree to the
                Linked Lives{" "}
                <a
                  href="/legal/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "hsl(190, 100%, 30%)",
                    fontWeight: 600,
                    textDecoration: "none",
                  }}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "hsl(190, 100%, 30%)",
                    fontWeight: 600,
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
                .
              </span>
            </Typography>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              disableElevation
              disabled={loading || gLoading}
              sx={{ height: "60px", textTransform: "none" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
          {/* <DividerWithText>or</DividerWithText>
          <Button
            variant="outlined"
            disabled={loading || gLoading}
            color="primary"
            startIcon={<Google />} // Add the Google icon here
            onClick={handleGoogleSignUp}
            fullWidth
          >
            {gLoading ? <CircularProgress size={24} /> : "Sign Up with Google"}
          </Button> */}
        </CardContent>
      </Card>
    </SimplePage>
  );
};

export default EmployeeSignUpPage;
