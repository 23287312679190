import { DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { setMembershipPlan } from "../services/memberServices";
import PricingCards from "../pages/public/sections/PricingCards";

// Props needed for Firestore, cloud function, and managing states
const ChangeMembership = ({
  memberId,
  timeZoneId,
  zipCode = 98221,
  membershipTier,
  setOpenPricingCards,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanClick = async (plan) => {
    setIsLoading(true);
    setSelectedPlan(plan.tier);

    try {
      // Assuming the implementation of setMembershipPlan is handled outside
      await setMembershipPlan({
        plan: plan.tier,
        hourlyRate: plan.hourlyRate,
        membershipFee: plan.membershipFee,
        timeZoneId: timeZoneId,
        memberId: memberId,
      });
      console.log(`Plan Tier: ${plan.tier} set successfully`);

      setOpenPricingCards(false); // Assuming this is a prop function
    } catch (error) {
      console.error("Error setting membership plan:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogContent>
      <Typography align="center" sx={{ mb: 1 }}>
        Select the plan that works best for you.
      </Typography>
      <PricingCards
        onClick={handlePlanClick}
        dense={true}
        buttonText="Select"
        loading={isLoading}
        loadingPlan={selectedPlan}
        currentPlan={membershipTier}
        zipCode={zipCode}
      />
    </DialogContent>
  );
};

export default ChangeMembership;
