import {
  DirectionsOutlined,
  FileCopyOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useSnackbar } from "../../../providers/SnackbarProvider";
import { handleCopy } from "../../../services/formServices";

const AddressDirectionsLineItem = ({
  visitData,
  visitPrivateData,
  loadingPrivate,
}) => {
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { location: { city = "", state = "", zipCode = "" } = {} } =
    visitData || {};

  console.log("visitData: ", visitData);

  const { address: { directions = "", line1 = "", line2 = "" } = {} } =
    visitPrivateData || {};

  // Public address part
  const visitAddressPublic = `${city}, ${state} ${zipCode}`;

  // Private address part with privacy filter
  let visitAddressPrivate = line1
    ? `${line1}${line2 ? ", " + line2 : ""}, `
    : "";

  // Full address combining private and public parts
  const visitAddress = `${visitAddressPrivate}, ${visitAddressPublic}`;

  const handleAddressClick = (address) => {
    let url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    // For mobile devices, try to open in native maps app
    if (isMobile) {
      if (navigator.userAgent.indexOf("Android") !== -1) {
        url = `geo:0,0?q=${encodeURIComponent(address)}`;
        window.location.href = url; // Change the current window location
      } else if (
        navigator.userAgent.indexOf("iPhone") !== -1 ||
        navigator.userAgent.indexOf("iPad") !== -1
      ) {
        url = `maps://maps.google.com/maps?daddr=${encodeURIComponent(
          address
        )}&amp;ll=`;
        window.location.href = url; // Change the current window location
      } else {
        window.open(url); // Fallback for other mobile devices
      }
    } else {
      window.open(url, "_blank"); // For non-mobile devices, open in a new tab
    }
  };

  return (
    <>
      <ListItemButton
        onClick={() => handleAddressClick(visitAddress)}
        sx={{ borderRadius: "15px", px: 0 }}
      >
        <ListItemIcon sx={{ minWidth: "48px" }}>
          <LocationOnOutlined color="primary" />
        </ListItemIcon>
        <ListItemText
          sx={{ marginRight: 4 }}
          primary={
            loadingPrivate ? (
              <Skeleton variant="text" />
            ) : (
              <>
                {visitAddressPrivate}
                {visitAddressPublic}
              </>
            )
          }
        />

        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation(); // Stop the ListItem's onClick from being triggered
              handleCopy(
                visitAddress,
                (message) => showSnackbar(message, "success"),
                (message) => showSnackbar(message, "success")
              );
            }}
          >
            <FileCopyOutlined />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>

      {directions && (
        <ListItem sx={{ paddingX: "0px" }}>
          <ListItemIcon sx={{ minWidth: "48px" }}>
            <DirectionsOutlined color="primary" />
          </ListItemIcon>
          <ListItemText sx={{ marginRight: 4 }} primary={directions} />
        </ListItem>
      )}
    </>
  );
};

export default AddressDirectionsLineItem;
