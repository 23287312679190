import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import React from "react";
import { db } from "../../../firebase";
import { useEmployee } from "../../../providers/EmployeeProvider";
import FactBox from "../../boxes/FactBox";
import { useAuth } from "../../../providers/AuthProvider";
import CRMDates from "./CRMDates";

const MemberStats = ({ memberPrivateData, visitsData, memberAdminData }) => {
  // Filter visits where the `start` timestamp is in the past

  const { employeeData } = useEmployee();
  const {currentUser} = useAuth()
  const eId = currentUser?.uid;
  const eDisplayName = `${
    employeeData?.firstName
  } ${employeeData?.lastName?.charAt(0)}`;
  const eAvatarUrl = employeeData?.avatarUrl || "";

  // Function to assign the current user to "Assigned to"
  const handleAssignToSelf = async () => {
    try {
      await updateDoc(doc(db, "membersPrivate", memberPrivateData.id), {
        "crm.assignedTo": {
          id: eId,
          displayName: eDisplayName,
          avatarUrl: eAvatarUrl,
        },
      });
    } catch (error) {
      console.error("Error assigning to self: ", error);
    }
  };

  const pastVisitsCount = visitsData
    ? Object.values(visitsData).filter(
        (visit) => DateTime.fromJSDate(visit.start.toDate()) < DateTime.now()
      ).length
    : 0;

  const formatDate = (dateValue) => {
    if (!dateValue) return "N/A";

    const date = DateTime.fromJSDate(dateValue.toDate());
    const now = DateTime.now();

    // Check if the date is within the current year
    const isWithinOneYear = date.hasSame(now, "year");

    // Check if the date is within the current month
    const isWithinOneMonth = date.hasSame(now, "month");

    // Check if the date is within the last 2 weeks
    const isWithinOneWeeks = now.diff(date, "days").days <= 7;

    if (isWithinOneWeeks) {
      return date.toFormat("EEE h:mm a"); // Day name, Month, day, hours, and minutes
    } else if (isWithinOneMonth) {
      return date.toFormat("EEE, MMM d"); // Day of week, month, and day
    } else if (isWithinOneYear) {
      return date.toFormat("MMM d, yyyy"); // Month and day only
    } else {
      return date.toFormat("MMM yyyy"); // Full format with year
    }
  };

  const FullDateTooltip = ({ dateValue }) => {
    const formattedDate = formatDate(dateValue);
    const fullDate = dateValue
      ? DateTime.fromJSDate(dateValue.toDate()).toLocaleString(
          DateTime.DATETIME_FULL
        )
      : "N/A";

    return (
      <Tooltip arrow title={fullDate}>
        <Typography fontWeight={"600"}>{formattedDate}</Typography>
      </Tooltip>
    );
  };

  // Define the statistics data structure
  const stats = [
    {
      label: "Joined",
      value: (
        <FullDateTooltip
          dateValue={memberPrivateData?.created}
        />
      ),
    },
    {
      label: "Total visits",
      value: pastVisitsCount,
    },
    // {
    //   label: "Assigned to",
    //   value: (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         gap: 1,
    //       }}
    //       onClick={handleAssignToSelf} // Assign to self when clicked
    //     >
    //       <Avatar
    //         sx={{ width: 24, height: 24 }}
    //         src={memberPrivateData?.crm?.assignedTo?.avatarUrl}
    //       ></Avatar>
    //       <Typography fontWeight={"600"}>
    //         {memberPrivateData?.crm?.assignedTo?.displayName
    //           ? memberPrivateData.crm.assignedTo.displayName
    //           : "--"}
    //       </Typography>
    //     </Box>
    //   ),
    // },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
        gap: 1,
        width: "100%",
      }}
    >
      {stats.map((stat, index) => (
        <FactBox key={index} value={stat.value} label={stat.label} />
      ))}
      <CRMDates memberPrivateData={memberPrivateData} />
    </Box>
  );
};

export default MemberStats;
