import { Check, CheckCircle, CheckCircleOutline, Clear } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { MEMBER_COUNT } from "../../services/variableServices";

const EmailView = ({ title, setView, email, setEmail }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const steps = [
    {
      primary: "Trusted and reliable help on-demand",
      secondary:
        "Excellent household help is ready whenever you need an extra hand at our $40/hr flat rate. No more waiting or feeling stuck.",
    },
    {
      primary: "Easily schedule online or by phone",
      secondary:
        "Schedule visits online or over the phone with ease. Experience the convenience tailored for seniors.",
    },
    // {
    //   primary: "Fits your budget and needs",
    //   secondary:
    //     "Members book around 2 hours per month on average, but we can help you as much or as little as needed to fit your budget.",
    // },
    {
      primary: "Enjoy lasting peace-of-mind",
      secondary:
        "Find peace as we tackle your challenging chores. We'll complete the tasks you don't want to or can't do!",
    },
    // {
    //   primary: `Trusted by over ${Math.floor(MEMBER_COUNT / 10) * 10}+ members`,
    //   secondary: `Join over ${
    //     Math.floor(MEMBER_COUNT / 10) * 10
    //   }+ members who count on us for their household help with our $50 signup fee. Consistent, reliable, and here for you.`, // Consistent, reliable, and here for you.`,
    // },
  ];

  // Load email from localStorage when the component mounts
  useEffect(() => {
    const storedEmail = localStorage.getItem("lastUsedEmail");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setError("");
    localStorage.setItem("lastUsedEmail", newEmail); // Update localStorage
  };

  const clearEmail = () => {
    setEmail("");
    setError("");
    localStorage.removeItem("lastUsedEmail"); // Clear localStorage
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // This prevents the form from submitting in the traditional way

    if (!email) {
      setError("Please enter your email.");
      return;
    }

    setLoading(true);

    const auth = getAuth();
    let newError; // initialize newError object here

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length === 0) {
        // No sign-in methods returned, email does not exist in Auth system
        console.log("Email does not exist. Redirecting to sign up.");
        // Proceed to SignUp view
        setView("SIGNUP");
      } else {
        // Sign-in methods found, email exists
        console.log("Email exists. Redirecting to login.");
        setView("LOGIN");
      }
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case "auth/missing-password":
          newError = "You must enter your password.";
          break;
        case "auth/invalid-email":
          newError = "The email address is badly formatted.";
          break;
        case "auth/user-not-found":
          newError = "There is no user with this email.";
          break;
        case "auth/wrong-password":
          newError = "The password is invalid.";
          break;
        default:
          newError = "An unknown error occurred. Please try again later.";
          break;
      }
      setError(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: "1em" }}
      >
        Please enter your email to continue.
      </Typography>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        name="email"
        type="email"
        value={email}
        onChange={handleEmailChange}
        error={error}
        helperText={error || "Your email is used to access our platform."}
        sx={{ mb: 1 }}
        InputProps={{
          endAdornment: email && (
            <InputAdornment position="end">
              <IconButton onClick={clearEmail}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={loading} // Disabled if loading or email is invalid
        size="large"
        sx={{ width: "100%", height: "56px", textTransform: "none", mt: 2 }} // Added marginTop to push the button to the bottom
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Continue"}
      </Button>
      <List sx={{mt: 2}}>
        {steps.map((step, index) => (
          <ListItem key={index} disableGutters disablePadding>
            <ListItemIcon sx={{ minWidth: "34px" }}>
              <Check color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={step.primary}
              // secondary={step.secondary}
              primaryTypographyProps={{
                color: "text.secondary",
                // fontWeight: "bold",
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default EmailView;
