import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../../firebase";
import AvatarField from "../../fields/AvatarField";

const EditAvatar = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  avatarUrl,
  memberId,
}) => {
  const [loading, setLoading] = useState(false);
  const [editAvatar, setEditAvatar] = useState(avatarUrl); // Store email input

  // Handle input change
  const handleChange = (avatarUrl) => {
    setEditAvatar(avatarUrl);
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Update the usersPrivate collection
      const memberDocRef = doc(db, "members", memberId);
      const memberPrivateDocRef = doc(db, "membersPrivate", memberId);

      await updateDoc(memberDocRef, { avatarUrl: editAvatar });
      await updateDoc(memberPrivateDocRef, { avatarUrl: editAvatar });

      handleNext(); // Move to the next step after successful avatarUrl update
    } catch (error) {
      console.error("Error updating avatarUrl: ", error);
    } finally {
      setLoading(false); // End loading state
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Update your profile picture
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          This helps others on the platform recognize you.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <AvatarField avatarUrl={avatarUrl} onAvatarUpload={handleChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Full-width button
          >
            {loading ? ( // Show loading spinner while avatarUrl is updating
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Save</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default EditAvatar;
