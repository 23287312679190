import React, { useEffect, useRef } from "react";

const ConfettiAnimation = () => {
  const containerRef = useRef(null);
  const confetti = useRef([]);
  const isConfettiActive = useRef(true); // Flag to track confetti activity

  useEffect(() => {
    // Globals
    const random = Math.random;
    const cos = Math.cos;
    const sin = Math.sin;
    const PI = Math.PI;
    const PI2 = PI * 2;

    const particles = 10;
    const spread = 40;
    const sizeMin = 5;
    const sizeMax = 12;
    const eccentricity = 10;
    const deviation = 100;
    const dxThetaMin = -0.1;
    const dxThetaMax = -dxThetaMin - dxThetaMin;
    const dyMin = 0.13;
    const dyMax = 0.18;
    const dThetaMin = 0.4;
    const dThetaMax = 0.7 - dThetaMin;

    const vibrantColors = [
      // () => color(255, 0, 0), // Bright Red
      () => color(0, 255, 0), // Bright Green
      () => color(0, 0, 255), // Bright Blue
      () => `hsl(191, 100%, 30%)`, // Bright Blue
      () => `hsl(150, 100%, 30%)`, // Bright Green
      // () => color(255, 255, 0), // Bright Yellow
      // () => color(255, 0, 255), // Bright Magenta
      () => color(0, 255, 255), // Bright Cyan
      // () => color(255, 165, 0), // Bright Orange
      () => color(128, 0, 128), // Bright Purple
    ];

    const colorThemes = [
      ...vibrantColors,
      // () => color(255, 20, 147), // Deep Pink
      () => color(0, 191, 255), // Deep Sky Blue
      () => color(75, 0, 130), // Indigo
      // () => color(255, 228, 181), // Light Peach
    ];

    function color(r, g, b) {
      return `rgb(${r},${g},${b})`;
    }

    const container = document.createElement("div");
    container.style.position = "fixed";
    container.style.top = "0";
    container.style.left = "0";
    container.style.width = "100%";
    container.style.height = "0";
    container.style.overflow = "visible";
    container.style.zIndex = "9999";
    containerRef.current.appendChild(container);

    class Confetto {
      constructor(theme) {
        this.frame = 0;
        this.outer = document.createElement("div");
        this.inner = document.createElement("div");
        this.outer.appendChild(this.inner);

        const outerStyle = this.outer.style;
        const innerStyle = this.inner.style;
        outerStyle.position = "absolute";
        outerStyle.width = `${sizeMin + sizeMax * random()}px`;
        outerStyle.height = `${sizeMin + sizeMax * random()}px`;
        innerStyle.width = "100%";
        innerStyle.height = "100%";
        innerStyle.backgroundColor = theme();

        outerStyle.perspective = "50px";
        outerStyle.transform = `rotate(${360 * random()}deg)`;
        this.x = window.innerWidth * random();
        this.y = -deviation;
        this.dx = sin(dxThetaMin + dxThetaMax * random());
        this.dy = dyMin + dyMax * random();
        outerStyle.left = `${this.x}px`;
        outerStyle.top = `${this.y}px`;

        this.update = (height, delta) => {
          this.frame += delta;
          this.x += this.dx * delta;
          this.y += this.dy * delta;
          outerStyle.left = `${this.x}px`;
          outerStyle.top = `${this.y}px`;
          return this.y > height + deviation;
        };
      }
    }

    const startConfetti = () => {
      // const theme = colorThemes[Math.floor(random() * colorThemes.length)];
      const addConfetto = () => {
        if (isConfettiActive.current) {
          const theme = colorThemes[Math.floor(random() * colorThemes.length)];
          // Only add if active
          const confetto = new Confetto(theme);
          confetti.current.push(confetto);
          container.appendChild(confetto.outer);
          setTimeout(addConfetto, spread * random());
        }
      };

      let frame;
      const loop = (timestamp) => {
        const height = window.innerHeight;
        for (let i = confetti.current.length - 1; i >= 0; --i) {
          if (confetti.current[i].update(height, 16)) {
            container.removeChild(confetti.current[i].outer);
            confetti.current.splice(i, 1);
          }
        }

        // Stop the loop if there are no confetti particles left
        if (confetti.current.length > 0) {
          frame = requestAnimationFrame(loop);
        }
      };

      const endTime = Date.now() + 2000;
      setTimeout(() => {
        isConfettiActive.current = false; // Stop adding new confetti
      }, 2000);

      addConfetto();
      requestAnimationFrame(loop);
    };

    startConfetti();

    return () => {
      // Clean up the container
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, []);

  return <div ref={containerRef} />;
};

export default ConfettiAnimation;
