import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import BigButton from "../buttons/BigButton";

const QuestionSubsection = ({ question, title, options, onAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(null); // Track the selected option

  const handleSelect = (optionText) => {
    setSelectedOption(optionText); // Update selected option in this section
    onAnswer(optionText);
  };

  return (
    <Box mb={6}>
      <Typography variant="h4" align="center">
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        gutterBottom
        color={"text.secondary"}
      >
        {question}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly", // Ensures even distribution across the row
          gap: 2, // Optional gap between buttons for spacing
          m: 1,
        }}
      >
        {options.map((option) => (
          <BigButton
            key={option.id}
            option={option}
            isSelected={selectedOption === option.text} // Compare the option to the selected state
            onClick={handleSelect}
          />
        ))}
      </Box>
    </Box>
  );
};

export default QuestionSubsection;
