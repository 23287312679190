import { Payment } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import React from "react";

const PayrollListItem = ({ visitData }) => {
  const { start = Timestamp.now() } = visitData;

  // Constants for payroll cycle and initial reference date
  const timezone = "America/Los_Angeles";
  const payrollStartReference = DateTime.fromISO("2024-10-30T00:00:00", {
    zone: timezone,
  });
  const payrollPeriodDays = 14;

  // Calculate the payroll cycle that the visit falls into
  const visitDateTime = start
    ? DateTime.fromJSDate(start.toDate(), { zone: timezone })
    : null;
  const daysSinceReference = visitDateTime.diff(
    payrollStartReference,
    "days"
  ).days;
  const cyclesSinceReference = Math.floor(
    daysSinceReference / payrollPeriodDays
  );

  // Payroll period's end and payout date
  const periodEndDate = payrollStartReference.plus({
    days: (cyclesSinceReference + 1) * payrollPeriodDays,
  });
  const payoutDate = periodEndDate.plus({ days: 2 }).startOf("day");

  // Render
  return (
    <ListItem sx={{ borderRadius: "15px", px: 0 }}>
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <Payment color="primary" />
      </ListItemIcon>

      <ListItemText
        sx={{ marginRight: 4 }}
        primary={`Paid out on ${payoutDate.toFormat("cccc MMM d")}`}
      />
    </ListItem>
  );
};

export default PayrollListItem;
