import { CheckCircle, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import PhoneField from "../components/fields/PhoneField";
import { functions } from "../firebase";

const JobLeadForm = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    age: "",
    zipCode: "",
    utmSource: localStorage.getItem("utm_source") || "",
  });

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "Name is required.";

    if (!formData.phone || formData.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Your phone number must be 10 digits.";

    tempErrors.email = /.+@.+\.[A-Za-z]+$/.test(formData.email)
      ? ""
      : "Email is not valid.";
    tempErrors.age =
      formData.age && formData.age > 0 ? "" : "Valid age is required.";
    tempErrors.zipCode =
      formData.zipCode && formData.zipCode.length === 5
        ? ""
        : "Valid Zip Code is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const submitJobApplication = httpsCallable(
          functions,
          "submitJobApplication"
        );
        await submitJobApplication(formData);
        setSubmitted(true);
      } catch (error) {
        console.error("Error submitting application", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Typography align="center" variant="h4" component="h1" gutterBottom>
        Application Form
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 3, gap: 2, display: "flex", flexDirection: "column" }}
      >
        <Box>
          <Typography variant="h6" fontSize={"1rem"}>
            Personal Information
          </Typography>
          <Typography>
            Please fill out your personal details so we can get in contact.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            fullWidth
            label="Age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
            type="number"
            error={!!errors.age}
            helperText={errors.age}
          />
        </Box>

        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          type="email"
          error={!!errors.email}
          helperText={errors.email}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <PhoneField
            phone={formData.phone}
            required={true}
            handleChange={handleChange}
            error={errors.phone}
          />
          <TextField
            fullWidth
            label="Zip Code"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            required
            type="number"
            error={!!errors.zipCode}
            helperText={errors.zipCode}
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, height: "56px", textTransform: "none" }}
          disabled={loading || submitted}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : submitted ? (
            <>
              <CheckCircle sx={{ mr: 1 }} /> Success
            </>
          ) : (
            <>
              <Send sx={{ mr: 1 }} /> Submit
            </>
          )}
        </Button>
      </Box>
    </>
  );
};

export default JobLeadForm;
