import { AccessTime, Edit, EventRounded } from "@mui/icons-material";
import {
  Chip,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import React, { useState } from "react";
import { formatDate, formatTimeRange } from "../../../services/dateServices";
import ExpirationChip from "../../ExpirationChip";
import EditDateTimeDialog from "../EditDateTimeDialog";
import EditEmployeeWindowDialog from "../dialogs/EditEmployeeWindowDialog";

const DateTimeListItem = ({ visitData, userRole, userId = "" }) => {
  const {
    flags = [],
    employees = {},
    expiration = null,
    start = Timestamp.now(),
    end = Timestamp.now(),
    status = "confirmed",
    timeZoneId = "America/Los_Angeles",
  } = visitData || {};

  // dialogs
  const [openEditDateTime, setOpenEditDateTime] = useState(false);
  const [openEditWindows, setOpenEditWindows] = useState(false);

  // After the visitData start but before midnight
  const isEditable = moment().isBetween(
    moment(start?.toDate()).tz(timeZoneId).startOf("day"),
    moment(start?.toDate()).tz(timeZoneId).endOf("day"),
    null,
    "()"
  );

  const handleEditDateTime = () => {
    setOpenEditDateTime(true);
  };

  const handleEditWindows = () => {
    setOpenEditWindows(true);
  };

  const isRefunded = flags.includes("refundIssued");

  // Function to compare visitData window with employee windows
  const isAnyWindowDifferent = () => {
    if (!employees || !start || !end) {
      return false; // Return false if visitData or necessary properties are missing
    }

    const visitStart = moment(start.toDate()).tz(timeZoneId);
    const visitEnd = moment(end.toDate()).tz(timeZoneId);

    // Filter employees with "responseStatus" == "accepted"
    const acceptedEmployees = Object.values(employees).filter(
      (employee) => employee.responseStatus === "accepted"
    );

    // Check if any accepted employee's window is different
    return acceptedEmployees.some((employee) =>
      Object.values(employee.windows || {}).some((window) => {
        const windowStart = moment(window.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

        return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
      })
    );
  };

  // Determining how to display time format
  // Determining how to display time format
  const employeeCount =
    employees &&
    Object.values(employees).filter(
      (employee) => employee.responseStatus === "accepted"
    ).length;
  const windowsAreDifferent = isAnyWindowDifferent();
  const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

  // Custom formatting function
  const formatTime = (momentObj) => {
    return momentObj.minute() === 0
      ? momentObj.format("ha").toLowerCase() // Formats as '3pm' if minutes are 00
      : momentObj.format("h:mma").toLowerCase(); // Formats as '3:30pm' otherwise
  };

  const renderDateTime = () => (
    <>
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <AccessTime color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Stack direction="row" alignItems={"center"} gap={1}>
            <Typography variant="h6">
              {formatDate(
                moment(start?.toDate()).tz(timeZoneId).toDate(),
                timeZoneId
              )}
            </Typography>
            {status === "reschedule" && (
              <Chip
                label="Reschedule"
                color="success"
                size="small"
                sx={{
                  zIndex: 1,
                  borderRadius: "5px",
                  // ...(isMobile ? chipStyles : desktopChipStyles),
                }}
              />
            )}
            {status === "cancelled" && (
              <Chip
                label="Cancelled"
                color="error"
                size="small"
                sx={{
                  zIndex: 1,
                  borderRadius: "5px",
                  // ...(isMobile ? chipStyles : desktopChipStyles),
                }}
              />
            )}
            {isRefunded && (
              <Chip
                label="Refund issued"
                color="success"
                size="small"
                sx={{
                  zIndex: 1,
                  borderRadius: "5px",
                  // ...(isMobile ? chipStyles : desktopChipStyles),
                }}
              />
            )}
            {(userRole === "employee" || userRole === "admin") &&
              expiration && (
                <ExpirationChip
                  showTooltip={true}
                  expirationDate={expiration}
                  timeZoneId={timeZoneId}
                />
              )}
          </Stack>
        }
        secondary={
          !shouldShowTimes && (
            <Typography variant="body1">
              {Object.entries(employees).filter(
                ([_, employee]) => employee.responseStatus === "accepted"
              )[0]?.[1]?.windows
                ? Object.values(
                    Object.entries(employees).filter(
                      ([_, employee]) => employee.responseStatus === "accepted"
                    )[0][1].windows
                  ).map((window, index) => (
                    <React.Fragment key={index}>
                      {formatTimeRange(
                        moment(window?.start?.toDate()).tz(timeZoneId).toDate(),
                        moment(window?.end?.toDate()).tz(timeZoneId).toDate(),
                        timeZoneId
                      )}

                      <br />
                    </React.Fragment>
                  ))
                : formatTimeRange(
                    moment(start?.toDate()).tz(timeZoneId).toDate(),
                    moment(end?.toDate()).tz(timeZoneId).toDate(),
                    timeZoneId
                  )}
            </Typography>
          )
        }
      />
    </>
  );

  const renderDateListItem = () => {
    return (
      <ListItem sx={{ borderRadius: "15px", px: 0 }}>
        <ListItemIcon sx={{ minWidth: "48px" }}>
          <EventRounded color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography variant="h6">
                {formatDate(
                  moment(visitData?.start?.toDate()).tz(timeZoneId).toDate()
                )}
              </Typography>
              <ExpirationChip
                showTooltip={true}
                expirationDate={visitData?.expiration}
                timeZoneId={timeZoneId}
              />
            </>
          }
        />
      </ListItem>
    );
  };

  const renderTimeListItemButton = () => {
    return (
      <Tooltip
        title={"Adjustments can only be made during the visitData day."}
        enterTouchDelay={0}
        arrow
        placement="top"
      >
        <ListItemButton
          onClick={() => isEditable && handleEditWindows()}
          sx={{
            borderRadius: "15px",
            px: 0,
            ...(isEditable
              ? {
                  backgroundColor: "grey.100",
                  paddingY: 1,
                  height: "60px",
                  pl: 1,
                  ml: -1,
                  mr: -1,
                }
              : {}),
            // pointerEvents: isEditable ? "auto" : "none", // Optionally handle pointer events if needed
          }}
        >
          <ListItemIcon sx={{ minWidth: "48px" }}>
            <AccessTime color="primary" />
          </ListItemIcon>
          <ListItemText
            secondary={
              <Typography variant="body1">
                {visitData?.employees[userId]?.windows
                  ? Object.values(visitData?.employees[userId].windows).map(
                      (window, index) => (
                        <React.Fragment key={index}>
                          {formatTimeRange(
                            moment(window?.start?.toDate())
                              .tz(timeZoneId)
                              .toDate(),
                            moment(window?.end?.toDate())
                              .tz(timeZoneId)
                              .toDate(),
                            timeZoneId
                          )}
                          <br />
                        </React.Fragment>
                      )
                    )
                  : `${formatTimeRange(
                      moment(visitData?.start?.toDate())
                        .tz(timeZoneId)
                        .toDate(),
                      moment(visitData?.end?.toDate()).tz(timeZoneId).toDate(),
                      timeZoneId
                    )}`}
              </Typography>
            }
          />
          <ListItemSecondaryAction sx={{ pr: isEditable ? 1 : 0 }}>
            <IconButton
              edge="end"
              disabled={!isEditable}
              onClick={(e) => {
                e.stopPropagation(); // Stop the ListItemButton's onClick from being triggered
                handleEditWindows();
              }}
              // sx={{ml: 0 }}
            >
              <Edit />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </Tooltip>
    );
  };

  return (
    <>
      {userRole === "admin" && (
        <>
          <ListItemButton sx={{ paddingX: "0px" }} onClick={handleEditDateTime}>
            {renderDateTime()}
            <IconButton aria-label="comment" onClick={handleEditDateTime}>
              <Edit />
            </IconButton>
          </ListItemButton>
        </>
      )}
      {userRole === "employee" && (
        <>
          {renderDateListItem()}
          {renderTimeListItemButton()}
        </>
      )}
      {userRole === "member" && (
        <ListItem sx={{ paddingX: "0px" }}>{renderDateTime()}</ListItem>
      )}

      <EditDateTimeDialog
        open={openEditDateTime}
        setOpen={setOpenEditDateTime}
        visitData={visitData}
      />
      <EditEmployeeWindowDialog
        open={openEditWindows}
        handleClose={() => setOpenEditWindows(false)}
        visitData={visitData}
        employeeId={userId}
      />
    </>
  );
};

export default DateTimeListItem;
