import { AccessTime } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useState } from "react";

const CRMDateEditor = ({
  label,
  dateValue,
  onSave,
  onClear,
  onSetToNow,
  onIncrement,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dateValue ? moment(dateValue.toDate()) : null
  );

  const handleMenuOpen = (event) => {
    event?.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (event) => {
    event?.stopPropagation();
    setOpenPicker(true); // Open the picker immediately on clicking "Edit"
    handleMenuClose();
  };

  const handleClearClick = (event) => {
    event?.stopPropagation();
    setSelectedDate(null);
    onClear();
    handleMenuClose();
  };

  const handleSetToNow = (event) => {
    event?.stopPropagation();
    onSetToNow();
    setAnchorEl(null);
  };

  const handleIncrement = (event, number, unit) => {
    event?.stopPropagation();
    onIncrement(number, unit);
    setAnchorEl(null);
  };

  const handleSave = () => {
    onSave(selectedDate);
    setOpenPicker(false); // Close the picker after saving
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setOpenPicker(false); // Close the picker after saving
  };

  const menuItems = [
    {
      label: "Edit",
      icon: <EditIcon fontSize="small" />,
      onClick: handleEditClick,
    },
    {
      label: "Clear",
      icon: <ClearIcon fontSize="small" />,
      onClick: handleClearClick,
    },
    ...(label === "Last contacted"
      ? [
          {
            label: "Set to now",
            icon: <AccessTime fontSize="small" />,
            onClick: handleSetToNow,
          },
        ]
      : []),
    ...(label === "Follow up date"
      ? [
          {
            label: "+1 week",
            icon: <AccessTime fontSize="small" />,
            onClick: (event) => handleIncrement(event, 1, "weeks"),
          },
          {
            label: "+2 week",
            icon: <AccessTime fontSize="small" />,
            onClick: (event) => handleIncrement(event, 2, "weeks"),
          },
          {
            label: "+1 month",
            icon: <AccessTime fontSize="small" />,
            onClick: (event) => handleIncrement(event, 1, "months"),
          },
        ]
      : []),
  ];

  const formatDate = (dateValue) => {
    if (!dateValue) return "--";

    const date = moment(dateValue.toDate());
    const now = moment();

    // Check if the date is within 1 year from now
    const isWithinOneYear = date.isSame(now, "year");

    // Check if the date is within 1 month from now
    const isWithinOneMonth = date.isSame(now, "month");

    // Check if the time is midnight
    const isMidnight = date.hours() === 0 && date.minutes() === 0;

    if (isWithinOneMonth && isMidnight) {
      return date.format("ddd MMM D"); // Day of the week, month, and day
    } else if (isWithinOneMonth) {
      return date.format("ddd MMM D, h:mm a"); // Day of the week, month, day, and time
    } else if (isWithinOneYear && isMidnight) {
      return date.format("MMM D"); // Only month and day
    } else if (isWithinOneYear) {
      return date.format("MMM D, h:mm a"); // Month, day, and time
    } else if (isMidnight) {
      return date.format("MMM D, YYYY"); // Month, day, and year
    } else {
      return date.format("MMM D, YYYY h:mm a"); // Full format
    }
  };

  return (
    <>
      <ListItemButton
        onClick={(event) => !openPicker && handleMenuOpen(event)}
        disabled={openPicker}
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F5F5F5",
          py: 1,
          px: 2,
          boxSizing: "border-box",
          borderRadius: "15px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            open={openPicker}
            closeOnSelect={false}
            onAccept={handleSave} // Close the picker when finished
            onClose={handleClose}
            label={`Edit ${label}`}
            inputFormat="MM/DD/YYYY h:mm a"
            onChange={(newDate) => setSelectedDate(newDate)} // Capture and save the new date
            value={selectedDate}
            sx={{ visibility: "hidden", width: 0, height: 0 }} // Hide when not open
          />
        </LocalizationProvider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mr: "auto",
            height: "100%",
          }}
        >
          <Typography fontWeight={"600"}>{formatDate(dateValue)}</Typography>

          <Typography variant="caption" color={"text.secondary"} sx={{mt: "auto"}}>
            {label}
          </Typography>
        </Box>
        {/* <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton> */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          slotProps={{
            paper: {
              // elevation: 1,
              sx: { width: "200px" },
            },
          }}
        >
          {menuItems.map((item) => (
            <MenuItem key={item.label} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        {/* Directly open the DateTimePicker on "Edit" */}
      </ListItemButton>
    </>
  );
};

export default CRMDateEditor;
