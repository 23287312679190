import {
  Timestamp,
  collection,
  doc,
  endAt,
  getDoc,
  increment,
  onSnapshot,
  orderBy,
  query,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon"; // Import Luxon for date manipulation
import moment from "moment-timezone";
import React, { createContext, useContext, useEffect, useState } from "react";
import LoadingUser from "../components/LoadingUser";
import { db } from "../firebase";
import {
  decodeGeoHash,
  getEmployeeStatus,
  groupGeohashBounds,
} from "../services/locationServices";
import { useAuth } from "./AuthProvider";

const EmployeeContext = createContext();

const EmployeeProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const [employeeData, setEmployeeData] = useState({});
  const [loadingEmployeeData, setLoadingEmployeeData] = useState({});

  const [employeePrivateData, setEmployeePrivateData] = useState(null);
  const [loadingEmployeePrivateData, setLoadingEmployeePrivateData] =
    useState(true);

  const [visitsData, setVisitsData] = useState({});

  const [openVisitsData, setOpenVisitsData] = useState({});

  const [eventsData, setEventsData] = useState({});

  const [loading, setLoading] = useState(true); // Initial loading state
  const [currentDayWithVisits, setCurrentDayWithVisits] = useState();

  useEffect(() => {
    if (!currentUser?.uid) return;

    setLoadingEmployeeData(true);

    const unsubEmployeeData = onSnapshot(
      doc(db, "employees", currentUser?.uid),
      (doc) => {
        setEmployeeData({ id: doc.id, ...doc.data() });
        setLoadingEmployeeData(false); // Set loading to false after data is fetched
      },
      (error) => {
        console.error("Error fetching employee data:", error);
        setLoadingEmployeeData(false); // Set loading to false even if there's an error
      }
    );

    // Cleanup function
    return () => {
      unsubEmployeeData();
      setLoadingEmployeeData(false); // Reset loading on unmount
    };
  }, [currentUser?.uid]);

  // Login streak
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        if (currentUser?.uid) {
          // Reference to the employee document
          const employeePrivateDocRef = doc(
            db,
            "employeesPrivate",
            currentUser?.uid
          );

          // Fetch the employee document from Firestore
          const employeePrivateDoc = await getDoc(employeePrivateDocRef);

          const employeePrivateData = employeePrivateDoc.data();

          // Call handleCheckInStreak after fetching employee data
          handleCheckInStreak(
            employeePrivateData?.progress?.streaks?.appCheckIn?.lastWeekDate,
            currentUser?.uid
          );

          // Determine browser timezone
          const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Update lastActive to now
          await updateDoc(doc(db, "employees", currentUser?.uid), {
            "admin.lastActive": Timestamp.now(),
            timeZoneId,
          });
        }
      } catch (error) {
        console.error("Error fetching employee data: ", error);
      }
    };

    fetchEmployeeData(); // Trigger the fetch on component mount
  }, [currentUser?.uid]);

  const handleCheckInStreak = async (lastWeekDate, employeeId) => {
    const now = DateTime.now().startOf("day"); // Get today's date, normalized

    // If lastWeekDate is undefined, initialize the streak
    if (!lastWeekDate) {
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": 1,
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
      return;
    }

    const lastWeekDateTime = DateTime.fromJSDate(lastWeekDate.toDate()).startOf(
      "day"
    ); // Convert to Luxon DateTime and normalize
    const daysSinceLastWeek = now.diff(lastWeekDateTime, "days").days;

    if (daysSinceLastWeek >= 7 && daysSinceLastWeek < 14) {
      // Increment the streak if last active was between 7 and 14 days ago
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": increment(1),
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
    } else if (daysSinceLastWeek >= 14) {
      // Reset the streak if last active was more than 14 days ago
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": 1,
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
    }
  };

  // Subscription to usersPrivate collection
  useEffect(() => {
    if (!currentUser?.uid) return;

    setLoadingEmployeePrivateData(true);

    const unsubEmployeePrivateData = onSnapshot(
      doc(db, "employeesPrivate", currentUser?.uid),
      (doc) => {
        setEmployeePrivateData({ id: doc.id, ...doc.data() });
        setLoadingEmployeePrivateData(false); // Set loading to false after data is fetched
      },
      (error) => {
        console.error("Error fetching employee data:", error);
        setLoadingEmployeePrivateData(false); // Set loading to false even if there's an error
      }
    );

    // Cleanup function
    return () => {
      unsubEmployeePrivateData();
      setLoadingEmployeePrivateData(false); // Reset loading on unmount
    };
  }, [currentUser?.uid]);

  // Queried VISITS for CURRENT USER
  useEffect(() => {
    // Get the user's UID. Here it's assumed you have access to this information, perhaps through context, props, or a global state.
    const userUID = currentUser?.uid;

    if (!userUID) return;

    console.log("userUID:", userUID);

    // Define a reference to the 'visits' collection.
    const visitsRef = collection(db, "visits");

    const visitsQuery = query(
      visitsRef,
      where("employeeArr", "array-contains", userUID), // Checks if the user's UID is in the 'employeeArr' array
      where("status", "==", "confirmed") // Filter visits where status is "confirmed"
    );

    // Attach a listener for this query.
    const unsubscribe = onSnapshot(visitsQuery, (querySnapshot) => {
      const visits = {};
      querySnapshot.forEach((doc) => {
        // Store the visit data in the 'visits' object using the visit ID as the key.
        visits[doc.id] = doc.data();
      });

      // Update the state with the new visits data.
      setVisitsData(visits);
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [currentUser?.uid]);

  useEffect(() => {
    // Check if employee data is loaded
    if (!employeeData) {
      return;
    }

    const areas = employeeData?.areas;
    const availability = employeeData?.availability;

    // Further checks
    if (!areas || employeeData?.status === "applicant") {
      return;
    }

    // We are using a Map here to benefit from its methods and to avoid duplicate visits
    const allVisits = new Map();

    // Store all unsubscribe functions
    const unsubscribeFunctions = [];

    // Iterate over each area in the areas map
    const bounds = groupGeohashBounds(employeeData?.geohash5Arr, 4);

    console.log("EmployeeProvider OpenVisits - bounds: ", bounds);

    bounds.forEach(([start, end]) => {
      const q = query(
        collection(db, "visits"),
        where("isOpenVisit", "==", true),
        where("status", "==", "confirmed"),
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const visitData = change.doc.data();

            console.log("EmployeeProvider OpenVisits - visitData: ", visitData);

            const visitCoords = decodeGeoHash(visitData?.location?.geohash);

            const {
              inArea = false,
              futureAvailability,
              end,
              start,
            } = getEmployeeStatus(
              visitCoords[0],
              visitCoords[1],
              areas,
              availability
            );

            if (
              inArea &&
              start <= DateTime.fromJSDate(visitData?.start.toDate()) &&
              (end === null ||
                end > DateTime.fromJSDate(visitData?.end.toDate()))
            ) {
              allVisits.set(change.doc.id, visitData); // Accumulating visits from all bounds
              console.log("Adding visit");
            }
          } else if (change.type === "removed") {
            console.log("Removing open visit: ", change.doc.id);
            allVisits.delete(change.doc.id);
          }
        });

        setOpenVisitsData(Object.fromEntries(allVisits));
      });

      // Add the unsubscribe function to the list
      unsubscribeFunctions.push(unsubscribe);
    });

    // Cleanup function
    return () => {
      unsubscribeFunctions.forEach((unsub) => unsub());
    };
  }, [employeeData?.areas, employeeData?.status]); // Dependency array

  useEffect(() => {
    if (!employeeData?.timeZoneId || (!visitsData && !openVisitsData)) return;

    const timeZoneId = employeeData.timeZoneId;
    const now = moment().tz(timeZoneId); // Get the current time with the correct timezone
    const startOfToday = now.clone().startOf("day"); // Clone now before using startOf("day")

    // Find the earliest visit starting from today
    const futureVisits = Object.values(visitsData || {})
      .map((visit) => moment(visit.start.toDate()).tz(timeZoneId))
      .filter((visitStart) => visitStart.isSameOrAfter(startOfToday));

    // Sort and get the earliest future visit
    const earliestFutureVisit = futureVisits.sort((a, b) => a.diff(b))[0];

    if (earliestFutureVisit) {
      // If a future visit is found, use that day
      setCurrentDayWithVisits(earliestFutureVisit.startOf("day"));
    } else if (openVisitsData) {
      // If no future visits, try to find the earliest open visit
      const futureOpenVisits = Object.values(openVisitsData || {})
        .map((openVisit) => moment(openVisit.start.toDate()).tz(timeZoneId))
        .filter((openVisitStart) => openVisitStart.isAfter(now)); // Ensure open visit is after the current time

      // Sort and get the earliest open visit
      const earliestOpenVisit = futureOpenVisits.sort((a, b) => a.diff(b))[0];

      if (earliestOpenVisit) {
        setCurrentDayWithVisits(earliestOpenVisit.startOf("day"));
      } else {
        setCurrentDayWithVisits(null); // No future visits or open visits found
      }
    } else {
      // No future visits and no open visits, set to null
      setCurrentDayWithVisits(null);
    }
  }, [visitsData, openVisitsData, employeeData?.timeZoneId]);

  // // POLYGON EVENTS nearby - Use effect to find nearby events
  // useEffect(() => {
  //   const employeeLocationHash = employeeData?.location?.geohash;
  //   if (!employeeLocationHash) return;

  //   const employeeGeohashCoordinates = decodeGeoHash(employeeLocationHash);
  //   console.log("employeeGeohashCoordinates: ", employeeGeohashCoordinates);
  //   const radiusInM = 10 * 1609.34; // 10 miles in meters, adjust as needed

  //   const bounds = geohashQueryBounds(employeeGeohashCoordinates, radiusInM);

  //   const matchedAddress = employeePrivateData?.address[0];

  //   if (!matchedAddress) return;

  //   const employeeCoordinates = {
  //     lat: matchedAddress.lat,
  //     lng: matchedAddress.lng,
  //   };
  //   console.log("employeeCoordinates: ", employeeCoordinates);

  //   const eventsRef = collection(db, "events"); // Adjust according to your events collection name
  //   const eventUnsubscribes = [];

  //   bounds.forEach(([start, end]) => {
  //     const eventsQuery = query(
  //       eventsRef,
  //       where("status", "==", "active"), // Filter events where status is "active"
  //       orderBy("location.geohash"),
  //       startAt(start),
  //       endAt(end)
  //     );

  //     const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
  //       const newEvents = {};
  //       querySnapshot.forEach((doc) => {
  //         const eventData = doc.data();

  //         console.log("employeeCoordinates: ", employeeCoordinates);
  //         console.log("eventData.location.bounds: ", eventData.location.bounds);

  //         // TODO: Fix this with employeeCoordinates
  //         // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
  //         if (
  //           isPointInPolygon(employeeCoordinates, eventData.location.bounds)
  //         ) {
  //           console.log("is within bounds");
  //           newEvents[doc.id] = eventData;
  //         }
  //       });

  //       // Update the state with new events within maxDistance
  //       setEventsData(...prevData.events, ...newEvents);
  //     });

  //     eventUnsubscribes.push(unsubscribe);
  //   });

  //   // Cleanup function
  //   return () => {
  //     eventUnsubscribes.forEach((unsub) => unsub());
  //   };
  // }, [
  //   employeeData?.location?.geohash,
  //   employeePrivateData?.address?.[0] ?? null,
  // ]); // Dependency array

  // Conditionally render the LoadingUser component if loading
  if (loadingEmployeeData || loadingEmployeePrivateData) {
    return (
      <LoadingUser
        loading={loadingEmployeeData || loadingEmployeePrivateData}
      />
    );
  }

  return (
    <EmployeeContext.Provider
      value={{
        employeeData,
        employeePrivateData,
        visitsData,
        openVisitsData,
        currentDayWithVisits,
        loadingEmployeeData,
        loadingEmployeePrivateData,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeProvider;

export const useEmployee = () => {
  return useContext(EmployeeContext);
};
