import { getFunctions, httpsCallable } from "firebase/functions";

export const sendSmsCode = async (data) => {
  const functions = getFunctions();
  const sendSmsCodeFunction = httpsCallable(functions, "sendSmsCode");
  try {
    const result = await sendSmsCodeFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling sendSmsCode function:", error);
    throw error;
  }
};
