import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment-timezone";

const DateDisplay = ({ selectedDate }) => {
  const [currentDate, setCurrentDate] = useState(selectedDate);
  const [slideStyle, setSlideStyle] = useState({
    transform: "translateX(0)",
    opacity: 1,
    transition: "none",
  });

  useEffect(() => {
    if (selectedDate !== currentDate) {
      // Determine the direction of slide
      const slideOutDirection = selectedDate > currentDate ? "left" : "right";

      // Start slide out with fade
      setSlideStyle({
        transform: `translateX(${slideOutDirection === "left" ? "-5%" : "5%"})`,
        opacity: 0,
        transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
      });

      // After slide out, set up new date on the opposite side and then slide in
      setTimeout(() => {
        setCurrentDate(selectedDate);
        setSlideStyle({
          // This sets the position offscreen to the opposite side, without a transition
          transform: `translateX(${
            slideOutDirection === "left" ? "5%" : "-5%"
          })`,
          opacity: 0,
          transition: "none",
        });

        // Trigger the slide in with fade
        setTimeout(() => {
          setSlideStyle({
            transform: "translateX(0)",
            opacity: 1,
            transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
          });
        }, 75); // Small delay to ensure the offscreen position is set before sliding in
      }, 100); // Match this duration with the transition duration
    }
  }, [selectedDate, currentDate]);

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        p: 2,
        mx: { xs: "-24px", sm: 0 },
        mb: 2,
        mt: { xs: 2, sm: 0 },
        borderRadius: { xs: "0px", sm: "15px" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box sx={{ ...slideStyle }}>
        <Typography
          component="span"
          variant="h6"
          fontWeight="400"
          marginRight="4px"
        >
          {moment(currentDate).format("dddd")},
        </Typography>
        <Typography component="span" variant="h6">
          {moment(currentDate).format(" MMMM Do")}
        </Typography>
      </Box>
    </Box>
  );
};

export default DateDisplay;
