import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import GuestHeader from "../../components/GuestHeader";
import useLogPageVisit from "../../hooks/useLogPageVisit";
import FAQSection from "./sections/FAQSection";
import WebFooter from "./sections/WebFooter";

function FaqPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useLogPageVisit("/faq");

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }
  }, []);

  return (
    <div>
      <GuestHeader />
      <FAQSection pageName="/faq" />
      <WebFooter pageName="/faq" />
    </div>
  );
}

export default FaqPage;
