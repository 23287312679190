import { Timestamp, doc, increment, updateDoc } from "firebase/firestore"; // Ensure you're importing these correctly based on your setup
import { db } from "../firebase";

/*   -- Legend for streakKey --
visitsAccepted – Tracks the number of visits accepted.
respondingEarly – Tracks how often the employee responds early.
appCheckIn – Tracks the number of app check-ins.
starReviews – Tracks the number of star reviews received.
     -- Legend for boostKey --
openVisit – Represents a boost applied for an open visit.
employeeReferral – Represents a boost applied for employee referrals.
defaultAvailability – Represents a boost applied for default availability.
*/
export const incrementStreak = async (employeeId, streakKey) => {
  // Reference to the employee document in Firestore
  const employeePrivateDocRef = doc(db, "employeesPrivate", employeeId); // Replace 'employees' with the actual collection name

  // Update the streak count using Firestore's increment
  await updateDoc(employeePrivateDocRef, {
    [`progress.streaks.${streakKey}.count`]: increment(1),
  });
};

export const resetStreak = async (employeeId, streakKey) => {
  // Reference to the employee document in Firestore
  const employeePrivateDocRef = doc(db, "employeesPrivate", employeeId); // Replace 'employees' with the actual collection name

  // Reset the streak count to 0
  await updateDoc(employeePrivateDocRef, {
    [`progress.streaks.${streakKey}.count`]: 0,
  });
};

// Function to set a specific boost's 'created' field to a specific date
export const applyBoost = async (employeeId, boostKey) => {
  // Reference to the employee document in Firestore
  const employeePrivateDocRef = doc(db, "employeesPrivate", employeeId); // Replace 'employees' with the actual collection name

  // Update the boost's 'created' field to the provided date
  await updateDoc(employeePrivateDocRef, {
    [`progress.boosts.${boostKey}.created`]: Timestamp.now(), // Set to the provided date
  });
};

// Function to set a specific boost's 'created' field to a specific date
export const resetBoost = async (employeeId, boostKey) => {
  // Reference to the employee document in Firestore
  const employeePrivateDocRef = doc(db, "employeesPrivate", employeeId); // Replace 'employees' with the actual collection name

  // Update the boost's 'created' field to the provided date
  await updateDoc(employeePrivateDocRef, {
    [`progress.boosts.${boostKey}.created`]: null, // Set to the provided date
  });
};
