import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import geohash from "ngeohash";
import { ConfirmationBurst } from "../animations/ConfirmationBurst";
import HelpersProvider, { useHelpers } from "../providers/HelpersProvider";
import { useSnackbar } from "../providers/SnackbarProvider";
import { rescheduleVisit } from "../services/memberServices";
import RescheduleOptions from "./RescheduleOptions";
import RescheduleVisitPublic from "./RescheduleVisitPublic";
import VisitSummary from "./VisitSummary";

const Screen = {
  // FETCHING: "fetching",
  SCHEDULER: "scheduler",
  SENDING: "sending",
  CONFIRMATION: "confirmation",
};

const RescheduleVisitMember = ({
  visitInput = {},
  employees = {}, // all employees that service that particular member
  handleClose,
}) => {
  const [visit, setVisit] = useState(visitInput); // Define visit state
  const [rescheduledVisit, setRescheduledVisit] = useState({});
  const [frequency, setFrequency] = useState(
    visitInput?.recurrence?.frequency || 0
  );

  // Check if useHelpers is accessible
  const helpers = useHelpers(); // Call unconditionally

  // Decode the geohash to get latitude and longitude
  const initialCoordinates = visitInput?.location?.geohash
    ? geohash.decode(visitInput.location.geohash)
    : { latitude: 0, longitude: 0 }; // Provide a default fallback

  // Initialize state with decoded coordinates
  const [coordinates, setCoordinates] = useState({
    lat: initialCoordinates.latitude,
    lng: initialCoordinates.longitude,
  });

  useEffect(() => {
    // Update coordinates if visitInput changes
    if (visitInput?.location?.geohash) {
      const { latitude, longitude } = geohash.decode(
        visitInput.location.geohash
      );
      setCoordinates({ lat: latitude, lng: longitude });
    }
  }, [visitInput]);

  // Determine the initial screen based on whether the visit is part of a series
  const initialScreen = visitInput?.recurrence?.recurringEventId
    ? Screen.SERIES
    : Screen.SCHEDULER;

  const [currentScreen, setCurrentScreen] = useState(initialScreen);

  const { showSnackbar } = useSnackbar();

  const handleRescheduleTypeSelection = (rescheduleType) => {
    // Set frequency to 0 for single visit, otherwise use the visit's frequency
    setFrequency(
      rescheduleType === "all_visits" ? visit.recurrence.frequency : 0
    );
    setCurrentScreen(Screen.SCHEDULER);
  };

  if (
    Object.keys(visitInput).length === 0 &&
    Object.keys(employees).length === 0
  )
    return;

  const handleReschedule = async (values) => {
    setRescheduledVisit({ ...values });
    console.log("values: ", values);
    setCurrentScreen(Screen.SENDING); // Move to sending info screen

    try {
      await rescheduleVisit(values);
      setCurrentScreen(Screen.CONFIRMATION); // Move to confirmation screen
      showSnackbar("Visit successfully rescheduled", "success");
    } catch (error) {
      console.error("Error rescheduling the visit: ", error);
      showSnackbar("Failed to reschedule visit", "error");
      setCurrentScreen(Screen.SCHEDULER); // Optionally move back to scheduler screen on failure
    }
  };

  const content = (
    <>
      {currentScreen === Screen.SERIES && (
        <RescheduleOptions
          visit={visit}
          onReschedule={handleRescheduleTypeSelection}
          handleClose={handleClose}
          timeZoneId={visitInput.timeZoneId}
        />
      )}
      {currentScreen === Screen.SCHEDULER && (
        <RescheduleVisitPublic
          onSubmit={handleReschedule}
          showTitle={false}
          initialFrequency={frequency}
          visitData={visit}
        />
      )}
      {currentScreen === Screen.SENDING && (
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Ensure it spans the full width
              gap: 2,
              marginY: 2,
            }}
          >
            <CircularProgress size={48} color="inherit" />
            <Typography variant="body1" align="center" sx={{ pt: 1 }}>
              This may take several seconds.
            </Typography>
          </Box>
        </DialogContent>
      )}
      {currentScreen === Screen.CONFIRMATION && (
        <>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                position: "sticky",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%", // Ensure it spans the full width
                gap: 2,
                my: 2,
                // maxWidth: "400px",
                width: "auto",
              }}
            >
              <ConfirmationBurst size="xlarge" />
              <Typography variant="h6" marginTop={2}>
                Confirmed!
              </Typography>
              <Typography>Your visit has been rescheduled.</Typography>
              <Box
                sx={{
                  backgroundColor: "#F5F5F5", // Light gray background
                  borderRadius: "15px",
                  px: 3,
                  py: 1,
                }}
              >
                <VisitSummary
                  timeZoneId={visitInput.timeZoneId}
                  start={visitInput.start}
                  end={visitInput.end}
                  employees={visitInput.employees}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              zIndex: 10,
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Button
              sx={{
                width: { xs: "100%", sm: "auto" },
                height: { xs: "60px", sm: "auto" },
              }}
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              Go Back
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );

  // Conditionally wrap in HelpersProvider if useHelpers is not accessible
  return helpers ? (
    content
  ) : (
    <HelpersProvider lat={coordinates?.lat} lng={coordinates?.lng}>
      {content}
    </HelpersProvider>
  );
};

export default RescheduleVisitMember;
