import { DialogContent, List } from "@mui/material";
import React from "react";
import useVisitData from "../../hooks/useVisitData";
import { useAuth } from "../../providers/AuthProvider";
import { useEmployee } from "../../providers/EmployeeProvider";
import { useMember } from "../../providers/MemberProvider";
import EmployeeActionButtons from "./actions/EmployeeActionButtons";
import VisitReviewSection from "./employee/VisitReviewSection";
import AddressDirectionsLineItem from "./items/AddressDirectionsListItem";
import DateTimeListItem from "./items/DateTimeListItem";
import DescriptionListItem from "./items/DescriptionListItem";
import FrequencyListItem from "./items/FrequencyListItem";
import HelpersListItem from "./items/HelpersListItem";
import MemberListItem from "./items/MemberListItem";
import PayrollListItem from "./items/PayrollListItem";
import RatingReviewListItem from "./items/RatingReviewListItem";
import ScheduleNextVisitItem from "./items/ScheduleNextVisitItem";

const VisitCardV2 = ({
  visitId = "",
  userRole = "member",
  onClose = () => {},
}) => {
  const { visitData, visitPrivateData, loading, loadingPrivate } =
    useVisitData(visitId);

  const { currentUser } = useAuth();

  // Call hooks and provide default values
  const { employeeData = {} } = useEmployee() || {}; // Default to an empty object if undefined
  const { memberData = {} } = useMember() || {}; // Default to an empty object if undefined

  // Determine userName based on userRole
  const userName =
    userRole === "employee" || userRole === "admin"
      ? employeeData?.firstName
      : userRole === "member"
      ? memberData?.firstName
      : "";

  console.log("userName: ", userName);

  if (loading) {
    return <>loading...</>;
  }

  return (
    <>
      <DialogContent sx={{ pt: 0 }}>
        <List>
          {(userRole === "employee" || userRole === "admin") && (
            <ScheduleNextVisitItem visitData={visitData} />
          )}
          <DateTimeListItem
            visitData={visitData}
            userRole={userRole}
            userId={currentUser?.uid}
          />
          <FrequencyListItem visitData={visitData} />
          {userRole !== "member" && (
            <MemberListItem
              visitData={visitData}
              visitPrivateData={visitPrivateData}
              loadingPrivate={loadingPrivate}
              userRole={userRole}
            />
          )}
          <AddressDirectionsLineItem
            visitData={visitData}
            visitPrivateData={visitPrivateData}
            loadingPrivate={loadingPrivate}
          />
          <DescriptionListItem
            visitData={visitData}
            visitPrivateData={visitPrivateData}
            userRole={userRole}
          />
          <RatingReviewListItem visitData={visitData} />
          <HelpersListItem visitData={visitData} memberId={visitData?.member} userRole={userRole} />
          {(userRole === "employee" || userRole === "admin") && (
            <PayrollListItem visitData={visitData} />
          )}
          <VisitReviewSection
            visitData={visitData}
            userId={currentUser?.uid}
            userRole={userRole}
            userName={userName}
            onClose={onClose}
          />
        </List>
      </DialogContent>
      {userRole !== "member" && (
        <EmployeeActionButtons
          visitData={visitData}
          userId={currentUser?.uid}
          handleDecline={onClose}
          handleAccept={onClose}
          handlePickup={onClose}
          handleHide={onClose}
          phone={visitPrivateData?.phone}
        />
      )}
    </>
  );
};

export default VisitCardV2;
