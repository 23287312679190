import React from "react";
import Chip from "@mui/material/Chip";
import moment from "moment-timezone";
import { Tooltip } from "@mui/material";
import { TimerOutlined } from "@mui/icons-material";

const ExpirationChip = ({
  expirationDate,
  timeZoneId,
  showTooltip = false,
}) => {
  if (!expirationDate) return null;

  // Check if expirationDate is a Firebase Timestamp and convert if necessary
  const date = expirationDate.toDate ? expirationDate.toDate() : expirationDate;
  const expiration = moment(date).tz(timeZoneId);

  const now = moment().tz(timeZoneId);
  console.log("now: ", now.format("MM-DD hh:mm a"));
  const diffMinutes = expiration.diff(now, "minutes");
  let label = "";
  let color = "default";

  // Check if the expiration is more than 7 days away
  if (diffMinutes >= 60 * 24 * 7) {
    return null; // Do not render anything if expiration date is past 7 days
  }

  if (diffMinutes < 60) {
    label = `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""}`;
    color = "error";
  } else if (diffMinutes < 60 * 24) {
    const hours = Math.round(diffMinutes / 60);
    label = `${hours} hour${hours !== 1 ? "s" : ""}`;
    color = "warning";
  } else if (diffMinutes < 60 * 24 * 7) {
    // Calculate full days until the expiration date
    const days = expiration.diff(now, "days");
    label = `${days} day${days !== 1 ? "s" : ""}`;
    color = "info";
  }

  if (diffMinutes < 0) {
    return null; // Do not render the chip if the expiration is in the past
  }

  const chip = (
    <Chip
      // icon={<TimerOutlined />}
      label={`Expires in ${label}`}
      size="small"
      variant="outlined"
      color={color}
      sx={{ borderRadius: "3px" }}
    />
  );

  return showTooltip ? (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={4000}
      title={`Expires on ${expiration.format("dddd, MMM D [at] h:mma")}`}
    >
      {chip}
    </Tooltip>
  ) : (
    chip
  );
};

export default ExpirationChip;
