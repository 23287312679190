import React from "react";
import {
  Box,
  autocompleteClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material"; // or your respective UI library
import { Link } from "react-router-dom"; // or your respective routing library
import FooterSimple from "./FooterSimple";
import Logo from "../svgs/Logo";
import { keyframes } from "@emotion/react";

// Define our keyframes for the animation
const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SimplePage = ({
  children,
  maxWidth,
  align = "center",
  showFooterMobile = true,
  buttonOffset = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = {
    // width: "auto",
    maxWidth: maxWidth || undefined,
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.default",
          alignItems: "center",
          position: "relative",
          pb: { xs: buttonOffset ? "60px" : "0px", sm: "0px" },
          px: 2,
        }}
      >
        <Box
          alignSelf="flex-start"
          sx={{ width: "150px", py: 2 }} // Explicitly giving the container a size
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Logo style={{ width: "100%", height: "100%" }} />{" "}
          </Link>
          {/* Ensuring the logo takes up all the available space */}
        </Box>

        {align === "center" && <Box flexGrow={1} />}
        {children}

        <Box flexGrow={1} />
        {(showFooterMobile || !isMobile) && <FooterSimple />}
      </Box>
    </>
  );
};
export default SimplePage;
