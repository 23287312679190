import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SimplePage from "../components/SimplePage";
import { auth, db } from "../firebase";

const EmployeeLogin = () => {
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const authInstance = getAuth();
  authInstance.setPersistence(browserLocalPersistence);

  const DividerWithText = (props) => {
    return (
      <Box display="flex" alignItems="center" my={2}>
        <Box flex={1}>
          <Divider />
        </Box>
        <Typography
          variant="body2"
          component="span"
          color="textSecondary"
          mx={2}
        >
          {props.children}
        </Typography>
        <Box flex={1}>
          <Divider />
        </Box>
      </Box>
    );
  };

  const handleChange = (e) => {
    console.log(values);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle Login with email and password
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(event);
    const newError = {}; // initialize newError object here
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      await redirectToDashboard();
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case "auth/missing-password":
          newError.password = "You must enter your password.";
          break;
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/user-not-found":
          newError.email = "There is no user with this email.";
          break;
        case "auth/wrong-password":
          newError.password = "The password is invalid.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    // setLoading(true);
    const newError = {}; // initialize newError object here
    try {
      const provider = new GoogleAuthProvider();

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user exists in your Firestore or other database
      const userDoc = await getDoc(doc(db, "employees", user.uid));

      console.log(userDoc);
      console.log(userDoc.exists());

      // If this call does not fail, they are an existing user
      if (userDoc.exists()) {
        console.log("redirecting");
        redirectToDashboard();
      } else {
        // New user & signing up
        // logic borrowed from EmployeeSignUp.js
        navigate("helper/create-account");
      }
    } catch (err) {
      console.error(err);
      newError.general =
        "An unknown error occurred. Please refresh and try again.";
      setErrors(newError);
    } finally {
      // setLoading(false);
    }
  };

  const redirectToDashboard = async () => {
    setLoading(true);
    try {
      navigate("/dashboard");
    } catch (err) {
      console.error("Failed to fetch Employee data:", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    console.log("TRUE!!");

    const auth = getAuth();
    console.log(auth.currentUser);
    try {
      if (auth.currentUser.employeeData) {
        console.log(auth.currentUser);
        console.log("useEffect loop");
        redirectToDashboard();
      }
    } catch (err) {
      console.log("Failed to load current user");
    }
    setLoading(false);
    console.log("FALSE!!");
  }, []);

  return (
    <SimplePage>
      <Card
        variant="outlined"
        style={{ padding: "20px", maxWidth: "450px", margin: "0 auto" }}
      >
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Helper Login
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            Not a helper yet?{" "}
            <Link
              to="/helper/signup"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              Sign up
            </Link>
          </Typography>
          <form onSubmit={handleLogin} noValidate>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={loading} // disable the button when loading
              sx={{ height: "60px", textTransform: "none" }}
              disableElevation
            >
              {loading ? <CircularProgress size={24} /> : "Login"}
            </Button>
          </form>
        </CardContent>
      </Card>
    </SimplePage>
  );
};

export default EmployeeLogin;
