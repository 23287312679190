import { Add, Remove } from "@mui/icons-material";
import {
  Button,
  Card,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

const IncrementerField = ({
  value,
  onChange,
  label = null,
  min = 0,
  max = 40,
  variant = "contained",
  labelPosition = "above", // "inline" or "above"
  endAdornment = "",
  justifyContent = "center",
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDecrement = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  const handleIncrement = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value.trim(); // Take raw input
    // Allow valid numbers within the range or empty input
    if (
      newValue === "" ||
      (/^\d+$/.test(newValue) &&
        parseInt(newValue, 10) >= min &&
        parseInt(newValue, 10) <= max)
    ) {
      onChange(newValue === "" ? "" : parseInt(newValue, 10));
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent={justifyContent}
      alignItems="center"
    >
      {isMobile && (
        <Button
          sx={{
            height: "60px",
            borderRadius: "50px",
          }}
          onClick={handleDecrement}
          disabled={value <= min}
        >
          <Remove />
        </Button>
      )}
      {labelPosition === "above" ? (
        <TextField
          label={label}
          value={value}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true, // Force the label to stay above
          }}
          type="number"
          variant={variant === "outlined" ? "outlined" : "filled"}
        />
      ) : (
        <Card
          elevation={0}
          sx={{
            width: "150px",
            textAlign: "center",
            backgroundColor:
              variant === "contained"
                ? "#e0e0e0"
                : variant === "outlined"
                ? "white"
                : null,
            borderRadius: "10px",
            border:
              variant === "outlined" ? "1px solid rgba(0,0,0,0.23)" : "unset",
            padding: "8px",
          }}
        >
          <Typography component="span" fontWeight="600">
            {label}:{" "}
          </Typography>
          <Typography component="span">{value}</Typography>
        </Card>
      )}
      {isMobile && (
        <Button
          sx={{
            height: "60px",
            borderRadius: "50px",
          }}
          onClick={handleIncrement}
          disabled={value >= max}
        >
          <Add />
        </Button>
      )}
    </Stack>
  );
};

export default IncrementerField;
