import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import AddEmployeeDialog from "../../AddEmployeeDialog";
import CancelVisitDialog from "../../CancelVisitDialog";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";
import AdditionalHelpList from "../../feedback/AdditionalHelpList";
import PushDrawer from "../../PushDrawer";
import RescheduleVisitMember from "../../RescheduleVisitMember";
import ReviewVisitMember from "../../ReviewVisitMember";
import VisitCardMenu from "../../VisitCardMenu";
import VisitRefundRequest from "../../VisitRefundRequest";

const VisitReviewSection = ({
  visitData = {},
  userId,
  userRole = "member",
  userName = "",
  onClose = () => {},
}) => {
  const {
    id: visitId = "",
    displayName: memberName = "",
    status: visitStatus,
    flags = [],
    rating = null,
    start = Timestamp.now(),
    timeZoneId = "America/Los_Angeles",
    employeesNeeded = null,
    member: memberId = "",
    billed = false,
    employees = {},
  } = visitData || {};

  // Dialog states
  const [showAddEmployeeDialog, setShowAddEmployeeDialog] = useState(false);
  const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false);
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showReview, setShowReview] = useState(false);

  const { currentUser } = useAuth();

  // Dialog functions
  const handleReviewOpen = () => {
    setShowReview(true);
  };

  const handleReviewClose = () => {
    setShowReview(false);
  };

  const handleCancelClose = () => {
    setShowConfirmDialog(false);
    onClose();
  };

  console.log("start: ", start.toDate());
  const isPastEvent = DateTime.now() > DateTime.fromJSDate(start?.toDate());

  console.log("isPastEvent: ", isPastEvent);

  const isReviewed = typeof rating === "number" && !isNaN(rating);
  const isRefunded = flags && flags.includes("refundIssued");
  const needsReschedule = visitStatus === "reschedule";

  const canReschedule =
    (isPastEvent && visitStatus === "reschedule") || !isPastEvent;
  const isActive = !isPastEvent && visitStatus === "confirmed";
  const canCancel = !isPastEvent && visitStatus !== "cancelled";
  const canReview = isPastEvent && !isReviewed && !needsReschedule;

  const canRefund =
    isPastEvent &&
    !isRefunded &&
    visitStatus !== "cancelled" &&
    visitStatus !== "reschedule";

  // Return if props are not loaded
  if (!visitId || !userId) return;
  return (
    <>
      {((userRole === "member" && visitData?.member == currentUser?.uid) ||
        userRole === "admin") && (
        <>
          <VisitCardMenu
            canReschedule={canReschedule}
            canCancel={canCancel}
            canReview={canReview}
            canRefund={canRefund}
            canAddHelp={isActive}
            setShowAddEmployeeDialog={setShowAddEmployeeDialog}
            setRescheduleDialogOpen={setRescheduleDialogOpen}
            setShowConfirmDialog={setShowConfirmDialog}
            setShowRefundDialog={setShowRefundDialog}
            handleReviewOpen={handleReviewOpen}
          />
          <CancelVisitDialog
            open={showConfirmDialog}
            setOpen={setShowConfirmDialog}
            handleCancel={handleCancelClose}
            visit={visitData}
            visitId={visitId}
            timeZoneId={timeZoneId}
            adminMode={userRole === "admin"}
            eId={userId}
            eName={userName}
          />

          <AddEmployeeDialog
            open={showAddEmployeeDialog}
            setOpen={setShowAddEmployeeDialog}
            visitId={visitId}
            employeesNeeded={employeesNeeded}
          />

          <VisitRefundRequest
            open={showRefundDialog}
            setOpen={setShowRefundDialog}
            visitId={visitId}
            memberId={memberId}
            billed={billed || false}
            employees={employees}
          />
          <ResponsiveDialog
            open={rescheduleDialogOpen}
            onClose={() => setRescheduleDialogOpen(false)}
            title={`Reschedule visit`}
            width={"800px"}
            fullHeight={true}
            maxHeight="583px"
            backgroundCloses={false}
          >
            {rescheduleDialogOpen && (
              <RescheduleVisitMember
                visitInput={{ id: visitId, ...visitData }}
                // employees={employees}
                handleClose={() => setRescheduleDialogOpen(false)}
              />
            )}
          </ResponsiveDialog>
          <PushDrawer
            title={"Review"}
            minWidth={"800px"}
            open={showReview}
            onOpen={handleReviewOpen}
            onClose={handleReviewClose}
            hideBackdrop={true}
          >
            <ReviewVisitMember
              visit={visitData}
              visitId={visitId}
              handleClose={handleReviewClose}
            />
          </PushDrawer>
        </>
      )}
      {(userRole === "admin" ||
        (userRole === "employee" &&
          visitData?.employeeArr?.includes(currentUser?.uid))) &&
        isPastEvent && (
          <AdditionalHelpList
            visitId={visitId}
            memberName={memberName}
            employeeId={userId}
          />
        )}
    </>
  );
};

export default VisitReviewSection;
