import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DrawerContainer from "../components/drawers/DrawerContainer";
import LoadingUser from "../components/LoadingUser";
import useLogPageVisit from "../hooks/useLogPageVisit";
import { CRMProvider } from "../providers/CRMProvider";
import { DrawerProvider } from "../providers/DrawerProvider";
import EmployeeProvider from "../providers/EmployeeProvider";
import HelpersProvider from "../providers/HelpersProvider";
import { MemberProvider } from "../providers/MemberProvider";

/*
import EmployeeDashboard from "../dashboard/employee/EmployeeDashboard";
import MemberDashboard from "../dashboard/member/MemberDashboard";
*/

// Lazy-loaded dashboards
const EmployeeDashboard = lazy(() =>
  import("../dashboard/employee/EmployeeDashboard")
);
const MemberDashboard = lazy(() =>
  import("../dashboard/member/MemberDashboard")
);

const DashboardPage = () => {
  const [userClaims, setUserClaims] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useLogPageVisit("/dashboard");

  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Get the ID token result and update userClaims state
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            console.log("idTokenResult: ", idTokenResult);
            console.log("idTokenResult.claims: ", idTokenResult.claims);

            setUserClaims(idTokenResult.claims);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error retrieving token claims: ", error);
            setLoading(false);
          });
      } else {
        // User is not signed in
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle sign out errors here, such as showing a notification to the user
    }
  };

  // Render components based on user claims and loading state
  if (loading) {
    return <LoadingUser />;
  } else if (userClaims) {
    return (
      <Suspense fallback={<LoadingUser />}>
        {userClaims.role === "employee" ? (
          <EmployeeProvider>
            <CRMProvider>
              <DrawerProvider>
                <DrawerContainer />
                <EmployeeDashboard />
              </DrawerProvider>
            </CRMProvider>
          </EmployeeProvider>
        ) : userClaims.role === "member" ? (
          <MemberProvider>
            <HelpersProvider>
              <DrawerProvider>
                <DrawerContainer />
                <MemberDashboard />
              </DrawerProvider>
            </HelpersProvider>
          </MemberProvider>
        ) : (
          handleSignOut()
        )}
      </Suspense>
    );
  } else {
    navigate("/login");
  }
};

export default DashboardPage;
