import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";

import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../../firebase";
import NameField from "../../fields/NameField";

export const validateName = (name) => {
  const errors = {};

  if (!name || name.trim().length === 0) {
    errors.name = "Name is required.";
  } else if (name.length < 2) {
    errors.name = "Name must be at least 2 characters long.";
  } else if (!/^[a-zA-Z\s'-]+$/.test(name)) {
    errors.name =
      "Name can only contain letters, spaces, hyphens, and apostrophes.";
  }

  return errors;
};

const EditName = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  firstName,
  lastName,
  memberId,
  stripeId,
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [editFirstName, setEditFirstName] = useState(firstName);
  const [editLastName, setEditLastName] = useState(lastName);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setEditFirstName(value);
    } else if (name === "lastName") {
      setEditLastName(value);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Validate names
    const firstNameErrors = validateName(editFirstName);
    const lastNameErrors = validateName(editLastName);
    const newErrors = {};

    if (firstNameErrors.name) newErrors.firstName = firstNameErrors.name;
    if (lastNameErrors.name) newErrors.lastName = lastNameErrors.name;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      await setDoc(
        doc(db, "members", memberId),
        {
          firstName: editFirstName,
          lastName: editLastName,
        },
        { merge: true }
      );

      await setDoc(
        doc(db, "membersPrivate", memberId),
        {
          firstName: editFirstName,
          lastName: editLastName,
        },
        { merge: true }
      );

      const updateStripeUser = httpsCallable(functions, "updateStripeUser");
      updateStripeUser({
        newFirstName: editFirstName,
        newLastName: editLastName,
        memberId,
        stripeId,
      });

      handleNext();
    } catch (err) {
      console.error("Error updating names:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Enter your name
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          This will be displayed to others on the platform.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <NameField
            name="firstName"
            value={editFirstName}
            error={errors.firstName}
            helperText={"First name"}
            handleChange={handleChange}
            label="First Name"
          />
          <NameField
            name="lastName"
            value={editLastName}
            error={errors.lastName}
            helperText={"Last name"}
            handleChange={handleChange}
            label="Last Name"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Save</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default EditName;
