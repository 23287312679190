import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationBurst } from "../../animations/ConfirmationBurst";
import ResponsiveDialog from "../../components/dialogs/ResponsiveDialog";
import FooterSimple from "../../components/FooterSimple";
import ReviewVisitMember from "../../components/ReviewVisitMember";
import { db } from "../../firebase";

const ReviewPage = () => {
  const [loading, setLoading] = useState(true);
  const [visit, setVisit] = useState(null);
  const [visitId, setVisitId] = useState(null);
  const [keyParam, setKeyParam] = useState(null);
  const [error, setError] = useState(null);
  const [reviewed, setReviewed] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    const ref = params.get("ref");
    const key = params.get("key");

    if (!ref || !key) {
      setReviewed(true);
      setLoading(false);
      return;
    }

    setKeyParam(key);

    const fetchVisitByRef = async () => {
      try {
        const visitsRef = collection(db, "visits");
        const q = query(visitsRef, where("ref", "==", ref));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log("Invalid or expired link.");
          setReviewed(true);
          setLoading(false);
          return;
        }

        const visitDoc = querySnapshot.docs[0];
        setVisitId(visitDoc.id);
        setVisit(visitDoc.data());
        setReviewed(visitDoc.data().rating);
      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVisitByRef();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center">
        {error}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        bgcolor: "white",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        position: "relative",
        px: 2,
      }}
    >
      <ResponsiveDialog
        open={true}
        onClose={() => {}}
        fullHeight={isMobile ? null : "calc(100vh - 50px)"}
        title={`Review`}
        width="800px"
        showAction={false}
        showBackdrop={false}
        maxHeight="583px"
        anchor="right"
        showLogo={true}
      >
        {reviewed ? (
          <>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  position: "sticky",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "300px",
                  // width: "100%", // Ensure it spans the full width
                  gap: 2,
                  marginY: 2,
                  marginX: "32px",
                }}
              >
                <ConfirmationBurst size="xlarge" />
                <Typography variant="h6" marginTop={2}>
                  Review Complete!
                </Typography>
                <Typography>
                  This visit has already been reviewed. Thanks for taking the
                  time to let us know how we're doing. We're always working to
                  provide the best service for you!
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => navigate("/dashboard")}
                sx={{
                  textTransform: "none",
                  minWidth: { xs: "100%", sm: "200px" },
                  height: { xs: "60px", sm: "50px" },
                }}
                disableElevation={true}
                variant="contained"
                fullWidth
              >
                Go to Dashboard
              </Button>
            </DialogActions>
          </>
        ) : (
          <ReviewVisitMember
            visit={visit}
            visitId={visitId}
            keyParam={keyParam}
          />
        )}
      </ResponsiveDialog>
      {!isMobile && <FooterSimple />}
    </Box>
  );
};

export default ReviewPage;
