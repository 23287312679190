import React from "react";
import { Box, Typography } from "@mui/material";

const FactBox = ({ value, label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#F5F5F5",
        py: 1,
        px: 2,
        boxSizing: "border-box",
        borderRadius: "15px",
      }}
    >
      <Typography fontWeight={"600"}>{value}</Typography>
      <Typography variant="caption" color={"text.secondary"}>
        {label}
      </Typography>
    </Box>
  );
};

export default FactBox;
