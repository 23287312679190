import { Call, ContentCopy, Person } from "@mui/icons-material";
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
} from "@mui/material";
import React from "react";
import { useDrawer } from "../../../providers/DrawerProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";
import { handleCopy } from "../../../services/formServices";
import { formatPhoneNumber } from "../../../services/stringServices";
import ColorAvatar from "../../ColorAvatar";
import MemberDrawer from "../../drawers/MemberDrawer";

const MemberListItem = ({
  visitData = {},
  visitPrivateData = {},
  loadingPrivate = true,
  userRole = "member",
}) => {
  const { phone = "", title = "" } = visitPrivateData || {}; // Fallback to empty object if null

  const {
    displayName = "",
    avatarUrl = "",
    member: memberId = "",
  } = visitData || {};

  const { showSnackbar } = useSnackbar();
  const { openDrawer } = useDrawer();

  const handleCallPhone = (phone) => {
    if (!phone) return; // Disable button if phone number is not available

    const formattedNumber = phone.replace(/[^+0-9]/g, "") || 5555555555; // removing any non-numeric/non-plus characters
    const telUrl = `tel:${formattedNumber}`;
    window.location.href = telUrl;
  };

  return (
    <ListItemButton
      onClick={() => openDrawer(MemberDrawer, { memberId, userRole })}
      sx={{ borderRadius: "15px", px: 0 }}
    >
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <Person color="primary" />
      </ListItemIcon>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        sx={{ mr: 4, ml: { xs: -1, sm: 0 } }}
      >
        <ColorAvatar name={title ? title : displayName} avatarUrl={avatarUrl} />

        <ListItemText
          primary={title ? title : displayName}
          secondary={
            loadingPrivate ? (
              <Skeleton variant="text" />
            ) : (
              formatPhoneNumber(phone)
            )
          }
        />
        <ListItemSecondaryAction sx={{ display: "flex", gap: 2 }}>
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation(); // Stop the ListItem's onClick from being triggered
              handleCallPhone(phone);
            }}
          >
            <Call />
          </IconButton>
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation(); // Stop the ListItem's onClick from being triggered
              handleCopy(
                phone,
                (message) => showSnackbar(message, "success"),
                (message) => showSnackbar(message, "error")
              );
            }}
          >
            <ContentCopy />
          </IconButton>
        </ListItemSecondaryAction>
      </Stack>
    </ListItemButton>
  );
};

export default MemberListItem;
