import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { StarRounded } from "@mui/icons-material";

const RatingChip = ({ rating }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const generateRatingStars = (rating) => {
    const MAX_STARS = 5;
    let stars = [];
    const starSpacing = theme.spacing(-0.5); // Negative value to bring stars closer, adjust as needed

    for (let i = 1; i <= MAX_STARS; i++) {
      stars.push(
        i <= rating ? (
          <StarRounded
            key={i}
            color="warning"
            sx={{ marginRight: i < MAX_STARS ? starSpacing : 0 }}
          />
        ) : (
          <StarRounded
            key={i}
            color="disabled"
            sx={{ marginRight: i < MAX_STARS ? starSpacing : 0 }}
          />
        )
      );
    }

    return stars;
  };

  return generateRatingStars(rating);
};

export default RatingChip;
