import {
  ExpandMore,
  StarOutlineRounded,
  StarRounded,
} from "@mui/icons-material";
import {
  Accordion,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Fade,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationBurst } from "../animations/ConfirmationBurst";
import { db, functions } from "../firebase";
import DateTimeListItem from "./visit/items/DateTimeListItem";
import HelpersListItem from "./visit/items/HelpersListItem";
// import { touchRippleClasses } from "@mui/core";

const ReviewVisitMember = ({
  visit,
  visitId,
  handleClose,
  stars = null,
  keyParam = null,
}) => {
  const [rating, setRating] = useState(stars);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false); // State to track loading
  const [showConfirmation, setShowConfirmation] = useState(false); // State to show confirmation
  const timeZoneId = visit?.timeZoneId;
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setRating(stars);
  }, [stars]);

  // Utility functions for date and time formatting
  const formatDate = (date) => {
    return moment(date).format("MMMM D, YYYY");
  };

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMore sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    // [`& .${touchRippleClasses.rippleVisible}`]: {
    //   color: theme.palette.primary.main, // This sets the ripple color
    //   opacity: 0.6,
    // },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(2),
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
  }));

  const handleSubmit = async () => {
    setLoading(true); // Start the loading state

    // Reference to the visit document
    const visitDocRef = doc(db, "visits", visitId);

    // If we have a key parameter, then we are calling this from public
    if (keyParam) {
      try {
        const submitReview = httpsCallable(functions, "submitReview");
        submitReview({
          visitId,
          rating,
          review,
          key: keyParam,
        }).catch((error) => {
          console.error("Error submitting review:", error);
        });

        setLoading(false);
        setShowConfirmation(true);
      } catch (error) {
        console.error("Error submitting review:", error);
        setLoading(false);
      }
    } else {
      try {
        await updateDoc(visitDocRef, {
          rating: rating,
          review: review,
        });

        const handleReview = httpsCallable(functions, "handleReview");
        handleReview({
          employees: visit?.employees,
          memberName: visit?.displayName,
          rating,
          review,
        });

        console.log("Document successfully updated!");
        setLoading(false); // Stop the loading state
        setShowConfirmation(true); // Show the confirmation
      } catch (error) {
        console.error("Error updating document: ", error);
        setLoading(false); // Stop the loading if there's an error
      }
    }
  };

  // useEffect(() => {
  //   if (showConfirmation) {
  //     const timer = setTimeout(() => {
  //       setShowConfirmation(false); // Hide the confirmation
  //       handleClose(); // Close the entire component
  //     }, 4000); // 4 seconds delay
  //     return () => clearTimeout(timer); // Clear timer if component is unmounted
  //   }
  // }, [showConfirmation, handleClose]);

  const formatEmployeeNames = (employees) => {
    if (!employees || Object.keys(employees).length === 0) return;

    // Get accepted employees' names
    const names = Object.values(employees)
      .filter((emp) => emp?.responseStatus === "accepted")
      .map((emp) => emp?.displayName?.slice(0, -3));

    // Format names into a natural language list
    if (names.length === 0) return "";
    if (names.length === 1) return names[0];
    return (
      names.slice(0, -1).join(", ") +
      (names.length > 1 ? " and " : "") +
      names.slice(-1)
    );
  };

  const employeeNames = formatEmployeeNames(visit?.employees);

  return (
    <>
      <DialogContent>
        {/* Centered Rating Section */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Ensure it spans the full width
              gap: 2,
              minHeight: "300px",
              marginY: 2,
            }}
          >
            <CircularProgress size={24} color="inherit" />
          </Box>
        ) : showConfirmation ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "300px",
              // width: "100%", // Ensure it spans the full width
              gap: 2,
              marginY: 2,
              marginX: "32px",
            }}
          >
            <ConfirmationBurst size="xlarge" />
            <Typography variant="h6" marginTop={2}>
              Received!
            </Typography>
            <Typography>
              Thanks for taking the time to let us know how we're doing. We're
              always working to provide the best service for you!
            </Typography>
          </Box>
        ) : (
          <>
            {/* Visit Details Accordion */}
            <Accordion disableGutters elevation={0}>
              <AccordionSummary
                sx={{ marginBottom: "16px" }}
                expandIcon={<ExpandMore color="primary" />}
              >
                <Typography color="primary">Visit Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DateTimeListItem visitData={visit} userRole={"member"} />
                <HelpersListItem
                  visitData={visit}
                  memberId={visit?.member}
                  userRole={"member"}
                  onlyAccepted={true}
                />
              </AccordionDetails>
            </Accordion>
            <Box
              sx={{
                display: "flex",
                position: "sticky",
                top: isMobile ? "32vh" : undefined,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%", // Ensure it spans the full width
                gap: 2,
                marginY: 2,
              }}
            >
              {/* Title */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" align="center">
                  How was your visit?
                </Typography>
                <Typography variant="center">with {employeeNames}</Typography>
              </Box>

              {/* Star Rating */}
              <Rating
                size="large"
                value={rating}
                name="visit-rating"
                icon={<StarRounded fontSize="inherit" />}
                emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                sx={{ fontSize: "3rem" }} // Adjust the size as needed
                onChange={(event, newRating) => {
                  setRating(newRating);
                }}
                precision={1}
              />
              {/* Comment Section */}
              <Fade in={!!rating}>
                <TextField
                  label="Leave a comment (optional)"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </Fade>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!rating || loading} // Disable the button if loading
            sx={{
              width: "100%",
              height: "60px",
              textTransform: "none",
            }}
            onClick={
              showConfirmation
                ? keyParam
                  ? () => navigate("/dashboard")
                  : handleClose
                : handleSubmit
            }
            variant="contained"
            color="primary"
          >
            {showConfirmation
              ? keyParam
                ? "Go to Dashboard"
                : "Go Back"
              : "Submit review"}
          </Button>
          {!showConfirmation && (
            <Typography
              variant="caption"
              color={"text.secondary"}
              align="center"
              sx={{ mt: 1 }}
            >
              {keyParam &&
                "To turn off review notifications, go to your dashboard settings. "}
              Your rating, review, and task description will be public.
            </Typography>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default ReviewVisitMember;
