import {
  Cake,
  CreditCard,
  Email,
  FileCopyOutlined,
  Home,
  Person,
  Phone,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from "react";
import AddressTab from "../../../dashboard/member/tabs/AddressTab";
import { useAuth } from "../../../providers/AuthProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";
import { handleCopy } from "../../../services/formServices";
import { formatPhoneNumber } from "../../../services/stringServices";
import ChangeMembership from "../../ChangeMembership";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";
import EditAvatar from "./EditAvatar";
import EditEmail from "./EditEmail";
import EditEmail2 from "./EditEmail2";
import EditName from "./EditName";
import EditPhone from "./EditPhone";
import EditPhone2 from "./EditPhone2";

const MemberDetails = ({
  memberData,
  memberPrivateData,
  memberAccountData,
  memberAdminData,
  userRole = "member",
}) => {
  // Dialogs
  const [openManageAddress, setOpenManageAddress] = useState(false);
  const [openChangeMembership, setOpenChangeMembership] = useState(false);
  const [openEditPhone, setOpenEditPhone] = useState(false);
  const [openEditPhone2, setOpenEditPhone2] = useState(false);
  const [openEditEmail, setOpenEditEmail] = useState(false);
  const [openEditEmail2, setOpenEditEmail2] = useState(false);
  const [openEditName, setOpenEditName] = useState(false);
  const [openEditAvatar, setOpenEditAvatar] = useState(false);

  const { currentUser } = useAuth();

  const isSelf = currentUser?.uid === memberData.id;
  console.log("isSelf: ", isSelf);

  const { showSnackbar } = useSnackbar();

  console.log("memberData: ", memberData);
  console.log("memberAdminData: ", memberAdminData);

  // Function to format the address
  const formatAddress = (address) => {
    const line1 = address?.line1 ? `${address?.line1}` : "";
    const line2 = address?.line2 ? `, ${address?.line2}` : "";
    const city = address?.city ? `, ${address?.city}` : "";
    const state = address?.state ? ` ${address?.state}` : "";
    const zipCode = address?.zipCode ? ` ${address?.zipCode}` : "";

    return `${line1}${line2}${city}${state}${zipCode}`;
  };

  const timeZoneId = memberData?.timeZoneId;
  const nextMembershipDate = memberPrivateData?.billing?.nextMembershipFeeDate;
  const membershipTier = memberAdminData?.membershipTier;
  const defaultAddressKey = memberPrivateData?.defaultAddress;
  const address = memberAccountData?.addresses?.[defaultAddressKey];
  const hourlyRate = memberAdminData?.hourlyRate;
  const membershipFee = memberAdminData?.membershipFee;

  // Function to handle address editing
  const handleEditAddress = () => {
    setOpenManageAddress(true);
  };

  // Close address editing dialog
  const handleCloseAddressEditor = () => {
    setOpenManageAddress(false);
  };

  const editIcon = () => (
    <Typography
      color="primary"
      sx={{
        textDecoration: "underline",
        fontSize: "0.875rem",
        fontWeight: "500",
      }}
    >
      edit
    </Typography>
  );

  // DRY: Store member details in a structure to iterate over
  const memberDetails = [
    {
      label: "Name",
      data: `${memberData?.firstName} ${memberData?.lastName}`,
      icon: <Person />,
      actions: [
        ...(userRole === "admin" || isSelf
          ? [
              {
                type: "edit",
                handler: () => setOpenEditName(true),
                icon: editIcon(),
              },
            ]
          : []),
        ...(!isSelf
          ? [
              {
                type: "copy",
                handler: () =>
                  handleCopy(
                    `${memberData?.firstName} ${memberData?.lastName}`,
                    (message) => showSnackbar(message, "success"),
                    (message) => showSnackbar(message, "error")
                  ),
                icon: <FileCopyOutlined />,
              },
            ]
          : []),
      ],
    },
    // {
    //   // label: "Profile picture",
    //   data: <Avatar src={memberData?.avatarUrl} />,
    //   icon: <Person />,
    //   actions: [
    //     ...(userRole === "admin" || isSelf
    //       ? [
    //           {
    //             type: "edit",
    //             handler: () => setOpenEditAvatar(true),
    //             icon: editIcon(),
    //           },
    //         ]
    //       : []),
    //   ],
    // },
    {
      label: "Phone",
      data: formatPhoneNumber(memberPrivateData?.phone),
      icon: <Phone />,
      actions: [
        ...(userRole === "admin" || isSelf
          ? [
              {
                type: "edit",
                handler: () => setOpenEditPhone(true),
                icon: editIcon(),
              },
            ]
          : []),
        ...(!isSelf
          ? [
              {
                type: "copy",
                handler: () =>
                  handleCopy(
                    memberPrivateData?.phone,
                    (message) => showSnackbar(message, "success"),
                    (message) => showSnackbar(message, "error")
                  ),
                icon: <FileCopyOutlined />,
              },
            ]
          : []),
      ],
    },

    // "Primary Email:"
    {
      label: "Email",
      data: memberPrivateData?.email,
      icon: <Email />,
      actions: [
        ...(userRole === "admin" || isSelf
          ? [
              {
                type: "edit",
                handler: () => setOpenEditEmail(true),
                icon: editIcon(),
              },
            ]
          : []),
        ...(!isSelf
          ? [
              {
                type: "copy",
                handler: () =>
                  handleCopy(
                    memberPrivateData?.email,
                    (message) => showSnackbar(message, "success"),
                    (message) => showSnackbar(message, "error")
                  ),
                icon: <FileCopyOutlined />,
              },
            ]
          : []),
      ],
    },

    // Address
    userRole === "admin" || userRole === "employee"
      ? {
          label: "Address",
          data: formatAddress(address),
          icon: <Home />,
          actions: [
            ...(userRole === "admin"
              ? [
                  {
                    type: "edit",
                    handler: handleEditAddress,
                    icon: editIcon(),
                  },
                ]
              : []),
            ...(!isSelf
              ? [
                  {
                    type: "copy",
                    handler: () =>
                      handleCopy(
                        formatAddress(address),
                        (message) => showSnackbar(message, "success"),
                        (message) => showSnackbar(message, "error")
                      ),
                    icon: <FileCopyOutlined />,
                  },
                ]
              : []),
          ],
        }
      : null,
    // Joined
    {
      label: "Joined",
      data: memberData?.created
        ? moment(memberData.created.toDate()).format("MMMM D, YYYY")
        : "",

      icon: <Cake />,
      actions: [
        ...(!isSelf
          ? [
              {
                type: "copy",
                handler: () =>
                  handleCopy(
                    memberData?.created
                      ? moment(memberData.created.toDate()).format(
                          "MMMM D, YYYY"
                        )
                      : "",
                    (message) => showSnackbar(message, "success"),
                    (message) => showSnackbar(message, "error")
                  ),
                icon: <FileCopyOutlined />,
              },
            ]
          : []),
      ],
    },

    // Membership tier
    userRole === "admin"
      ? {
          label: membershipTier === 0 ? "Basic Plan" : "Premium Plan",
          data:
            membershipTier === 0
              ? `$${hourlyRate}/hr, no monthly fees`
              : `$${hourlyRate}/hr + $${membershipFee}/mo`,
          secondary: nextMembershipDate
            ? `Next cycle: ${moment(nextMembershipDate.toDate())
                .tz(timeZoneId)
                .format("MMM Do")}`
            : "",
          icon: <CreditCard />,
          actions: [
            {
              type: "edit",
              handler: () => setOpenChangeMembership(true),
              icon: editIcon(),
            },
          ],
        }
      : null,

    // "Secondary Phone:"
    {
      label: "Secondary Phone",
      data: formatPhoneNumber(memberPrivateData?.phone2),
      icon: <Phone />,
      actions: [
        ...(userRole === "admin" || isSelf
          ? [
              {
                type: "edit",
                handler: () => setOpenEditPhone2(true),
                icon: editIcon(),
              },
            ]
          : []),
        ...(!isSelf
          ? [
              {
                type: "copy",
                handler: () =>
                  handleCopy(
                    memberPrivateData?.phone2,
                    (message) => showSnackbar(message, "success"),
                    (message) => showSnackbar(message, "error")
                  ),
                icon: <FileCopyOutlined />,
              },
            ]
          : []),
      ],
    },

    // "Secondary Email"
    {
      label: "Secondary Email",
      data: memberPrivateData?.email2,
      icon: <Email />,
      actions: [
        ...(userRole === "admin" || isSelf
          ? [
              {
                type: "edit",
                handler: () => setOpenEditEmail2(true),
                icon: editIcon(),
              },
            ]
          : []),
        ...(!isSelf
          ? [
              {
                type: "copy",
                handler: () =>
                  handleCopy(
                    memberPrivateData?.email2,
                    (message) => showSnackbar(message, "success"),
                    (message) => showSnackbar(message, "error")
                  ),
                icon: <FileCopyOutlined />,
              },
            ]
          : []),
      ],
    },
  ].filter(Boolean);

  console.log("memberDetails: ", memberDetails);

  return (
    <>
      <Stack spacing={2} alignItems="center">
        <List sx={{ width: "100%" }}>
          {memberDetails.map(({ label, data, icon, actions = [] }) => (
            <ListItem key={label} divider>
              {/* <ListItemIcon>
                  {icon}
                </ListItemIcon> */}
              <ListItemText
                primary={label}
                secondary={data && data}
                sx={{ mr: 4 }}
              />
              <ListItemSecondaryAction>
                <Box sx={{ display: "flex", gap: 2 }}>
                  {actions.map((action, index) => (
                    <IconButton
                      key={index}
                      edge="end"
                      onClick={action.handler}
                      disabled={!data && action.type === "copy"}
                    >
                      {action.icon}
                    </IconButton>
                  ))}
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Stack>
      {/* Responsive Dialog for Address Edit */}
      <ResponsiveDialog
        open={openManageAddress}
        onClose={handleCloseAddressEditor}
        title={"Manage Address"}
        width={"800px"}
        desktopAnchor={"right"}
        anchor={"right"}
        showBackdrop={false}
        fullHeight={true}
      >
        {openManageAddress && (
          <AddressTab
            memberAdminData={memberAdminData}
            memberData={memberData}
            memberId={memberData?.id}
            memberPrivateData={memberPrivateData}
            accountData={memberAccountData}
            userRole={"admin"}
          />
        )}
      </ResponsiveDialog>
      {/* Responsive Dialog for Changing Membership */}
      <ResponsiveDialog
        open={openChangeMembership}
        onClose={() => setOpenChangeMembership(false)}
        title={"Change Plan"}
        width={"800px"}
      >
        <ChangeMembership
          memberId={memberData?.id}
          timeZoneId={memberData?.timeZoneId}
          zipCode={memberData?.location?.zipCode}
          membershipTier={memberAdminData?.membershipTier}
          setOpenPricingCards={setOpenChangeMembership}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openEditName}
        onClose={() => setOpenEditName(false)}
        title={"Edit name"}
        width={"600px"}
        anchor={"bottom"}
        fullHeight={true}
        showBackdrop={true}
      >
        <EditName
          memberId={memberData?.id}
          stripeId={memberAdminData?.stripeId}
          firstName={memberData?.firstName}
          lastName={memberData?.lastName}
          canSkip={false}
          handleNext={() => setOpenEditName(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openEditAvatar}
        onClose={() => setOpenEditAvatar(false)}
        title={"Edit profile picture"}
        width={"600px"}
        anchor={"bottom"}
        fullHeight={true}
        showBackdrop={true}
      >
        <EditAvatar
          memberId={memberData?.id}
          avatarUrl={memberData?.avatarUrl}
          canSkip={false}
          handleNext={() => setOpenEditAvatar(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openEditPhone}
        onClose={() => setOpenEditPhone(false)}
        title={"Edit phone"}
        width={"600px"}
        anchor={"bottom"}
        fullHeight={true}
        showBackdrop={true}
      >
        <EditPhone
          memberId={memberData?.id}
          stripeId={memberAdminData?.stripeId}
          phone={memberPrivateData?.phone}
          canSkip={false}
          handleNext={() => setOpenEditPhone(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openEditPhone2}
        onClose={() => setOpenEditPhone2(false)}
        title={"Edit phone"}
        width={"600px"}
        anchor={"bottom"}
        fullHeight={true}
        showBackdrop={true}
      >
        <EditPhone2
          memberId={memberData?.id}
          phone={memberPrivateData?.phone2}
          canSkip={false}
          handleNext={() => setOpenEditPhone2(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openEditEmail}
        onClose={() => setOpenEditEmail(false)}
        title={"Edit email"}
        width={"600px"}
        anchor={"bottom"}
        fullHeight={true}
        showBackdrop={true}
      >
        <EditEmail
          memberId={memberData?.id}
          stripeId={memberAdminData?.stripeId}
          email={memberPrivateData?.email}
          canSkip={false}
          handleNext={() => setOpenEditEmail(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openEditEmail2}
        onClose={() => setOpenEditEmail2(false)}
        title={"Edit email"}
        width={"600px"}
        anchor={"bottom"}
        fullHeight={true}
        showBackdrop={true}
      >
        <EditEmail2
          memberId={memberData?.id}
          stripeId={memberAdminData?.stripeId}
          email={memberPrivateData?.email2}
          canSkip={false}
          handleNext={() => setOpenEditEmail2(false)}
        />
      </ResponsiveDialog>
    </>
  );
};

export default MemberDetails;
