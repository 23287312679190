import { Feedback, StarOutlineRounded, StarRounded } from "@mui/icons-material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ReportIcon from "@mui/icons-material/Report";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { ConfirmationBurst } from "../../animations/ConfirmationBurst";
import { db } from "../../firebase";
import { useEmployee } from "../../providers/EmployeeProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { sendSMSClient } from "../../services/communicationServices";
import { addVisitFlag } from "../../services/schedulingServices";
import GridButtonsV2 from "../buttons/GridButtonsV2";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import CreatePost from "../feed/CreatePost";
import { useAuth } from "../../providers/AuthProvider";

const AdditionalHelpList = ({ visitId, memberName, employeeId }) => {
  const [loading, setLoading] = useState(false); // State to track loading
  const [showConfirmation, setShowConfirmation] = useState(false); // State to show confirmation

  const [openDialog, setOpenDialog] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { showSnackbar } = useSnackbar();
  const { employeeData } = useEmployee();
  const {currentUser} = useAuth();

  const [checklist, setChecklist] = useState({
    triedDoor: false,
    calledTwice: false,
    waited15Min: false,
  });

  const [rating, setRating] = useState();
  const [review, setReview] = useState("");

  const updateChecklist = (item) => {
    setChecklist({ ...checklist, [item]: !checklist[item] });
  };

  const isCancellationChecklistActionEnabled =
    checklist.triedDoor && checklist.calledTwice && checklist.waited15Min;

  const handleDialogClose = () => {
    setOpenDialog("");
    setShowConfirmation(false);
    setLoading(false); // Reset loading when dialog closes
  };

  const handleMemberCancellationAction = async () => {
    if (!isCancellationChecklistActionEnabled) return; // Prevent action if conditions are not met

    setLoading(true);
    // Implement action logic here

    sendSMSClient({
      phone: "3607081269",
      body: `${memberName} cancelled last minute.`,
    });

    await addVisitFlag({ visitId, flag: "memberCancellation" });
    updateEmployeeRating("memberCancellation", "member cancellation");

    setLoading(false);
    setShowConfirmation(true);
    showSnackbar("Feedback received");
  };

  const handleSafetyConcernAction = async () => {
    setLoading(true);
    // Implement action logic here

    sendSMSClient({
      phone: "3607081269",
      body: `${memberName} has been reported for safety concerns.`,
    });

    await addVisitFlag({ visitId, flag: "safetyConcern" });
    updateEmployeeRating("safetyConcern", "safety concern");

    showSnackbar("Feedback received");
    setLoading(false);
    setShowConfirmation(true);
  };

  const handleSpecialAssistanceAction = async () => {
    setLoading(true);

    sendSMSClient({
      phone: "3607081269",
      body: `${memberName} requires special assistance.`,
    });

    await addVisitFlag({ visitId, flag: "specialAssistance" });
    updateEmployeeRating("specialAssistance", "requires special assistance");

    showSnackbar("Feedback received");
    setLoading(false);
    setShowConfirmation(true);
  };

  const handleFeedback = async () => {
    setLoading(true); // Start the loading state

    updateEmployeeRating(rating, review);

    setLoading(false);
    setShowConfirmation(true);
  };

  const handleCreatePost = () => {
    // Reference to the visit document
    updateEmployeeRating("createdPost", "created public post");

    handleDialogClose();
  };

  const handleActionClick = (id) => {
    setOpenDialog(id); // Open the corresponding dialog
  };

  const updateEmployeeRating = async (rating, review) => {
    const visitDocRef = doc(db, "visits", visitId);

    // Prepare the nested fields for update
    const updateFields = {
      [`employees.${employeeId}.rating`]: rating || 5,
      [`employees.${employeeId}.review`]: review || "Post created",
    };

    try {
      await updateDoc(visitDocRef, updateFields);
      console.log("Document successfully updated with rating and review!");
      setLoading(false); // Stop the loading state
    } catch (error) {
      console.error("Error updating document: ", error);
      setLoading(false); // Stop the loading if there's an error
    }
  };

  const iconStyles = {
    // color: theme.palette.grey[700],
    // width: "32px",
    // height: "32px",
  };

  const listItems = [
    {
      id: "memberCancellation",
      icon: <EventBusyIcon color="primary" sx={{ ...iconStyles }} />,
      primary: "Member Cancellation",
      secondary: "Member did not show up or cancelled at the door.",
      dialogTitle: "Member Cancellation",
      dialogContent: (
        <>
          <Typography sx={{ mb: 2 }}>
            Marking this visit as cancelled last minute will charge the member a
            cancellation fee and pay you for one hour of your time.
          </Typography>
          <Box
            sx={{
              p: 2,
              borderRadius: "15px",
              backgroundColor: "#f5f5f5", // Light gray background
            }}
          >
            <Typography gutterBottom>
              <strong>Confirm completion</strong> (check the boxes):
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checklist.triedDoor}
                    onChange={() => updateChecklist("triedDoor")}
                  />
                }
                label="Tried the door 3 different times"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checklist.calledTwice}
                    onChange={() => updateChecklist("calledTwice")}
                  />
                }
                label="Called the member twice"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checklist.waited15Min}
                    onChange={() => updateChecklist("waited15Min")}
                  />
                }
                label="Waited at least 15 minutes"
              />
            </FormGroup>
          </Box>
        </>
      ),
      action: handleMemberCancellationAction,
    },
    {
      id: "feedback",
      icon: <Feedback color="primary" sx={{ ...iconStyles }} />,
      primary: "Give Feedback",
      secondary: "Share your experience about the visit for internal use only.",
      dialogTitle: "Visit Feedback",
      dialogContent: (
        <>
          <Typography sx={{ mb: 2 }}>
            Rate and leave a comment about your visit experience for internal
            use only.
          </Typography>
          <Box
            sx={{
              p: 2,
              borderRadius: "15px",
              // backgroundColor: "#f5f5f5", // Light gray background
              // border: "1px solid rgba(0, 0, 0, 0.6)", // Add black outline if current day
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Rating
              size="large"
              value={rating}
              name="visit-rating"
              icon={<StarRounded fontSize="inherit" />}
              emptyIcon={<StarOutlineRounded fontSize="inherit" />}
              sx={{ fontSize: "3rem" }} // Adjust the size as needed
              onChange={(event, newRating) => {
                setRating(newRating);
              }}
              precision={1}
            />
            <TextField
              label="Write about your visit..."
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={review}
              onChange={(e) => setReview(e.target.value)}
              sx={{ mt: 2 }} // Add margin top for spacing
            />
            <Typography variant="caption" sx={{ mt: 1 }}>
              Feedback is not shared with the member.
            </Typography>
          </Box>
        </>
      ),
      action: handleFeedback, // Assuming handleSubmit is the function to handle feedback submission
    },
    // {
    //   id: "createPost",
    //   icon: <PostAdd color="primary" sx={{ ...iconStyles }} />,
    //   primary: "Create Public Post",
    //   secondary:
    //     "Create a public social media post to share about an excellent visit.",
    //   action: handleCreatePost, // Define the function to handle post submission
    // },
    {
      id: "safetyConcern",
      icon: <ReportIcon color="primary" sx={{ ...iconStyles }} />,
      primary: "Safety Concern",
      secondary: "Report unsafe conditions or member behavior during a visit.",
      dialogTitle: "Safety Concern",
      dialogContent:
        "If you've encountered any safety issues or concerning behavior, please report it here to ensure a safe environment for everyone.",
      action: handleSafetyConcernAction,
    },
    {
      id: "specialAssistance",
      icon: <HealthAndSafetyIcon color="primary" sx={{ ...iconStyles }} />,
      primary: "Special Assistance",
      secondary:
        "Member requires enhanced care needs beyond our standard services.",
      dialogTitle: "Special Assistance",
      dialogContent:
        "If a member's needs surpass our standard service scope, please indicate here for additional support or specialized assistance.",
      action: handleSpecialAssistanceAction,
    },
  ];

  const renderDialogActions = (onClick, item) => {
    return (
      <>
        {showConfirmation ? (
          <Box sx={{ width: "100%", boxSizing: "border-box" }}>
            <Button
              disabled={loading}
              onClick={handleDialogClose}
              color="primary"
              variant="contained"
              sx={{
                height: "60px",
                minWidth: "100%",
                textTransform: "none",
              }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <Box sx={{ width: "100%", boxSizing: "border-box" }}>
            <Button
              disabled={loading}
              onClick={handleDialogClose}
              color="primary"
              variant="text"
              sx={{
                height: "60px",
                minWidth: "50%",
                textTransform: "none",
              }}
            >
              Go back
            </Button>
            <Button
              onClick={onClick}
              disabled={
                loading ||
                (item.id === "memberCancellation" &&
                  !isCancellationChecklistActionEnabled)
              }
              color="primary"
              variant="contained"
              sx={{
                height: "60px",
                minWidth: "50%",
                textTransform: "none",
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Confirm"
              )}
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <ResponsiveDialog
        open={openDialog === "createPost"}
        onClose={handleDialogClose}
        title={`Create a post`}
        width="600px"
        fullHeight={true}
        showBackdrop={false}
        desktopAnchor={"right"}
      >
        <CreatePost
          user={{ ...employeeData, id: currentUser?.uid }}
          addNewPost={handleCreatePost}
          placeholder={"Write about your visit..."}
          textRequired={true}
          mediaRequired={true}
        />
      </ResponsiveDialog>
      <Box sx={{ my: 2, width: "100%", boxSizing: "border-box" }}>
        <GridButtonsV2 actions={listItems} onActionClick={handleActionClick} />
      </Box>

      {listItems.map(
        (item) =>
          item.id !== "createPost" && (
            <ResponsiveDialog
              key={item.id}
              onClose={handleDialogClose}
              open={openDialog === item.id}
              title={item.dialogTitle}
              dialogActions={renderDialogActions(item.action, item)}
              width={"600px"}
              showBackdrop={false}
              desktopAnchor={"right"}
            >
              <DialogContent sx={{ minHeight: "100px" }}>
                {/* Centered Rating Section */}
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      position: "sticky",
                      top: isMobile ? "32vh" : undefined,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%", // Ensure it spans the full width
                      gap: 2,
                      marginY: 2,
                    }}
                  >
                    <CircularProgress size={24} color="inherit" />
                  </Box>
                ) : showConfirmation ? (
                  <Box
                    sx={{
                      display: "flex",
                      position: "sticky",
                      top: isMobile ? "32vh" : undefined,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      // width: "100%", // Ensure it spans the full width
                      gap: 2,
                      marginY: 2,
                      marginX: "32px",
                    }}
                  >
                    <ConfirmationBurst size="xlarge" />
                    <Typography variant="h6" marginTop={2}>
                      Received!
                    </Typography>
                    <Typography>
                      Thanks for taking the time to let us know how your visits
                      are going.
                    </Typography>
                  </Box>
                ) : (
                  item.dialogContent
                )}
              </DialogContent>
            </ResponsiveDialog>
          )
      )}
    </>
  );
};

export default AdditionalHelpList;
