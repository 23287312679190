import { ButtonGroup, DialogActions } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useEmployee } from "../../../providers/EmployeeProvider";
import AcceptVisitButton from "../../AcceptVisitButton";
import DeclineVisitButton from "../../DeclineVisitButton";
import PickupVisitButton from "../../PickupVisitButton";
import HideOpenVisitButton from "../employee/HideOpenVisitButton";

const EmployeeActionButtons = ({
  visitData = {},
  phone = "",
  userId,
  handleDecline = () => {},
  handleAccept = () => {},
  handlePickup = () => {},
  handleHide = () => {},
}) => {
  const {
    employees = {},
    id: visitId = "",
    start = Timestamp.now(),
    isOpenVisit = false,
  } = visitData || {};

  // Use to get visits and open visits
  const { visitsData, openVisitsData } = useEmployee();

  const [loading, setLoading] = useState(false);

  const isPastEvent = DateTime.now() > DateTime.fromJSDate(start.toDate());

  console.log("employees[userId]: ", employees[userId])


  // Render different buttons based on the event time and status
  const renderActionButtons = () => {
    const responseStatus = employees?.[userId]?.responseStatus;

    console.log("responseStatus: ", responseStatus);


    const acceptButtonStyles = {
      width: { xs: "50%", sm: "50%" },
      height: "60px",
      backgroundColor:
        responseStatus === "accepted" ? "lightblue" : "transparent",
    };

    const declineButtonStyles = {
      width: { xs: "50%", sm: "50%" },
      height: "60px",
      backgroundColor:
        responseStatus === "declined" ? "lightblue" : "transparent",
    };

    return (
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <ButtonGroup
          fullWidth
          sx={{
            width: { xs: "100%", sm: "100%" },
            height: "60px",
          }}
        >
          <DeclineVisitButton
            responseStatus={responseStatus}
            visit={visitData}
            visitId={visitId}
            currentEmployeeId={userId}
            customStyles={declineButtonStyles}
            handleDecline={handleDecline}
            loading={loading}
            setLoading={setLoading}
            phone={phone}
          />
          <AcceptVisitButton
            responseStatus={responseStatus}
            visit={visitData}
            visitId={visitId}
            visits={visitsData}
            currentEmployeeId={userId}
            customStyles={acceptButtonStyles}
            handleAccept={handleAccept}
            loading={loading}
            setLoading={setLoading}
          />
        </ButtonGroup>
      </DialogActions>
    );
  };

  return (
    <>
      {isPastEvent ? (
        <>
          {/* 
        !isReviewed && (
          <DialogActions
            sx={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Button
              sx={{
                width: { xs: "100%", sm: "100%" },
                height: "60px",
              }}
              disableElevation={!isMobile}
              onClick={handleReviewOpen}
              variant="contained"
              color="primary"
            >
              Feedback
            </Button>
          </DialogActions>
          */}
        </>
      ) : isOpenVisit && !employees[userId] ? (
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          {/* Case 3: Present open visitData */}
          <ButtonGroup fullWidth sx={{ height: "60px" }}>
            <HideOpenVisitButton
              visitId={visitId}
              currentEmployeeId={userId}
              customStyles={{
                width: { xs: "50%", sm: "100%" },
                height: "60px",
              }}
              handleHide={handleHide}
              loading={loading}
              setLoading={setLoading}
            />
            <PickupVisitButton
              visit={visitData}
              visitId={visitId}
              openVisitsData={openVisitsData}
              currentEmployeeId={userId}
              customStyles={{
                width: { xs: "50%", sm: "100%" },
                height: "60px",
              }}
              handlePickup={handlePickup}
              loading={loading}
              setLoading={setLoading}
            />
          </ButtonGroup>
        </DialogActions>
      ) : (
        // Case 4: Present visitData
        employees[userId] != null && renderActionButtons()
      )}
    </>
  );
};

export default EmployeeActionButtons;
