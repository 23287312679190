import { Close, ArrowBackIosNewRounded } from "@mui/icons-material";
import {
  Box,
  Paper,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const PushDrawer = ({
  title,
  children,
  open,
  onOpen,
  onClose,
  minWidth = "600px",
  width = null,
  hideBackdrop,
  actionButtons = null,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const elevation = isMobile ? 4 : 0;

  // Conditional style for hiding scrollbar
  const hideScrollbarStyle = {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for Chrome, Safari and Opera
    },
    msOverflowStyle: "none", // Hide scrollbar for IE and Edge
    scrollbarWidth: "none", // Hide scrollbar for Firefox
  };

  const renderDrawerHeader = (title) => (
    <Box
      sx={{
        position: "relative", // Added to position children absolutely within this Box.
        display: "flex",
        alignItems: "center", // Vertically aligns items in the center.
        padding: 2,
        justifyContent: "space-between", // Distributes space between the children (IconButton and the title).
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        height: "50px",
      }}
    >
      <IconButton onClick={handleClose}>
        <ArrowBackIosNewRounded />
      </IconButton>

      {/* Title container to help center the title text */}
      <Box
        sx={{
          position: "absolute", // Absolute positioning is used here to center the title.
          top: "50%", // Positions the top edge of the element at the center of the parent.
          left: "50%", // Positions the left edge of the element at the center of the parent.
          transform: "translate(-50%, -50%)", // Shifts the element back by 50% of its own width and height to achieve true centering.
          textAlign: "center", // Ensures the text within is centered.
        }}
      >
        <Typography variant="h6">{title}</Typography> {/* This is your title */}
      </Box>
    </Box>
  );

  const handleClose = () => {
    onClose();
  };

  return (
    <SwipeableDrawer
      disableSwipeToOpen={true}
      open={open}
      anchor="right"
      onOpen={onOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          className: "hideScrollbar",
          overflowY: "hidden",
          maxWidth: 600,
          width: isMobile ? "100vw" : "auto",
          // margin: "auto",
          borderTopLeftRadius: "0px", // Rounded top-left corner
          borderTopRightRadius: "0px", // Rounded top-right corner
          minWidth: { xs: "auto", sm: minWidth }, // Minimum width of 600px on 'sm' screens and larger.
          // ...(!isMobile ? { width: width } : {}),
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: !isMobile && hideBackdrop ? "transparent" : undefined,
          },
        },
      }}
    >
      {renderDrawerHeader(title)}
      {children}
      {/* { children } */}

      {/* action buttons */}
      {actionButtons && (
        <Paper
          elevation={elevation} // elevation doesn't accept breakpoints, you might need to conditionally change it based on the viewport width or use a different approach
          sx={{
            display: "flex",
            position: { xs: "fixed", sm: "relative" },
            marginTop: { xs: "8px", sm: "0" },
            bottom: { xs: "0", sm: "auto" }, // 'auto' is used instead of 'none' for valid CSS
            left: { xs: "0", sm: "auto" }, // same here
            right: { xs: "0", sm: "auto" }, // and here
            backgroundColor: "background.paper",
            padding: "8px",
            justifyContent: "space-between",
            borderRadius: { xs: "0px", sm: "borderRadius" }, // assuming 'auto' means standard border-radius
            height: "60px",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          {/* Action button components */}
          {actionButtons}
        </Paper>
      )}
    </SwipeableDrawer>
  );
};

export default PushDrawer;
