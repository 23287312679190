import {
  Box,
  Button,
  Fade,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../providers/SnackbarProvider";

import { EventAvailable, EventBusy } from "@mui/icons-material";
import moment from "moment-timezone";
import { createLog } from "../../services/logServices";
import BookingOptions from "./BookingOptions";
import DateDisplay from "./DateDisplay";
import DatePicker from "./DatePicker";
import VisitDisplay from "./VisitDisplay";

const VisitPicker = ({
  visits,
  selectedVisit,
  setSelectedVisit,
  setFilters,
  filters,
  employees,
  timeZoneId,
  memberId,
  parentSelectedDate = null,
  bestMatchEmployee = null,
  adminMode = false,
}) => {
  const { showSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Additional states for selected date, filters, and available visits
  const [selectedDate, setSelectedDate] = useState(
    parentSelectedDate ? moment(parentSelectedDate).tz(timeZoneId).format("YYYY-MM-DD") : null
  );
  const [filteredVisits, setFilteredVisits] = useState([]); // filtered visits

  const [availableVisits, setAvailableVisits] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);

  console.log("selectedDate: ", moment(selectedDate).tz(timeZoneId));

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (filteredVisits.length > 0 && availableVisits.length === 0) {
      setSelectedDate(null);
    }
  }, [availableVisits]);

  // If we have selected a day (no visit yet) and that day is no longer available
  // OR If we have selected a visit and that visit is no longer available
  useEffect(() => {
    if (!selectedDate) return;

    console.log("selectedDate: ", selectedDate);

    // Check if there are no more visits on the previously selected day
    const selectedDay = moment(selectedDate).tz(timeZoneId);
    console.log("selectedDay: ", selectedDay.format("MM-DD hh:mm a"));
    const visitsOnSelectedDay = visits.filter((visit) => {
      // console.log(
      //   "moment(visit.start).tz(timeZoneId): ",
      //   moment(visit.start).tz(timeZoneId).format("MM-DD hh:mm a")
      // );

      const result = moment(visit.start)
        .tz(timeZoneId)
        .isSame(selectedDay, "day");

      // console.log("result: ", result);
      return result;
    });

    if (visitsOnSelectedDay.length === 0) {
      console.log("there are no visits on the selected day");
      setSelectedDate(null);
    }

    if (
      selectedVisit.key &&
      !visits.some(
        (visit) =>
          generateVisitKey(memberId, visit, filters.frequency) ===
          selectedVisit.key
      )
    ) {
      setSelectedVisit({}); // Reset selectedVisit
      showSnackbar("The selected time is no longer available.", "warning");
    }
  }, [visits]);

  if (!Array.isArray(visits)) {
    console.error("Visits data is not an array or not loaded yet.");
    return null; // Or return a loading state/component
  }

  const handleDateChange = (date) => {
    setSelectedVisit({}); // Reset selectedVisit and its key
    setSelectedDate(date);

    if (!adminMode) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Selected a date.`,
        logType: "activity",
      });
    }
  };

  const handleTimeSlotSelect = (visit) => {
    const visitKey = generateVisitKey(memberId, visit, filters.frequency);

    setSelectedVisit({
      visit,
      key: visitKey,
    });

    if (!adminMode) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Selected a time slot.`,
        logType: "activity",
      });
    }
    // console.log("selectedVisit.visit:", visit);
  };

  const generateVisitKey = (memberId, visit, frequency) => {
    return `${memberId}-${visit.employeeIds.join("-")}-${visit.start}-${
      visit.end
    }-${frequency}-${visit.duration}`;
  };

  const resetFilters = () => {
    setFilters({
      duration: 2,
      frequency: 0,
      employeeIds: [],
      numEmployees: "1",
    });
  };

  const gridSpacing = isMobile ? 0 : 2;

  return (
    <Box>
      <BookingOptions
        filters={filters}
        setFilters={setFilters}
        handleReset={resetFilters}
        visits={visits}
        employees={employees}
        bestMatchEmployee={bestMatchEmployee}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        onFilterVisits={(values) => setFilteredVisits(values)}
        onFilterVisitsDate={(values) => setAvailableVisits(values)}
      />
      <Grid container spacing={gridSpacing} sx={{ p: { xs: 0, sm: 2 } }}>
        <Grid item xs={12} sm={6} md={6}>
          {/* Calendar Component */}
          <DatePicker
          timeZoneId={timeZoneId}
            visits={filteredVisits}
            filters={filters}
            selectedDate={selectedDate}
            onChange={handleDateChange}
            allowCollapse={isMobile}
          />
        </Grid>
        {selectedDate && (
          <Grid item xs={12} sm={6} md={6}>
            <DateDisplay selectedDate={selectedDate} />
            {availableVisits.length > 0 && (
              <VisitDisplay
              timeZoneId={timeZoneId}
                memberId={memberId}
                filters={filters}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedVisit={selectedVisit}
                employees={employees}
                availableVisits={availableVisits}
                bestMatchEmployee={bestMatchEmployee}
                handleTimeSlotSelect={handleTimeSlotSelect}
              />
            )}
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: 0,
          }}
        >
          {filteredVisits.length > 0 && availableVisits.length === 0 && (
            <Fade in={fadeIn} timeout={500}>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex", // Maintain flex display
                  alignItems: "center", // Align items in the center vertically
                  justifyContent: "center", // Center content horizontally
                  m: 2, // Margin
                  fontStyle: "italic", // Make the text italic
                  color: "text.secondary",
                }}
              >
                <EventAvailable sx={{ mr: 1 }} />{" "}
                {/* Material UI Filter Icon */}
                Select a date
              </Typography>
            </Fade>
          )}
          {filteredVisits.length === 0 && (
            <Fade in={fadeIn} timeout={500}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "flex", // Maintain flex display
                    alignItems: "center", // Align items in the center vertically
                    justifyContent: "center", // Center content horizontally
                    m: 2, // Margin
                    fontStyle: "italic", // Make the text italic
                    color: "text.secondary",
                  }}
                >
                  <EventBusy sx={{ mr: 1 }} />
                  {/* Material UI Filter Icon */}
                  No matching visits.
                </Typography>
                <Button
                  onClick={resetFilters}
                  sx={{
                    minWidth: { xs: "30%", sm: "150px" },
                    height: { xs: "60px", sm: "auto" },
                    textTransform: "none",
                  }}
                  disableElevation={true}
                  variant="outlined"
                  color="primary"
                >
                  Reset filters
                </Button>
              </Box>
            </Fade>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default VisitPicker;
