import React from "react";
import VisitCardV2 from "../visit/VisitCardV2";

const VisitDrawer = ({ visitId, userRole, onClose }) => {
  return (
    <>
      <VisitCardV2 visitId={visitId} userRole={userRole} onClose={onClose} />
    </>
  );
};

export default VisitDrawer;
