import { useEffect, useRef } from "react";
import { Timestamp, setDoc, doc, arrayUnion } from "firebase/firestore"; 
import { db } from "../firebase";

const useTrackVisibility = (callback, logPath) => {
  const ref = useRef(null);

  const logToFirebase = async (path) => {
    const timestamp = Timestamp.now();

    const logEntry = {
      path: path,
      timestamp: timestamp,
    };

    const storedSessionId = localStorage.getItem("sessionId");
    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            "~logs": arrayUnion(logEntry),
          },
          { merge: true }
        );
      } catch (err) {
        console.error("Error logging to Firebase:", err);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting) {
          if (logPath) {
            console.log(logPath); // Still log to console if needed
            await logToFirebase(logPath); // Log to Firebase
          }
          if (callback) {
            callback();
          }
        }
      },
      {
        root: null,
        rootMargin: "-30% 0px -70% 0px", // Move the top and bottom boundaries to the middle of the viewport
        threshold: 0, // Trigger when any part of the element crosses this boundary
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [callback, logPath]);

  return ref;
};

export default useTrackVisibility;
