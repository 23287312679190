import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import GuestHeader from "../../components/GuestHeader";
import useLogPageVisit from "../../hooks/useLogPageVisit";
import ContactSection from "./sections/ContactSection";
import ContactUsSection from "./sections/ContactUsSection";
import WebFooter from "./sections/WebFooter";

function ContactPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useLogPageVisit("/contact");

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }
  }, []);

  return (
    <div>
      <GuestHeader />
      <ContactSection />
      {/* <ContactUsSection /> */}
      <WebFooter />
    </div>
  );
}

export default ContactPage;
