import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../../firebase";
import { createCommunicationLog } from "../../../services/logServices";

import {
  AccessTime,
  Call,
  Email,
  NorthEast,
  Person,
  PhoneInTalk,
  PhoneMissed,
  Sms,
  SouthWest,
  ToggleOff,
  ToggleOn,
  Voicemail,
} from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import angryFace from "../../../assets/angry-face.png";
import happyFace from "../../../assets/happy-face.png";
import sadFace from "../../../assets/sad-face.png";
import skepticalFace from "../../../assets/skeptical-face.png";
import smilingFace from "../../../assets/smiling-face.png";

// Interaction options, sentiment, interest, and priority options
const interactionOptions = [
  {
    id: "text",
    text: "Text",
    color: "hsl(60,0%,30%)",
    icon: <Sms />,
  },
  {
    id: "call",
    text: "Call",
    color: "hsl(140,0%,30%)",
    icon: <Call />,
  },
  {
    id: "voicemail",
    text: "Voicemail",
    color: "hsl(350,0%,30%)",
    icon: <Voicemail />,
  },
  {
    id: "email",
    text: "Email",
    color: "hsl(190,0%,30%)",
    icon: <Email />,
  },
  {
    id: "in-person",
    text: "In-person",
    color: "hsl(350,0%,30%)",
    icon: <Person />,
  },
];

const sentimentOptions = [
  {
    id: "Absolutely",
    image: smilingFace,
    text: "Absolutely!",
    color: "hsl(140,100%,30%)",
  },
  { id: "Yes", image: happyFace, text: "Yes", color: "hsl(190,100%,30%)" },
  {
    id: "maybe",
    image: skepticalFace,
    text: "Maybe",
    color: "hsl(60,100%,30%)",
  },
  {
    id: "not really",
    image: sadFace,
    text: "Not really",
    color: "hsl(40,100%,30%)",
  },
  { id: "no", image: angryFace, text: "No", color: "hsl(350,100%,30%)" },
];

const interestOptions = [
  {
    id: 5,
    image: smilingFace,
    text: "5",
    color: "hsl(140,100%,30%)",
  },
  {
    id: 4,
    image: happyFace,
    text: "4",
    color: "hsl(190,100%,30%)",
  },
  {
    id: 3,
    image: skepticalFace,
    text: "3",
    color: "hsl(60,100%,30%)",
  },
  {
    id: 2,
    image: sadFace,
    text: "2",
    color: "hsl(40,100%,30%)",
  },
  {
    id: 1,
    image: angryFace,
    text: "1",
    color: "hsl(350,100%,30%)",
  },
];

const priorityOptions = [
  { id: 1, text: "P1", color: "hsl(10,100%,30%)" },
  { id: 2, text: "P2", color: "hsl(50,100%,40%)" },
  { id: 3, text: "P3", color: "hsl(100,100%,30%)" },
  { id: 4, text: "P4", color: "hsl(190,100%,30%)" },
  { id: 5, text: "P5", color: "hsl(250,100%,30%)" },
];

const tagOptions = [
  "mailbox full",
  "mailbox not setup",
  "disconnected number",
  "busy",
  "wrong number",
  "call failed",
  "did not ring",
  "hung up",
];

const buttonConfigs = [
  { action: "add", unit: "day", label: "+1 Day" },
  { action: "add", unit: "week", label: "+1 Week" },
  { action: "add", unit: "month", label: "+1 Month" },
  { action: "subtract", unit: "day", label: "-1 Day" },
  { action: "subtract", unit: "week", label: "-1 Week" },
  { action: "subtract", unit: "month", label: "-1 Month" },
];

const LogCommunication = ({
  onClose = () => {},
  idsArr,
  employee,
  client,
  collectionName,
  location,
}) => {
  const [interactionType, setInteractionType] = useState([]);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [sentiment, setSentiment] = useState("");
  const [interest, setInterest] = useState("");
  const [priority, setPriority] = useState("");
  const [madeContact, setMadeContact] = useState(false);
  const [initiatedByUser, setInitiatedByUser] = useState(true);
  const [loading, setLoading] = useState(false);
  const [communicationDate, setCommunicationDate] = useState(moment());
  const [followUpDate, setFollowUpDate] = useState(
    moment.tz("America/Los_Angeles").add(7, "days").startOf("day")
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const logData = {
        madeContact,
        collectionName,
        idsArr,
        description,
        interactionType,
        initiator: initiatedByUser ? employee : client,
        receiver: initiatedByUser ? client : employee,
        tags,
        sentiment,
        interest,
        priority,
        location,
        communicationDate,
        followUpDate,
      };

      await createCommunicationLog(logData);

      // Update the lead or member's crm document with the new followUpDate, communicationDate,
      // Get the document reference based on collection type and client.id
      const clientDocRef = doc(db, collectionName, client.id);

      console.log("collectionName: ", collectionName);
      console.log("client.id: ", client.id);

      // Prepare the updates
      const updates = {
        // [`crm.followUpDate`]: Timestamp.fromDate(followUpDate.toDate()), // Update only this field
        [`crm.lastContactedDate`]: Timestamp.fromDate(
          communicationDate.toDate()
        ), // Update only this field
        // [`crm.interest`]: interest, // Update only this field
        // [`crm.priority`]: priority, // Update only this field
      };

      // Update the client document
      await updateDoc(clientDocRef, updates);

      handleClose();
    } catch (error) {
      console.error("Error submitting log:", error);
      // Optional: Add user notification or feedback here (e.g., a toast notification)
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    // Reset variables
    setInteractionType([]);
    setDescription("");
    setTags([]);
    setMadeContact(false);
    setInitiatedByUser(true);
    setCommunicationDate(moment());

    // callback
    onClose();
  };

  const currentDate = moment(); // Get the current date

  // Calculate the difference in days, weeks, and months
  const daysDiff = followUpDate.diff(currentDate, "days");
  const weeksDiff = followUpDate.diff(currentDate, "weeks");
  const monthsDiff = followUpDate.diff(currentDate, "months");

  // Determine the closest unit of measurement
  let closestUnit;
  if (Math.abs(daysDiff) < 7) {
    closestUnit = `${Math.abs(daysDiff)} day${
      Math.abs(daysDiff) === 1 ? "" : "s"
    }`;
  } else if (Math.abs(weeksDiff) < 4) {
    closestUnit = `${Math.abs(weeksDiff)} week${
      Math.abs(weeksDiff) === 1 ? "" : "s"
    }`;
  } else {
    closestUnit = `${Math.abs(monthsDiff)} month${
      Math.abs(monthsDiff) === 1 ? "" : "s"
    }`;
  }

  console.log("priority: ", priority);

  const handleChange = (event) => {
    setInitiatedByUser(event.target.value === "myself");
  };

  const handleChangeInteraction = (optionId) => {
    setInteractionType(
      (prevTypes) =>
        prevTypes.includes(optionId)
          ? prevTypes.filter((id) => id !== optionId) // Remove if already selected
          : [...prevTypes, optionId] // Add if not selected
    );
  };

  console.log("interactionType: ", interactionType);

  const handleSelectInterest = (event) => {
    setInterest(event.target.value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DateTimePicker
            label="Communication date"
            inputFormat="MM/DD/YYYY"
            value={communicationDate}
            onChange={(newDate) => setCommunicationDate(newDate)}
            renderInput={(params) => <TextField {...params} fullWidth />}
            sx={{ width: "100%" }}
          />
          <IconButton
            onClick={() => setCommunicationDate(moment())}
            aria-label="Set to now"
            sx={{ ml: 1 }}
          >
            <AccessTime />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          {/* Initiated by user */}
          <FormControl fullWidth>
            <InputLabel id="initiated-by-label">Initiated by user</InputLabel>
            <Select
              labelId="initiated-by-label"
              label="Initiated by user"
              value={
                initiatedByUser ? "Initiated by user" : "Initiated by member"
              }
              onClick={() => setInitiatedByUser((prev) => !prev)} // Toggle on click
              readOnly // Ensures it only toggles, no dropdown options
              IconComponent={initiatedByUser ? ToggleOn : ToggleOff} // Custom icon
            >
              <MenuItem value="Initiated by user">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <NorthEast color="info" />
                  <Typography>Myself</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                value="Initiated by member"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <SouthWest color="warning" />
                  <Typography>Member</Typography>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          {/* Made contact */}
          <FormControl fullWidth>
            <InputLabel id="made-contact-label">Made contact</InputLabel>
            <Select
              labelId="made-contact-label"
              label="Made contact"
              value={madeContact ? "Made Contact" : "No Contact"}
              onClick={() => setMadeContact((prev) => !prev)} // Toggle on click
              readOnly // Ensures it only toggles, no dropdown options
              IconComponent={madeContact ? ToggleOn : ToggleOff} // Custom icon
            >
              <MenuItem value="Made Contact">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <PhoneInTalk color="success" />
                  <Typography>Yes</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                value="No Contact"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <PhoneMissed color="error" />
                  <Typography>No</Typography>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Interaction type */}
        <FormControl component="fieldset" fullWidth>
          <InputLabel
            shrink
            id="interaction-select-label"
            sx={{ backgroundColor: "white", px: 1, ml: -1 }}
          >
            Interaction type
          </InputLabel>
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)", // MUI default border color
              borderRadius: 1,
              px: 2,
              py: 1,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {interactionOptions.map((option) => (
              <Box
                key={option.id}
                sx={{
                  width: { xs: "50%", sm: "33%" }, // Two columns on mobile, 3 on desktop
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={interactionType.includes(option.id)}
                      onChange={() => handleChangeInteraction(option.id)}
                    />
                  }
                  label={option.text}
                  sx={{ flexGrow: 1 }}
                />
              </Box>
            ))}
          </Box>
        </FormControl>

        {interactionType.length > 0 && (
          <>
            {/* Description */}
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <>
              {/* Sentiment IconTextButton */}
              {/* <Typography mt={2}>Sentiment</Typography>
        <Box sx={{display: "flex", flexWrap: "wrap", gap: 1, my: 2,}}>
          {sentimentOptions.map((option) => (
            <IconTextButton
              key={option.id}
              option={option}
              isSelected={sentiment === option.text}
              onClick={(selected) => setSentiment(selected)}
            />
          ))}
        </Box> */}

              {/* Interest IconTextButton */}

              {/* <FormControl>
                    <InputLabel>Interest</InputLabel>
                    <Select
                      label="Interest"
                      value={interest}
                      onChange={handleSelectInterest}
                      renderValue={(selected) => {
                        const selectedOption = interestOptions.find(
                          (opt) => opt.id === selected
                        );
                        return (
                          <Box display="flex" alignItems="center">
                            <Box
                              sx={{
                                backgroundColor: selectedOption.color,
                                width: 18,
                                height: 18,
                                borderRadius: "50%",
                                mr: 1,
                              }}
                            />
                            <ListItemText primary={selectedOption.text} />
                            <Avatar
                              src={selectedOption.image}
                              sx={{ width: 24, height: 24, ml: "auto" }}
                            />
                          </Box>
                        );
                      }}
                      MenuProps={{
                        disablePortal: true,
                        disableAutoFocusItem: true,
                        autoFocus: false,
                      }}
                    >
                      {interestOptions.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Box
                            sx={{
                              backgroundColor: option.color,
                              width: 18,
                              height: 18,
                              borderRadius: "50%",
                              mr: 1,
                            }}
                          />
                          <ListItemText primary={option.text} />
                          <Avatar
                            src={option.image}
                            sx={{ width: 24, height: 24, ml: "auto" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
            </>
            {/* <Box>
                <DateTimePicker
                  label="Follow up date"
                  inputFormat="MM/DD/YYYY"
                  value={followUpDate}
                  onChange={(newDate) => setFollowUpDate(newDate)}
                />
                {closestUnit && (
                  <FormHelperText sx={{ ml: 2 }}>{closestUnit}</FormHelperText>
                )}
              </Box> */}
            {/* <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: 1,
                  my: 2,
                }}
              >
                {buttonConfigs.map(({ action, unit, label }) => (
                  <Box
                    key={`${action}-${unit}`}
                    sx={{ flex: "1 1 calc(33% - 10px)" }} // Adjusts button width and margin
                  >
                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{ height: "60px", textTransform: "none" }}
                      onClick={() =>
                        setFollowUpDate(followUpDate.clone()[action](1, unit))
                      }
                    >
                      {label}
                    </Button>
                  </Box>
                ))}
              </Box> */}
            {/* Tags Autocomplete */}
            <Autocomplete
              multiple
              options={tagOptions} // Use the defined tagOptions array
              freeSolo
              value={tags} // Bind to tags state
              onChange={(event, newValue) => {
                setTags(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Tags" variant="outlined" />
              )}
            />
            {/* Priority IconTextButton */}
            {/* <Typography mt={2}>Priority</Typography> */}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
          disabled={loading} // Disable the button while loading data from useMember
          color="primary"
          sx={{
            // width: "100%",
            // height: "60px",
            textTransform: "none",
            width: "100px",
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading} // Disable the button while loading data from useMember
          color="primary"
          sx={{
            // width: "100%",
            // height: "60px",
            textTransform: "none",
            width: "150px",
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Create log"
          )}
        </Button>
      </DialogActions>
    </LocalizationProvider>
  );
};

export default LogCommunication;
