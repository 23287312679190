/* global google */

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  AddCircle,
  DeleteForever,
  Draw,
  NearMe,
  RemoveCircle,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as turf from "@turf/turf";
import debounce from "lodash.debounce";

import React, { useEffect, useRef, useState } from "react";
import { getMembersInArea } from "../../services/locationServices";

class CustomRenderer {
  render({ count, position }) {
    // Calculate the scale, ensuring a minimum value
    const minScale = 7; // Set your desired minimum scale
    const scale = Math.max(minScale, Math.log(count) * 4);

    return new google.maps.Marker({
      position,
      label: {
        text: String(count),
        color: "white",
        fontSize: "12px",
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "blue",
        fillOpacity: 0.8,
        scale: scale, // Use the adjusted scale
        strokeColor: "darkblue",
        strokeWeight: 1,
      },
      clickable: false, // Disable clickability
    });
  }

  onRemove(marker) {
    marker.setMap(null);
  }
}

function douglasPeucker(points, epsilon) {
  // Find the point with the maximum distance from line between the start and end
  let maxDist = 0;
  let index = 0;
  const end = points.length - 1;
  for (let i = 1; i < end; i++) {
    const dist = perpendicularDistance(points[i], points[0], points[end]);
    if (dist > maxDist) {
      index = i;
      maxDist = dist;
    }
  }

  // If max distance is greater than epsilon, recursively simplify
  if (maxDist > epsilon) {
    const res1 = douglasPeucker(points.slice(0, index + 1), epsilon);
    const res2 = douglasPeucker(points.slice(index, end + 1), epsilon);

    // Return the joined list of simplified points
    return res1.slice(0, res1.length - 1).concat(res2);
  } else {
    // Nothing to do, return start and end points
    return [points[0], points[end]];
  }
}

function perpendicularDistance(point, lineStart, lineEnd) {
  const x0 = point.lng;
  const y0 = point.lat;
  const x1 = lineStart.lng;
  const y1 = lineStart.lat;
  const x2 = lineEnd.lng;
  const y2 = lineEnd.lat;
  const numerator = Math.abs(
    (y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1
  );
  const denominator = Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2);
  return numerator / denominator;
}

function GoogleMapsDrawingV2({
  employeeGeohash = null,
  initialBounds,
  initialCenter,
  mobileHeight = null,
  onBoundsChange = () => {},
  onWarning = () => {},
  inputHeight = null,
}) {
  const mapRef = useRef(null);
  const map = useRef(null);
  const poly = useRef(null);
  const drawing = useRef(null);

  const [locating, setLocating] = useState(false);
  const [userLocationMarker, setUserLocationMarker] = useState(null);
  const [points, setPoints] = useState([]);
  const [clusterer, setClusterer] = useState(null); // Store the clusterer reference

  const [currentPolygon, setCurrentPolygon] = useState(null);
  const drawMode = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const lastFetchCenter = useRef(initialCenter);

  // Function to fetch members near the center
  const fetchMembersNearCenter = async (center) => {
    try {
      console.log("fetchMembersNearCenter - Fetching members near center");
      const members = await getMembersInArea({ center }, 10);
      setPoints(members);
    } catch (error) {
      console.error("Error fetching members: ", error);
    }
  };

  // Debounced function to fetch members after movement
  const debouncedFetchMembers = debounce((currentCoords) => {
    fetchMembersNearCenter(currentCoords);
    lastFetchCenter.current = currentCoords; // Update last fetched center
  }, 2000); // 2-second debounce

  // Handle map movement
  const handleMapMove = () => {
    console.log("handleMapMove - starting");

    if (!map.current) return;

    const currentCenter = map.current.getCenter();
    const currentCoords = {
      lat: currentCenter.lat(),
      lng: currentCenter.lng(),
    };

    const lastCoords = lastFetchCenter.current;
    const distance = turf.distance(
      [lastCoords.lng, lastCoords.lat],
      [currentCoords.lng, currentCoords.lat],
      { units: "miles" }
    );

    console.log("handleMapMove - distance: ", distance);

    if (distance > 10) {
      debouncedFetchMembers(currentCoords); // Call debounced fetch function
    }
  };

  const mapStyle = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }], // Hides points of interest
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [{ visibility: "off" }], // Hides administrative labels
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [{ visibility: "off" }], // Hides administrative labels
    },
    // {
    //   featureType: "administrative",
    //   elementType: "labels",
    //   stylers: [{ visibility: "off" }], // Hides administrative labels
    // },
    // {
    //   featureType: "road",
    //   elementType: "labels",
    //   stylers: [{ visibility: "off" }], // Hides road labels
    // },
  ];

  useEffect(() => {
    const initializeCurrentPolygon = () => {
      // Exit early if currentPolygon is already initialized
      if (currentPolygon) return;

      // Ensure initialBounds has enough points to form a polygon
      if (initialBounds && initialBounds.length >= 3) {
        // Check if the polygon is closed; if not, close it
        const bounds = [...initialBounds];
        if (
          bounds[0].lat !== bounds[bounds.length - 1].lat ||
          bounds[0].lng !== bounds[bounds.length - 1].lng
        ) {
          bounds.push(bounds[0]); // Close the loop by adding the first point to the end
        }

        // Convert to Turf.js format and create a polygon
        const coordinates = bounds.map((p) => [p.lng, p.lat]);
        const polygon = turf.polygon([coordinates]);

        // Set this polygon as the currentPolygon
        setCurrentPolygon(polygon);
      }
    };

    initializeCurrentPolygon();
  }, [initialBounds]);

  // useEffect(() => {
  //   if (!initialCenter || !map.current) return; // Ensure map is ready and initialCenter is available

  //   const center = map.current.getCenter(); // Get LatLng object
  //   const lat = center.lat(); // Get latitude
  //   const lng = center.lng(); // Get longitude

  //   const fetchMemberCoords = async () => {
  //     try {
  //       const memberCoords = await getMembersInArea(
  //         { geohash: employeeGeohash, center: { lat, lng } }, // Pass the updated center
  //         10
  //       );
  //       setPoints(memberCoords);
  //       // console.log("memberCoords: ", memberCoords);
  //     } catch (error) {
  //       console.error("Error getting member coordinates: ", error);
  //       setPoints([]); // Set to empty array in case of error
  //     }
  //   };

  //   fetchMemberCoords();
  // }, [initialCenter, employeeGeohash, locating]); // Depend on initialCenter and employeeGeohash

  const disableMapOptions = () => {
    if (map.current) {
      map.current.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        gestureHandling: "none", // Disable gesture handling when drawing
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        clickableIcons: false, // Disable clicks on POIs
        disableDoubleClickZoom: true,
        styles: mapStyle, // Apply custom styles
      });
    }
  };

  const enableMapOptions = () => {
    if (map.current) {
      map.current.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
        fullscreenControl: false,
        streetViewControl: false,
        clickableIcons: false, // Disable clicks on POIs
        disableDoubleClickZoom: true,
        gestureHandling: "auto", // Re-enable gesture handling after drawing
        styles: mapStyle, // Apply custom styles
      });
    }
  };

  const handleWarning = (warning) => {
    onWarning(warning);
  };

  const navigateToCurrentLocation = () => {
    setLocating(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        map.current.setCenter(pos);
        map.current.setZoom(12); // You can adjust the zoom level as needed
        setLocating(false);
      });
    }
  };

  const getUserLocation = () => {
    setLocating(true); // Start locating process

    if (navigator.geolocation && !userLocationMarker) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Clear existing marker if it exists
          if (userLocationMarker) {
            userLocationMarker.setMap(null);
          }

          // Create a new marker at the current location
          const marker = new google.maps.Marker({
            position: pos,
            map: map.current,
            clickable: false, // Disable clickability for the marker
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#4285F4",
              fillOpacity: 1,
              scale: 8,
              strokeColor: "white",
              strokeWeight: 2,
            },
          });
          setUserLocationMarker(marker);
          setLocating(false); // Successfully located, stop locating
        },
        () => {
          setLocating(false); // Failed to locate, stop locating
        }
      );
    } else {
      setLocating(false); // Geolocation is not supported by the browser
    }
  };

  useEffect(() => {
    console.log("Triggered CURRENT POLYGON");
    if (map.current && currentPolygon) {
      console.log("FLAG 1");
      // Extract coordinates from the Turf.js polygon
      const coordinates = currentPolygon.geometry.coordinates;

      let paths = [];
      if (currentPolygon.geometry.type === "Polygon") {
        console.log("FLAG 2");

        paths = coordinates[0].map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));
      } else if (currentPolygon.geometry.type === "MultiPolygon") {
        // Handle MultiPolygons
        paths = coordinates.map((polygon) =>
          polygon[0].map((coord) => ({
            lat: coord[1],
            lng: coord[0],
          }))
        );
      }

      // Clear existing polygon if it exists
      if (poly.current) {
        console.log("FLAG 3");

        poly.current.setMap(null);
        poly.current = null;
      }

      // Create a new Google Maps Polygon
      poly.current = new google.maps.Polygon({
        paths: paths,
        map: map.current,
        clickable: false,
      });

      console.log("FLAG 4");

      // Adjust the map to fit the new polygon
      const bounds = new google.maps.LatLngBounds();
      if (Array.isArray(paths[0][0])) {
        console.log("FLAG 5A");

        // For MultiPolygons
        paths.forEach((polygon) => {
          polygon.forEach((point) => {
            bounds.extend(new google.maps.LatLng(point.lat, point.lng));
          });
        });
      } else {
        console.log("FLAG 5B");

        // For single Polygons
        paths.forEach((point) => {
          bounds.extend(new google.maps.LatLng(point.lat, point.lng));
        });
      }
      map.current.fitBounds(bounds);
    } else if (poly.current) {
      console.log("FLAG 6");

      // If there is no currentPolygon, remove existing polygon from the map
      poly.current.setMap(null);
      poly.current = null;
    }
  }, [currentPolygon]);

  const startDrawing = (mode) => {
    drawMode.current = mode;
    disableMapOptions();
    setIsDrawing(true);

    mapRef.current.addEventListener("mousedown", drawFreeHand, {
      passive: false,
    });
    mapRef.current.addEventListener("touchstart", drawFreeHand, {
      passive: false,
    });
  };

  const drawFreeHand = (event) => {
    console.log("drawMode.current in drawFreeHand 1: ", drawMode.current);
    event.preventDefault();

    // Create a new polygon instead of a polyline
    drawing.current = new google.maps.Polygon({
      map: map.current,
      clickable: false,
      strokeColor: drawMode.current === "add" ? "green" : "red",
      strokeOpacity: 1.0,
      strokeWeight: 2,
      fillColor: drawMode.current === "add" ? "green" : "red",
      fillOpacity: 0.35,
    });

    const moveHandler = (e) => {
      const latLng =
        e.latLng ||
        map.current
          .getProjection()
          .fromPointToLatLng(
            new google.maps.Point(e.touches[0].clientX, e.touches[0].clientY)
          );
      drawing.current.getPath().push(latLng);
    };

    const endDrawing = () => {
      google.maps.event.clearListeners(map.current, "mousemove");
      google.maps.event.clearListeners(map.current, "touchmove");

      const path = drawing.current.getPath();
      drawing.current.setMap(null);

      // **Add this check**
      if (path.getLength() < 2) {
        drawing.current.setMap(null);
        exitDrawing();
        return; // Not enough points to form a polygon
      }

      // Convert path to array and apply Douglas-Peucker simplification
      const simplifiedPath = douglasPeucker(
        path.getArray().map((p) => ({ lat: p.lat(), lng: p.lng() })),
        0.001
      );

      const coordinates = [
        ...simplifiedPath.map((p) => [p.lng, p.lat]),
        [simplifiedPath[0].lng, simplifiedPath[0].lat], // Close the loop
      ];

      const newPolygon = turf.polygon([coordinates]);

      let resultPolygon;
      if (drawMode.current === "add") {
        if (currentPolygon) {
          const featureCollection = turf.featureCollection([
            currentPolygon,
            newPolygon,
          ]);

          // Check if polygons overlap
          const polygonsOverlap = turf.booleanOverlap(
            currentPolygon,
            newPolygon
          );

          console.log("polygonsOverlap: ", polygonsOverlap);

          if (polygonsOverlap) {
            resultPolygon = turf.union(featureCollection);
            console.log("==> resultPolygon: ", resultPolygon);
          } else {
            resultPolygon = currentPolygon;
            handleWarning(
              "The shapes do not overlap and will result in a multipolygon."
            );
          }
        } else {
          resultPolygon = newPolygon;
        }
      } else if (drawMode.current === "subtract") {
        if (currentPolygon) {
          const featureCollection = turf.featureCollection([
            currentPolygon,
            newPolygon,
          ]);

          resultPolygon = turf.difference(featureCollection);

          if (resultPolygon && resultPolygon.geometry.type === "MultiPolygon") {
            // Reset if it causes 2 shapes.
            resultPolygon = currentPolygon;
            handleWarning(
              "The subtraction results in multiple separate shapes."
            );
          }
        } else {
          resultPolygon = null;
        }
      }

      if (resultPolygon) {
        console.log("==> SETTING CURRENT POLYGON: ");

        setCurrentPolygon(resultPolygon);
        const coords = resultPolygon.geometry.coordinates[0].map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));
        // initializePolygonWithBounds(coords);
        onBoundsChange(coords);
      } else {
        console.log("==> NO RESULT: ");
        // if (poly.current) {
        //   poly.current.setMap(null);
        //   poly.current = null;
        // }
        setCurrentPolygon(null);
        onBoundsChange([]);
      }

      // Clear the drawing listeners from the map
      setIsDrawing(false);
      enableMapOptions();
    };

    // map.current.addListener("mouseup", endDrawing);
    // map.current.addListener("touchend", endDrawing);
    google.maps.event.addListener(map.current, "mouseup", endDrawing);
    google.maps.event.addListener(map.current, "touchend", endDrawing);

    // map.current.addListener("mousemove", moveHandler);
    // map.current.addListener("touchmove", moveHandler);
    google.maps.event.addListener(map.current, "mousemove", moveHandler);
    google.maps.event.addListener(map.current, "touchmove", moveHandler);
  };

  const initialize = () => {
    const center = new google.maps.LatLng(
      initialCenter.lat || 48.47,
      initialCenter.lng || -122.7
    );

    const mapOptions = {
      zoom: 12,
      center: center,
      streetViewControl: false,
      fullscreenControl: false,
      zoomFractional: true, // Enables fractional zoom (Google Maps API allows fractional zoom natively)
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false, // Disable clicks on POIs
      styles: mapStyle, // Apply custom styles
    };
    map.current = new google.maps.Map(mapRef.current, mapOptions);
    const newClusterer = new MarkerClusterer({
      map: map.current,
      markers: [], // Start with an empty set of markers
      renderer: new CustomRenderer(),
    });
    setClusterer(newClusterer); // Set the clusterer reference

    // Fetch members immediately on load
    fetchMembersNearCenter(initialCenter);

    // Add event listener for map movement
    map.current.addListener("bounds_changed", handleMapMove);
  };

  useEffect(() => {
    initialize();
    getUserLocation();
    return () => {
      debouncedFetchMembers.cancel(); // Clean up debounce on unmount
    };
  }, []);

  useEffect(() => {
    if (clusterer && points.length > 0) {
      // Clear previous markers in the clusterer
      clusterer.clearMarkers();

      // Create new markers and add to clusterer
      const markers = points.map((point) => {
        return new google.maps.Marker({
          position: { lat: point.lat, lng: point.lng },
          clickable: false, // Disable clickability for the marker
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5,
            fillColor: point.status === "active" ? "blue" : "red",
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: point.status === "active" ? "darkblue" : "darkred",
          },
        });
      });

      // Add the new markers to the clusterer
      clusterer.addMarkers(markers);
    }
  }, [points, clusterer]);

  const buttonStyle = {
    bgcolor: "white",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
    position: "absolute",
    top: 50,
    left: 0, // Adjust based on the size of the first button
    m: { xs: 1, sm: 2 },
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
    height: "42px",
    justifyContent: "center",
    pr: { xs: 0, sm: "15px" },
    pl: { xs: 0, sm: "10px" },
    textTransform: "none",
  };

  const clearCurrentShape = () => {
    if (poly.current) {
      poly.current.setMap(null);
      poly.current = null;
    }
    setCurrentPolygon(null);
    onBoundsChange([]); // Clear bounds as well
  };

  const exitDrawing = () => {
    // Clear any partially drawn shape
    if (drawing.current) {
      drawing.current.setMap(null);
      drawing.current = null;
    }

    // Remove the drawing event listeners
    google.maps.event.clearListeners(map.current, "mousemove");
    google.maps.event.clearListeners(map.current, "touchmove");
    google.maps.event.clearListeners(map.current, "mousedown");
    google.maps.event.clearListeners(map.current, "mouseup");
    google.maps.event.clearListeners(map.current, "touchend");

    // Reset the drawing state
    setIsDrawing(false);
    enableMapOptions(); // Re-enable map interactions
  };

  return (
    <div>
      <Box
        sx={{
          borderRadius: { xs: 0, sm: "15px" },
          overflow: "hidden",
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box
          ref={mapRef}
          sx={{
            height: inputHeight
              ? inputHeight
              : {
                  xs: mobileHeight
                    ? mobileHeight
                    : "calc(100svh - 88px - 80px - 20px - 2vh)",
                  sm: "calc(100svh - 64px - 80px - 72px - 54px)",
                },
            width: "100%",
          }}
        />
        {isDrawing && (
          <Box
            sx={{
              position: "absolute",
              top: 4,
              left: 4,
              right: 4,
              backgroundColor:
                drawMode.current === "add"
                  ? "hsla(120, 100%, 50%, 0.35)"
                  : drawMode.current === "subtract"
                  ? "hsla(0, 100%, 50%, 0.35)"
                  : "yellow",
              display: "flex",
              justifyContent: "center",
              borderRadius: "15px",
              py: 1,
              // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              // border:
              //   drawMode.current === "add"
              //     ? "1px solid hsla(120, 100%, 40%, 0.95)"
              //     : drawMode.current === "subtract"
              //     ? "1px solid hsla(0, 100%, 40%, 0.95)"
              //     : "yellow",
              boxSizing: "border-box",
              zIndex: 1000,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                px: 1,
                fontWeight: "600",
                py: 0.5,
                color: "white",
                width: "fit-content",
                borderRadius: "10px",
                backgroundColor: (theme) =>
                  drawMode.current === "add"
                    ? theme.palette.success.main
                    : drawMode.current === "subtract"
                    ? theme.palette.error.main
                    : "yellow",
              }}
            >
              {drawMode.current === "add" ? "Adding area" : "Subtracting area"}
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          disabled={isDrawing}
          color="inherit"
          sx={{ ...buttonStyle, top: 50, gap: 0 }}
          onClick={() => startDrawing("add")}
        >
          {/* <Box position="relative"> */}
          <Draw color="primary" />
          <AddCircle color="success" />
          {/* </Box> */}
        </Button>
        <Button
          disabled={isDrawing}
          variant="contained"
          color="inherit"
          sx={{ ...buttonStyle, top: 100, gap: 0 }}
          onClick={() => startDrawing("subtract")}
        >
          <Draw color="primary" />
          <RemoveCircle color="error" />
        </Button>

        <Button
          disabled={isDrawing}
          variant="contained"
          color="inherit"
          sx={{ ...buttonStyle, top: 150 }}
          onClick={navigateToCurrentLocation}
        >
          {locating ? (
            <>
              <CircularProgress size={24} thickness={6} color={"inherit"} />
            </>
          ) : (
            <>
              <NearMe color="primary" />
            </>
          )}
        </Button>
        <Button
          disabled={isDrawing}
          variant="contained"
          color="inherit"
          sx={{ ...buttonStyle, top: 200 }}
          onClick={clearCurrentShape}
        >
          <DeleteForever sx={{ color: "text.secondary" }} />
        </Button>
      </Box>
    </div>
  );
}

export default GoogleMapsDrawingV2;
