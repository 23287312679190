import { Box, Typography } from "@mui/material";
import React from "react";

const EmployeeBio = ({ employeeData = {} }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
      <Typography
        variant="body2"
        paragraph
        sx={{ backgroundColor: "#F5F5F5", p: 2, borderRadius: "15px" }}
      >
        <strong>About {employeeData.firstName}</strong>
        <br />
        {employeeData.bio}
      </Typography>
    </Box>
  );
};

export default EmployeeBio;
