import { AddAPhotoRounded, CloudUpload } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Fab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import imageCompression from "browser-image-compression";
import { updateProfile } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { storage } from "../../firebase";
import { useAuth } from "../../providers/AuthProvider";

// Define the styled component with the same styles you used for ListItemButton
const DropzoneArea = styled(Box)(({ theme }) => ({
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  padding: 16,
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: "white",
  transform: "scale(1)", // Default scale
  boxShadow: "none", // Default no shadow
  transition: "background-color 0.3s, box-shadow 0.3s, transform 0.3s",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.2)",
    backgroundColor: "white",
  },
  "&:hover .hoverText": {
    opacity: 1, // Only show text on hover
  },
  "&:focus-visible, &:focus": {
    backgroundColor: "white",
  },
  "&:active": {
    backgroundColor: "white", // Consistent with other states
  },
  "&.Mui-focusVisible": {
    backgroundColor: "white", // Explicitly targeting the class that might be causing the issue
  },
}));

function AvatarField({ avatarUrl, onAvatarUpload = () => {} }) {
  const { currentUser } = useAuth(); // Use the custom hook to get the current user

  const [photoURL, setPhotoURL] = useState(avatarUrl); // Initialize with avatarUrl prop
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [progressVisible, setProgressVisible] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const id = currentUser && currentUser.uid;

  // This effect runs whenever the avatarUrl prop changes.
  useEffect(() => {
    // Check if the incoming avatarUrl is not null or undefined
    if (avatarUrl != photoURL && avatarUrl !== "") {
      setPhotoURL(avatarUrl); // Update the photoURL state
    }
  }, [avatarUrl]);

  // Fade the progress bar once we reach 100%
  useEffect(() => {
    if (uploadProgress >= 100) {
      setTimeout(() => setProgressVisible(false), 1000); // Fade out after 1 second
      setTimeout(() => setUploadProgress(0), 1500); // Fade out after 1 second
    }
  }, [uploadProgress]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!currentUser) {
        console.error("No authenticated user available.");
        return;
      }

      // Reset progress and simulate initial upload progress
      setPhotoURL(null);
      setProgressVisible(true);
      setUploadProgress(0);
      let simulatedProgress = 0;

      const simulateUploadProgress = () => {
        if (uploadProgress < 50 && photoURL === null) {
          simulatedProgress = uploadProgress + 10; // Adjust the increment and interval as needed
          setUploadProgress(simulatedProgress);
          setTimeout(simulateUploadProgress, 100); // Adjust the timing as needed
        }
      };

      simulateUploadProgress();

      const file = acceptedFiles[0];

      console.log("originalFile instanceof Blob", file instanceof Blob); // true
      console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

      // Compress the image file
      const options = {
        maxSizeMB: 0.15, // (max file size in MB, adjust as needed)
        maxWidthOrHeight: 500, // (compressed file's maximum dimension in pixels)
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);

        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        // Proceed with the upload...
        const metadata = {
          contentType: compressedFile.type,
        };

        const storageRef = ref(storage, "avatars/" + id);
        const uploadTask = uploadBytesResumable(
          storageRef,
          compressedFile,
          metadata
        );

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Adjust to continue from simulated progress
            const progress =
              50 + (snapshot.bytesTransferred / snapshot.totalBytes) * 50;
            setUploadProgress(progress);

            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error("Upload failed:", error);
          },
          () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              updateProfile(currentUser, {
                photoURL: downloadURL,
              })
                .then(() => {
                  console.log("User profile updated!");
                  setPhotoURL(downloadURL); // Update the local photoURL state
                  setUploadProgress(100);
                  onAvatarUpload(downloadURL); // Call onAvatarUpload prop to pass URL up to parent
                })
                .catch((error) => {
                  console.error("Error updating user profile:", error);
                });
            });
          }
        );
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    },
    [currentUser, onAvatarUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  return (
    <>
      <DropzoneArea
        {...getRootProps()}
        sx={{
          border: isMobile ? "1px dashed rgba(0,0,0,.3)" : "none", // Apply dashed border if mobile

          backgroundColor: isDragActive
            ? theme.palette.action.selected
            : "inherit",
        }}
      >
        <input {...getInputProps()} />
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          {/* {uploadProgress > 0 && uploadProgress < 100 && ( */}
          <CircularProgress
            variant="determinate"
            value={uploadProgress}
            size={96} // Make the CircularProgress slightly larger than the Avatar
            thickness={2} // Adjust thickness to your liking
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
              transition: "opacity 0.5s ease-out", // Smooth transition for opacity
              opacity: progressVisible ? 1 : 0, // Fade out when progress is not visible
              circle: {
                strokeLinecap: "round", // This makes the progress bar slightly rounded, optional
              },
            }}
          />
          {/* )} */}
          <Avatar
            src={photoURL}
            alt="User Avatar"
            sx={{
              width: 96,
              height: 96,
            }}
          />
          <Fab
            color="primary"
            size="small"
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: 10,
              opacity: uploadProgress === 0 && !progressVisible ? 1 : 0,
              transition: "opacity 0.3s",
            }}
          >
            <AddAPhotoRounded />
          </Fab>
        </Box>

        {/* Adjust the size here */}
        {isMobile ? (
          <Box
            sx={{
              zIndex: 2, // Ensure it's visible above the avatar but below the cursor
              display: "flex",
              flexDirection: "row",
              px: 5,
              alignItems: "center",
              gap: 1,
              color: theme.palette.grey[500],
            }}
          >
            <CloudUpload sx={{ fontSize: "2rem" }} />
            <Typography sx={{ fontSize: "1rem" }} variant="body2">
              Tap to upload
            </Typography>
          </Box>
        ) : (
          <Box
            className="hoverText"
            sx={{
              opacity: isDragActive ? 1 : 0,
              zIndex: 2, // Ensure it's visible above the avatar but below the cursor
              pointerEvents: "none", // Allows clicking "through" the text
              transition: "opacity 0.3s",
              display: "flex",
              flexDirection: "row",
              px: 5,
              alignItems: "center",
              gap: 1,
              color: theme.palette.grey[500],
            }}
          >
            <CloudUpload sx={{ fontSize: "2rem" }} />
            <Typography sx={{ fontSize: "1rem" }} variant="body2">
              {isDragActive ? "drop photo to upload" : "or drag and drop"}
            </Typography>
          </Box>
        )}
      </DropzoneArea>
    </>
  );
}

export default AvatarField;
