import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import {
  Alert,
  DialogContent,
  Divider,
  Icon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import moment from "moment";
import React, { useState } from "react";

const DiscountsList = ({
  discounts,
  timeZoneId = "America/Los_Angeles",
  memberId,
  memberPhone = "",
  isAdmin = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openApplyDiscount, setOpenApplyDiscount] = useState(false);

  const getIcon = (type) => {
    switch (type) {
      case "percent":
        return <PercentIcon />;
      case "dollars":
        return <AttachMoneyIcon />;
      case "hours":
        return <AccessTimeIcon />;
      default:
        return null;
    }
  };

  const formatDate = (timestamp) => {
    return timestamp
      ? moment(timestamp.toDate()).tz(timeZoneId).format("MM/DD/YY")
      : "";
  };

  const formatAmount = (amount, type) => {
    if (type === "hours") {
      return `${amount} hour${amount !== 1 ? "s" : ""}`;
    }
    return `${amount} ${type}`;
  };

  const isExpired = (discount) => {
    if (!discount.expiration) return null;
    const discountDate = moment(discount.expiration.toDate()).tz(timeZoneId);
    const expired = discountDate.isBefore(moment().tz(timeZoneId));
    return expired;
  };

  // Early return if discounts data is not loaded

  console.log("discounts: ", discounts);

  // if (!discounts) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "100%",
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  return (
    <>
      <DialogContent>
        {discounts && (
          <List>
            {Object.entries(discounts).map(([key, discount], index) => {
              const expired = isExpired(discount);
              return (
                <>
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      my: 1,
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      {!isMobile && (
                        <Icon sx={{ mr: 1 }}>{getIcon(discount.type)}</Icon>
                      )}

                      <Box>
                        <Typography variant="subtitle2">
                          {discount.description}
                        </Typography>
                        <Typography variant="body2">
                          {formatAmount(discount.amount, discount.type)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flexShrink: 1,
                        display: "flex",
                        flexDirection: "column",
                        ml: 1,
                      }}
                    >
                      {discount.used ? (
                        <Alert
                          icon={false}
                          color="primary"
                          sx={{ ml: 1, py: 0 }}
                        >
                          <Typography variant="caption">
                            Used: {formatDate(discount.used)}
                          </Typography>
                        </Alert>
                      ) : expired ? (
                        <Alert icon={false} color="error" sx={{ ml: 1, py: 0 }}>
                          <Typography variant="caption">
                            Expired: {formatDate(discount.expiration)}
                          </Typography>
                        </Alert>
                      ) : (
                        <Alert
                          icon={false}
                          color="success"
                          sx={{ ml: 1, py: 0 }}
                        >
                          <Box display="flex" flexDirection={"column"}>
                            <Typography variant="caption">
                              Created: {formatDate(discount.created)}
                            </Typography>
                            <Typography variant="caption">
                              Expires:{" "}
                              {discount.expiration
                                ? formatDate(discount.expiration)
                                : "never"}
                            </Typography>
                          </Box>
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  {index !== Object.keys(discounts).length - 1 && <Divider />}
                </>
              );
            })}
          </List>
        )}
      </DialogContent>
    </>
  );
};

export default DiscountsList;
