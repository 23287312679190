// BasicCard.js
import { Box, Typography } from "@mui/material";
import React from "react";
import useTrackVisibility from "../../hooks/useTrackVisibility";

const BasicCard = ({ section, index, pageName = "" }) => {
  const ref = useTrackVisibility(() => {},
  `${pageName}/cards/"${section.title}"`); // Pass the logMessage here

  return (
    <Box
      ref={ref} // Attach the ref from the custom hook
      sx={{
        backgroundColor: "#fff",
        p: { xs: 2, sm: 8 },
        mb: { xs: 4, sm: 6 },
        borderRadius: { xs: "15px", sm: "50px" },
        boxShadow: section.shadow,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
      }}
    >
      {/* Image on the left */}
      <Box
        component="img"
        src={section.image}
        alt={section.title}
        sx={{
          maxWidth: "100%",
          maxHeight: { xs: "120px", sm: "150px", md: "200px" },
          borderRadius: "8px",
          flex: "0.25 0 auto",
          mr: { md: 4, xs: 0 },
          mb: { xs: 2, md: 0 },
        }}
      />

      {/* Text on the right */}
      <Box
        sx={{
          flex: "1 1 auto",
          textAlign: { xs: "left", md: "left" },
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          {section.title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {section.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default BasicCard;
