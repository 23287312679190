import React from "react";
import { styled } from "@mui/material/styles";
import { Box, ListItemButton } from "@mui/material";

// Styled component for Box
const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  width: "100%",
  backgroundColor: "white",
  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",

}));

// Styled component for ListItemButton
const StyledListItemButton = styled(ListItemButton)(
  ({ theme, block = false }) => ({
    padding: theme.spacing(2),
    borderRadius: "15px",
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    pointerEvents: block ? "none" : "auto",
  })
);

const HomeCard = ({ onClick, disabled, children }) => {
  return (
    <StyledBox>
      <StyledListItemButton onClick={onClick} block={disabled}>
        {children}
      </StyledListItemButton>
    </StyledBox>
  );
};

export default HomeCard;
