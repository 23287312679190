import { Box, Skeleton, Typography } from "@mui/material";

const CountdownTimer = ({
  timeLeft,
  showColons = false,
  showBox = true,
  timerSize = "3rem",
  timerWeight = "700",
  timerColor = "primary",
  showLabels = true,
  showZeros = true,
  timerWidth = "60px",
  labelsCompact = false,
  labelSize="0.875rem"
}) => {
  const formatTime = (timeInSeconds) => {
    if (timeInSeconds <= 0) {
      return { hours: null, minutes: null, seconds: null };
    }

    const hours = Math.floor(timeInSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");

    return { hours, minutes, seconds };
  };

  const { hours = null, minutes = null, seconds = null } = formatTime(timeLeft);

  const renderTimeSegment = (value, label) => (
    <Box
      sx={{
        width: timerWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(showBox && {
          mx: 5,
        }),
        gap: showBox ? "4px" : "0px",
      }}
    >
      <Typography
        component="div"
        align="center"
        color={timerColor}
        fontSize={timerSize}
        fontWeight={timerWeight}
        sx={{
          ...(showBox && {
            p: 2,
            borderRadius: "15px",
            backgroundColor: "hsla(190, 100%, 95%, 1)",
            width: "80px",
          }),
        }}
      >
        {value ? (
          value
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "2px",
              height: "100%",
            }}
          >
            <Skeleton width={"20px"} />
            <Skeleton width={"20px"} />
          </Box>
        )}
      </Typography>
      {showLabels && (
        <Typography
          variant="body1"
          color={timerColor}
          component="div"
          fontWeight={"400"}
          fontSize={labelSize}
          sx={{
            mt: labelsCompact ? "-6px" : 0,

          }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box display="flex">
        {(hours > 0 || showZeros) && (
          <>
            {renderTimeSegment(hours, labelsCompact ? "hr" : "Hours")}
            {showColons && (
              <Typography
                variant="h4"
                color={timerColor}
                fontSize={timerSize}
                component="span"
                sx={{ mx: 1, mt: 0.25 }}
              >
                :
              </Typography>
            )}
          </>
        )}
        {renderTimeSegment(minutes, labelsCompact ? "min" : "Minutes")}
        {showColons && (
          <Typography
            variant="h4"
            color={timerColor}
            fontSize={timerSize}
            component="span"
            sx={{ mx: 1, mt: 0.25 }}
          >
            :
          </Typography>
        )}

        {renderTimeSegment(seconds, labelsCompact ? "sec" : "Seconds")}
      </Box>
    </Box>
  );
};

export default CountdownTimer;
