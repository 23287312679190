import React, { useEffect } from "react";
import {
  TextField,
  Button,
  Fade,
  CircularProgress,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Clear, Google, Phone, PhoneIphone } from "@mui/icons-material";

const EmailInput = ({
  onNext,
  values,
  handleChange,
  onSmsLogin,
  errors,
  loading,
  handleGoogleSignIn,
  gLoading,
}) => {
  // Load email from localStorage when the component mounts
  useEffect(() => {
    const storedEmail = localStorage.getItem("lastUsedEmail");
    if (storedEmail) {
      handleChange({ target: { name: "email", value: storedEmail } });
    }
  }, []);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    handleChange({ target: { name: "email", value: newEmail } });
    localStorage.setItem("lastUsedEmail", newEmail); // Update localStorage
  };

  const clearEmail = () => {
    handleChange({ target: { name: "email", value: "" } });
    localStorage.removeItem("lastUsedEmail"); // Clear localStorage
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (event, submissionType) => {
    event.preventDefault();
    if (!isValidEmail(values.email)) {
      handleChange({
        target: {
          name: "email",
          value: values.email,
          error: "Invalid email format",
        },
      });
    } else {
      if (submissionType === "phone") {
        onSmsLogin();
      } else if (submissionType === "password") {
        onNext(2);
      }
    }
  };

  return (
    <>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        name="email"
        type="email"
        value={values.email}
        onChange={handleEmailChange}
        error={!!errors.email}
        helperText={errors.email || " "}
        sx={{ mb: 1 }}
        InputProps={{
          endAdornment: values.email && (
            <InputAdornment position="end">
              <IconButton onClick={clearEmail}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={loading || !isValidEmail(values.email)} // Disabled if loading or email is invalid
        startIcon={<PhoneIphone />} // Add the Google icon here
        onClick={(e) => handleSubmit(e, "phone")}
        fullWidth
        sx={{ width: "100%", height: "56px" }} // Added marginTop to push the button to the bottom
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Login with Phone"
        )}
      </Button>
      <Divider sx={{ width: "100%", my: 2 }}>
        <Typography
          variant="body2"
          component="span"
          color="textSecondary"
          mx={2}
        >
          or
        </Typography>
      </Divider>
      <Button
        variant="outlined"
        onClick={(e) => handleSubmit(e, "password")}
        disabled={loading || !isValidEmail(values.email)} // Disabled if loading or email is invalid
        style={{ width: "100%", height: "56px" }} // Added marginTop to push the button to the bottom
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Login with Password"
        )}
      </Button>
      {/* <Button
        variant="outlined"
        color="primary"
        startIcon={<Google />} // Add the Google icon here
        onClick={handleGoogleSignIn}
        fullWidth
        sx={{ width: "100%", height: "56px" }} // Added marginTop to push the button to the bottom
      >
        {gLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Continue with Google"
        )}
      </Button> */}
    </>
  );
};

export default EmailInput;
