export const extractPhoneNumber = (phone) => {
  // If you pass in an empty string, the function will return an empty string.

  // Remove all non-numeric characters
  const digits = phone.replace(/\D/g, "");

  // Extract the last 10 digits
  return digits.slice(-10);
};

export const formatPhoneNumber = (phone) => {
  // Handle undefined or null phone values
  if (!phone) {
    return "n/a";
  }

  // Convert the phone number to a string and extract the last 10 digits
  const phoneStr = phone.toString().replace(/\D/g, "").slice(-10);

  // Format the phone number as (XXX) XXX-XXXX
  const areaCode = phoneStr.slice(0, 3);
  const centralOfficeCode = phoneStr.slice(3, 6);
  const lineNumber = phoneStr.slice(6);

  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};
