import { Box, CardMedia, Typography, useTheme } from "@mui/material";
import React from "react";
import component_img from "../../../assets/linked-lives-senior-help.jpeg";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

const OurMissionSection = ({ handleScroll, pageName = "" }) => {
  const ref = useTrackVisibility(() => {}, `${pageName}/"our mission"`); // Pass the logMessage here

  // const bulletPoints = [
  //   {
  //     text: "We cherish our family roots and are dedicated to building a service where everyone is cared for like family.",
  //     Icon: PlaylistAddCheck, // Replace with your chosen icon
  //   },
  //   {
  //     text: "Our family values drive us to connect seniors with trustworthy, dependable, and excellent helpers.",
  //     Icon: PhonelinkRing, // Replace with your chosen icon
  //   },
  //   {
  //     text: "Through these connections, we believe our experiences are enriched, and our society strengthens",
  //     Icon: AccessTime, // Replace with your chosen icon
  //   },
  // ];
  const theme = useTheme();

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row-reverse" },
        alignItems: "center",
        maxWidth: "1000px",
        mx: { xs: 2, sm: "auto" },
        mb: { xs: 0, sm: 6 },
        mt: { xs: 4, sm: 10 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: 1, sm: 4 },
          flex: 1, // This allows the box to grow and take up equal space
          maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h4.fontSize", sm: "h4.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 2,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
          }}
        >
          Our Mission
        </Typography>
        <Typography variant="h6" fontWeight="500" mb={4}>
          <span
            style={{
              fontStyle: "italic",
            }}
          >
            “To foster meaningful, intergenerational relationships through
            excellent, local, and dependable help.”
          </span>
        </Typography>
        <Typography variant="body1" mb={4}>
          We're more than just a household help company. We're involved in the
          community, working to uplift and support everyone.
        </Typography>
        {/* <List>
          {bulletPoints.map(({ text, Icon }, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
        {/* <Button
          variant="contained"
          disableElevation
          onClick={() => handleScroll("signup")}
          sx={{
            width: { xs: "100%", sm: "300px" },
            height: "56px",
            textTransform: "none",
            mx: "auto",
          }}
        >
          Join Our Community
        </Button> */}
      </Box>
      <CardMedia
        component="img"
        image={component_img}
        alt="Family at senior event"
        sx={{
          width: { xs: "100%", sm: "50%" }, // Ensures the image takes up half the width on sm screens and up
          height: { sm: "auto" }, // Adjusts height automatically to maintain aspect ratio
          aspectRatio: { xs: "3 / 2", sm: "inherit" }, // Maintains aspect ratio on xs, adjusts on sm
          borderRadius: "15px",
          flex: 1, // This will allow it to grow and take up equal space
        }}
      />
    </Box>
  );
};

export default OurMissionSection;
