import { getFunctions, httpsCallable } from "firebase/functions";

export const addVisitFlag = async (data) => {
  const functions = getFunctions();
  const addVisitFlagFunction = httpsCallable(functions, "addVisitFlag");
  try {
    const result = await addVisitFlagFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addVisitFlag function:", error);
    throw error;
  }
};
