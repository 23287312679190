// MemberDrawer.js
import React from "react";
import useMemberData from "../../hooks/useMemberData";
import HelpersProvider from "../../providers/HelpersProvider";
import MemberCard from "../crm/member/MemberCard";

const MemberDrawer = ({ memberId, userRole = "member", onClose }) => {
  const {
    memberData,
    memberAdminData,
    memberAccountData,
    memberPrivateData,
    visitsData,
    employeesData,
    loading,
    loadingVisits,
    loadingPrivate,
  } = useMemberData(memberId);

  return (
    <HelpersProvider
      lat={memberPrivateData?.location?.lat}
      lng={memberPrivateData?.location?.lng}
    >
      <MemberCard
        memberId={memberId}
        userRole={userRole}
        memberData={memberData}
        memberAdminData={memberAdminData}
        memberAccountData={memberAccountData}
        memberPrivateData={memberPrivateData}
        visitsData={visitsData}
        employeesData={employeesData}
        loading={loading}
        loadingVisits={loadingVisits}
        loadingPrivate={loadingPrivate}
        onClose={onClose}
      />
    </HelpersProvider>
  );
};

export default MemberDrawer;
