import React, { useState, useEffect } from "react";

const EnvironmentInfo = () => {
  // Initialize state with default values
  const [info, setInfo] = useState({
    width: 0,
    height: 0,
    userAgent: "N/A",
    isStandalone: false,
    platform: "N/A",
  });

  const [batteryInfo, setBatteryInfo] = useState({
    level: null,
    charging: null,
  });

  useEffect(() => {
    // Update state with window and navigator properties
    setInfo({
      width: window.innerWidth,
      height: window.innerHeight,
      userAgent: navigator.userAgent,
      isStandalone: window.matchMedia("(display-mode: standalone)").matches,
      platform: navigator.userAgentData?.platform || "unknown", // Fallback to 'unknown' if userAgentData is not available
    });

    // Check for getBattery support
    if (navigator.getBattery) {
      navigator.getBattery().then((battery) => {
        setBatteryInfo({ level: battery.level, charging: battery.charging });

        battery.addEventListener("levelchange", () => {
          setBatteryInfo((prev) => ({ ...prev, level: battery.level }));
        });

        battery.addEventListener("chargingchange", () => {
          setBatteryInfo((prev) => ({ ...prev, charging: battery.charging }));
        });
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInfo((currentInfo) => ({
        ...currentInfo,
        width: window.innerWidth,
        height: window.innerHeight,
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div>
        <h2>Environment Information</h2>
        <p>Width: {info.width}px</p>
        <p>Height: {info.height}px</p>
        <p>User Agent: {info.userAgent}</p>
        <p>Is Standalone: {info.isStandalone ? "Yes" : "No"}</p>
        <p>Platform: {info.platform}</p>
        <p>
          Battery Level:{" "}
          {batteryInfo.level ? `${batteryInfo.level * 100}%` : "N/A"}
        </p>
        <p>Charging: {batteryInfo.charging ? "Yes" : "No"}</p>
        {/* Render more info as needed */}
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "50%",
            height: "100vh",
            backgroundColor: "lightblue",
            marginRight: "10px",
          }}
        >
          100vh Box
        </div>
        <div
          style={{
            width: "50%",
            height: info.height,
            backgroundColor: "lightgreen",
          }}
        >
          Viewport Height Box
        </div>
      </div>
    </div>
  );
};

export default EnvironmentInfo;
