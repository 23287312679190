import { ArrowForwardIosRounded } from "@mui/icons-material";
import { Box, ListItemButton, Skeleton, Typography } from "@mui/material"; // Import Material-UI components
import React, { useState } from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import VisitDrawer from "../drawers/VisitDrawer";
import VisitListItem from "../VisitListItem";

const VisitOverviewCard = ({
  visit, // needs to have id pushed in as well
  loading = false,
  userRole = "member",
  localEmployees, // for rescheduling
  eId = "",
  eName = "",
  label = null, // or past
  my = 2,
}) => {
  const { openDrawer } = useDrawer();

  const [showVisit, setShowVisit] = useState(false);

  const handleVisitOpen = () => {
    openDrawer(VisitDrawer, { visitId: visit.id, userRole });
    console.log("open visit");
  };

  const handleVisitClose = () => {
    setShowVisit(false);
    // setSelectedVisit(null); // Set selectedVisit to null inline
  };

  return (
    <>
      {loading ? (
        // Show a loading skeleton while fetching data
        <Box sx={{ my: my, width: { xs: "100%", sm: "50%" }, minHeight: "60px" }}>
          <Skeleton variant="rounded" height={72}>
            {/* <Box
                variant="circular"
                height={56}
                width={56}
                sx={{ mr: "24px", backgroundColor: "white" }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box
                  variant="rectangular"
                  sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  height={24}
                  width={150}
                />
                <Box
                  variant="rectangular"
                  sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  height={20}
                  width={100}
                />
              </Box> */}
          </Skeleton>
        </Box>
      ) : visit ? (
        // Show the next visit if available
        <Box sx={{ my: my, flexGrow: 1, width: { xs: "100%", sm: "50%" }, minHeight: "60px" }}>
          <ListItemButton
            onClick={handleVisitOpen}
            sx={{
              borderRadius: "15px",
              backgroundColor: label === "upcoming" ? "#F5F5F5" : "white",
              border:
                label === "upcoming"
                  ? "unset"
                  : "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <VisitListItem
                  visit={visit}
                  compact={true}
                  margins="small"
                  adminMode={true}
                />
                <ArrowForwardIosRounded color="primary" />{" "}
                {/* Set the arrow color */}
              </Box>

              {label && (
                <Typography variant="caption" color={"text.secondary"}>
                  {label === "upcoming"
                    ? "Upcoming visit"
                    : "Last visit"}
                </Typography>
              )}
            </Box>
          </ListItemButton>
        </Box>
      ) : (
        // Show a message if there are no upcoming visits
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            width: "auto",
            flexGrow: 1,
            my: my,
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            width: { xs: "100%", sm: "50%" }, minHeight: "60px",
          }}
        >
          <Typography variant="body1" color="textSecondary">
            No {label ? label : ""} visits.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default VisitOverviewCard;
