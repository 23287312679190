import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import CounterStats from "../../components/CounterStats";
import CredibilityGrid from "../../components/CredibilityGrid";
import GuestHeader from "../../components/GuestHeader";
import ServiceLookup from "../../components/locations/ServiceLookup";
import InfiniteScrollAvatars from "../../components/popup/InfiniteScrollAvatars";
import useLogPageVisit from "../../hooks/useLogPageVisit";
import useTrackVisibility from "../../hooks/useTrackVisibility";
import BenefitsSection from "./sections/BenefitsSection";
import CountdownSection from "./sections/CountdownSection";
import FAQSection from "./sections/FAQSection";
import LandingPage from "./sections/LandingPage";
import LandingVideoSection from "./sections/LandingVideoSection";
import ReviewSection from "./sections/ReviewSection";
import SentimentSection from "./sections/SentimentSection";
import ServicesOverviewSection from "./sections/ServicesOverviewSection";
import WebFooter from "./sections/WebFooter";

function Home() {
  const countdownRef = useTrackVisibility(() => {},
  `/home/"get started section"`); // Pass the logMessage here

  const { cityState } = useParams();

  let city = "";
  let state = "";

  if (cityState) {
    const parts = cityState.split("-");

    // State is always the last part
    state = parts[parts.length - 1].toUpperCase();

    // City is all parts except the last one, joined back with spaces
    city = parts
      .slice(0, -1)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  }

  const pageTitle = `Find Trusted Senior Help ${
    city && `in ${city}`
  } with Linked Lives`;
  const pageDescription = `Discover our excellent senior help service${
    city ? ` in ${city}` : " with 100's of chores and tasks"
  }${
    state ? `, ${state}` : ""
  }. Our trusted helpers will take care of your tasks, chores, and to-dos. Sign up to book your first visit today.`;

  console.log("city/state", [city, state]);

  useLogPageVisit("/home");

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <GuestHeader />
      <LandingPage city={city} state={state} />
      <CredibilityGrid pageName="/home" />
      <CounterStats pageName="/home" />
      {/* <ProblemSection /> */}
      <InfiniteScrollAvatars />

      <ServicesOverviewSection pageName="/home" />
      <LandingVideoSection pageName="/home" />

      <ReviewSection pageName="/home" />

      {/* <FreeHourOffer /> */}
      <BenefitsSection />
      {/* <SatisfactionGuarantee pageName="/home" /> */}
      {/* <AppOverview pageName="/home" /> */}
      {/* <AsSeenInSection /> */}
      <FAQSection pageName="/home" />
      <ServiceLookup pageName="/home" />
      <SentimentSection pageName="/home" />

      {/* <CountdownBar isSection={true} isCompact={false} /> */}
      <div style={{ height: "20px" }} ref={countdownRef} />
      <CountdownSection pageName="/home" />
      {/* <WheelPopup fullScreen={true} /> */}

      <WebFooter pageName="/home" />
    </div>
  );
}

export default Home;
