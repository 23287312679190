import { getFunctions, httpsCallable } from "firebase/functions";

export const sendSMSClient = async (data) => {
  const functions = getFunctions();
  const sendSMSClientFunction = httpsCallable(functions, "sendSMSClient");
  try {
    const result = await sendSMSClientFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling sendSMSClient function:", error);
    throw error;
  }
};

export function getRandomSalesMessage() {
  const messages = [
    "Please spread the word to a friend this week and help us grow through word-of-mouth!",
    "Please mention our service to a friend this week to help us grow through word-of-mouth!",
    "Please share your experience with a friend to help us grow our small business!",
    "Please help us grow by telling others about your experience with Linked Lives!",
    "Please let your friends know how much you enjoy our services. Your recommendation means the world!",
    "Please help us spread the word by sharing your Linked Lives experience with friends and family!",
    "Please help us grow our small business by inviting a friend to join!",
    "Nothing speaks louder than your personal recommendation. This week, would you consider sharing your experience with a friend?",
    "Your word of mouth is key to helping us grow our small business, would you consider telling a friend or two?",
    "This week, let your neighbors know how much you love our services, every word helps!",
    "We rely on your word of mouth to grow our small business. Help us this week by talking to a friend!"
  ];

  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}
