import { LockOpen, MoveToInbox } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSnackbar } from "../providers/SnackbarProvider";
import { sendSignInLink } from "../services/memberServices";
import CopyUrlButton from "./buttons/CopyUrlButton";

const SendLoginLink = ({ open, onClose, memberId }) => {
  const { showSnackbar } = useSnackbar();
  const [url, setUrl] = useState(""); // For storing the URL once link is sent
  const [isSending, setIsSending] = useState(false); // For handling the loading state
  const [isLinkSent, setIsLinkSent] = useState(false); // To toggle between views

  // This handler will be triggered when the button is clicked
  const handleSendLoginLink = async () => {
    try {
      setIsSending(true); // Start loading state
      const uid = memberId;
      const replyTo = "support@ourlinkedlives.com";

      // Call the sendSignInLink function to send the link
      const response = await sendSignInLink({ uid, replyTo });
      setUrl(response.url); // Save the URL to state
      setIsLinkSent(true); // Switch to the "link sent" view
    } catch (error) {
      console.error("Failed to send sign-in link: ", error);
      showSnackbar("Failed to send sign-in link", "error");
    } finally {
      setIsSending(false); // End loading state
    }
  };

  return (
    <>
      <DialogContent>
        {isLinkSent ? (
          // View 2: Show the link and allow copying
          <Box textAlign="center" sx={{ my: 4 }}>
            <MoveToInbox color="primary" sx={{ fontSize: 40, mb: 1 }} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Login link sent!
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              The login link has been sent via sms and email.
            </Typography>
            <CopyUrlButton url={url} />
            {/* Use CopyUrlLink component to display and copy the link */}
          </Box>
        ) : (
          // View 1: Description and button to send link
          <Box textAlign="center" sx={{ my: 4 }}>
            <LockOpen color="primary" sx={{ fontSize: 40, mb: 1 }} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Password-less login
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              Do you wish to send a password-less login link?
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
              gap: 1,
              width: "100%",
            },
          }}
        >
          <Button
            onClick={onClose}
            variant={isLinkSent ? "contained" : "text"}
            color="primary"
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: "60px",
              textTransform: "none",
            }}
          >
            {isLinkSent ? "Close" : "Go Back"}
          </Button>
          {!isLinkSent && (
            <Button
              variant="contained"
              onClick={handleSendLoginLink}
              color="primary"
              disabled={isSending} // Disable button while sending
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: "60px",
                textTransform: "none",
              }}
            >
              {isSending ? (
                <CircularProgress size={24} color="inherit" /> // Show loading spinner while sending
              ) : (
                "Send Link"
              )}
            </Button>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default SendLoginLink;
