import { Avatar, Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PingPongScroll from "./PingPongScroll";

const adCopyUrls = [
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FdXA5IwQcnUe0HyNvSKXXqWPb6ug2?alt=media&token=d063516b-e919-474b-9959-368245a60f97", // caz
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FvR7PceHW1tOQSF8nnlZM8K6GXoB3?alt=media&token=070cca76-468b-4c3f-831f-a228fb3b1843", // john
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FlCfkMpZc2AYVfEEHcqfGGjPxSif2?alt=media&token=8cfd839a-addd-4ef3-a8d2-9daf759b5ce0", // evan
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FhZJwf6mbMxYO0dy2x0jS4dbTYgB2.jpeg?alt=media&token=d7ea17df-c10f-4bba-a580-41bae8a5b19e", // ethan
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FPsHA5ZvGMXXJLCljzhKK6Iv6xXR2?alt=media&token=d4073e7e-2a7c-4d6a-a524-19e8efedac9f", //alex
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F8jBCWIZIhZRMaOqwKJGsvsiXmkt1?alt=media&token=f4202b0e-082a-4375-b729-119c4be7e59c", // addie
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FmORSk4BgMcS9dUiU29RgWvdleUB3?alt=media&token=8654191c-f255-4ce6-883c-e6582c12fe0b", // carlo
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FwNoANWJjh1Unp4MtBex4wfmBexv1?alt=media&token=53546ad1-1e22-4d9f-9b08-6e5ba01ccd02", // eli

  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FafZCNQGDLLhQl4wNwG42gs5eXcm2?alt=media&token=29b8936f-99a8-4785-9057-a016c69aeec1", // dakota
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FU3uallG7AiUYqtD7ox5ZTUXw3Gb2?alt=media&token=13aa4490-a701-467b-9b73-a500b7914f47", // ruby
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FTtluJmUq1CgE9UM1c7L4HEPGSIk1?alt=media&token=54ac4637-2d70-439a-919a-c631de476cf5", // toby
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=0a1886c1-3c0f-41b7-b332-0195982cd7af", // zach
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2Fmichael-h.jpg?alt=media&token=41177cdd-2dc5-4dfb-99a0-8dc42eca63b5", // michael
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FVpWaaQKGZ1YqJ6WuRi12SUQUC992.jpg?alt=media&token=cc3ff9c2-e8d4-4302-9811-3d207625d841", //brandon
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FQCDplEfeicOXPMNLanUl7EgTFcS2.jpeg?alt=media&token=7dd11fca-726b-40af-bd2e-a8aae947fb85", // sam
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FHsgq7Q0Zlih6wBwpz8dXUZe1lPa2.jpeg?alt=media&token=c882b656-d083-4f4c-b294-d4ec21222d91", // tyler
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FaqymXn3yV2N6SA7ZGZJYk6HwT8l1?alt=media&token=8327f1d5-c30c-45a5-b836-4c3e4d2079af", // adam
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FXdunSNWrqyLSunAspuE3ee5LGfT2.jpeg?alt=media&token=13abfc97-0ade-473d-9e5c-20d0479c4c5d", // kylie
  // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FSDWtwf3thNZQIqeIYXXIxH8lBLq2?alt=media&token=2f5786e8-1906-4a53-9776-16c6360afb8b", // jack
];

const InfiniteScrollAvatars = ({ rows = null, scrollable = false }) => {
  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FdXA5IwQcnUe0HyNvSKXXqWPb6ug2?alt=media&token=d063516b-e919-474b-9959-368245a60f97", // caz
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FlCfkMpZc2AYVfEEHcqfGGjPxSif2?alt=media&token=8cfd839a-addd-4ef3-a8d2-9daf759b5ce0", // evan
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FwNoANWJjh1Unp4MtBex4wfmBexv1?alt=media&token=53546ad1-1e22-4d9f-9b08-6e5ba01ccd02", // eli
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FHsgq7Q0Zlih6wBwpz8dXUZe1lPa2.jpeg?alt=media&token=c882b656-d083-4f4c-b294-d4ec21222d91", // tyler
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FmORSk4BgMcS9dUiU29RgWvdleUB3?alt=media&token=8654191c-f255-4ce6-883c-e6582c12fe0b", // carlo
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FvR7PceHW1tOQSF8nnlZM8K6GXoB3?alt=media&token=070cca76-468b-4c3f-831f-a228fb3b1843", // john
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FVpWaaQKGZ1YqJ6WuRi12SUQUC992.jpg?alt=media&token=cc3ff9c2-e8d4-4302-9811-3d207625d841", //brandon
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FXdunSNWrqyLSunAspuE3ee5LGfT2.jpeg?alt=media&token=13abfc97-0ade-473d-9e5c-20d0479c4c5d", // kylie
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FhZJwf6mbMxYO0dy2x0jS4dbTYgB2.jpeg?alt=media&token=d7ea17df-c10f-4bba-a580-41bae8a5b19e", // ethan
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=0a1886c1-3c0f-41b7-b332-0195982cd7af", // zach
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FU3uallG7AiUYqtD7ox5ZTUXw3Gb2?alt=media&token=13aa4490-a701-467b-9b73-a500b7914f47", // ruby
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FTtluJmUq1CgE9UM1c7L4HEPGSIk1?alt=media&token=54ac4637-2d70-439a-919a-c631de476cf5", // toby
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FafZCNQGDLLhQl4wNwG42gs5eXcm2?alt=media&token=29b8936f-99a8-4785-9057-a016c69aeec1", // dakota
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FQCDplEfeicOXPMNLanUl7EgTFcS2.jpeg?alt=media&token=7dd11fca-726b-40af-bd2e-a8aae947fb85", // sam
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F8jBCWIZIhZRMaOqwKJGsvsiXmkt1?alt=media&token=f4202b0e-082a-4375-b729-119c4be7e59c", // addie
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FaqymXn3yV2N6SA7ZGZJYk6HwT8l1?alt=media&token=8327f1d5-c30c-45a5-b836-4c3e4d2079af", // adam
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FSDWtwf3thNZQIqeIYXXIxH8lBLq2?alt=media&token=2f5786e8-1906-4a53-9776-16c6360afb8b", // jack
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        mt: 4,
        mx: "auto",
      }}
    >
      <PingPongScroll>
        <Box>
          {/* First row (odd indices) */}
          <Box
            sx={{ display: "flex", mb: isMobile || rows === 2 ? "-18px" : 0 }}
          >
            {imageUrls
              .filter((_, index) =>
                isMobile || rows === 2 ? index % 2 === 0 : true
              )
              .map((url, index) => (
                <Avatar
                  key={index}
                  src={url}
                  sx={{
                    width: 128,
                    height: 128,
                    mx: { xs: 0, sm: scrollable ? 0 : 1 },
                    border: "5px solid white",
                  }}
                />
              ))}
          </Box>

          {/* Second row (even indices) - only on mobile */}
          {(isMobile || rows === 2) && (
            <Box sx={{ display: "flex", ml: "69px" }}>
              {imageUrls
                .filter((_, index) => index % 2 !== 0)
                .map((url, index) => (
                  <Avatar
                    key={index}
                    src={url}
                    sx={{
                      width: 128,
                      height: 128,
                      mx: { xs: 0, sm: scrollable ? 0 : 1 },
                      border: "5px solid white",
                    }}
                  />
                ))}
            </Box>
          )}
        </Box>
      </PingPongScroll>
    </Box>
  );
};

export default InfiniteScrollAvatars;
