import { Card, Stack } from "@mui/material";
import React from "react";

import { ArrowForwardIosRounded } from "@mui/icons-material";
import EmployeeListItem from "./EmployeeListItem";

const HelpersList = ({ employeesData, memberData, memberPrivateData, userRole = "member" }) => {
  return (
    <>
      <Stack sx={{ gap: { xs: 2, sm: 2 }, my: 2 }}>
        {Object.entries(employeesData || {})
          .sort(([idA], [idB]) => {
            const isFollowedA = memberPrivateData.helpersFollowing?.includes(
              idA
            )
              ? 1
              : 0;
            const isFollowedB = memberPrivateData.helpersFollowing?.includes(
              idB
            )
              ? 1
              : 0;
            return isFollowedB - isFollowedA; // Sort helpersFollowing first
          })

          .map(([uid, employee]) => {
            // const employee = employeesData?.[uid];
            // if (!employee) return null; // Ensure it explicitly returns null to avoid undefined output

            return (
              <Card
                key={uid}
                variant="outlined"
                sx={{
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                  position: "relative",
                }}
              >
                <EmployeeListItem
                  memberId={memberData?.id}
                  memberPrivateData={memberPrivateData}
                  employeeData={{ ...employee, id: uid }}
                  coordinates={{
                    lat: memberPrivateData?.location?.lat,
                    lng: memberPrivateData?.location?.lng,
                  }}
                  userRole={userRole}
                />
                <ArrowForwardIosRounded
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
              </Card>
            );
          })}
      </Stack>
    </>
  );
};

export default HelpersList;
