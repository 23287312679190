import {
  Call,
  ContentCopy,
  Email,
  FactCheck,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { getEmployeeServiceString } from "../../services/employeeServices";
import { handleCopy } from "../../services/formServices";
import FollowButton from "../buttons/FollowButton";
import ColorAvatar from "../ColorAvatar";
import ChangeEmployeeStatus from "../manageEmployees/ChangeEmployeeStatus";

const EmployeeInfo = ({
  employeeData,
  employeePrivateData,
  memberPrivateData = {}, // used for follow tracking, must hold .id
  memberId="",
  userRole = "member",
  inArea = true, // can be true or false
  futureAvailability = 0, // integer
  serviceStart = undefined, // undefined if not available at all, null treated as indefinite (into past or future), date is a hard start/end date
  serviceEnd = undefined, // undefined if not available at all, null treated as indefinite (into past or future), date is a hard start/end date
  employeeId = "",
}) => {
  const { openDrawer } = useDrawer();
  const [anchorEl, setAnchorEl] = useState(null);

  const { phone = "", email = "" } = employeePrivateData || {};

  console.log("employeeData: ", employeeData);
  const { isAvailableToday = false, bookingDaysInAdvance = 2 } =
    employeeData || {};

  console.log("isAvailableToday: ", isAvailableToday);

  const { showSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);

  // Data structure for menu items
  const menuItems = [
    {
      label: "Copy phone",
      icon: <ContentCopy fontSize="small" />,
      action: () => {
        handleCopy(
          employeePrivateData?.phone,
          (message) => showSnackbar(message, "success"),
          (message) => showSnackbar(message, "error")
        );
        // alert("Phone number copied!");
      },
    },
    {
      label: "Copy email",
      icon: <ContentCopy fontSize="small" />,
      action: () => {
        handleCopy(
          employeePrivateData?.email,
          (message) => showSnackbar(message, "success"),
          (message) => showSnackbar(message, "error")
        );
        // alert("Email copied!");
      },
    },
    userRole === "admin"
      ? {
          label: "Change status",
          icon: <FactCheck fontSize="small" />,
          action: () => {
            openDrawer(ChangeEmployeeStatus, {
              employeeId,
              currentStatus: employeeData?.status,
              title: "Change status",
            });
            // alert("Email copied!");
          },
        }
      : null,
  ].filter(Boolean);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 2,
            // border: "1px solid #e0e0e0",
            borderRadius: 1,
          }}
        >
          <ColorAvatar
            name={`${employeeData?.firstName} ${employeeData?.lastName}`}
            avatarUrl={employeeData?.avatarUrl}
            size={96}
          />
          <Box>
            <Typography variant="h6" component="div">
              {employeeData?.firstName ? (
                `${employeeData.firstName} ${employeeData.lastName}`
              ) : (
                <Skeleton width="150px" />
              )}
            </Typography>
            <Typography variant="body2" fontStyle={"italic"}>
              {employeeData.age}, {employeeData.gender}
            </Typography>
          </Box>
          {/* Three Dots Menu */}
          {(userRole === "employee" || userRole === "admin") && (
            <IconButton onClick={handleMenuOpen}>
              <MoreVert />
            </IconButton>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            slotProps={{
              paper: {
                // elevation: 1,
                sx: { width: "200px" },
              },
            }}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  item.action();
                  handleMenuClose();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {memberId && (userRole === "admin" || userRole === "member") && (
          <FollowButton
            memberPrivateData={memberPrivateData}
            memberId={memberId}
            employeeId={employeeId}
          />
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: inArea
            ? isAvailableToday
              ? "hsla(120, 38%, 95%, 1)"
              : "#F5F5F5"
            : "hsla(0, 38%, 95%, 1)",
          color: inArea
            ? isAvailableToday
              ? "hsla(120, 25%, 30%, 1)"
              : "unset"
            : "hsla(0, 25%, 30%, 1)",
          p: 2,
          borderRadius: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography variant="body1">
          {getEmployeeServiceString(
            inArea,
            futureAvailability,
            bookingDaysInAdvance,
            isAvailableToday,
            serviceStart,
            serviceEnd
          )}
        </Typography>
      </Box>
      {(userRole === "employee" || userRole === "admin") && (
        <>
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              borderRadius: "15px",
              px: 2,
              height: "56px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Call />
              <Typography variant="body1">{phone}</Typography>
            </Box>
            <IconButton
              onClick={() => {
                handleCopy(
                  phone,
                  (message) => showSnackbar(message, "success"),
                  (message) => showSnackbar(message, "error")
                );
              }}
            >
              <ContentCopy />
            </IconButton>
          </Box>

          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              borderRadius: "15px",
              px: 2,
              height: "56px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Email />
              <Typography variant="body1">{email}</Typography>
            </Box>
            <IconButton
              onClick={() => {
                handleCopy(
                  email,
                  (message) => showSnackbar(message, "success"),
                  (message) => showSnackbar(message, "error")
                );
              }}
            >
              <ContentCopy />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default EmployeeInfo;
