import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContactUs from "../../../components/popup/ContactUs";
import { MEMBER_COUNT } from "../../../services/variableServices";

const ContactUsSection = () => {
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    function shuffleArray(array) {
      let shuffledArray = array.slice(); // Create a copy of the array to avoid modifying the original
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [
          shuffledArray[j],
          shuffledArray[i],
        ]; // Swap elements
      }
      return shuffledArray;
    }

    // Shuffle and slice the array to get 4 random URLs on component mount
    setSelectedImages(shuffleArray(imageUrls).slice(0, 4));
  }, []); // Empty dependency array to ensure this runs only once

  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FHsgq7Q0Zlih6wBwpz8dXUZe1lPa2.jpeg?alt=media&token=c882b656-d083-4f4c-b294-d4ec21222d91", // Replace with your actual image paths
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FQCDplEfeicOXPMNLanUl7EgTFcS2.jpeg?alt=media&token=7dd11fca-726b-40af-bd2e-a8aae947fb85",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FaqymXn3yV2N6SA7ZGZJYk6HwT8l1?alt=media&token=8327f1d5-c30c-45a5-b836-4c3e4d2079af",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FhZJwf6mbMxYO0dy2x0jS4dbTYgB2.jpeg?alt=media&token=d7ea17df-c10f-4bba-a580-41bae8a5b19e",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FvR7PceHW1tOQSF8nnlZM8K6GXoB3?alt=media&token=070cca76-468b-4c3f-831f-a228fb3b1843",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FXdunSNWrqyLSunAspuE3ee5LGfT2.jpeg?alt=media&token=13abfc97-0ade-473d-9e5c-20d0479c4c5d",
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FtrZkasnGwGh4KZ6jc7qOc6U1cfz2?alt=media&token=827f342c-a9bd-4d6d-8455-38c90f823ec2",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=0a1886c1-3c0f-41b7-b332-0195982cd7af",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FlCfkMpZc2AYVfEEHcqfGGjPxSif2?alt=media&token=8cfd839a-addd-4ef3-a8d2-9daf759b5ce0",
  ];

  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", sm: "600px" },
        mx: "auto",
        p: { xs: 2, sm: 4 },
        overflow: "hidden",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Typography align="center" variant="h3" sx={{ mb: 4, mt: 2 }}>
          {/* Get 1-hour free! */}
          Reach out
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Discover why over {Math.floor(MEMBER_COUNT / 10) * 10}+ seniors use
          Linked Lives for personal, on-demand help with anything from
          gardening to computer help. Fill out our form and we'll give you a
          call.
        </Typography>
        {/* <Typography
          variant="body2"
          align="left"
          fontStyle="italic"
          sx={{ mb: 4 }}
        >
          Reach out on behalf of friends, family, and neighbors as well!
        </Typography> */}

        <ContactUs />

        {/* <Typography align="center" variant="body2" sx={{ mt: 2 }}>
          It's time to tackle your to-do list.
        </Typography> */}
      </Box>
    </Box>
  );
};

export default ContactUsSection;
