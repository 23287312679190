export const formatName = (str) => {
  if (typeof str !== "string" || !str) {
    // Return the original input if it's not a string or is falsy (empty, null, undefined)
    return str;
  }

  return str
    .split(" ")
    .map((word) =>
      word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ""
    )
    .join(" ");
};

export const formatPhoneNumber = (phoneNumber) => {
  // Check if the input is a string and not null or undefined
  if (!phoneNumber || typeof phoneNumber !== "string") {
    return;
  }

  // Remove all non-digit characters
  const digits = phoneNumber.replace(/\D/g, "");

  // Format the phone number as (XXX) XXX-XXXX
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
};
