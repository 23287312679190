import moment from "moment-timezone";

export const formatDate = (date, timeZoneId = "America/Los_Angeles") => {
  return moment(date).tz(timeZoneId).format("dddd, MMM Do");
};

export const formatTimeRange = (startDate, endDate, timeZoneId = "America/Los_Angeles", compactPeriod = false) => {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const start = moment(startDate).tz(timeZoneId);
  const end = moment(endDate).tz(timeZoneId);

  // Format start and end times without :00 if on the hour
  const startTime = start.format(start.minutes() === 0 ? "h" : "h:mm");
  const endTime = end.format(end.minutes() === 0 ? "h" : "h:mm");

  // Add am/pm in lowercase based on conditions
  const startPeriod = start.format("a"); // 'a' for lowercase am/pm
  const endPeriod = end.format("a");
  const timeRange = (startPeriod === endPeriod && compactPeriod) 
    ? `${startTime} - ${endTime}${startPeriod}` 
    : `${startTime}${startPeriod} - ${endTime}${endPeriod}`;

  const displayTimeZone = browserTimeZone !== timeZoneId
    ? ` ${moment.tz(timeZoneId).zoneAbbr()}`
    : "";

  return `${timeRange}${displayTimeZone}`;
};


export const formatAddress = (address) => {
  let formattedAddress = address.line1; // Start with line 1, which presumably always exists

  if (address.line2) {
    formattedAddress += `, ${address.line2}`; // Add line 2 if it exists
  }

  // Continue adding components with assured existence, separated by commas
  formattedAddress += `, ${address.city}`;
  formattedAddress += `, ${address.state}`;

  // Assuming zipCode always exists as well
  formattedAddress += ` ${address.zipCode}`;

  return formattedAddress;
};

// Function to format the timestamp based on the specified criteria
export const formatTimestamp = (timestamp) => {
  const now = moment();
  const logDate = moment(timestamp.toDate()); // Convert Firestore timestamp to moment object

  // Check the difference in days
  const diffDays = now.diff(logDate, "days");

  if (diffDays < 7) {
    return logDate.format("ddd h:mm A"); // e.g., Thu 3:45 PM
  } else if (diffDays < 365) {
    return logDate.format("MMM D h:mm A"); // e.g., Oct 17 3:45 PM
  } else {
    return logDate.format("MMM D, YYYY"); // e.g., Oct 17, 2022
  }
};

// drop the :00 for a time string
export const formatTime = (time, timeZoneId) => {
  const formattedTime = moment(time.toDate()).tz(timeZoneId).format("h:mma");
  return formattedTime.replace(":00", "");
};

export const formatDateRange = (eventDays) => {
  const dates = Object.values(eventDays).map((day) => ({
    start: moment(day.startTime.toDate()),
    end: moment(day.endTime.toDate()),
  }));

  if (dates.length === 0) return "Dates not set";

  // Find the earliest start date and the latest end date
  const earliestStart = moment.min(dates.map((date) => date.start));
  const latestEnd = moment.max(dates.map((date) => date.end));

  if (earliestStart.isSame(latestEnd, "day")) {
    return earliestStart.format("MMMM Do"); // Single day event
  } else if (earliestStart.isSame(latestEnd, "month")) {
    // If start and end dates are in the same month
    return `${earliestStart.format("MMMM Do")} - ${latestEnd.format("Do")}`;
  } else {
    // If start and end dates are in different months
    return `${earliestStart.format("MMMM Do")} - ${latestEnd.format(
      "MMMM Do"
    )}`;
  }
};

export const formatTimeRangeCompact = ({ start, end, timeZoneId }) => {
  const startTime = moment(start.toDate()).tz(timeZoneId);
  const endTime = moment(end.toDate()).tz(timeZoneId);

  const isSameAmPm = startTime.format("a") === endTime.format("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.format(
    `h${startTime.minute() !== 0 ? ":mm" : ""}${!isSameAmPm ? " a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.format(
    `h${endTime.minute() !== 0 ? ":mm" : ""} a`
  );

  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const formatTimeRangeCompactListItem = ({ start, end, timeZoneId }) => {
  const startTime = moment(start.toDate()).tz(timeZoneId);
  const endTime = moment(end.toDate()).tz(timeZoneId);

  const isSameAmPm = startTime.format("a") === endTime.format("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.format(
    `h${startTime.minute() !== 0 ? ":mm" : ""}${!isSameAmPm ? "a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.format(
    `h${endTime.minute() !== 0 ? ":mm" : ""}a`
  );

  return `${startTimeFormatted} to ${endTimeFormatted}`;
};
