import { Box, Collapse, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import angryFace from "../../../assets/angry-face.png";
import happyFace from "../../../assets/happy-face.png";
import sadFace from "../../../assets/sad-face.png";
import skepticalFace from "../../../assets/skeptical-face.png";
import smilingFace from "../../../assets/smiling-face.png";

import nextWeek from "../../../assets/7-calendar.png";
import thisWeek from "../../../assets/clock-hurry.png";
import threeMonths from "../../../assets/hourglass.png";
import nextMonth from "../../../assets/month-calendar.png";
import undecidedTime from "../../../assets/question-circle.png";

import GetStartedButton from "../../../components/buttons/GetStartedButton";
import QuestionSubsection from "../../../components/feedback/QuestionSubsection";
import ContactUs from "../../../components/popup/ContactUs";
import {
  addField,
  logAction,
  logFieldTimestamp,
} from "../../../hooks/useLogPageVisit";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

const questions = [
  {
    id: 1,
    title: "Interest Level",
    question: "How interested are you in using Linked Lives?",
    options: [
      {
        id: "Absolutely",
        image: smilingFace,
        text: "Absolutely!",
        color: "hsl(140,100%,30%)",
      },
      {
        id: "Yes",
        image: happyFace,
        text: "Yes",
        color: "hsl(190,100%,30%)",
      },
      {
        id: "maybe",
        image: skepticalFace,
        text: "Maybe",
        color: "hsl(60,100%,30%)",
      },
      {
        id: "not really",
        image: sadFace,
        text: "Not really",
        color: "hsl(40,100%,30%)",
      },
      { id: "no", image: angryFace, text: "No", color: "hsl(350,100%,30%)" },
    ],
  },
  {
    id: 2,
    title: "Timeline",
    question: "When would you like to schedule your first visit?",
    options: [
      {
        id: "This week",
        image: thisWeek,
        text: "This week",
        color: "hsl(190,100%,30%)",
      },
      {
        id: "Next week",
        image: nextWeek,
        text: "Next week",
        color: "hsl(160,100%,30%)",
      },
      {
        id: "Within a month",
        image: nextMonth,
        text: "Within a month",
        color: "hsl(130,100%,30%)",
      },
      {
        id: "Within 3 months",
        image: threeMonths,
        text: "Within 3 months",
        color: "hsl(100,100%,30%)",
      },
      {
        id: "Undecided",
        image: undecidedTime,
        text: "Undecided",
        color: "hsl(60,100%,30%)",
      },
    ],
  },
  // {
  //   id: 3,
  //   question: "What are your key priorities?",
  //   options: [
  //     {
  //       id: "quality",
  //       image: "/path/to/quality.png",
  //       text: "Quality of service",
  //     },
  //     {
  //       id: "budget",
  //       image: "/path/to/budget.png",
  //       text: "Staying within budget",
  //     },
  //     { id: "speed", image: "/path/to/speed.png", text: "Quick turnaround" },
  //     {
  //       id: "relationship",
  //       image: "/path/to/relationship.png",
  //       text: "Building a relationship",
  //     },
  //   ],
  // },
];

const SentimentSection = ({ pageName = "" }) => {
  const ref = useTrackVisibility(() => {}, `${pageName}/"Sentiment section"`); // Pass the logMessage here

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState([]);

  const handleResponse = (response, questionId) => {
    logAction(`Question ${questionId}: ${response}`);
    setResponses([...responses, { questionId, response }]);
    // logFieldTimestamp("_questionsSubmitted");
    addField("_sentiment", `Q${questionId}: ${response}`);

    // Move to the next question

    setCurrentQuestion(currentQuestion + 1);
  };

  return (
    <Box ref={ref} sx={{ maxWidth: "1000px", mx: "auto", mt: 10, mb: 5 }}>
      {questions.map((question, index) => (
        <Collapse key={question.id} in={currentQuestion >= index}>
          <QuestionSubsection
            question={question.question}
            options={question.options}
            title={question.title}
            onAnswer={(response) => handleResponse(response, question.id)}
          />
        </Collapse>
      ))}

      {/* Show ContactUs component after the last question has been answered */}
      <Collapse key={"next steps"} in={currentQuestion >= questions.length}>
        <Typography variant="h4" align="center">
          Next steps
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          color={"text.secondary"}
        >
          We'd love to get in touch and get to know you!
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            maxWidth: "400px",
            mx: { xs: 1, sm: "auto" },
            p: { xs: 2, sm: 4 },
          }}
        >
          <GetStartedButton isStandalone={true} />{" "}
          {/* The ContactUs component */}
          <Divider sx={{ my: 2 }}>or</Divider>
          <Box sx={{ background: "white", p: 2, borderRadius: "15px" }}>
            <ContactUs
              fields={["name", "phone", "message"]}
              buttonText={"Contact us: (360) 336-6344"}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SentimentSection;
