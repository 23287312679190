import { Box, CardMedia, Typography, useTheme } from "@mui/material";
import React from "react";
import component_img from "../../../assets/linked-lives-senior-help.jpeg";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

const SatisfactionGuarantee = ({pageName = "pageName"}) => {
  const theme = useTheme();

  const ref = useTrackVisibility(() => {},
  `${pageName}/"satisfaction guarantee"`); // Pass the logMessage here

  return (
    <Box
    ref={ref}
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row-reverse" },
        alignItems: "center",
        maxWidth: "1000px",
        mx: { xs: 2, sm: "auto" },
        mb: { xs: 0, sm: 6 },
        mt: { xs: 4, sm: 10 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: 1, sm: 4 },
          flex: 1, // This allows the box to grow and take up equal space
          maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
        }}
      >
        <Typography
          align="left"
          sx={{
            fontSize: { xs: "h4.fontSize", sm: "h4.fontSize" }, // Responsive font sizes
            fontWeight: "800", // Responsive font weights
            lineHeight: "inherit",
            mb: 2,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
          }}
        >
          Love Linked Lives{" "}
          <span
            style={{
              color: theme.palette.primary.vibrant,
              fontStyle: "italic",
            }}
          >
            or your Money Back
          </span>
        </Typography>
        <Typography variant="body1" mb={4}>
          If you aren't satisfied with your Linked Lives visit, you won't have to pay a cent
          <span
            style={{
              color: theme.palette.primary.vibrant,
              fontStyle: "italic",
              fontWeight: "800",
            }}
          >
            {" "}
            - it's totally free.
          </span>
          That is our satisfaction guarantee.
        </Typography>
      </Box>
      <CardMedia
        component="img"
        image={component_img}
        alt="Family at senior event"
        sx={{
          width: { xs: "100%", sm: "50%" }, // Ensures the image takes up half the width on sm screens and up
          height: { sm: "auto" }, // Adjusts height automatically to maintain aspect ratio
          aspectRatio: { xs: "3 / 2", sm: "inherit" }, // Maintains aspect ratio on xs, adjusts on sm
          borderRadius: "15px",
          flex: 1, // This will allow it to grow and take up equal space
        }}
      />
    </Box>
  );
};

export default SatisfactionGuarantee;
