import { EventRepeatOutlined } from "@mui/icons-material";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon"; // Importing DateTime from luxon
import React from "react";

const FrequencyListItem = ({ visitData }) => {
  const {
    start = Timestamp.now(), // Default to null if start is undefined
    recurrence: { frequency = 0 } = {}, // Default recurrence to an empty object and frequency to 0
  } = visitData || {};

  return (
    <>
      {frequency > 0 && (
        <ListItem sx={{ paddingX: "0px" }}>
          <ListItemIcon sx={{ minWidth: "48px" }}>
            <EventRepeatOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography>
                {frequency > 1
                  ? `Every ${frequency}-weeks on ${DateTime.fromJSDate(
                      start?.toDate()
                    ).toFormat("cccc")}`
                  : `Every week on ${DateTime.fromJSDate(
                      start?.toDate()
                    ).toFormat("cccc")}`}
              </Typography>
            }
          />
        </ListItem>
      )}
    </>
  );
};

export default FrequencyListItem;
