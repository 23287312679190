import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import GuestHeader from "../../components/GuestHeader";
import TaskList from "../../components/TaskList";
import FAQSection from "./sections/FAQSection";
import WebFooter from "./sections/WebFooter";
import { logPageVisit } from "../../hooks/useLogPageVisit";

function TaskSearchPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  logPageVisit("/task-search")

  return (
    <div>
      <Helmet>
        <title>Senior Services from Tasks to Tech Help</title>
        <meta
          name="description"
          content="Excellent senior household help with the tasks that you either don’t want to or can’t do anymore."
        />
      </Helmet>
      <GuestHeader />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
              // height: "100%",
            }}
          >
            <Typography
              component="h1" // This changes the underlying element to an h1
              variant="h4"
              fontWeight="700"
              gutterBottom
            >
              Help with{" "}
              <span style={{ color: theme.palette.primary.vibrant }}>
                100's of Tasks
              </span>
            </Typography>
            <Typography variant="body1" mb={4}>
              Seach through the 100's of tasks that we can help with. It's an
              easy way to clarify if you have a specific question. Don't see
              your task or one similar? You can always call us at (360) 336-6344
            </Typography>
          </Box>
        </Container>
      </Box>

      <TaskList />

      <FAQSection pageName="/task-search" />

      <WebFooter pageName="/task-search" />
    </div>
  );
}

export default TaskSearchPage;
