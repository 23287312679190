import { EmojiEvents, PriceChange, TrendingUp } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { arrayUnion, doc, increment, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import ConfettiAnimation from "../../animations/ConfettiAnimation";
import { ConfirmationBurst } from "../../animations/ConfirmationBurst";
import { db } from "../../firebase";
import { sendSMSClient } from "../../services/communicationServices";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const LevelUpDialog = ({ onClose, open, employeeId, employeeName = "" }) => {
  const [selection, setSelection] = useState(""); // To keep track of the user's choice
  const [confirmed, setConfirmed] = useState(false); // To toggle between selection and confirmation view
  const [loading, setLoading] = useState(false);

  const wageIncrement = 0.25;
  const bonusAmount = 50;

  const handleSelection = (value) => {
    setSelection(value);
  };

  const handleConfirm = async () => {
    setLoading(true);

    try {
      setConfirmed(true); // Show confirmation view
    } catch (error) {
      console.error("Error during confirmation process:", error);
    } finally {
      setLoading(false); // Ensure loading is stopped even if there's an error
    }
  };

  const handleIncrementLevel = async () => {
    try {
      // Get the reference to the employee's document in the "employees" collection
      const employeeDocRef = doc(db, "employees", employeeId);

      // Update the progress.currentLevel field using Firestore's increment function
      await updateDoc(employeeDocRef, {
        currentLevel: increment(1), // Increment currentLevel by 1
      });

      console.log("Level incremented successfully");
    } catch (error) {
      console.error("Error incrementing level:", error);
    }
  };

  const handleApplyRaise = async () => {
    try {
      // Get the reference to the employee's document
      const employeePrivateDocRef = doc(db, "employeesPrivate", employeeId);

      // Update the wage in Firestore
      await updateDoc(employeePrivateDocRef, {
        wage: increment(wageIncrement),
        flags: arrayUnion("wageUpdated"),
      });

      console.log("Wage updated successfully");
    } catch (error) {
      console.error("Error applying raise:", error);
    }
  };

  const handleApplyBonus = async () => {
    try {
      // Get the reference to the employee's document
      const employeePrivateDocRef = doc(db, "employeesPrivate", employeeId);

      // Update the wage in Firestore
      await updateDoc(employeePrivateDocRef, {
        bonus: increment(bonusAmount),
        flags: arrayUnion("bonusUpdated"),
      });

      console.log("bonus updated successfully");
    } catch (error) {
      console.error("Error applying raise:", error);
    }
  };

  const handleClose = async () => {
    if (confirmed) {
      await handleIncrementLevel();

      if (selection === "bonus") {
        await handleApplyBonus();
      } else if (selection === "raise") {
        await handleApplyRaise();
      }

      sendSMSClient({
        phone: 3607081269,
        body: `${employeeName} has leveled up with a ${selection}`,
      });
    }

    onClose();
  };

  return (
    <ResponsiveDialog
      title="Level Up"
      width={"450px"}
      open={open}
      onClose={handleClose}
      hideBackdrop={true}
      anchor="bottom"
    >
      {confirmed && <ConfettiAnimation />}
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mb: "100px",
        }}
      >
        {!confirmed ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 2,
              width: "auto",
              height: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                width: "60px",
                height: "60px",
                minWidth: "60px",
                cursor: "pointer",
                overflow: "hidden",
                background: "linear-gradient(90deg, #4CAF50 0%, #2196F3 100%)",
                boxShadow: `0px 0px 15px 3px hsl(150,100%,30%)`,
              }}
            >
              <EmojiEvents sx={{ fontSize: 40, color: "#fff" }} />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Unlock your reward
            </Typography>
            <Typography variant="body1">
              You've leveled up! Choose between unlocking a wage increase or
              cash bonus.
            </Typography>

            {/* Option Selection */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant={selection === "bonus" ? "outlined" : "text"}
                onClick={() => handleSelection("bonus")}
                sx={{
                  display: "flex",
                  width: "48%", // 50% minus some gap
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  textTransform: "none",
                  boxSizing: "border-box",
                }}
              >
                <PriceChange
                  sx={{
                    marginRight: "8px",
                  }}
                />
                <Typography variant="body1">${bonusAmount} bonus</Typography>
              </Button>

              <Button
                variant={selection === "raise" ? "outlined" : "text"}
                onClick={() => handleSelection("raise")}
                sx={{
                  display: "flex",
                  width: "48%", // 50% minus some gap
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  textTransform: "none",
                  boxSizing: "border-box",
                }}
              >
                <TrendingUp
                  sx={{
                    marginRight: "8px",
                  }}
                />
                <Typography variant="body1">
                  {(wageIncrement * 100).toFixed(0)}¢ raise
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ my: 4 }}>
              <ConfirmationBurst size="xlarge" />
            </Box>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Leveled up
            </Typography>
            <Typography variant="body1">
              You unlocked{" "}
              {selection === "bonus"
                ? "a $50 bonus! This bonus will be applied to your next paycheck."
                : "a 25 cent raise! This raise will go into effect the following pay period."}
            </Typography>
          </Box>
        )}
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions>
        {!confirmed ? (
          <Button
            variant="contained"
            disabled={!selection || loading || confirmed}
            onClick={handleConfirm}
            sx={{ height: "60px", width: "100%", textTransform: "none" }}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Confirm"
            )}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ height: "60px", width: "100%", textTransform: "none" }}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </ResponsiveDialog>
  );
};

export default LevelUpDialog;
