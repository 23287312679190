import { Box, Typography } from "@mui/material";
import React from "react";

const MultiplierDetails = ({ totalMultiplier }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography variant="body1" sx={{ color: "#666" }}>
            Earn
          </Typography>
          <Typography
            sx={{
              backgroundColor: "#e0e0e0",
              px: 1,
              py: 0.5,
              borderRadius: "5px",
              fontWeight: "bold",
              mx: "4px",
            }}
          >
            {totalMultiplier.toFixed(0)}{" "}
            {totalMultiplier.toFixed(0) == 1 ? "point" : "points"}
          </Typography>
          <Typography variant="body1" sx={{ color: "#666" }}>
            per visit
          </Typography>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              backgroundColor: "#e0e0e0",
              px: 1,
              py: 0.5,
              borderRadius: "5px",
              fontWeight: "bold",
              mx: "4px",
            }}
          >
            {(totalMultiplier - 1).toFixed(2)}x
          </Typography>
          <Typography variant="body1" sx={{ color: "#666" }}>
            bonus multiplier
          </Typography>
        </Box> */}
      </Box>
    </>
  );
};

export default MultiplierDetails;
