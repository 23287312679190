import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Card, Typography } from "@mui/material";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { Timestamp, arrayUnion, doc, getDoc, increment, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import RoleSelection from "../../forms/signUp/RoleSelection";
import SimplePage from "../SimplePage";

import { keyframes } from "@emotion/react";
import { sendSmsCode } from "../../services/authServices";
import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";
import SmsVerification from "./SmsVerification";
import useLogPageVisit from "../../hooks/useLogPageVisit";

const fadeInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {sw
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const GeneralLogin = () => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [userCredential, setUserCredential] = useState(null); // Add this state to store user credentials
  const [gLoading, setGLoading] = useState(false);
  const [smsStatus, setSmsStatus] = useState(null);
  const [smsError, setSmsError] = useState("");

  useLogPageVisit("/member/login");


  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', {
        value: 2,
        currency: 'USD',
      });
    }

  }, []);

  const [prevStep, setPrevStep] = useState(step);
  const [navigationDirection, setNavigationDirection] = useState("forward");

  useEffect(() => {
    if (step !== prevStep) {
      setNavigationDirection(step > prevStep ? "forward" : "backward");
      setPrevStep(step);
    }
  }, [step]);

  const navigate = useNavigate();
  const auth = getAuth();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const getAnimation = (isCurrentStep) => {
    if (isCurrentStep) {
      return navigationDirection === "forward"
        ? `${fadeInFromLeft} 0.5s forwards`
        : `${fadeInFromRight} 0.5s forwards`;
    } else {
      return navigationDirection === "forward"
        ? `${fadeInFromRight} 0.5s forwards`
        : `${fadeInFromLeft} 0.5s forwards`;
    }
  };

  console.log("step: ", step);
  console.log("prevStep: ", prevStep);

  // Handle Login with email and password
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    const newError = {}; // initialize newError object here
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      navigate("/dashboard");
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case "auth/missing-password":
          newError.password = "You must enter your password.";
          break;
        case "auth/invalid-email":
          newError.password = "The email address is badly formatted.";
          break;
        case "auth/user-not-found":
          newError.password = "There is no user with this email.";
          break;
        case "auth/wrong-password":
          newError.password = "The password is incorrect.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setGLoading(true);
    const newError = {}; // initialize newError object here
    try {
      const provider = new GoogleAuthProvider();

      const credential = await signInWithPopup(auth, provider);
      const user = credential.user;

      console.log("user: ", user);

      // Check if user exists in the "members" collection
      const memberDocRef = doc(db, "members", user.uid);
      const memberDoc = await getDoc(memberDocRef);
      console.log("1");

      if (memberDoc.exists()) {
        // setProviderLoading(true);
        navigate("/dashboard");
        return; // Exit early since we found the user in "members"
      }
      console.log("2");

      // Check if user exists in the "employees" collection
      const employeeDocRef = doc(db, "employees", user.uid);
      const employeeDoc = await getDoc(employeeDocRef);
      console.log("3");

      if (employeeDoc.exists()) {
        // setProviderLoading(true);
        navigate("/dashboard");
        return; // Exit early since we found the user in "employees"
      }
      console.log("4");

      // If the user is not found in either collection, send them to the role choice
      // setUserCredential(credential); // Store the user credential
      // setSelectedRole("Pending"); // Set role selection to pending after successful sign up
    } catch (err) {
      console.error(err);
      newError.general =
        "An unknown error occurred. Please refresh and try again.";
      setErrors(newError);
    } finally {
      setGLoading(false);
    }
  };

  const handleNext = (value) => {
    console.log("value: ", value);
    if (value) setStep(value);
    else if (step === 2) {
      handleLogin();
    } else {
      setStep(step + 1);
    }
  };
  const handleBack = () => setStep(1);

  const handleSmsLogin = () => {
    handleSendSmsCode();
    setStep(3);
  };

  const handleSendSmsCode = async () => {
    setSmsStatus("sending"); // Set status to 'sending' before the operation
    try {
      console.log("sending code");
      await sendSmsCode({ email: values.email });
      setSmsStatus("success"); // Set status to 'success' after successful operation
      setSmsError("");
    } catch (error) {
      console.error("Error sending SMS: ", error);
      setSmsStatus("error"); // Set status to 'error' in case of failure
      setSmsError(
        error.message ||
          "An error occurred while sending the verification code."
      );
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          const claims = idTokenResult.claims;

          // Check if the custom claims contain a specific role
          if (claims.role) {
            console.log("User has a role, navigating to dashboard.");
            navigate("/dashboard");
          } else {
            console.log("User does not have a specified role.");
            setLoading(false);
            // Optionally, handle users without the specific role
          }
        } catch (error) {
          console.error("Error fetching user token: ", error);
          setLoading(false);
        }
      } else {
        // User is not signed in
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [navigate]);

  return (
    <SimplePage>
      <Box
        variant="outlined"
        sx={{
          padding: { xs: 1, sm: 3 },
          borderRadius: "15px",
          // border: { xs: "none", sm: "1px solid rgba(0, 0, 0, 0.12)" },
          overflow: "hidden",
          maxWidth: "400px",
          width: "100%",
          minHeight: "300px",
          margin: "0 auto",
          position: "relative",
          display: "flex", // Use flex layout
          flexDirection: "column", // Stack children vertically
          boxSizing: "border-box",
        }}
      >
        {step === 1 && (
          <Card
            elevation={0}
            sx={{
              animation: getAnimation(), // Apply the animation here
            }}
          >
            <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
              Login
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ marginBottom: "1em" }}
            >
              Don't have an account?{" "}
              <Link
                to="/signup"
                style={{ color: "inherit", textDecoration: "underline" }}
              >
                Sign up
              </Link>
            </Typography>
            <EmailInput
              onNext={handleNext}
              values={values}
              handleChange={handleChange}
              onSmsLogin={handleSmsLogin}
              errors={errors}
              loading={loading}
              handleGoogleSignIn={handleGoogleSignIn}
              gLoading={gLoading}
            />
          </Card>
        )}
        {step === 2 && (
          <Card
            elevation={0}
            sx={{
              animation: getAnimation(), // Apply the animation here
            }}
          >
            <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
              Enter password
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ marginBottom: "1em" }}
            >
              Use your password to login to your account.
            </Typography>
            <PasswordInput
              onBack={handleBack}
              onNext={handleNext}
              onSmsLogin={handleSmsLogin}
              values={values}
              handleChange={handleChange}
              errors={errors}
              setErrors={setErrors}
              loading={loading}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              handleLogin={handleLogin}
            />
          </Card>
        )}
        {step === 3 && (
          <Card
            elevation={0}
            sx={{
              animation: getAnimation(), // Apply the animation here
            }}
          >
            <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
              Enter 4-digit code
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ marginBottom: "1em" }}
            >
              Your code may take a few moments to arrive.
            </Typography>
            <SmsVerification
              onBack={handleBack}
              navigate={navigate}
              onSendSmsCode={handleSendSmsCode}
              email={values?.email || ""}
              smsStatus={smsStatus}
              smsError={smsError}
            />
          </Card>
        )}

        {step === 4 && <RoleSelection userCredential={userCredential} />}
      </Box>
    </SimplePage>
  );
};

export default GeneralLogin;
