import {
  Timestamp,
  arrayUnion,
  doc,
  increment,
  setDoc,
} from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../firebase";

export const logPageVisit = async (path) => {
  // Get the current timestamp
  const timestamp = Timestamp.now();

  // Create a log entry
  const logEntry = {
    timestamp: timestamp,
    path: path,
  };

  let storedSessionId = localStorage.getItem("sessionId");
  if (storedSessionId) {
    try {
      await setDoc(
        doc(db, "sessions", storedSessionId),
        {
          lastVisited: new Date(),
          "~logs": arrayUnion(logEntry),
          totalPageViews: increment(1), // Increment totalPageViews by 1
        },
        { merge: true }
      );
    } catch (err) {
      console.log("Error logging page visit: ", err);
    }
  }
};

export const addQuestion = async (question) => {
  let storedSessionId = localStorage.getItem("sessionId");
  if (storedSessionId) {
    try {
      await setDoc(
        doc(db, "sessions", storedSessionId),
        {
          _questions: arrayUnion(question), // Add the question to the _questions array
        },
        { merge: true }
      );
    } catch (err) {
      console.log("Error adding question: ", err);
    }
  }
};

export const addField = async (fieldName, value) => {
  let storedSessionId = localStorage.getItem("sessionId");
  if (storedSessionId) {
    try {
      await setDoc(
        doc(db, "sessions", storedSessionId),
        {
          [fieldName]: arrayUnion(value), // Use the specified field name
        },
        { merge: true }
      );
    } catch (err) {
      console.log(`Error adding value to ${fieldName}: `, err);
    }
  }
};

export const logFieldTimestamp = async (field) => {
  let storedSessionId = localStorage.getItem("sessionId");
  if (storedSessionId) {
    try {
      await setDoc(
        doc(db, "sessions", storedSessionId),
        {
          [field]: new Date(), // Use square brackets to dynamically set the field name
        },
        { merge: true }
      );
    } catch (err) {
      console.log("Error logging page visit: ", err);
    }
  }
};

export const logAction = async (entry) => {
  // Get the current timestamp
  const timestamp = Timestamp.now();

  // Create a log entry
  const logEntry = {
    timestamp: timestamp,
    entry: entry,
  };

  let storedSessionId = localStorage.getItem("sessionId");
  if (storedSessionId) {
    try {
      await setDoc(
        doc(db, "sessions", storedSessionId),
        {
          "~logs": arrayUnion(logEntry),
        },
        { merge: true }
      );
    } catch (err) {
      console.log("Error logging page visit: ", err);
    }
  }
};

const useLogPageVisit = (path) => {
  useEffect(() => {
    logPageVisit(path);
  }, [path]); // Only run when path changes
};

export default useLogPageVisit;
