import { Divider, Typography, useTheme } from "@mui/material";
import React from "react";

function ContactDetails() {
  const theme = useTheme();

  return (
    <>
      <Typography variant="body1" color={"text.secondary"}>
        For all Washington locations:
      </Typography>
      <Typography variant="body1" fontWeight={"800"}>
        Call or text:{" "}
        <a
          href="tel:+13603366344"
          style={{
            textDecoration: "none",
            color: theme.palette.primary.main,
          }}
        >
          (360) 336-6344
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        For locations outside of Washington:
      </Typography>
      <Typography variant="body1" fontWeight={"800"}>
        Call or text:{" "}
        <a
          href="tel:+13603366344"
          style={{
            textDecoration: "none",
            color: theme.palette.primary.main,
          }}
        >
          (360) 336-6351
        </a>
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        General questions:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          info@ourLinkedLives.com
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Support:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:support@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          support@ourLinkedLives.com
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Billing:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          billing@ourLinkedLives.com
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Scheduling
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          scheduling@ourLinkedLives.com
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Hiring
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          hiring@ourLinkedLives.com
        </a>
      </Typography>
      {/* <Typography variant="body1">
                Feel free to reach out anytime. We're here to help!
              </Typography> */}
    </>
  );
}

export default ContactDetails;
