import { Edit, FilterList, Star } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../providers/SnackbarProvider";

import moment from "moment-timezone";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const BookingOptions = ({
  filters,
  setFilters,
  handleReset,
  visits,
  employees,
  bestMatchEmployee,
  selectedDate,
  setSelectedDate,
  onFilterVisits,
  onFilterVisitsDate,
}) => {
  const { showSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const buttonSize = isMobile ? "small" : "medium";

  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterResults, setFilterResults] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);

  const updateVisibleVisits = (date, filters) => {
    if (!employees) return;

    if (!Array.isArray(visits)) {
      console.error("Visits data is not an array or not loaded yet.");
      return null; // Or return a loading state/component
    }

    if (!selectedDate) return;

    // Assuming visits is some global array or fetched data.
    const { filterResults, filterEmployees } = filterVisits(visits, {
      date,
      ...filters,
    });
    onFilterVisitsDate(filterResults);
    setAvailableEmployees(filterEmployees);
  };

  const filterVisits = (visits, filters) => {
    if (!employees) return;

    if (!Array.isArray(visits)) {
      console.error("Visits data is not an array or not loaded yet.");
      return null; // Or return a loading state/component
    }
    const { date, duration, frequency, employeeIds, numEmployees } = filters;

    let filterResults = visits;

    if (duration) {
      filterResults = filterResults.filter(
        (visit) => visit.duration === duration
      );
    }

    if (frequency) {
      filterResults = filterResults.filter((visit) =>
        visit.frequency.includes(frequency)
      );
    }

    // console.log("visit.employeeIds: ", visit.employeeIds);

    if (numEmployees && numEmployees !== "all") {
      // Filter based on the number of employees
      const num = parseInt(numEmployees);
      filterResults = filterResults.filter(
        (visit) => visit.employeeIds.length === num
      );
    }

    // Initially, assume all employees are available
    let filterEmployees = Object.keys(employees);

    if (employeeIds && employeeIds.length > 0) {
      if (numEmployees == 1) {
        // For numEmployees 1, consider all available slots for the selected employees
        filterResults = filterResults.filter((visit) =>
          employeeIds.some((id) => visit.employeeIds.includes(id))
        );
      } else {
        // Filter based on specific employeeIds
        filterResults = filterResults.filter((visit) =>
          employeeIds.every((id) => visit.employeeIds.includes(id))
        );

        // Calculate available employees based on the filtered visits
        filterEmployees = [
          ...new Set(filterResults.flatMap((visit) => visit.employeeIds)),
        ];
      }
    }

    if (date) {
      filterResults = filterResults.filter((visit) =>
        moment(visit.start).isSame(moment(date), "day")
      );
    }

    return {
      filterResults,
      filterEmployees,
    };
  };

  const formatFilterSummary = () => {
    const { duration, frequency, numEmployees, employeeIds } = filters;

    // Formatting Duration
    const durationText = duration
      ? `${duration} hour${duration > 1 ? "s" : ""}`
      : "";

    // Formatting Frequency
    const frequencyText =
      frequency === 0
        ? "One-time"
        : frequency === 1
        ? "Every week"
        : frequency === 2
        ? "Every 2-weeks"
        : "Every 4-weeks";

    // Formatting Number of Employees
    const numEmployeesText =
      numEmployees > 1 ? `, ${numEmployees} employees` : "";

    // Formatting Employee Names
    const employeeNames = employeeIds
      .map((id) => employees[id]?.firstName)
      .join(", ");
    const employeeNamesText =
      employeeIds.length > 0 ? `: ${employeeNames}` : "";

    return `${durationText}, ${frequencyText}${numEmployeesText}${employeeNamesText}`;
  };

  useEffect(() => {
    updateVisibleVisits(selectedDate, filters);
  }, [filters, visits, selectedDate]);

  useEffect(() => {
    if (!employees) return;
    if (!visits) return;

    const { filterResults = {}, filterEmployees = {} } = filterVisits(visits, filters) || {};
    onFilterVisits(filterResults);
    setFilterResults(filterResults);
    setAvailableEmployees(filterEmployees);
  }, [filters, visits]);

  useEffect(() => {
    // When numEmployees changes, reset the employeeId filter to empty

    setFilters((prevFilters) => ({
      ...prevFilters,
      employeeIds: [],
    }));
  }, [filters?.numEmployees]); // Add filters.numEmployees as a dependency of the effect

  // useEffect(() => {

  //   const uniqueDurations = [...new Set(visits.map((visit) => visit.duration))];

  //   console.log("uniqueDurations: ", uniqueDurations);
  //   console.log("filters.duration: ", filters.duration);

  //   if (!uniqueDurations.includes(filters.duration)) {
  //     setFilters((prevFilters) => {
  //       const newDuration = uniqueDurations.includes(2)
  //         ? 2
  //         : uniqueDurations[0] || "";
  //       return {
  //         ...prevFilters,
  //         duration: newDuration,
  //       };
  //     });
  //   }
  // }, [visits, filters.duration]);

  if (!Array.isArray(visits)) {
    console.error("Visits data is not an array or not loaded yet.");
    return null; // Or return a loading state/component
  }

  const handleApplyFilters = () => {
    setFiltersOpen(false);
    setSelectedDate(null);
  };

  return (
    <>
      {/* Minimized Filter View */}
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          transition: "background-color 0.3s ease", // Transition effect for background-color

          "&:hover": {
            backgroundColor: "#e0e0e0", // Noticeably darker on hover
          },
          py: 1,
          px: { xs: 1, sm: 4 },
          mx: { xs: "-24px" },
          mt: { xs: "-20px" },
          mb: 2,
          borderRadius: { xs: "0px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setFiltersOpen(!filtersOpen)}
      >
        <Box
          sx={{ display: "flex", flexGrow: 1, justifyContent: "space-between" }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                mr: 1,
              }} // Subtle color for the title
            >
              <FilterList sx={{ mr: 1 }} /> {/* Material UI Filter Icon */}
              Filters:
            </Typography>

            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {/* Subtle color for the summary text */}
              {formatFilterSummary()}
            </Typography>
          </Box>
          <Button
            size={buttonSize}
            startIcon={<Edit />}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Edit
          </Button>
        </Box>
      </Box>

      {/* Expanded Filters */}
      {/* <Stack spacing={2} direction={{ xs: "column", sm: "column", md: "row" }}>
        <Collapse in={!filtersOpen}> */}

      <ResponsiveDialog
        title={"Booking Filters"}
        open={filtersOpen}
        onClose={handleApplyFilters}
        dialogActions={
          <Box
            sx={{
              display: { xs: "flex", sm: "block" },
              flexDirection: { xs: "column-reverse", sm: "row" },

              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Button
              onClick={handleReset}
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
                textTransform: "none",
                mr: { xs: 0, sm: 1 },
              }}
              disableElevation={true}
              variant="outlined"
              color="primary"
            >
              Reset
            </Button>
            <Button
              onClick={handleApplyFilters}
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
                textTransform: "none",
                mb: { xs: 1, sm: 0 },
              }}
              disableElevation={true}
              variant="contained"
              color="primary"
              disabled={filterResults.length === 0}
            >
              {filterResults.length === 0
                ? "No results"
                : `Show ${filterResults.length} ${
                    filterResults.length === 1 ? "result" : "results"
                  }`}
            </Button>
          </Box>
        }
        width={"600px"}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body1" gutterBottom>
            Set your filters to see available visit times.
          </Typography>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Duration</InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              label="Duration"
              value={filters.duration}
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  duration: e.target.value,
                }))
              }
            >
              {[...new Set(visits.map((visit) => visit.duration))]
                .sort((a, b) => a - b) // Sort in ascending order
                .map((duration) => (
                  <MenuItem key={duration} value={duration}>
                    {duration} {duration > 1 ? "hours" : "hour"}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel>Frequency</InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              value={filters.frequency}
              label="Frequency"
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  frequency: e.target.value,
                }))
              }
            >
              {[
                { value: 0, label: "One-time" },
                { value: 1, label: "Every week" },
                { value: 2, label: "Every 2-weeks" },
                { value: 4, label: "Every 4-weeks" },
              ].map((freq) => (
                <MenuItem
                  key={freq.value}
                  value={freq.value}
                  disabled={
                    !visits.some((visit) =>
                      visit.frequency.includes(freq.value)
                    )
                  }
                >
                  {freq.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel>
              {filters.numEmployees > 1 ? "Employees" : "Employee"}
            </InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              value={filters.employeeIds}
              label={filters.numEmployees > 1 ? "Employees" : "Employee"}
              onChange={(e) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  employeeIds: e.target.value,
                }));
                setSelectOpen(false); // Close the select menu
              }}
              open={selectOpen}
              onClose={() => setSelectOpen(false)}
              onOpen={() => setSelectOpen(true)}
              multiple
              renderValue={(selected) => (
                <Grid container alignItems="center" justifyContent="flex-start">
                  <AvatarGroup max={4}>
                    {/* {selected.map((id) => {
                            const employee = employees.find(
                              (emp) => emp.id === id
                            ); */}
                    {selected.map((id) => {
                      const employee = employees[id];
                      return (
                        <Avatar
                          key={id}
                          src={employee?.avatarUrl}
                          sx={{ width: 24, height: 24 }}
                          alt={employee?.firstName}
                        />
                      );
                    })}
                  </AvatarGroup>
                </Grid>
              )}
            >
              {employees && Object.keys(employees).length > 0 ? (
                Object.entries(employees).map(
                  ([id, employee], index, array) => (
                    <MenuItem
                      key={id}
                      value={id}
                      sx={{
                        marginBottom: index !== array.length - 1 ? 1 : 0,
                      }}
                      disabled={!availableEmployees.includes(id)}
                    >
                      <Grid container alignItems="center">
                        <Checkbox checked={filters.employeeIds.includes(id)} />
                        <Grid item marginRight={2}>
                          <Avatar
                            src={employee?.avatarUrl}
                            sx={{ width: 24, height: 24 }}
                            alt={employee?.firstName}
                          />
                        </Grid>
                        <Grid item marginRight={2}>
                          <Typography>
                            {employee?.firstName}{" "}
                            {employee?.lastName?.charAt(0)}.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          marginRight={2}
                          display={"flex"}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {id === bestMatchEmployee && (
                            <Tooltip
                              enterTouchDelay={0}
                              title="Great match for the tasks you want completed."
                            >
                              <SvgIcon color="action" style={{ color: "gold" }}>
                                <Star />
                              </SvgIcon>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  )
                )
              ) : (
                <MenuItem disabled>
                  <div>No employees found.</div>
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel># of Employees</InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              value={filters.numEmployees}
              label="Number of Employees"
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  numEmployees: e.target.value,
                }))
              }
            >
              {[...new Set(visits.map((visit) => visit.employeeIds.length))]
                .sort((a, b) => a - b) // Sort in ascending order
                .map((numEmployees) => (
                  <MenuItem key={numEmployees} value={numEmployees}>
                    <Typography>{numEmployees}</Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
      </ResponsiveDialog>
      {/* </Collapse>
      </Stack> */}
    </>
  );
};

export default BookingOptions;
