import {
  AttachMoney,
  CheckRounded,
  CloseRounded,
  PersonRounded,
  QuestionMarkRounded,
} from "@mui/icons-material";
import { Avatar, Badge, useTheme } from "@mui/material";
import React from "react";

const getStatusProps = (status) => {
  switch (status) {
    case "accepted" || "confirmed":
      return {
        badgeColor: "lightgreen",
        icon: <CheckRounded style={{ color: "darkgreen", fontSize: 16 }} />,
      };
    case "declined":
      return {
        badgeColor: "lightcoral",
        icon: <CloseRounded style={{ color: "darkred", fontSize: 16 }} />,
      };
    case "needsAction":
      return {
        badgeColor: "lightgrey",
        icon: <QuestionMarkRounded style={{ color: "grey", fontSize: 16 }} />,
      };
    case "unavailable":
      return {
        badgeColor: "lightgrey",
        icon: <CloseRounded style={{ color: "grey", fontSize: 16 }} />,
      };
    case "available":
      return {
        badgeColor: "lightgrey",
        icon: <CheckRounded style={{ color: "grey", fontSize: 16 }} />,
      };
    case "refunded":
      return {
        badgeColor: "lightgreen",
        icon: <AttachMoney style={{ color: "grey", fontSize: 16 }} />,
      };
    default:
      return {
        badgeColor: "lightgrey",
        icon: <QuestionMarkRounded style={{ color: "grey", fontSize: 16 }} />,
      };
  }
};

const AvatarStatusBadge = ({
  status,
  avatarUrl,
  displayName,
  index = -1,
  showBadge = true,
}) => {
  const { badgeColor, icon } = getStatusProps(status);

  const theme = useTheme();

  const getComplementaryColor = (index) => {
    const colors = [
      theme.palette.complementary.color1,
      theme.palette.complementary.color2,
      theme.palette.complementary.color3,
      theme.palette.complementary.color4,
      theme.palette.complementary.color5,
      theme.palette.complementary.color6,
    ];

    return colors[index % colors.length];
  };

  const borderStyles = {
    border:
      index != -1
        ? `5px solid ${getComplementaryColor(index)}`
        : `5px solid lightgray`, // Outline with complementary color
  };

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="solid"
      badgeContent={showBadge ? icon : null}
      sx={{
        "& .MuiBadge-badge": {
          bgcolor: badgeColor, // dynamic background color based on status
          width: "16px",
          // ... other styles you might want to apply
        },
      }}
    >
      {avatarUrl ? (
        <Avatar src={avatarUrl} sx={{ ...borderStyles }}>
          {displayName?.charAt(0)}
        </Avatar>
      ) : (
        <Avatar sx={{ ...borderStyles }}>
          <PersonRounded />
        </Avatar>
      )}
    </Badge>
  );
};

export default AvatarStatusBadge;
