import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../../firebase";

const NotificationsTab = ({
  memberId,
  memberPrivateData,
  showTitle = true,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  console.log("NotificationsTab - memberPrivateData: ", memberPrivateData);

  const handleToggle = async (event) => {
    const { name, checked } = event.target;
    const [medium, type] = name.split("_");
    const updatedNotifications = {
      ...memberPrivateData?.notifications,
      [type]: {
        ...memberPrivateData?.notifications?.[type],
        [medium]: checked,
      },
    };

    // Update in Firebase
    const memberPrivateDocRef = doc(db, "membersPrivate", memberId);

    try {
      await updateDoc(memberPrivateDocRef, {
        notifications: updatedNotifications,
      });
    } catch (error) {
      console.error("Error updating notifications", error);
    }
  };

  const notificationTypes = [
    {
      type: "bookingConfirmation",
      title: "Confirmations",
      description: "Receive booking confirmation messages.",
      minRequired: 2,
    },
    {
      type: "visitCancellations",
      title: "Cancellations",
      description: "Receive visit cancellation messages.",
      minRequired: 2,
    },
    // {
    //   type: "visitUpdates",
    //   title: "Visit Updates",
    //   description: "Receive updates for visit or employee changes.",
    // },
    {
      type: "visitReminders",
      title: "Reminders",
      description: "Get reminders for upcoming visits.",
      minRequired: 2,
    },
    {
      type: "visitReviews",
      title: "Reviews",
      description: "Receive messages to review visits.",
    },
    {
      type: "newHelpers",
      title: "New Helpers",
      description: "Receive updates for new helpers.",
    },
    {
      type: "followedHelpers",
      title: "Followed Helpers",
      description: "Get updates for followed helpers.",
    },
    // {
    //   type: "newsletters",
    //   title: "Newsletters",
    //   description: "Receive our monthly newsletters.",
    // },
    {
      type: "promotions",
      title: "Promotions",
      description: "Get notified about new promotions deals.",
    },
  ];

  const columnSpacing =
    isMobile && (memberPrivateData?.phone2 || memberPrivateData?.email2)
      ? memberPrivateData?.phone2 && memberPrivateData?.email2
        ? 3
        : memberPrivateData?.phone2 || memberPrivateData?.email2
        ? 4
        : 6
      : memberPrivateData?.phone2 && memberPrivateData?.email2
      ? 2
      : memberPrivateData?.phone2 || memberPrivateData?.email2
      ? 2.666
      : 4;
  return (
    <Box sx={{ mt: { xs: 4, sm: 0 }, mb: { xs: 50, sm: 2 } }}>
      {showTitle && (
        <>
          <Typography variant="h6" gutterBottom>
            Notification Settings
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Manage how you receive updates and alerts. You can choose your
            preferred delivery method for different notifications.
          </Typography>
        </>
      )}
      <Box
        sx={{
          position: "sticky",
          top: -32,
          bgcolor: "background.paper",
          zIndex: 1,
        }}
      >
        {/* Sticky header box */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            bgcolor: "background.paper",
            height: "60px",
            marginTop: "-20px",
          }}
        >
          {!(
            isMobile &&
            (memberPrivateData?.phone2 || memberPrivateData?.email2)
          ) && (
            <Grid item xs={4}>
              <Typography variant="subtitle2">Type</Typography>
            </Grid>
          )}
          <Grid item xs={columnSpacing}>
            <Typography variant="subtitle2" align="center">
              SMS{memberPrivateData?.phone2 && (isMobile ? " 1st" : " 1st")}
            </Typography>
            <Typography
              variant="caption"
              align="center"
              noWrap
              color="text.secondary"
              sx={{
                display: "block",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {memberPrivateData.phone}
            </Typography>
          </Grid>
          <Grid item xs={columnSpacing}>
            <Typography variant="subtitle2" align="center">
              Email
              {memberPrivateData?.email2 && (isMobile ? " 1st" : " 1st")}
            </Typography>
            <Typography
              variant="caption"
              align="center"
              noWrap
              color="text.secondary"
              sx={{
                display: "block",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {memberPrivateData.email}
            </Typography>
          </Grid>
          {memberPrivateData?.phone2 && (
            <Grid item xs={columnSpacing}>
              <Typography variant="subtitle2" align="center">
                SMS {isMobile ? "2nd" : "2nd"}
              </Typography>
              <Typography
                variant="caption"
                align="center"
                noWrap
                color="text.secondary"
                sx={{
                  display: "block",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {memberPrivateData.phone2}
              </Typography>
            </Grid>
          )}
          {memberPrivateData?.email2 && (
            <Grid item xs={columnSpacing}>
              <Typography variant="subtitle2" align="center">
                Email {isMobile ? "2nd" : "2nd"}
              </Typography>
              <Typography
                variant="caption"
                align="center"
                noWrap
                color="text.secondary"
                sx={{
                  display: "block",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {memberPrivateData.email2}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Divider sx={{ marginY: "10px" }} />
      </Box>
      {notificationTypes.map(({ type, title, description, minRequired }) => {
        const notificationSettings =
          memberPrivateData?.notifications?.[type] || {};

        // Build list of available mediums based on memberPrivateData
        const mediums = ["sms", "email"];
        if (memberPrivateData?.phone2) {
          mediums.push("sms2");
        }
        if (memberPrivateData?.email2) {
          mediums.push("email2");
        }

        // Count the number of checked mediums, treating undefined as checked
        const numChecked = mediums.reduce((count, medium) => {
          return count + (notificationSettings[medium] !== false ? 1 : 0);
        }, 0);

        return (
          <Grid container spacing={2} key={type} alignItems="center">
            <Grid
              item
              xs={
                isMobile &&
                (memberPrivateData?.phone2 || memberPrivateData?.email2)
                  ? 12
                  : 4
              }
              sm={4}
            >
              <Typography variant="body1" fontWeight={600} gutterBottom>
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </Grid>
            {mediums.map((medium) => {
              const isChecked = notificationSettings[medium] ?? true;
              // Disable the checkbox if it's checked and numChecked is at the minRequired
              const isDisabled =
                isChecked && minRequired && numChecked <= minRequired;

              return (
                <Grid
                  item
                  xs={columnSpacing}
                  sx={{ textAlign: "center" }}
                  key={medium}
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={handleToggle}
                    name={`${medium}_${type}`}
                    disabled={isDisabled}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider sx={{ marginY: "10px" }} />
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default NotificationsTab;
