import { ChatOutlined } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import RatingChip from "../../RatingChip";

const RatingReviewListItem = ({ visitData }) => {
  const { rating = null, review = "" } = visitData || {};

  return (
    <>
      {rating && (
        <ListItem sx={{ paddingX: "0px" }}>
          <ListItemIcon sx={{ minWidth: "48px" }}>
            <ChatOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={<RatingChip rating={rating} />}
            secondary={review}
          />
        </ListItem>
      )}
    </>
  );
};

export default RatingReviewListItem;
