import { Close } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useSnackbar } from "../providers/SnackbarProvider";
import EmployeeDeclineVisit from "./EmployeeDeclineVisit";

const DeclineVisitButton = ({
  responseStatus,
  visit,
  visitId,
  currentEmployeeId,
  customStyles,
  handleDecline,
  loading,
  setLoading,
  phone = "",
}) => {
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleDeclineClick = () => {
    setDeclineDialogOpen(true);
  };

  return (
    <>
      <Button
        style={customStyles}
        disabled={loading}
        onClick={() => responseStatus !== "declined" && handleDeclineClick()}
        sx={{ textTransform: "none" }}
      >
        {loading === "decline" ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <>
            <Close sx={{ mr: 1 }} />
            Decline
          </>
        )}
      </Button>
      <EmployeeDeclineVisit
        open={declineDialogOpen}
        setOpen={setDeclineDialogOpen}
        visit={visit}
        visitId={visitId}
        handleDecline={handleDecline}
        loading={loading}
        setLoading={setLoading}
        employeeId={currentEmployeeId}
        phoneNumber={phone}
      />
    </>
  );
};

export default DeclineVisitButton;
