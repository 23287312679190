import {
  Avatar,
  Box,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import { useHelpers } from "../../providers/HelpersProvider";
import { getEmployeeServiceString } from "../../services/employeeServices";
import { getEmployeeStatus } from "../../services/locationServices";
import EmployeeDrawer from "../drawers/EmployeeDrawer";

const EmployeeListItem = ({
  employeeData,
  memberId = "", // used for setting favorite
  memberPrivateData = {}, // used for setting favorite
  userRole = "member",
  coordinates = { lat: 0, lng: 0 },
}) => {
  const {
    id: employeeId = "",
    firstName = "",
    lastName = "",
    avatarUrl = "",
    age = 20,
    gender = "male",
  } = employeeData || {};

  const { calculateTotalAvailability, getNextAvailableDate } = useHelpers();

  const nextAvailableDate = getNextAvailableDate({ employeeId });
  const totalAvailableHours = calculateTotalAvailability({ employeeId });

  const {
    inArea = false,
    futureAvailability = 0,
    end: serviceEnd = undefined,
    start: serviceStart = undefined,
  } = getEmployeeStatus(
    coordinates?.lat,
    coordinates?.lng,
    employeeData.areas,
    employeeData.availability
  );

  const { openDrawer } = useDrawer();
  console.log("employeeData: ", employeeData);
  const isAvailableToday = employeeData?.isAvailableToday;
  const bookingDaysInAdvance = employeeData?.bookingDaysInAdvance;

  const handleClick = () => {
    openDrawer(EmployeeDrawer, {
      employeeId,
      userRole,
      coordinates: [],
      memberId,
    });
  };

  return (
    <ListItem disablePadding alignItems="flex-start">
      <ListItemButton onClick={handleClick}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "flex-start" },
            gap: 2,
            width: "100%",
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            sx={{
              alignItems: "center", // Conditional styling based on 'isMobile'
              width: "100%",
            }}
          >
            <Avatar
              src={avatarUrl}
              alt={firstName}
              sx={{ width: 115, height: 115 }}
            />
            <Stack
              direction="column"
              minWidth="100px"
              sx={{
                alignItems: "flex-start", // Conditional styling based on 'isMobile'
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  {firstName} {lastName.charAt(0) + "."}
                </Typography>
                {/* <Tooltip title="Set as favorite Link">
                <IconButton onClick={() => setIsFavorite(!isFavorite)}>
                  {isFavorite ? (
                    <Star color="primary" /> // Changed to StarIcon for filled state
                  ) : (
                    <StarOutline color="action" /> // Changed to StarBorderIcon for outlined state
                  )}
                </IconButton>
              </Tooltip>
              <Button
                startIcon={<CalendarTodayIcon />}
                variant="text"
                color="primary"
              >
                Book Visit
              </Button> */}
              </Box>
              <Typography variant="body2" color="textSecondary">
                Age: {age}, {gender}
              </Typography>
              <Typography
                sx={{
                  backgroundColor: "#F5F5F5",
                  mt: 1,
                  px: 1,
                  py: 0.5,
                  borderRadius: "5px",
                }}
              >
                {getEmployeeServiceString(
                  inArea,
                  futureAvailability,
                  bookingDaysInAdvance,
                  isAvailableToday,
                  serviceStart,
                  serviceEnd,
                  nextAvailableDate,
                  totalAvailableHours
                )}
              </Typography>
              {/* {employeeData.skills.map((skill) => (
                <Chip
                  key={skill}
                  label={skill}
                  size="small"
                  style={{ margin: "4px" }}
                />
              ))} */}
              {/* <Typography variant="body2" color="textSecondary">
                Total visits: {employeeData.totalVisits}
              </Typography> */}
              {/* {employeeData?.rating && (
                <Typography variant="body2" color="textSecondary">
                  Rating: {employeeData.rating.toFixed(1)}
                </Typography>
              )} */}
            </Stack>
          </Stack>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default EmployeeListItem;
