import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  CardContent,
  IconButton,
  Radio,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React from "react";
import { db, functions } from "../../firebase";
import { getActiveEmployeesAtLocation } from "../../services/locationServices";

const AddressCard = ({
  addressKey,
  address,
  isDefault,
  onDelete,
  memberId,
  stripeId,
  addresses,
}) => {
  const theme = useTheme();

  const handleRadioChange = async () => {
    try {
      // Create a reference to the specific 'data' document inside the 'private' subcollection of the member
      const memberDataDocRef = doc(db, "members", memberId);

      // Call the function to get employees in the area
      const employeesInArea = await getActiveEmployeesAtLocation(
        address.lat,
        address.lng
      );

      // Extract helper IDs into an array
      const helpersArr = Object.keys(employeesInArea);

      // Determine status based on the number of helpers
      const status = helpersArr.length > 0 ? "active" : "waitlist";

      await updateDoc(memberDataDocRef, {
        location: {
          city: address.city,
          state: address.state,
          zipCode: address.zipCode, // Assuming you have zipCode in your address object
          geohash: address.geohash.substring(0, 6),
        },
        timeZoneId: address.timeZoneId,
        // status, // status is set from MemberProvider when geohash changes
      });

      // NEW: Update the membersPrivate collection
      const memberPrivateDocRef = doc(db, "membersPrivate", memberId);

      await setDoc(
        memberPrivateDocRef,
        {
          location: {
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
            line1: address.line1,
            line2: address.line2,
            geohash: address.geohash,
            lat: address.lat,
            lng: address.lng,
            directions: address.directions,
          },
          timeZoneId: address.timeZoneId,
          defaultAddress: addressKey,
          helpersArr,
          // status, // status is set from MemberProvider when geohash changes
        },
        { merge: true }
      );

      const updateStripeUser = httpsCallable(functions, "updateStripeUser");
      updateStripeUser({
        newAddress: {
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          zipCode: address.zipCode,
        },
        memberId,
        stripeId,
      });

      console.log("Default address set successfully:", addressKey);
    } catch (error) {
      console.error("Error setting default address:", error);
    }
  };

  const showDelete = Object.keys(addresses).length > 1;
  console.log("showDelete: ", showDelete);

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
        width: "100%",
        ...(isDefault ? { borderColor: theme.palette.primary.main } : {}),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: theme.spacing(1),
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={isDefault}
            onChange={handleRadioChange}
            value="defaultAddress"
            name="defaultAddress"
            inputProps={{ "aria-label": "Default address" }}
          />
          <Typography
            variant="body1"
            fontWeight="600"
            color={isDefault ? "primary" : "textPrimary"}
          >
            {isDefault ? "Default Address" : ""}
          </Typography>
        </div>

        <div>
          {/* <IconButton onClick={() => onEdit(addressKey, address)}>
              <EditIcon />
            </IconButton> */}
          {showDelete && (
            <IconButton onClick={() => onDelete(addressKey)}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
      <CardContent>
        <Typography variant="h6" fontWeight="600">
          {address.line1 || "N/A"}
        </Typography>
        <Typography variant="body1">{address.line2 || ""}</Typography>
        <Typography variant="body1">
          {address.city || "N/A"}, {address.state || "N/A"}{" "}
          {address.zipCode || "N/A"}
        </Typography>
        {/* <Typography
            variant="body1"
            style={{ marginTop: "10px", fontWeight: "bold" }}
          >
            People with access
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="body2" align="left">
                    Susan M. (you)
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color="textSecondary"
                  >
                    susan.m@example.com
                  </Typography>
                </div>
                <Typography variant="body2" align="right">
                  Billing
                </Typography>
              </div>
            </div>

            <div style={{ marginBottom: "5px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="body2" align="left">
                    Duncan M.
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color="textSecondary"
                  >
                    duncan.m@example.com
                  </Typography>
                </div>
                <Typography variant="body2" align="right">
                  Editor
                </Typography>
              </div>
            </div>

            <div style={{ marginBottom: "5px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="body2" align="left">
                    Bob P.
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color="textSecondary"
                  >
                    bob.p@example.com
                  </Typography>
                </div>
                <Typography variant="body2" align="right">
                  Viewer
                </Typography>
              </div>
            </div>
          </div> */}
      </CardContent>
    </Card>
  );
};

export default AddressCard;
