import { Today } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { useEmployee } from "../../../providers/EmployeeProvider";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";
import ScheduleNextVisit from "./ScheduleNextVisit";

const ScheduleNextVisitItem = ({ visitData }) => {
  const { start = Timestamp.now(), timeZoneId = "America/Los_Angeles" } =
    visitData || {};

  const { employeeData } = useEmployee();
  const { currentUser } = useAuth();

  const eName = employeeData?.firstName;
  const eId = currentUser.uid;

  // dialogs
  const [openScheduleVisit, setOpenScheduleVisit] = useState(false);

  // After the visitData start but before midnight
  const isAfterVisit = moment().isBetween(
    moment(start?.toDate()).tz(timeZoneId),
    moment(start?.toDate()).tz(timeZoneId).endOf("day"),
    null,
    "()"
  );

  const handleOpenScheduleVisit = () => {
    setOpenScheduleVisit(true);
  };

  const handleClose = () => {
    setOpenScheduleVisit(false);
  };

  return (
    <>
      {isAfterVisit && (
        <Button
          onClick={handleOpenScheduleVisit}
          startIcon={<Today />}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{textTransform: "none", height: "44px"}}
        >
          <Typography>Schedule next visit</Typography>
        </Button>
      )}

      <ResponsiveDialog
        title={"Schedule a visit"}
        open={openScheduleVisit}
        onClose={() => setOpenScheduleVisit(false)}
        width="800px"
        fullHeight={true}
        maxHeight="583px"
      >
        {openScheduleVisit && (
          <ScheduleNextVisit
            eName={eName}
            eId={eId}
            memberId={visitData?.member}
            onClose={handleClose}
          />
        )}
      </ResponsiveDialog>
    </>
  );
};

export default ScheduleNextVisitItem;
