// -- components/memberDashboard/Profile.js
import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import AddAddress from "../../../components/AddAddress";
import ResponsiveDialog from "../../../components/dialogs/ResponsiveDialog";
import { db } from "../../../firebase";
import AddressCard from "../AddressCard"; // adjust the path as per your directory structure

// addresses = data?.account?.addresses

const AddressTab = ({
  memberData,
  memberAdminData,
  memberPrivateData,
  accountData,
  memberId,
  userRole = "member",
}) => {
  const { firstName = "", lastName = "" } = memberData || {};
  const { accountId = "", stripeId = "" } = memberAdminData || {};
  const {
    defaultAddress = "",
    phone = "",
    email = "",
  } = memberPrivateData || {};
  const { addresses = {} } = accountData || {};

  const [selectedAddressKey, setSelectedAddressKey] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);

  // console.log("memberId: ", memberId)
  // console.log("addresses: ", addresses)

  const handleEditAddress = (addressKey, address) => {
    console.log("Edit this address:", addressKey, address);
    // TODO: Logic to edit the address
  };

  const handleDeleteAddress = (addressKey) => {
    console.log("Delete this address:", addressKey);
    setSelectedAddressKey(addressKey);
    console.log("addressKey:", addressKey);
    setDeleteModalOpen(true);
  };

  const confirmDeleteAddress = async () => {
    try {
      if (selectedAddressKey) {
        // Update firebase
        const accountDocRef = doc(
          db,
          "accounts",
          accountId || memberId // if there's no accountId, the default is the memberId
        );

        // Delete the address from Firestore
        await updateDoc(accountDocRef, {
          [`addresses.${selectedAddressKey}`]: deleteField(), // This deletes the specific key from the map
        });

        // Again, maybe to trigger a refresh?
        // setData((currentData) => ({ ...currentData }));

        console.log("Address deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting address: ", error);
    } finally {
      setDeleteModalOpen(false);
      setSelectedAddressKey(null);
    }
  };

  // if (!data) return null;

  // if (loading) return <Typography variant="body1">Loading...</Typography>;

  return (
    <>
      <DialogContent>
        {userRole === "member" && (
          <>
            <Typography variant="h6" gutterBottom>
              Service Address
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              Manage and organize your saved locations. Keep your home, rental,
              or second home addresses up to date for quicker booking and
              navigation.
            </Typography>
          </>
        )}
        <Stack spacing={2} sx={{mr: "auto"}}>
          {addresses && Object.keys(addresses).length > 0 ? (
            Object.entries(addresses).map(([addressKey, address]) =>
              address ? (
                <AddressCard
                  key={addressKey}
                  addressKey={addressKey}
                  memberId={memberId}
                  stripeId={stripeId}
                  addresses={addresses}
                  address={address}
                  isDefault={addressKey === defaultAddress}
                  onEdit={handleEditAddress}
                  onDelete={handleDeleteAddress}
                />
              ) : null
            )
          ) : (
            <Typography
              align="center"
              color="text.secondary"
              justifyContent={"center"}
              sx={{
                px: 10,
                py: 3,
                my: 1,
                mx: "auto",
                borderRadius: "15px",
                border: "1px solid rgba(0,0,0,0.23)",
              }}
            >
              No service address
            </Typography>
          )}
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: { xs: "center", sm: "flex-start" },
          ml: { xs: 0, sm: 2 },
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Button
          variant="outlined"
          startIcon={<Add color="primary" />}
          onClick={() => setOpenAddAddress(true)}
          sx={{
            minWidth: { xs: "100%", sm: "200px" },
            height: "60px",
            textTransform: "none",
          }}
        >
          Add address
        </Button>
      </DialogActions>

      <ResponsiveDialog
        open={openAddAddress}
        onClose={() => setOpenAddAddress(false)}
        title={"Add Service Address"}
        width={"600px"}
        fullHeight={true}
      >
        <AddAddress
          memberId={memberId}
          memberPrivateData={memberPrivateData}
          memberData={memberData}
          accountId={accountId || memberId}
          stripeId={stripeId}
          onClose={() => setOpenAddAddress(false)}
          isAdmin={userRole === "admin"}
        />
      </ResponsiveDialog>

      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this address?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteAddress} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressTab;
