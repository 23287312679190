import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase"; // Adjust the path to your Firebase config

const useEmployeeData = (employeeId) => {
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState(null);
  const [employeeAdminData, setEmployeeAdminData] = useState(null);
  const [employeePrivateData, setEmployeePrivateData] = useState(null);
  const [visitsData, setVisitsData] = useState(null);

  useEffect(() => {
    if (!employeeId) return;

    setLoading(true);

    // Employee data listener
    const unsubEmployee = onSnapshot(
      doc(db, "employees", employeeId),
      (doc) => {
        setEmployeeData({ id: doc.id, ...doc.data() });
      }
    );

    // Employee private collection data listener
    const unsubEmployeePrivate = onSnapshot(
      doc(db, "employeesPrivate", employeeId),
      (doc) => {
        setEmployeePrivateData(doc.data());
      }
    );

    // Admin data listener
    const unsubAdmin = onSnapshot(
      doc(db, "employees", employeeId, "admin", "data"),
      (doc) => {
        setEmployeeAdminData(doc.data());
      }
    );

    setLoading(false);

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubEmployee();
      unsubAdmin();
      unsubEmployeePrivate();
    };
  }, [employeeId]);

  // Visits listener, similar to member hook approach, no manual unsubVisits management needed
  useEffect(() => {
    if (!employeeId) return;

    setLoading(true);

    const visitsQuery = query(
      collection(db, "visits"),
      where("employeeArr", "array-contains", employeeId)
    );

    const unsubscribe = onSnapshot(visitsQuery, (snapshot) => {
      // Convert snapshot docs into an object with visitId as keys
      const visitsData = snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = { ...doc.data(), id: doc.id };
        return acc;
      }, {});

      console.log("Subscribing to visits data");
      setVisitsData(visitsData);
      setLoading(false);
    });

    // Cleanup function for the visits listener
    return () => {
      console.log("Unsubscribing from visits data");
      unsubscribe();
    };
  }, [employeeId]);

  return {
    employeeData,
    employeeAdminData,
    employeePrivateData,
    visitsData,
    loading,
  };
};

export default useEmployeeData;
