import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import GuestHeader from "../../components/GuestHeader";

import { useTheme } from "@mui/material"; // Don't forget to import useTheme
import useLogPageVisit from "../../hooks/useLogPageVisit";
import WebFooter from "./sections/WebFooter";

function TermsAndConditionsPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useLogPageVisit("/terms-and-conditions");

  return (
    <div>
      <GuestHeader />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            paddingY: theme.spacing(4),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
              // height: "100%",
            }}
          >
            <Typography variant="h4" fontWeight="700" gutterBottom>
              Linked Lives Terms and Conditions Agreement.
            </Typography>
            <div>
              <p>Last Updated: Nov 12, 2024</p>
              <h4>Terms of Service Overview</h4>
              <p>
                These Terms of Service (“Terms”) apply to and govern your access
                to use of any “Service” that are owned, operated, or provided by
                Linked Lives, LLC, as well as any of its subsidiaries,
                divisions, and affiliates (“Linked Lives”).
              </p>
              <p>
                Linked Lives offers the Service to you conditioned upon your
                acceptance of all terms, conditions, policies, and notices
                stated herein or incorporated by reference. Please read these
                Terms carefully before using the Service or making any
                transaction. By making any transaction or otherwise using the
                Service in any manner, you acknowledge and accept that you have
                read and understood these Terms and you agree to be bound by
                them. You also acknowledge, agree, and consent to the terms of
                our Service, which is incorporated herein by reference. If for
                any reason you do not accept and agree to these Terms, then you
                must stop using the Service.
              </p>
              <p>
                If we make any material changes and you are registered as using
                the Service, we will also send an email to you at the last email
                address you provided to us. Your continued use of the Service
                after our provision of notice to you will constitute your
                affirmative acceptance to the modified Terms. If you do not
                agree to, or cannot comply with, the Terms as amended, then you
                must stop using the Service.
              </p>
              <p>
                LINKED LIVES IS NOT A MEDICAL PROVIDER AND DOES NOT PROVIDE
                MEDICAL OR EMERGENCY SERVICES OF ANY KIND. IF YOU ARE
                EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL “911”
                IMMEDIATELY.
              </p>
              <h4>Membership Agreement</h4>
              <p>
                Under the “Membership” program, persons can become a lifetime
                “Member” by creating their account and paying the one-time $50
                signup fee. A “Member” can then schedule their preferred “Link
                Employee” for help with household tasks at the Member’s
                residential address, called a “Visit”. A visit is scheduled on a
                one-time or recurring basis to provide the Member with routine
                household help. A visit can last anywhere from one hour, up to
                several hours, in hour increments. You understand that Linked
                Lives Employees are not medically trained nor licensed for
                technical work, and therefore will not be expected to perform
                any professional services including but not limited to home
                care, home health, carpentry, plumbing, electrical, roof work,
                hazardous waste removal, pesticides, interior or exterior
                painting, or operation of heavy machinery. In addition, you
                agree to supply all tools, equipment, materials, and supplies
                needed to complete the Visit tasks and understand that your Link
                Employee is not responsible for providing these items. In the
                event that you must reschedule or cancel a Visit, you may cancel
                up to 1 hour in advance for visits under 2 hours of total labor,
                and midnight the day before for visits over 2 hours of total
                labor. Cancellation within the windows above will incur a
                cancellation fee equal to 50% of the visit total charge.
              </p>
              {/* <h4>Link Day Agreement</h4>
              <p>
                Under the “Link Day” program, Linked Lives hosts a monthly
                community day of service that is separate from their
                “Membership” program. On Link Day, volunteers from our community
                are dispatched to the homes of participating seniors to assist
                with general household tasks free of charge. Link Day services
                do not fall under the usual scope of Member visits and are not
                performed by Link Employees, but by community volunteers. Just
                as with regular services, volunteers are not medically trained
                or licensed for technical work. The tasks they perform are
                restricted to non-hazardous, non-technical chores such as
                tidying, basic gardening, and other similar tasks. You agree to
                supply all tools, equipment, materials, and supplies needed for
                the volunteer to complete these tasks. Please note that, unlike
                regular visits, rescheduling or cancellations of Link Day
                services do not incur a cancellation fee, but we encourage
                notifying us as soon as possible to allow for volunteer schedule
                adjustments. Participation in Link Day services is voluntary and
                must be separately requested by you. For all services performed
                through the “Link Day” program, there will be zero charges or
                payments incurred for these services.
              </p> */}
              <h4>Payment Terms</h4>
              <p>
                All payments are made digitally. For Members on "AutoPay",
                Linked Lives will bill their card on file the morning after
                their visit to make the "Payment". For Members not on AutoPay,
                The "Invoice" is due by the date specified in the invoice. All
                If Payment is not received by the stated due date, Linked Lives
                reserves the right to attempt to charge your payment method
                on-file for the amount due. In case of failure to collect the
                overdue amount, Linked Lives shall suspend the Service until the
                Payment is received. With prior notice to you, Linked Lives
                reserves the right to determine and change any payment term,
                rate, mechanism, or structure. Linked Lives, in its sole
                discretion, may offer credits or refunds on a case-by-case
                basis. All Payments will be displayed in United States Dollars.
              </p>
              <h4>Payment Processing</h4>
              <p>
                Linked Lives uses Stripe (https://stripe.com/privacy) for
                payment processing. Stripe is a U.S. based financial
                infrastructure that allows businesses to accept payments online
                and in person. Stripe is used by many companies, including
                Amazon, Marriott, Shopify, and Google. Stripe securely processes
                all payment information, such as credit card and debit card
                numbers, which means that this information is never stored on
                our servers. By using Linked Lives, you also agree
                to Stripe's terms and policies for payment processing.
              </p>
              <p>
                You represent and warrant that you have the right to use any
                credit card or other means of payment that you provide to us.
                All billing information you provide to us must be current,
                complete, truthful, and accurate. You agree to promptly update
                your account and other information, including your email address
                and credit card numbers, and expiration dates, so that we can
                complete your transactions and contact you as needed. Providing
                any untruthful or inaccurate information is a breach of these
                Terms and may result in the cancellation of your payment. We
                reserve the right to refuse or cancel a payment for any reason
                including limitations on availability, inaccuracies, or errors
                in service or pricing information, or problems identified by our
                credit and fraud avoidance department. If your payment is
                canceled after your credit card (or other payment account) has
                been charged, we will issue a credit to your credit card (or
                other applicable payment account) in the amount of the charge.
                We will attempt to contact you if all or any portion of your
                payment is canceled or if additional information is required to
                accept your order.
              </p>
              <h4>Termination and Suspension of Services</h4>
              <p>
                Both Linked Lives and the Member hold the right to terminate or
                suspend use of the Service at any time and for any reason.
                Without limitation, Linked Lives may terminate or suspend your
                right to use the Service at its sole discretion, if you violate
                any term of this Agreement or any policy of Linked Lives posted
                on the Service, or if Linked Lives otherwise finds that you have
                engaged in unlawful or inappropriate behavior in connection with
                your use of the Service. Even after your Service is terminated
                or suspended, this Agreement will remain enforceable against you
                regarding your prior use of the Service. Linked Lives will not
                be liable to any party for any modification or discontinuance of
                the Service, in part or in its entirety.
              </p>
              <h4>Consent to Contact and Communication</h4>
              <p>
                By providing your phone number and email to us, you agree to be
                contacted by or on behalf of Linked Lives at the number or
                address you have provided, including phone call, email, letter,
                or text message, to receive communications relating to the
                Service (such as Visit reminders, scheduling, service reviews,
                and payments). The submission of sensitive or account-related
                information through text communication is strictly prohibited
                and should not be carried out under any circumstances. Data
                rates may apply.
              </p>
              <h4>Disclaimer of Liability</h4>
              <p>
                Although Linked Lives upholds high standards of service, neither
                Linked Lives, nor any of its subsidiaries or affiliates, assumes
                any responsibility for the acts or omissions of any Linked Lives
                Employees or Members, such as property damage or bodily injury
                that may result from the services provided. You acknowledge that
                your reliance on any Linked Lives employees or members via the
                Service is solely at your own risk and you assume full
                responsibility for all risks associated herewith. Linked Lives
                is not responsible for any indirect, incidental, consequential,
                special, exemplary, or punitive damages related to the service.
                Your sole remedy for dissatisfaction with the service is to stop
                using it.
              </p>
              <h4>Indemnification Agreement</h4>
              <p>
                Except to the extent prohibited under applicable law, you agree
                to indemnify, defend, and hold harmless Linked Lives and its
                officers, directors, employees, and agents from and against any
                claims, losses, liabilities, damages, costs, or expenses,
                including attorneys’ fees and costs, that may arise from or in
                connection with (a) your use of the Service, (b) violation of
                these Terms by you, and (c) the acts or omissions of any other
                Linked Lives Employee or Member, or participating senior. If you
                fail to promptly indemnify and defend a covered claim, Linked
                Lives shall have the right to defend itself, and in such case,
                you shall promptly reimburse Linked Lives for all of its
                associated costs and expenses. Linked Lives reserves the right
                to assume the exclusive defense and control of any matter
                otherwise subject to your indemnity.
              </p>
              <h4>Dispute Resolution through Arbitration</h4>
              <p>
                You and Linked Lives agree that any and all disputes or claims
                that have arisen or may arise between you and Linked Lives that
                relate in any way to your participation in, use, or access of
                the Service shall be resolved through confidential, final and
                binding arbitration rather than in court. The Federal
                Arbitration Act (“FAA”) governs the interpretation and
                enforcement of this Agreement to arbitrate. If a dispute arises
                between you and Linked Lives, we want to learn about and address
                your concerns and, if we are unable to do so to your
                satisfaction, to provide you with a neutral and cost-effective
                means of resolving the dispute quickly. Please contact us
                regarding disputes between you and Linked Lives related to the
                Service.
              </p>
              <h4>Severability</h4>
              <p>
                You and Linked Lives agree that if any portion of these Terms is
                found illegal or unenforceable, that portion will be construed
                to be enforceable to the maximum extent permitted by law and the
                remainder of the Terms will remain valid and enforceable to the
                maximum extent permitted by law.
              </p>
              <h4>Questions and How to Contact Us</h4>
              <p>
                If you have any questions regarding these Terms or otherwise
                need to contact us, please email us at info@ourlinkedlives.com
                or call us via telephone at 360-336-6344.
              </p>
              <h4>Acceptance</h4>
              <p>
                By checking the box next to "I have read and agree to the Terms
                and Conditions" during the account creation process, or by
                signing up over the phone and using our services, you are
                indicating your legal signature and acknowledgement that you
                have read, understand, and agree to the Terms and Conditions of
                Linked Lives Services outlined in this document. You acknowledge
                that you are under no pressure or duress to agree to these terms
                and that you have had a reasonable opportunity to review the
                Terms and Conditions. You also acknowledge that participation in
                this service is voluntary. Your ongoing use of Linked Lives
                services signifies your acceptance of any changes to these Terms
                and Conditions.
              </p>
              <p>
                THIS LEGAL SIGNATURE AND ACKNOWLEDGEMENT SERVES AS CONFIRMATION
                OF BOTH PARTIES' AGREEMENT TO THE TERMS CONTAINED WITHIN THIS
                AGREEMENT, INCLUDING THE VOLUNTARY SURRENDER OF CERTAIN LEGAL
                RIGHTS, AS OUTLINED ABOVE IN THE TERMS AND CONDITIONS DOCUMENT.
              </p>
              <p>
                Thank you for taking the time to read through these Terms and
                Conditions. We are committed to providing you with the best
                possible service and experience, and these Terms are an
                important part of ensuring that we can do so effectively. We
                appreciate your trust in Linked Lives and look forward to
                serving you.
              </p>
            </div>
          </Box>
        </Container>
      </Box>
      <WebFooter />
    </div>
  );
}

export default TermsAndConditionsPage;
