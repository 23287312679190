import mojs from "@mojs/core";
import { Check, KeyboardArrowDownRounded } from "@mui/icons-material";
import NotificationsActiveOutlined from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationsOffOutlined from "@mui/icons-material/NotificationsOffOutlined";
import PersonAddAlt1 from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveOutlined from "@mui/icons-material/PersonRemoveOutlined";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useRef, useState } from "react";
import { db, functions } from "../../firebase";

/**
 * Follow is 2 part
 *
 * 1. Favorite (displays at the top of lists)
 * 2. Notifications (on or off)
 *
 *
 * */

const FollowButton = ({ memberPrivateData, memberId, employeeId }) => {
  const [isFollowed, setIsFollowed] = useState(false);
  const [notificationOn, setNotificationOn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const wrapperRef = useRef(null);

  // Assume memberPrivateData is already fetched and contains relevant fields
  useEffect(() => {
    if (memberPrivateData && employeeId) {
      // Ensure 'helpersFollowing' and 'helpersNotifications' are arrays or default to empty arrays
      const helpersFollowing = Array.isArray(
        memberPrivateData?.helpersFollowing
      )
        ? memberPrivateData.helpersFollowing
        : [];
      const helpersNotifications = Array.isArray(
        memberPrivateData?.helpersNotifications
      )
        ? memberPrivateData.helpersNotifications
        : [];

      setIsFollowed(helpersFollowing.includes(employeeId));
      setNotificationOn(helpersNotifications.includes(employeeId));
    }
  }, [
    memberPrivateData?.helpersFollowing,
    memberPrivateData?.helpersNotifications,
  ]); // Dependency array to re-check whenever memberPrivateData or employeeId changes

  console.log("FollowButton - memberPrivateData: ", memberPrivateData);

  if (Object.keys(memberPrivateData).length === 0) return;

  const createAnimation = (parentElement) => {
    const RADIUS = 30;

    const createBurst = (offsetX) => {
      // Main burst with lines
      const burst = new mojs.Burst({
        parent: parentElement,
        left: offsetX,
        zIndex: -100,
        radius: { 20: RADIUS },
        angle: Math.random() * 360, // Random starting angle for each burst
        count: 4,
        children: {
          shape: "line",
          radius: RADIUS / 7,
          scale: { 2: 1 },
          stroke: ["#FF9C00", "#FF4561", "#4CAF50", "#448AFF", "#FFC107"],
          strokeDasharray: "100%",
          strokeDashoffset: { "-100%": "100%" },
          degreeShift: "rand(5, 15)", // Randomize shift
          duration: "rand(700, 900)",
          easing: "quad.out",
          strokeLinecap: "round",
        },
      });

      // Smaller secondary burst
      const burst2 = new mojs.Burst({
        parent: parentElement,
        left: offsetX,
        zIndex: -100,
        radius: { 20: RADIUS * 1.5 },
        angle: Math.random() * 360, // Random starting angle for secondary burst
        count: 7,
        children: {
          radius: 2.5,
          fill: ["#FF9C00", "#FF4561", "#4CAF50", "#448AFF", "#FFC107"],
          scale: { 1: 0, easing: "quad.in" },
          pathScale: [0.8, null],
          degreeShift: "rand(10, 20)",
          duration: "rand(500, 700)",
          easing: "quint.out",
        },
      });

      // Timeline to control animations
      const timeline = new mojs.Timeline({ speed: 1.5 });
      timeline.add(burst, burst2);
      return timeline;
    };

    // Create bursts from three different locations
    const burstLeft = createBurst(20); // Center location
    const burstCenter = createBurst(80); // Center location
    const burstRight = createBurst(140); // Right location

    // Play all bursts
    burstLeft.replay();
    burstCenter.replay();
    burstRight.replay();
  };

  const openDropdown = (event) => setAnchorEl(event.currentTarget);
  const closeDropdown = () => setAnchorEl(null);

  // Determine the bell icon to show based on notification status
  const getBellIcon = () => {
    if (isFollowed === false) return <PersonAddAlt1 />;
    else if (notificationOn) return <NotificationsActiveOutlined />;
    else if (!notificationOn) return <NotificationsOffOutlined />;
    return null;
  };

  const handleFollow = async (event) => {
    setAnchorEl(event.currentTarget); // Set the dropdown anchor first

    const memberPrivateDocRef = doc(db, "membersPrivate", memberId); // Reference to the current user's private document

    try {
      // If already a favorite, remove from the helpersFollowing array
      await updateDoc(memberPrivateDocRef, {
        helpersFollowing: arrayUnion(employeeId),
        // helpersNotifications: arrayUnion(employeeId),
      });
      handleUpdateEmployeeFollowers("follow");
    } catch (error) {
      console.error("Error helpersFollowing helper:", error);
    }

    createAnimation(wrapperRef.current);
  };

  const handleUnfollow = async () => {
    const memberPrivateDocRef = doc(db, "membersPrivate", memberId); // Reference to the current user's private document

    try {
      // If already a favorite, remove from the helpersFollowing array
      await updateDoc(memberPrivateDocRef, {
        helpersFollowing: arrayRemove(employeeId),
        helpersNotifications: arrayRemove(employeeId),
      });
      handleUpdateEmployeeFollowers("unfollow");
    } catch (error) {
      console.error("Error helpersFollowing helper:", error);
    }
  };

  const handleNotificationOn = async () => {
    const memberPrivateDocRef = doc(db, "membersPrivate", memberId); // Reference to the current user's private document

    try {
      // If already a favorite, remove from the helpersFollowing array
      await updateDoc(memberPrivateDocRef, {
        helpersNotifications: arrayUnion(employeeId),
      });
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  const handleNotificationOff = async () => {
    const memberPrivateDocRef = doc(db, "membersPrivate", memberId); // Reference to the current user's private document

    try {
      // If already a favorite, remove from the helpersFollowing array
      await updateDoc(memberPrivateDocRef, {
        helpersNotifications: arrayRemove(employeeId),
      });
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  const handleUpdateEmployeeFollowers = async (action = "follow") => {
    const handleFollowAction = httpsCallable(functions, "handleFollowAction");

    try {
      const result = await handleFollowAction({ employeeId, action });
      console.log(result.data.message);
    } catch (error) {
      console.error("Error calling handleFollowAction:", error);
    }
  };

  console.log("FollowButton - memberPrivateData: ", memberPrivateData);

  // Menu options data structure
  const menuOptions = [
    {
      label: "Notifications on",
      icon: <NotificationsActiveOutlined />,
      action: handleNotificationOn,
      isSelected: notificationOn,
    },
    {
      label: "Notifications off",
      icon: <NotificationsOffOutlined />,
      action: handleNotificationOff,
      isSelected: !notificationOn,
    },
    {
      label: "Unfollow",
      icon: <PersonRemoveOutlined />,
      action: handleUnfollow,
      isSelected: false, // Unfollow is not tied to notification status
    },
  ];

  return (
    <div
      ref={wrapperRef}
      style={{ position: "relative", display: "inline-block" }}
    >
      <Button
        onClick={isFollowed ? openDropdown : handleFollow}
        variant={isFollowed ? "outlined" : "contained"}
        sx={{
          display: "flex",

          alignItems: "center",
          position: "relative", // Ensure the button is positioned
          zIndex: 100, // Higher zIndex to stay above animations
          justifyContent: "space-between",
          textTransform: "none",
          minWidth: "100px",
          transition: "width 0.3s ease",
          backgroundColor: isFollowed ? "#f5f5f5" : "hsl(190,100%,30%)",
          border: isFollowed
            ? "1px solid #f5f5f5"
            : "1px solid hsl(190,100%,30%)",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "32px",
            overflow: "hidden",
            transition: "width 0.3s ease",
          }}
        >
          {getBellIcon()}
        </div>
        <Typography variant="body1" fontWeight={"500"}>
          {isFollowed ? "Following" : "Follow"}
        </Typography>
        <div
          style={{
            width: isFollowed ? "32px" : "0px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            transition: "width 0.3s ease",
          }}
        >
          <KeyboardArrowDownRounded />
        </div>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeDropdown}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              option.action();
              closeDropdown();
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.label} sx={{ mr: 2 }} />
            {option.isSelected && <Check />}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FollowButton;
