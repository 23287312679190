import {
  Box,
  Button,
  Card,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  Timestamp,
  where,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import VisitPostCard from "./VisitPostCard";

const VisitReviewList = ({
  START_LIMIT = 5,
  employeeId = undefined,
  STAR_MIN = 5,
  SHOW_ALL = false,
  spacing = "normal",
  maxWidth = "600px",
  userRole = "member",
  onClick = () => {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true); // Loading state
  const [hasMore, setHasMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [visitsData, setVisitsData] = useState([]);

  // Add a ref for the loading trigger div
  const observerRef = useRef(null);

  const fetchMore = async () => {
    if (!lastVisible || !hasMore || loading) return;

    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 200));

    const nextQuery = [
      collection(db, "visits"),
      orderBy("start", "desc"),
      startAfter(lastVisible),
      limit(FETCH_LIMIT),
    ];

    if (SHOW_ALL) {
      nextQuery.push(where("start", "<=", Timestamp.now()));
      nextQuery.push(where("status", "==", "confirmed"));
    } else {
      // where("rating", "in", ratingArray),
      nextQuery.push(where("rating", "==", 5));
    }

    if (employeeId) {
      nextQuery.push(where("employeeArr", "array-contains", employeeId));
    }

    const q = query(...nextQuery);

    getDocs(q)
      .then((snapshot) => {
        const newReviews = [];
        snapshot.forEach((doc) => {
          newReviews.push({ id: doc.id, ...doc.data() });
        });

        if (newReviews.length > 0) {
          setVisitsData((prev) => [...prev, ...newReviews]);
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }

        setHasMore(newReviews.length === FETCH_LIMIT);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching more reviews:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          fetchMore();
        }
      },
      {
        rootMargin: "200px", // Load 200px before the end of the list is in view
      }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [loading, hasMore, fetchMore]);

  const FETCH_LIMIT = 3;

  // Generate the array of ratings based on STAR_MIN (e.g., if STAR_MIN is 3, generate [3, 4, 5])
  const ratingArray = Array.from(
    { length: 6 - STAR_MIN },
    (_, i) => i + STAR_MIN
  );

  useEffect(() => {
    const visitQuery = [
      collection(db, "visits"),
      orderBy("start", "desc"),
      limit(START_LIMIT),
    ];

    if (SHOW_ALL) {
      visitQuery.push(where("start", "<=", Timestamp.now()));
      visitQuery.push(where("status", "==", "confirmed"));
    } else {
      // where("rating", "in", ratingArray),
      visitQuery.push(where("rating", "==", 5));
    }

    // If employeeId is defined, add an array-contains query for employeeIds
    if (employeeId) {
      visitQuery.push(where("employeeArr", "array-contains", employeeId));
    }

    const q = query(...visitQuery);

    const fetchData = async () => {
      // await new Promise((resolve) => setTimeout(resolve, 1000));

      const snapshot = await getDocs(q);
      const fetchedReviews = [];
      snapshot.forEach((doc) => {
        fetchedReviews.push({ id: doc.id, ...doc.data() });
      });
      setVisitsData(fetchedReviews);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]); // Set the last visible document
      setHasMore(fetchedReviews.length > 0); // Set hasMore to true if results are fetched
      setLoading(false);
    };

    fetchData();
  }, [employeeId]);

  const VisitPostCardSkeleton = () => {
    return (
      <Card
        elevation={0}
        variant="outlined"
        sx={{
          boxShadow: "0px 8px 8px 0px rgba(0,0,0,0.1)",
          width: "100%",
          height: "100%",
          mb: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
          boxSizing: "border-box",
        }}
      >
        {/* Avatar and Name */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Skeleton variant="circular" width={60} height={60} />
          <Box>
            <Skeleton variant="text" width={60} height={24} />
            <Skeleton variant="text" width={120} height={18} />
          </Box>
        </Box>

        {/* Description */}
        <Box>
          <Skeleton variant="text" width="90%" height={20} />
          <Skeleton variant="text" width="85%" height={20} />
          <Skeleton variant="text" width="80%" height={20} />
        </Box>

        {/* Employees List */}
        <Box sx={{ display: "flex", ml: "auto", gap: 1 }}>
          <Skeleton variant="circular" width={50} height={50} />
          <Box>
            <Skeleton variant="text" width={60} height={24} />
            <Skeleton variant="text" width={80} height={18} />
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        width: "100%",
        maxWidth: maxWidth,
        mx: { xs: 0, sm: "auto" },
      }}
    >
      {visitsData.length > 0 &&
        visitsData
          .sort((a, b) => b.start.seconds - a.start.seconds)
          .map((visit, index) => (
            <VisitPostCard
              key={visit.id}
              visit={visit}
              onClick={onClick}
              STAR_MIN={STAR_MIN}
            />
          ))}
      {/* Skeleton loaders displayed when loading is true */}
      {loading &&
        Array.from(new Array(3)).map((_, index) => (
          <VisitPostCardSkeleton key={index} />
        ))}
      <Box ref={observerRef} sx={{ height: "1px", width: "100%", mt: 3 }} />
      {visitsData.length === 0 && !loading && (
        <Typography
          align="center"
          color="text.secondary"
          justifyContent={"center"}
          sx={{
            px: 10,
            py: 3,
            my: 1,
            mx: "auto",
            borderRadius: "15px",
            border: "1px solid rgba(0,0,0,0.23)",
          }}
        >
          No reviews
        </Typography>
      )}
      {hasMore && (
        <Button
          variant="contained"
          size="large"
          color="success"
          onClick={() => fetchMore()}
          sx={{
            height: "60px",
            width: { xs: "100%", sm: "250px" },
            mx: "auto",
            textTransform: "none",
            my: 3,
          }}
        >
          Load more
        </Button>
      )}
    </Box>
  );
};

export default VisitReviewList;
