import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, ButtonBase, Typography } from "@mui/material";

import { KeyboardArrowLeft } from "@mui/icons-material";
import { sendSmsCode } from "../../services/authServices";
import SmsVerification from "../login/SmsVerification";

const SmsView = ({ handleClose, setView, email, showBack = false }) => {
  const [smsStatus, setSmsStatus] = useState(null);
  const [smsError, setSmsError] = useState("");

  const navigate = useNavigate();

  const handleSendSmsCode = async () => {
    setSmsStatus("sending"); // Set status to 'sending' before the operation
    try {
      console.log("sending code");
      await sendSmsCode({ email });
      setSmsStatus("success"); // Set status to 'success' after successful operation
      setSmsError("");
    } catch (error) {
      console.error("Error sending SMS: ", error);
      setSmsStatus("error"); // Set status to 'error' in case of failure
      setSmsError(
        error.message ||
          "An error occurred while sending the verification code."
      );
    }
  };

  useEffect(() => {
    handleSendSmsCode();
  }, []);

  return (
    <Box>
      {showBack && (
        <ButtonBase
          onClick={() => setView("LOGIN")}
          sx={{ color: "text.secondary", my: 1 }}
        >
          <KeyboardArrowLeft /> Back
        </ButtonBase>
      )}
      <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
        Enter code
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: "1em" }}
      >
        Check your phone for your 4-digit login code.
      </Typography>
      <SmsVerification
        onBack={() => setView("LOGIN")}
        navigate={navigate}
        onSendSmsCode={handleSendSmsCode}
        email={email}
        smsStatus={smsStatus}
        smsError={smsError}
        showBack={false}
      />
    </Box>
  );
};

export default SmsView;
