// Spinner.js
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Spinner = ({ primary, secondary }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
    spacing={2}
  >
    {primary && <Typography variant="h6" py={2} gutterBottom>{primary}</Typography>}
    <CircularProgress size="60px" />
    {secondary && <Typography variant="body2" py={2} color="textSecondary">{secondary}</Typography>}
  </Box>
);

export default Spinner;
